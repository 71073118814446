import { useActivePlannerId, useServices } from '@/hooks';
import { CourseSectionListViewModel } from '@/viewmodels';
import { Box, Button, List, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { CourseSectionListItem } from './CourseSectionListItem';

export interface PersonalCourseSectionListProps {
  sx?: SxProps;
  className?: string;
  viewModel: CourseSectionListViewModel;
  canManageConnectedApps: boolean;
  editCourse: (id: string) => void;
  canEditCourse?: boolean;
}

export const CourseSectionList = observer(
  ({
    sx,
    className,
    viewModel,
    editCourse,
    canManageConnectedApps,
    canEditCourse = true
  }: PersonalCourseSectionListProps) => {
    const { localization, route } = useServices();
    const plannerId = useActivePlannerId();
    const strings = localization.localizedStrings.courseSection.lists;

    const navigate = useNavigate();

    function showManageConnectedApps() {
      const destination = route.resolvePlannerSettingsLocation('connected-apps', plannerId);
      navigate(destination);
    }

    return (
      <Box sx={sx} className={className}>
        <Stack width="100%">
          {viewModel.courseSections.length > 0 && (
            <Box width="100%">
              <List>
                {viewModel.courseSections.map((course) => (
                  <Fragment key={course.courseSection!.id}>
                    <CourseSectionListItem
                      courseSection={course}
                      editCourse={(cs) => editCourse(cs.courseSection!.id)}
                      deleteCourse={(courseSection, cancelWork) =>
                        void viewModel.deleteCourseSection(courseSection, cancelWork)
                      }
                      canEdit={viewModel.canEditCourseSections}
                    />
                  </Fragment>
                ))}
              </List>
            </Box>
          )}

          {viewModel.courseSections.length === 0 && (
            <Typography p={3} fontStyle="italic" color="textSecondary">
              {strings.emptyIndicatorTitle}
            </Typography>
          )}

          {canManageConnectedApps &&
            (viewModel.canEditCourseSections && canEditCourse ? (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column-reverse', sm: 'row' },
                  px: 2,
                  pb: 1
                }}
              >
                <Button sx={{ mx: { xs: 2, sm: 0 } }} onClick={showManageConnectedApps}>
                  {strings.manageClassroomCourseSections}
                </Button>
              </Box>
            ) : (
              !viewModel.canEditCourseSections && (
                <Typography sx={{ px: 2, pb: 1 }} variant="subtitle2" color="textSecondary">
                  {strings.readOnlyLabel}
                </Typography>
              )
            ))}
        </Stack>
      </Box>
    );
  }
);
