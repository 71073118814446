import { Collapse, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import LocalizedStrings from 'strings';

export interface ScheduleCycleDetailsScheduleMultipleWeeksOptionsProps {
  sx?: SxProps;
  className?: string;
  cycleDayCount: number;
  onCycleDayCountValueChanged: (value: number) => void;
  canEditFirstCycleDay: boolean;
  firstCycleDay: number;
  onFirstCycleDayValueChanged: (value: number) => void;
  isReadOnly?: boolean;
}

export const ScheduleCycleDetailsScheduleMultipleWeeksOptions = observer(
  ({
    sx,
    className,
    cycleDayCount,
    onCycleDayCountValueChanged,
    canEditFirstCycleDay,
    firstCycleDay,
    onFirstCycleDayValueChanged,
    isReadOnly
  }: ScheduleCycleDetailsScheduleMultipleWeeksOptionsProps) => {
    const [numberOfWeeks, setNumberOfWeeks] = useState<number | undefined>(cycleDayCount / 7);

    useEffect(() => {
      if (numberOfWeeks != null) {
        onCycleDayCountValueChanged(numberOfWeeks * 7);
      }
    }, [numberOfWeeks]);

    return (
      <Stack sx={sx} className={className} spacing={2}>
        <TextField
          label={LocalizedStrings.scheduleCycle.create.multipleWeeksCountLabel()}
          value={numberOfWeeks ?? ''}
          type="number"
          inputProps={{ type: 'number', inputMode: 'numeric', pattern: 'd*' }}
          size="small"
          disabled={isReadOnly}
          onChange={(e) => {
            if (e.target.value === '') {
              setNumberOfWeeks(undefined);
              return;
            }

            const value = +e.target.value;
            if (isNaN(value)) {
              return;
            }

            if (value < 1) {
              setNumberOfWeeks(1);
            } else {
              setNumberOfWeeks(value);
            }
          }}
          onBlur={() => {
            if ((numberOfWeeks ?? 0) <= 0) {
              setNumberOfWeeks(1);
            }
          }}
        />

        {canEditFirstCycleDay && (
          <Collapse in={cycleDayCount / 7 > 1}>
            <FormControl fullWidth size="small" disabled={isReadOnly}>
              <InputLabel id="first-week-select-label">
                {LocalizedStrings.scheduleCycle.create.multipleWeeksStartWeekLabel()}
              </InputLabel>

              <Select
                labelId="first-week-select-label"
                value={firstCycleDay}
                label={LocalizedStrings.scheduleCycle.create.multipleWeeksStartWeekLabel()}
                onChange={(e) => onFirstCycleDayValueChanged(e.target.value as number)}
              >
                {Array(cycleDayCount / 7)
                  .fill(null)
                  .map((_, index) => {
                    const value = index * 7 + 1;

                    return (
                      <MenuItem key={`start-day-option-${value}`} value={value}>
                        {index + 1}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Collapse>
        )}
      </Stack>
    );
  }
);
