import { WorkIconInfo } from '@/models';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ColorIndicator, WorkIcon } from '../../lists';

export interface PlanningWorkEditWorkInfoProps {
  sx?: SxProps;
  className?: string;
  work: Work;
  color: string;
  icon: WorkIconInfo | undefined;
}

export const PlanningWorkEditWorkInfo = observer(
  ({ sx, className, color, work, icon }: PlanningWorkEditWorkInfoProps) => {
    return (
      <Stack sx={sx} className={className} direction="row" spacing={1} overflow="hidden">
        <ColorIndicator color={color} />
        <WorkIcon size={32} icon={icon} />

        <Stack flex={1} overflow="hidden">
          <Typography variant="body1" fontWeight="600" noWrap>
            {work.title}
          </Typography>

          <Typography variant="body2" noWrap>
            {work.description?.text ?? LocalizedStrings.plannedWork.edit.noDescription()}
          </Typography>
        </Stack>
      </Stack>
    );
  }
);
