import {
  AttachmentInfo,
  EditableAttachmentInfo,
  EditableWork,
  TimeOfDay,
  WorkIconInfo,
  urlForExternalSourceBadge,
  urlForWorkIconFromWork
} from '@/models';
import { ServiceContainer } from '@/providers';
import { AnalyticsService, LocalizationService } from '@/services';
import {
  LoadableState,
  PlannerCalendarStore,
  PlannerDataStore,
  PlannerDetailedCourseSectionsLoadable,
  WorkDataStore,
  WorkIconsLoadable,
  mergeLoadableStates
} from '@/stores';
import { isError } from '@/utils';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSection } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_pb';
import { ImportanceLevel } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/importance_level_pb';
import { RichText } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/rich_text_pb';
import { TextFormat } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/text_format_pb';
import { WorkIcon } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_icon_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { WorkStatus } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_status_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import {
  AppBaseUpdatableDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';

export interface WorkEditViewModel extends UpdatableDialogViewModel {
  readonly isApplying: boolean;
  readonly hasChanges: boolean;
  readonly allCourseSections: CourseSectionDetails[];
  readonly workIcons: WorkIconInfo[];
  title: string;
  readonly icon: WorkIcon;
  description: string | undefined;
  readonly attachments: EditableAttachmentInfo[];
  readonly courseSection: CourseSection | undefined;
  importanceLevel: ImportanceLevel;
  dueDate?: Date;
  isAllDay: boolean;
  readonly isNewWork: boolean;

  setCourseSection(courseSectionId: string | undefined): void;
  setWorkIcon(iconId: string): void;
  addAttachments(attachments: AttachmentInfo[]): void;
  getDatesWithOccurrenceForCourseSection(fromDate: Date, toDate: Date): Date[];
  getCourseSectionOccurrencesStartTimeForDate(date: Date): TimeOfDay[];
  save(): Promise<void>;
  cancelWork(): Promise<boolean>;
}

export class AppWorkEditViewModel extends AppBaseUpdatableDialogViewModel implements WorkEditViewModel {
  @observable private _state: LoadableState = 'pending';
  @observable private _isApplying = false;
  @observable private _editableWork: EditableWork | undefined;

  private readonly _calendarStore: PlannerCalendarStore;
  private _saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.save());
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.dismiss()
  );

  @computed
  private get editableWork(): EditableWork {
    return this._editableWork!;
  }

  constructor(
    private readonly _workId: string | undefined,
    private readonly _newWorkDefaultValues: PartialMessage<Work> | undefined,
    private readonly _plannerId: string,
    private readonly _onSuccess: (work: Work) => Promise<void>,
    onCancel: () => Promise<void>,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _analytics: AnalyticsService = ServiceContainer.services.analytics,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore
  ) {
    super(localization, onCancel, true);
    this._calendarStore = _plannerStore.getCalendarStore(_plannerId);
    makeObservable(this);
  }

  @computed
  private get courseSectionsById(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get workIconsLoadable(): WorkIconsLoadable {
    return this._workStore.workIcons;
  }

  @computed
  get isNewWork() {
    return this._workId == null;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this.isApplying;
    // We always want the save button enabled for a new task (except while saving obviously).
    this._saveButtonConfig.isEnabled = !this.isApplying && (this._workId == null || this.hasChanges);
    this._saveButtonConfig.showLoading = this.isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get state(): LoadableState {
    return mergeLoadableStates([this._state, this.courseSectionsById.state, this.workIconsLoadable.state]);
  }

  @computed
  get hasData(): boolean {
    return this._editableWork != null && this.courseSectionsById.hasData && this.workIconsLoadable.hasData;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get isSubmitting(): boolean {
    return this.isApplying;
  }

  @computed
  get hasChanges(): boolean {
    return this.editableWork.hasChanges;
  }

  @computed
  get title(): string {
    return this.editableWork.title;
  }

  set title(value: string) {
    this.editableWork.title = value;
  }

  @computed
  get icon(): WorkIcon {
    const workIcons = this.workIconsLoadable.data;
    const defaultIcon = workIcons.iconsById.get(workIcons.defaultIconId)!;
    return workIcons.iconsById.get(this.editableWork.iconId) ?? defaultIcon;
  }

  @computed
  get description(): string | undefined {
    return this.editableWork.description?.text;
  }

  set description(value: string | undefined) {
    this.editableWork.description =
      value != null ? new RichText({ text: value, format: TextFormat.PLAIN_TEXT }) : undefined;
  }

  @computed
  get attachments(): EditableAttachmentInfo[] {
    return this.editableWork.attachmentsInfos.filter((a) => !a.markedAsDeleted);
  }

  @computed
  get allCourseSections(): CourseSectionDetails[] {
    return this.courseSectionsById.values.slice().sort(
      (cs1, cs2) =>
        cs1.courseSection!.title.localeCompare(cs2.courseSection!.title, this._localization.currentLocale, {
          sensitivity: 'base'
        }) ||
        cs1.courseSection!.section.localeCompare(cs1.courseSection!.section, this._localization.currentLocale, {
          sensitivity: 'base'
        })
    );
  }

  @computed
  get workIcons(): WorkIconInfo[] {
    return Array.from(this.workIconsLoadable.data.iconsById.values() ?? [])
      .filter((icon) => !icon.isHidden)
      .map((icon) => ({
        id: icon.iconId,
        title: icon.iconName,
        lightUrl: urlForWorkIconFromWork(icon, this.editableWork.updatedModel, 'light'),
        darkUrl: urlForWorkIconFromWork(icon, this.editableWork.updatedModel, 'dark'),
        externalBadgeUrl: urlForExternalSourceBadge(
          this.editableWork.externalSource?.sourceName,
          this.workIconsLoadable.data
        )
      }));
  }

  @computed
  get courseSection(): CourseSection | undefined {
    if (this.editableWork.courseSectionId == null) {
      return undefined;
    }

    return this.courseSectionsById.data.get(this.editableWork.courseSectionId)?.courseSection;
  }

  @computed
  get importanceLevel(): ImportanceLevel {
    return this.editableWork.importance;
  }

  set importanceLevel(value: ImportanceLevel) {
    this.editableWork.importance = value;
  }

  @computed
  get dueDate(): Date | undefined {
    return this.editableWork.dueDate;
  }

  set dueDate(value: Date | undefined) {
    this.editableWork.dueDate = value;
  }

  @computed
  get isAllDay(): boolean {
    return this.editableWork.isAllDay;
  }

  set isAllDay(value: boolean) {
    this.editableWork.isAllDay = value;
  }

  setWorkIcon(iconId: string) {
    this.editableWork.iconId = iconId;
  }

  setCourseSection(courseSectionId: string | undefined) {
    this.editableWork.courseSectionId = courseSectionId ?? '';
  }

  addAttachments(attachments: AttachmentInfo[]) {
    attachments.forEach((a) => this.editableWork.addAttachment(a));
  }

  getDatesWithOccurrenceForCourseSection(fromDate: Date, toDate: Date): Date[] {
    return this._calendarStore
      .getOccurrencesForCourseSection(this.editableWork.courseSectionId ?? '', fromDate, toDate)
      .map((o) => o.date);
  }

  getCourseSectionOccurrencesStartTimeForDate(date: Date): TimeOfDay[] {
    return this._calendarStore.getCourseSectionOccurrencesStartTimeForDate(
      this.editableWork.courseSectionId ?? '',
      date
    );
  }

  @action
  async save() {
    if (this.isApplying || (!this.hasChanges && this._workId != null)) {
      return;
    }

    try {
      this._error = undefined;
      this._isApplying = true;

      const isCreating = this._workId == null;
      const updatedWork = this.editableWork.updatedModel;

      const result = await (isCreating
        ? this._workStore.createWork(updatedWork)
        : this._workStore.updateWork(updatedWork));

      if (isCreating) {
        this._analytics.logAdConversionOnce('task-created');
      }

      await this._plannerStore.fetchPlannerContents(this._plannerId);

      runInAction(() => (this._isApplying = false));
      await this._onSuccess(result);
    } catch (e) {
      runInAction(() => {
        this._error = (e as Error).message;
        this._isApplying = false;
      });
    }
  }

  async cancelWork(): Promise<boolean> {
    if (this.isApplying || this._workId == null) {
      return false;
    }

    try {
      this._error = undefined;
      this._isApplying = true;

      await this._workStore.setWorkStatus(this._workId, WorkStatus.CANCELLED, this.editableWork.initialModel.syncToken);
      void this._plannerStore.fetchPlannerContents(this._plannerId);
      runInAction(() => (this._isApplying = false));
      return true;
    } catch (e) {
      runInAction(() => {
        this._error = (e as Error).message;
        this._isApplying = false;
      });
      return false;
    }
  }

  async reloadData() {
    await this.loadData();
  }

  private async loadData() {
    runInAction(() => (this._state = 'pending'));

    try {
      await Promise.all([this.courseSectionsById.fetch(false), this.workIconsLoadable.fetch(false)]);

      if (isError(this.workIconsLoadable.state)) {
        throw new Error("Couldn't load work icons");
      }

      let work: Work | undefined;
      const defaultIconId = this.workIconsLoadable.data.defaultIconId;

      if (this._workId == null) {
        work = undefined;
      } else {
        const workLoadable = this._workStore.getWorkLoadable(this._workId);
        await workLoadable.fetch(true);

        if (workLoadable.hasData) {
          work = workLoadable.data;
        }
      }

      runInAction(() => {
        this._editableWork = new EditableWork(this._plannerId, defaultIconId, this._newWorkDefaultValues, work);
        this._state = 'fulfilled';
      });
    } catch (e) {
      console.error(e);
      runInAction(() => (this._state = e as Error));
    }
  }
}
