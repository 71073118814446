import { CloseRounded, SearchRounded } from '@mui/icons-material';
import { Card, IconButton, InputAdornment, Stack, TextField, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Observer, observer } from 'mobx-react-lite';
import { MouseEvent, ReactNode } from 'react';
import LocalizedStrings from 'strings';
import { RequiresTodaySchoolsSubscriptionButton, RequiresTodaySchoolsSubscriptionIconButton } from '../../utils';

export interface AdminContentCardAction {
  label: { case: 'text'; value: string; icon?: ReactNode } | { case: 'icon'; value: ReactNode };
  onPress: (e: MouseEvent<HTMLButtonElement>) => void;
  requiresActiveSubscription?: boolean;
  isHidden?: boolean;
}

export interface AdminContentCardProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  searchValue?: string;
  onSearch?: (value: string) => void;
  children: ReactNode;
  actions?: AdminContentCardAction[];
}

export const AdminContentCard = observer(
  ({ sx, className, onSearch, searchValue, actions = [], children, title, subtitle }: AdminContentCardProps) => {
    const hasSearch = searchValue != null && onSearch != null;
    const strings = LocalizedStrings.admin;
    const theme = useTheme();

    const visibleActions = actions.filter((a) => a.isHidden !== true);

    return (
      <Card
        sx={{ ...sx, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
        className={className}
        elevation={1}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          pt={hasSearch ? 1.5 : 1}
          px={2}
          alignItems={{ xs: 'stretch', sm: 'center' }}
          spacing={1}
        >
          <Stack flex={1}>
            <Typography variant="h6" fontWeight="500">
              {title}
            </Typography>
            {subtitle != null && <Typography variant="body2">{subtitle}</Typography>}
          </Stack>

          {(hasSearch || visibleActions.length > 0) && (
            <Stack direction="row" spacing={2} justifyContent="flex-end" alignItems="center">
              {renderActions(visibleActions)}

              {hasSearch && (
                <TextField
                  size="small"
                  sx={{ flex: { xs: 1, sm: undefined } }}
                  value={searchValue}
                  onChange={(e) => onSearch(e.target.value)}
                  placeholder={strings.defaultSearchPlaceholder()}
                  InputProps={{
                    sx: { minWidth: 300 },
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchRounded />
                      </InputAdornment>
                    ),
                    endAdornment:
                      searchValue.length > 0 ? (
                        <InputAdornment position="end">
                          <IconButton edge="end" color="inherit" onClick={() => onSearch('')}>
                            <CloseRounded sx={{ color: theme.palette.text.secondary }} />
                          </IconButton>
                        </InputAdornment>
                      ) : null
                  }}
                />
              )}
            </Stack>
          )}
        </Stack>

        <Observer>{() => <>{children}</>}</Observer>
      </Card>
    );
  }
);

function renderActions(actions: AdminContentCardAction[]) {
  return actions.map((action, index) => {
    switch (action.label.case) {
      case 'icon':
        return renderIconAction(`${index}`, action);
      case 'text':
        return renderTextAction(`${index}`, action, action.label.icon);
    }
  });
}

function renderIconAction(key: string, action: AdminContentCardAction) {
  return (
    <RequiresTodaySchoolsSubscriptionIconButton
      key={key}
      size="medium"
      onClick={(e: MouseEvent<HTMLButtonElement>) => action.onPress(e)}
      requiresSubscription={action.requiresActiveSubscription ?? true}
    >
      {action.label.value}
    </RequiresTodaySchoolsSubscriptionIconButton>
  );
}

function renderTextAction(key: string, action: AdminContentCardAction, icon: ReactNode | undefined) {
  return (
    <RequiresTodaySchoolsSubscriptionButton
      key={key}
      size="medium"
      onClick={(e: MouseEvent<HTMLButtonElement>) => action.onPress(e)}
      requiresSubscription={action.requiresActiveSubscription ?? true}
      startIcon={icon}
    >
      {action.label.value}
    </RequiresTodaySchoolsSubscriptionButton>
  );
}
