import { useServices } from '@/hooks';
import { AttachmentInfo } from '@/models';
import { CloseRounded } from '@mui/icons-material';
import { Avatar, Box, Card, CardActionArea, IconButton, Link, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { AttachmentKindIcon } from './AttachmentKindIcon';

export interface WorkAttachmentProps {
  sx?: SxProps;
  className?: string;

  attachment: AttachmentInfo;
  showThumbnailIfAvailable: boolean;
  onRemove?: () => void;
}

export const WorkAttachment = ({
  attachment,
  className,
  sx,
  onRemove,
  showThumbnailIfAvailable
}: WorkAttachmentProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.work.attachments;
  const theme = useTheme();

  const hasTitle = attachment.title != null && attachment.title.length > 0;
  const title = hasTitle ? attachment.title : strings.attachmentKindText(attachment.kind);
  const subtitle =
    attachment.kind === 'link' ? attachment.url : hasTitle ? strings.attachmentKindText(attachment.kind) : undefined;

  return (
    <Card sx={sx} className={className} elevation={theme.palette.mode === 'light' ? 1 : 8}>
      <CardActionArea component="div">
        <Stack direction="row" spacing={2} alignItems="center" pr={1} flex={1} overflow="hidden">
          {/* Note: Injecting Google user number does not work with that url. */}
          <Link
            href={attachment.url}
            target="_blank"
            rel="noreferrer"
            sx={{
              ...sx,
              flex: 1,
              '&:hover .attachmentTitle': { textDecoration: 'underline !important' },
              overflow: 'hidden'
            }}
            className={className}
            color="inherit"
            underline={'none'}
          >
            <Stack direction="row" spacing={2} overflow="hidden" width="100%">
              <Box
                width={75}
                display="flex"
                alignItems="center"
                justifyContent="center"
                position="relative"
                flexShrink={0}
              >
                <Avatar sx={{ backgroundColor: theme.palette.grey['600'] }}>
                  <AttachmentKindIcon
                    attachment={attachment}
                    sx={{ color: theme.palette.getContrastText(theme.palette.grey['600']) }}
                  />
                </Avatar>

                {showThumbnailIfAvailable && attachment.thumbnailUrl != null && attachment.thumbnailUrl.length > 0 && (
                  <img
                    src={attachment.thumbnailUrl}
                    alt={title}
                    style={{ width: '100%', height: '100%', position: 'absolute', objectFit: 'cover' }}
                  />
                )}
              </Box>

              <Stack spacing="2px" py={1} flex={1} overflow="hidden">
                <Typography className="attachmentTitle" noWrap variant="body1" fontWeight="500">
                  {title}
                </Typography>

                <Typography noWrap variant="body2" color="textSecondary">
                  {subtitle}
                </Typography>
              </Stack>
            </Stack>
          </Link>

          {onRemove != null && (
            <IconButton onClick={onRemove}>
              <CloseRounded />
            </IconButton>
          )}
        </Stack>
      </CardActionArea>
    </Card>
  );
};
