import { getOptionalDashConcatenation } from '@/models';
import { PlannerSideBarCourseSectionItemViewModel } from '@/viewmodels';
import { ListItemButton, ListItemText, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link, useParams } from 'react-router-dom';
import { useServices } from '../../../hooks';
import { ColorIndicator } from '../../lists';

export interface MainScreenPlannerSideBarCourseProps {
  sx?: SxProps;
  className?: string;
  course: PlannerSideBarCourseSectionItemViewModel;
  isCompact: boolean;
  close: () => void;
}

export const MainScreenPlannerSideBarCourse = observer(
  ({ sx, course, className, close }: MainScreenPlannerSideBarCourseProps) => {
    const { route } = useServices();
    const theme = useTheme();

    const params = useParams();
    const activeCourseSectionId: string = params.courseSectionId ?? '';
    const isActive = course.id === activeCourseSectionId;

    return (
      <Tooltip title={getOptionalDashConcatenation(course.courseTitle, course.section)} placement="right" arrow>
        <ListItemButton
          sx={{
            ...sx,
            borderRadius: 1,
            px: 1,
            [':hover']: {
              backgroundColor: !isActive ? theme.palette.action.focus : 'transparent'
            }
          }}
          className={className}
          component={Link}
          to={route.resolvePlannerCourseSectionDetailLocation(course.plannerId, course.id)}
          onClick={close}
          dense
        >
          <ColorIndicator sx={{ height: 24, mr: 1 }} color={course.color} />
          <ListItemText
            sx={{ flex: 1 }}
            primary={course.courseTitle}
            primaryTypographyProps={{ color: isActive ? 'primary' : undefined, noWrap: true }}
            secondary={course.section}
            secondaryTypographyProps={{ noWrap: true, variant: 'caption' }}
          />
        </ListItemButton>
      </Tooltip>
    );
  }
);
