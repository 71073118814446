import { ScheduleCycleCreateCopyDialogViewModel } from '@/viewmodels';
import { Checkbox, FormControlLabel, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Subheader } from '../../../../utils';

export interface ScheduleCycleCreateCopyKeepSectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: ScheduleCycleCreateCopyDialogViewModel;
}

export const ScheduleCycleCreateCopyKeepSection = observer(
  ({ sx, className, viewModel }: ScheduleCycleCreateCopyKeepSectionProps) => {
    const strings = LocalizedStrings.scheduleCycle.create;

    return (
      <Stack sx={sx} className={className} spacing={1} alignItems="flex-start">
        <Subheader>{strings.keepSectionTitle()}</Subheader>

        <FormControlLabel
          control={<Checkbox />}
          label={strings.keepTermsLabel()}
          checked={viewModel.shouldKeepTerms}
          onChange={(_, checked) => (viewModel.shouldKeepTerms = checked)}
        />
        <FormControlLabel
          control={<Checkbox />}
          label={strings.keepPeriodSchedulesLabel()}
          checked={viewModel.shouldKeepPeriodSchedules}
          onChange={(_, checked) => (viewModel.shouldKeepPeriodSchedules = checked)}
        />
        <Stack spacing={0.1}>
          <FormControlLabel
            control={<Checkbox />}
            label={strings.keepSpecialDaysLabel()}
            checked={viewModel.shouldKeepSpecialDays}
            onChange={(_, checked) => (viewModel.shouldKeepSpecialDays = checked)}
          />
          <FormControlLabel
            sx={{ pl: 2 }}
            disabled={!viewModel.shouldKeepSpecialDays}
            control={<Checkbox />}
            label={strings.keepSpecialDayOccurrencesLabel()}
            checked={viewModel.shouldKeepSpecialDayOccurrences}
            onChange={(_, checked) => (viewModel.shouldKeepSpecialDayOccurrences = checked)}
          />
        </Stack>
        <Stack spacing={0.1}>
          <FormControlLabel
            control={<Checkbox />}
            label={strings.keepActivitySchedulesLabel()}
            checked={viewModel.shouldKeepActivitySchedules}
            onChange={(_, checked) => (viewModel.shouldKeepActivitySchedules = checked)}
          />
          <FormControlLabel
            sx={{ pl: 2 }}
            disabled={!viewModel.shouldKeepActivitySchedules}
            control={<Checkbox />}
            label={strings.keepActivityScheduleExceptionsLabel()}
            checked={viewModel.shouldKeepActivityScheduleExceptions}
            onChange={(_, checked) => (viewModel.shouldKeepActivityScheduleExceptions = checked)}
          />
        </Stack>
      </Stack>
    );
  }
);
