import { useServices, useViewModel } from '@/hooks';
import { ScheduleCycleActivitySchedulesCoursesFilter } from '@/viewmodels';
import { ArrowDropDownRounded, SchoolRounded } from '@mui/icons-material';
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../../../lists';
import { FormPopoverHeader, UpdatablePresenter } from '../../../../utils';
import { useScheduleCycleUserDashboard } from '../../UseScheduleCycleUserDashboardHook';
import { ScheduleCycleActivitySchedulesCoursesList } from './ScheduleCycleActivitySchedulesCoursesList';
import { ScheduleCycleActivitySchedulesList } from './ScheduleCycleActivitySchedulesList';

export interface ScheduleCycleActivitySchedulesProps {
  sx?: SxProps;
  className?: string;
  filters?: ScheduleCycleActivitySchedulesCoursesFilter;
}

export const ScheduleCycleActivitySchedules = observer(
  ({ sx, className, filters }: ScheduleCycleActivitySchedulesProps) => {
    const { route } = useServices();
    const theme = useTheme();
    const { plannerId, dashboard, scheduleCycleId } = useScheduleCycleUserDashboard();
    const strings = LocalizedStrings.scheduleCycle.edit.activitySchedules;

    const [searchParams, setSearchParams] = useSearchParams();
    const courseId = searchParams.get('courseId') ?? '';

    const viewModel = useViewModel(
      (viewModels) => viewModels.createScheduleCycleActivitySchedulesViewModel(scheduleCycleId, dashboard, plannerId!),
      [plannerId, scheduleCycleId]
    );

    const classesLinkDestination =
      plannerId != null ? route.resolvePlannerSettingsLocation('courses', plannerId) : undefined;

    const showCoursesList = useMediaQuery(() => theme.breakpoints.up('sm'));
    const [coursesListPopoverRef, setCoursesListPopoverRef] = useState<HTMLDivElement | undefined>();

    return (
      <Stack sx={sx} height="100%" width="100%" className={className}>
        <UpdatablePresenter
          sx={{ height: '100%', width: '100%' }}
          viewModel={viewModel}
          renderData={() => {
            // If no courseId param is provided, we display the first course.
            const resolvedCourseId = (courseId || viewModel.courseInfos[0]?.id) ?? '';
            const selectedCourse = viewModel.getActivityScheduleInfosForCourseId(resolvedCourseId);

            return (
              <Stack height="100%" width="100%" spacing={2} direction="row" overflow="hidden" pt={2}>
                {showCoursesList && (
                  <>
                    <Stack sx={{ height: '100%', overflow: 'hidden' }}>
                      <Typography px={1} fontWeight="600">
                        {strings.classesListTitle()}
                      </Typography>
                      <ScheduleCycleActivitySchedulesCoursesList
                        sx={{ minWidth: { sm: 175, md: 250 }, flex: 1 }}
                        courses={viewModel.courseInfos}
                        selectedCourseId={courseId}
                        manageClassesLocation={!viewModel.isReadOnly ? classesLinkDestination : undefined}
                        onCourseSelect={(id) => {
                          searchParams.set('courseId', id);
                          setSearchParams(searchParams);
                        }}
                      />
                    </Stack>

                    <Divider orientation="vertical" />
                  </>
                )}

                {selectedCourse != null && (
                  <Stack
                    sx={{ height: '100%', overflow: 'hidden', maxWidth: { xs: undefined, sm: 400 }, flex: 1 }}
                    spacing={1}
                  >
                    {!showCoursesList && (
                      <Box>
                        <Typography fontWeight="600">{strings.selectedActivityClassTitle()}</Typography>
                        <Stack
                          spacing={1}
                          alignItems="center"
                          direction="row"
                          px={showCoursesList ? 1 : 2}
                          py={showCoursesList ? 0 : 2}
                          sx={{
                            cursor: !showCoursesList ? 'pointer' : undefined,
                            borderRadius: 1,
                            [':hover']: { backgroundColor: !showCoursesList ? theme.palette.action.hover : undefined }
                          }}
                          onClick={!showCoursesList ? (e) => setCoursesListPopoverRef(e.currentTarget) : undefined}
                        >
                          <ColorIndicator
                            color={selectedCourse.color}
                            sx={{ height: selectedCourse.section.length > 0 ? 40 : 24 }}
                          />
                          <Stack flex={1}>
                            <Typography fontWeight="600">{selectedCourse.title}</Typography>
                            <Typography variant="caption" color="textSecondary">
                              {selectedCourse.section}
                            </Typography>
                          </Stack>
                          <ArrowDropDownRounded fontSize="small" />
                        </Stack>

                        {coursesListPopoverRef != null && (
                          <Popover
                            open={true}
                            anchorEl={coursesListPopoverRef}
                            onClose={() => setCoursesListPopoverRef(undefined)}
                            slotProps={{ paper: { sx: { width: '100%', maxHeight: 500 } } }}
                          >
                            <Stack sx={{ height: '100%', overflow: 'hidden' }}>
                              <FormPopoverHeader title={strings.classesListTitle()} />

                              <ScheduleCycleActivitySchedulesCoursesList
                                sx={{ p: 2, flex: 1 }}
                                courses={viewModel.courseInfos}
                                selectedCourseId={courseId}
                                onCourseSelect={(id) => {
                                  searchParams.set('courseId', id);
                                  setSearchParams(searchParams);
                                  setCoursesListPopoverRef(undefined);
                                }}
                                manageClassesLocation={!viewModel.isReadOnly ? classesLinkDestination : undefined}
                              />
                            </Stack>
                          </Popover>
                        )}
                      </Box>
                    )}

                    <Typography fontWeight="600">{strings.selectedActivitySchedulesListTitle()}</Typography>

                    <ScheduleCycleActivitySchedulesList
                      sx={{ flex: 1 }}
                      activity={selectedCourse.activity}
                      activitySchedules={selectedCourse.activitySchedules}
                      removeActivitySchedule={(id) => void viewModel.removeActivitySchedule(id)}
                      filters={filters}
                      isReadOnly={!viewModel.canEditActivitySchedulesForCourseId(resolvedCourseId)}
                    />
                  </Stack>
                )}

                {!showCoursesList && selectedCourse == null && (
                  <Stack flex={1}>
                    <List>
                      <ListItem>
                        <ListItemText
                          primary={strings.noClasses()}
                          primaryTypographyProps={{ color: 'textSecondary', fontStyle: 'italic' }}
                        />
                      </ListItem>

                      {classesLinkDestination != null && (
                        <ListItemButton className="rounded" component={Link} to={classesLinkDestination}>
                          <ListItemIcon>
                            <SchoolRounded fontSize="small" />
                          </ListItemIcon>
                          <ListItemText primary={strings.manageClassesButtonTitle()} />
                        </ListItemButton>
                      )}
                    </List>
                  </Stack>
                )}
              </Stack>
            );
          }}
        />
      </Stack>
    );
  }
);
