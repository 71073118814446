import { useServices } from '@/hooks';
import { WorkDetailsDueDateViewModel } from '@/viewmodels';
import { Box, BoxProps, styled, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface WorkDetailsDueDateCountProps {
  viewModel: WorkDetailsDueDateViewModel;

  className?: string;

  /**
   * Indicates to disable the complete action
   */
}

export const WorkDetailsDueDateCount = observer(({ viewModel, className }: WorkDetailsDueDateCountProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.work.details;
  const theme = useTheme();

  const remainingDays = viewModel.remainingDays ?? 0;
  const remainingDaysColor =
    remainingDays < 0 ? theme.palette.error.main : remainingDays === 0 ? theme.palette.warning.main : undefined;

  if (!viewModel.hasDueDate) {
    return null;
  }

  return (
    <Root display="flex" alignItems="baseline" className={className}>
      {/* Why do we need two level of boxes? No clue.
            But it's the only way to align the two typography with their last baseline. */}
      <Box>
        <Box className={classes.textContainer}>
          <Typography variant="h3" className={classes.remainingDays} color={remainingDaysColor}>
            {Math.abs(remainingDays)}
          </Typography>
        </Box>
      </Box>
      {/* Why do we need two level of boxes? No clue.
            But it's the only way to align the two typography with their last baseline. */}
      <Box className={classes.remainingDaysLabelContainer}>
        <Box className={classes.textContainer}>
          <Typography variant="h6" color={remainingDaysColor ?? 'textSecondary'} className={classes.remainingDaysLabel}>
            {strings.remainingDaysFormat(remainingDays)}
          </Typography>
        </Box>
      </Box>
    </Root>
  );
});

const PREFIX = 'DueDate';
const classes = {
  textContainer: `${PREFIX}-textContainer`,
  noDueDate: `${PREFIX}-noDueDate`,
  remainingDays: `${PREFIX}-remainingDays`,
  remainingDaysLabelContainer: `${PREFIX}-remainingDaysLabelContainer`,
  remainingDaysLabel: `${PREFIX}-remainingDaysLabel`
};

const Root = styled((props: BoxProps) => <Box {...props} />)(({ theme }) => ({
  [`& .${classes.textContainer}`]: {
    display: 'inline-block'
  },

  [`& .${classes.noDueDate}`]: {
    alignSelf: 'center',
    flexGrow: 1,
    textAlign: 'center',

    [theme.breakpoints.down('sm')]: {
      alignSelf: 'baseline',
      textAlign: 'left'
    }
  },

  [`& .${classes.remainingDays}`]: {
    [theme.breakpoints.only('xs')]: {
      ...theme.typography.h4
    },

    lineHeight: 1
  },

  [`& .${classes.remainingDaysLabelContainer}`]: { flex: 1 },

  [`& .${classes.remainingDaysLabel}`]: {
    [theme.breakpoints.only('xs')]: {
      ...theme.typography.h5
    },
    marginLeft: theme.spacing(1)
  }
}));
