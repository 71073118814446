import { ProductViewModel } from '@/viewmodels';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { BookmarkRounded } from '@mui/icons-material';
import SubscriptionIcon from '@mui/icons-material/PaymentRounded';
import { LoadingButton } from '@mui/lab';
import { alpha, Card, CardProps, Stack, Typography, useTheme } from '@mui/material';
import { differenceInCalendarDays, set } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import LocalizedStrings from 'strings';
import { useOptionalActivePlannerId, useServices } from '../../hooks';
import { ScheduleCycleInfo, scheduleCycleIsPast } from '../../models';
import { ConfirmationDialogWithChoices } from '../utils';

export interface ProductProps extends CardProps {
  className?: string;
  viewModel: ProductViewModel;
}

export const Product = observer(({ className, viewModel, ...props }: ProductProps) => {
  const { dateService, userStore } = useServices();
  const plannerId = useOptionalActivePlannerId();

  const strings = LocalizedStrings.subscriptions.product;
  const theme = useTheme();

  const [isFetchingScheduleCycles, setIsFetchingScheduleCycles] = useState(false);
  const [showSchoolsToSharePicker, setShowSchoolsToSharePicker] = useState(false);
  const [showSingleSchoolToShareConfirmation, setShowSingleSchoolToShareConfirmation] = useState<{
    school: SchoolInformation;
    scheduleCycle: ScheduleCycleInfo | undefined;
  } | null>(null);

  const {
    description,
    title,
    canSubscribe,
    isApplying,
    currentSubscriptionTrialDaysRemaining,
    isCurrentSubscription,
    isCurrentSubscriptionActive,
    error
  } = viewModel;

  const showSpecialOffer = useMemo(() => {
    const specialOfferEndDate = set(new Date(), { year: 2024, month: 9, date: 15 });
    return differenceInCalendarDays(dateService.now, specialOfferEndDate) < 0;
  }, []);

  const trialLabelColor = useMemo(() => {
    if (!isCurrentSubscription) {
      return showSpecialOffer ? 'textSecondary' : 'primary';
    }

    if (!isCurrentSubscriptionActive) {
      return theme.palette.error.main;
    }

    if (currentSubscriptionTrialDaysRemaining == null) {
      return showSpecialOffer ? 'textSecondary' : 'primary';
    }

    if (currentSubscriptionTrialDaysRemaining < 0) {
      return theme.palette.error.main;
    } else if (currentSubscriptionTrialDaysRemaining === 0 || currentSubscriptionTrialDaysRemaining === 1) {
      return theme.palette.warning.main;
    }

    return showSpecialOffer ? 'textSecondary' : 'primary';
  }, [isCurrentSubscription, currentSubscriptionTrialDaysRemaining, showSpecialOffer]);

  async function onSubscribeButtonClick() {
    if (viewModel.schoolIds.length === 0) {
      await viewModel.subscribe(viewModel.schoolIds, undefined);
    } else if (viewModel.schoolIds.length === 1) {
      const school = userStore.getSchoolForId(viewModel.schoolIds[0])!;

      if (school.school!.scheduleCycleIds.length > 0) {
        await viewModel.subscribe(viewModel.schoolIds, undefined);
      } else if (plannerId != null) {
        const planner = userStore.getPlannerForId(plannerId)!;

        // If school has no schedule cycle and the planner has an active schedule cycle, we give the option to the
        // user to move the schedule to the school.
        setIsFetchingScheduleCycles(true);
        const scheduleCyclesLoadable = userStore.getScheduleCycles(planner.scheduleCycleIds);
        await scheduleCyclesLoadable.fetch(true);
        let scheduleCycle: ScheduleCycleInfo | undefined;

        if (scheduleCyclesLoadable.hasData) {
          scheduleCycle = scheduleCyclesLoadable.data.find((sc) => !scheduleCycleIsPast(sc.scheduleCycle, dateService));
        }

        setShowSingleSchoolToShareConfirmation({ school, scheduleCycle });
        setIsFetchingScheduleCycles(false);
      }
    } else {
      // Asking the user using which of their schools they want to share.
      setShowSchoolsToSharePicker(true);
    }
  }

  function onConfirmSharedSchools(ids: string[] | undefined) {
    setShowSchoolsToSharePicker(false);

    if (ids != null) {
      void viewModel.subscribe(ids, undefined);
    }
  }

  function onConfirmSharedSingleSchool(results: string[] | undefined) {
    setShowSingleSchoolToShareConfirmation(null);

    if (results != null) {
      void viewModel.subscribe(viewModel.schoolIds, results[0]);
    }
  }

  return (
    <Card
      {...props}
      className={className}
      sx={{
        ...props.sx,
        display: 'flex',
        flexDirection: 'column',
        p: 2,
        borderColor: viewModel.isCurrentSubscription ? theme.palette.primary.main : undefined,
        backgroundColor: alpha(theme.palette.background.paper, 0.4)
      }}
      variant="outlined"
    >
      <Stack spacing={2}>
        <Stack spacing={0.5}>
          <Stack direction="row" alignItems="baseline" spacing={1}>
            <Typography flex={1} variant="h6" color={isCurrentSubscription ? 'primary' : 'textPrimary'}>
              {title}
            </Typography>

            {isCurrentSubscription && (
              <Stack direction="row" alignItems="center" spacing={1}>
                <BookmarkRounded sx={{ fontSize: 'medium' }} color="primary" />

                <Typography variant="subtitle2" color="primary" fontWeight="600">
                  {strings.currentSubscription()}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Typography variant="subtitle2" color="textSecondary">
            {description}
          </Typography>
        </Stack>

        {/* Price details */}
        <Stack flex={1} spacing={2} px={1}>
          {(!isCurrentSubscription || currentSubscriptionTrialDaysRemaining != null) && (
            <Stack spacing={0.5}>
              <Typography
                color={trialLabelColor}
                variant="h5"
                fontWeight="600"
                textAlign="center"
                sx={{
                  textDecorationLine:
                    currentSubscriptionTrialDaysRemaining == null && showSpecialOffer ? 'line-through' : undefined
                }}
              >
                {currentSubscriptionTrialDaysRemaining != null
                  ? trialDaysRemainingLabel(currentSubscriptionTrialDaysRemaining, isCurrentSubscriptionActive)
                  : strings.freeTrialLabel()}
              </Typography>

              {currentSubscriptionTrialDaysRemaining == null && showSpecialOffer && (
                <Typography color="primary" variant="h5" fontWeight="600" textAlign="center">
                  {strings.freeTrialSpecialOfferLabel()}
                </Typography>
              )}

              {!isCurrentSubscription && (
                <Typography textAlign="center" color="textSecondary" variant="caption">
                  {strings.noCreditCardRequired()}
                </Typography>
              )}
            </Stack>
          )}

          <Typography
            variant={isCurrentSubscription && currentSubscriptionTrialDaysRemaining == null ? 'h5' : 'subtitle2'}
            fontWeight="600"
            color={'textSecondary'}
            textAlign="center"
            sx={{ whiteSpace: 'pre-line' }}
          >
            {priceText(viewModel)}
          </Typography>
        </Stack>

        <Stack spacing={2}>
          {isCurrentSubscription ? (
            <LoadingButton
              variant="contained-grey"
              startIcon={<SubscriptionIcon />}
              fullWidth
              loading={isApplying}
              disabled={isApplying}
              onClick={() => void viewModel.manageSubscription()}
            >
              {strings.manageSubscription()}
            </LoadingButton>
          ) : (
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              loading={isApplying || isFetchingScheduleCycles}
              disabled={isApplying || !canSubscribe}
              onClick={() => void onSubscribeButtonClick()}
            >
              {strings.subscribeButtonLabel()}
            </LoadingButton>
          )}

          {error != null && (
            <Typography variant="caption" color="error" fontWeight="500" textAlign="center">
              {viewModel.isCurrentSubscription
                ? strings.manageSubscriptionErrorMessage(error)
                : strings.subscribeError()}
            </Typography>
          )}
        </Stack>
      </Stack>

      {showSchoolsToSharePicker && (
        <ConfirmationDialogWithChoices
          isOpen={true}
          title={title}
          message={strings.schoolSelectionMessage()}
          confirmButtonLabel={strings.schoolSelectionSubmitLabel()}
          choices={
            viewModel.schoolIds?.map((id) => ({ key: id, title: userStore.getSchoolForId(id)!.school!.name })) ?? []
          }
          initialSelectedChoicesKeys={viewModel.schoolIds}
          onSubmit={onConfirmSharedSchools}
          requiresAtLeastOneSelection
        />
      )}

      {showSingleSchoolToShareConfirmation != null && (
        <ConfirmationDialogWithChoices
          isOpen={true}
          title={title}
          message={strings.shareSchoolConfirmationMessage(showSingleSchoolToShareConfirmation.school.school!.name)}
          confirmButtonLabel={strings.schoolSelectionSubmitLabel()}
          choices={
            showSingleSchoolToShareConfirmation.scheduleCycle != null
              ? [
                  {
                    key: showSingleSchoolToShareConfirmation.scheduleCycle.scheduleCycle.id,
                    title: strings.shareSchoolConfirmationMoveScheduleOption(
                      showSingleSchoolToShareConfirmation.scheduleCycle.scheduleCycle.name
                    )
                  }
                ]
              : []
          }
          initialSelectedChoicesKeys={
            showSingleSchoolToShareConfirmation.scheduleCycle != null
              ? [showSingleSchoolToShareConfirmation.scheduleCycle.scheduleCycle.id]
              : undefined
          }
          onSubmit={onConfirmSharedSingleSchool}
        />
      )}
    </Card>
  );
});

function trialDaysRemainingLabel(daysRemaining: number, isCurrentSubscriptionActive: boolean): string {
  const strings = LocalizedStrings.subscriptions.product;

  if (!isCurrentSubscriptionActive) {
    return strings.expiredTrialSubscription();
  } else {
    return strings.trialDaysRemaining(daysRemaining);
  }
}

function priceText(viewModel: ProductViewModel): string {
  if (
    viewModel.isCurrentSubscription &&
    (viewModel.currentSubscriptionTrialDaysRemaining == null || !viewModel.isCurrentSubscriptionActive)
  ) {
    return viewModel.formattedPrice;
  } else {
    return LocalizedStrings.subscriptions.product.upcomingPriceLabel(viewModel.formattedPrice);
  }
}
