import { Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../hooks';

export interface SettingsCancelButtonProps {
  sx?: SxProps;
  className?: string;
  onClick: () => void;
  disabled: boolean;
  customTitle?: string;
}

export const SettingsCancelButton = observer(
  ({ sx, className, onClick, disabled, customTitle }: SettingsCancelButtonProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    return (
      <Button
        sx={{ ...sx, minWidth: 150 }}
        className={className}
        color="error"
        variant="contained-grey"
        onClick={() => onClick()}
        disabled={disabled}
      >
        {customTitle ?? strings.genericCancel}
      </Button>
    );
  }
);
