import { Day, UserDashboardInfo, compareTerms, getAllSchedulesTagsFromScheduleCycle } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { PlannerDataStore, ScheduleCycleDataStore, SchoolDataStore, UserDataStore } from '@/stores';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { autorun, computed, makeObservable, observable } from 'mobx';
import { AppScheduleCycleMasterScheduleGridViewModel } from './ScheduleCycleMasterScheduleGridViewModel';
import { ScheduleCyclePeriodSchedulesGridViewModel } from './ScheduleCyclePeriodSchedulesGridViewModel';
import { ScheduleCycleActivitySchedulesCoursesFilter } from './ScheduleCycleActivitySchedulesCoursesFilter';

export interface ScheduleCycleMasterScheduleViewModel {
  readonly scheduleCycleStartDay: Day;
  readonly scheduleCycleEndDay: Day;
  readonly allTerms: Term[];
  readonly allScheduleTags: string[];
  scheduleTag: string;
  getCurrentTermForId(id: string): { name: string | undefined; startDay: Day; endDay: Day };
  getGridViewModelForTermId(id: string, scheduleTag: string): ScheduleCyclePeriodSchedulesGridViewModel;
}

export class AppScheduleCycleMasterScheduleViewModel implements ScheduleCycleMasterScheduleViewModel {
  @observable private _scheduleTag = '';

  constructor(
    protected readonly _dashboard: UserDashboardInfo,
    private readonly _scheduleCycleId: string,
    private readonly _plannerId: string | undefined,
    private readonly _displayedTermId: string,
    private readonly _filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    protected readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    makeObservable(this);

    this._scheduleTag = this.allScheduleTags[0] ?? '';

    autorun(() => {
      // If the active schedule tag is removed (ex: discard draft), we reset it.
      if (!this.allScheduleTags.includes(this._scheduleTag)) {
        this._scheduleTag = this.allScheduleTags[0] ?? '';
      }
    });
  }

  @computed
  private get scheduleCycleStore(): ScheduleCycleDataStore {
    return this._userStore.getScheduleCycleStore(this._scheduleCycleId, this._dashboard);
  }

  @computed
  private get scheduleCycle(): ScheduleCycle {
    return this.scheduleCycleStore.scheduleCycle;
  }

  @computed
  get scheduleCycleStartDay(): Day {
    return this.scheduleCycle.startDay!;
  }

  @computed
  get scheduleCycleEndDay(): Day {
    return this.scheduleCycle.endDay!;
  }

  @computed
  get allTerms(): Term[] {
    return Array.from(this.scheduleCycle.terms)
      .filter((t) => !t.shouldDelete)
      .sort((a, b) => compareTerms(a, b, this._localization.currentLocale));
  }

  @computed
  get allScheduleTags(): string[] {
    return getAllSchedulesTagsFromScheduleCycle(this.scheduleCycle, this._localization.currentLocale);
  }

  @computed
  get scheduleTag(): string {
    return this._scheduleTag;
  }

  set scheduleTag(value: string) {
    this._scheduleTag = value;
  }

  getCurrentTermForId(id: string): { name: string | undefined; startDay: Day; endDay: Day } {
    if (id.length > 0) {
      const term = this.scheduleCycle.terms.filter((t) => !t.shouldDelete).find((t) => t.id === id);

      if (term != null) {
        return { name: term.name, startDay: term.startDay!, endDay: term.endDay! };
      }
    }

    return { name: undefined, startDay: this.scheduleCycle.startDay!, endDay: this.scheduleCycle.endDay! };
  }

  getGridViewModelForTermId(id: string, scheduleTag: string): ScheduleCyclePeriodSchedulesGridViewModel {
    return new AppScheduleCycleMasterScheduleGridViewModel(
      id,
      scheduleTag,
      this._dashboard,
      this._scheduleCycleId,
      this._plannerId,
      this._displayedTermId,
      this._filters,
      this._plannerStore,
      this._schoolStore,
      this._userStore,
      this._localization
    );
  }
}
