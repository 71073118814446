import { useServices } from '@/hooks';
import AddIcon from '@mui/icons-material/AddRounded';
import { Fab } from '@mui/material';
import { SxProps } from '@mui/system';
import { MouseEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CreateWorkOptionsMenu } from '../shared';

export interface CreateItemButtonProps {
  className?: string;
  sx?: SxProps;
  canPublishWork: boolean;
}

export const CreateItemButton = ({ className, sx, canPublishWork }: CreateItemButtonProps) => {
  const { localization, navigation, pasteboard } = useServices();
  const strings = localization.localizedStrings.workList;

  const location = useLocation();
  const navigate = useNavigate();

  const [createItemMenuAnchor, setCreateItemMenuAnchor] = useState<HTMLButtonElement | undefined>();

  function onCreateWorkButtonPressed(e: MouseEvent<HTMLButtonElement>) {
    setCreateItemMenuAnchor(e.currentTarget);
  }

  function showCreateWork() {
    setCreateItemMenuAnchor(undefined);
    navigation.navigateToWorkEdit(navigate, location, undefined);
  }

  function showCreateNote() {
    setCreateItemMenuAnchor(undefined);
    navigation.navigateToNoteEdit(navigate, location, undefined);
  }

  function showCreatePublishedWork() {
    setCreateItemMenuAnchor(undefined);
    navigation.navigateToPublishedWorkEdit(navigate, location, undefined);
  }

  function onPaste() {
    setCreateItemMenuAnchor(undefined);
    pasteboard.setPasteContext({});
  }

  return (
    <>
      <Fab
        sx={sx}
        size="small"
        variant="extended"
        color="primary"
        aria-label={strings.createWorkButtonAriaLabel}
        className={className}
        onClick={onCreateWorkButtonPressed}
      >
        <AddIcon sx={{ mr: 0.5 }} fontSize="small" />
        {strings.createWorkButtonAriaLabel}
      </Fab>

      <CreateWorkOptionsMenu
        open={createItemMenuAnchor != null}
        anchorEl={createItemMenuAnchor}
        onClose={() => setCreateItemMenuAnchor(undefined)}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        canPublishWork={canPublishWork}
        onCreatePersonalWorkOptionSelect={showCreateWork}
        onCreateNoteOptionSelect={showCreateNote}
        onPublishWorkOptionSelect={showCreatePublishedWork}
        onPaste={onPaste}
      />
    </>
  );
};
