import CollapseIcon from '@mui/icons-material/KeyboardDoubleArrowLeftRounded';
import { alpha, Button, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../hooks';

export interface SideBarCollapseButtonProps {
  sx?: SxProps;
  className?: string;
  onPress: () => void;
}

export const SideBarCollapseButton = observer(({ sx, className, onPress }: SideBarCollapseButtonProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.school;
  const theme = useTheme();

  return (
    <Button
      className={className}
      startIcon={<CollapseIcon />}
      onClick={onPress}
      sx={{
        ...sx,
        color: theme.palette.text.secondary,
        ['&:hover']: {
          backgroundColor: alpha(theme.palette.text.secondary, theme.palette.action.hoverOpacity)
        }
      }}
    >
      {strings.sidebarCollapse}
    </Button>
  );
});
