import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, PropsWithChildren, ReactElement, useState } from 'react';
import { ContentCard } from '../ContentCard';

export interface SettingsCardAction {
  icon: ReactElement;
  action: (event: MouseEvent<HTMLButtonElement>) => Promise<void>;
  tooltip?: string;
  showLoading?: boolean;
}

export interface SettingsCardProps extends PropsWithChildren {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  actions?: SettingsCardAction[];
  supportsFullHeight?: boolean;
  canBeCollapsed?: boolean;
  collapsedByDefault?: boolean;
  borderColor?: string;
  titleColor?: string;
}

export const SettingsCard = observer(
  ({
    sx,
    className,
    actions = [],
    title,
    subtitle,
    children,
    supportsFullHeight = false,
    canBeCollapsed = false,
    collapsedByDefault = false,
    borderColor,
    titleColor
  }: SettingsCardProps) => {
    const theme = useTheme();
    const [showActionLoading, setShowActionLoading] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(canBeCollapsed && collapsedByDefault);

    const shouldBeFullHeight = useMediaQuery(() => theme.breakpoints.only('xs'));

    async function onActionClick(action: () => Promise<void>, showLoading: boolean) {
      if (showLoading) {
        setShowActionLoading(true);
      }

      await action();
      setShowActionLoading(false);
    }

    return (
      <ContentCard
        sx={{ ...sx, height: shouldBeFullHeight && supportsFullHeight ? '100%' : undefined, overflow: 'hidden' }}
        className={className}
        forcedBorderColor={borderColor}
      >
        <Stack
          width="100%"
          overflow="hidden"
          onClick={canBeCollapsed ? () => setIsCollapsed(!isCollapsed) : undefined}
          sx={{ cursor: canBeCollapsed ? 'pointer' : 'auto' }}
        >
          <Stack
            direction="row"
            px={2}
            py={subtitle != null ? 1 : actions.length > 0 || canBeCollapsed ? 0.5 : 2}
            alignItems="center"
          >
            <Stack flex={1}>
              <Typography
                variant="body1"
                fontWeight="600"
                sx={{ userSelect: canBeCollapsed ? 'none' : undefined }}
                color={titleColor}
              >
                {title}
              </Typography>
              {subtitle != null && (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ userSelect: canBeCollapsed ? 'none' : undefined }}
                >
                  {subtitle}
                </Typography>
              )}
            </Stack>

            {actions.length > 0 && (
              <Stack direction="row" spacing={0.5}>
                {actions.map((action, index) => (
                  <Tooltip key={index} title={action.tooltip ?? ''}>
                    <IconButton
                      color="primary"
                      onClick={(e) => {
                        e.stopPropagation();
                        void onActionClick(() => action.action(e), action?.showLoading ?? false);
                      }}
                      disabled={showActionLoading}
                    >
                      {showActionLoading ? <CircularProgress size={20} /> : action.icon}
                    </IconButton>
                  </Tooltip>
                ))}
              </Stack>
            )}

            {canBeCollapsed && (
              <IconButton color="primary">{isCollapsed ? <ExpandMoreRounded /> : <ExpandLessRounded />}</IconButton>
            )}
          </Stack>
        </Stack>

        <Collapse in={!isCollapsed}>
          <Divider variant="middle" />

          <Box display="flex" flexDirection="column">
            {children}
          </Box>
        </Collapse>
      </ContentCard>
    );
  }
);
