import { Divider, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useViewModel } from '../../../hooks';
import { ScheduleCycleSpecialDays } from '../../shared/settings/schedule-cycle/special-days/ScheduleCycleSpecialDays';

export interface AdminSchoolScheduleCycleSpecialDaysPageProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  scheduleCycleId: string;
  isReadOnly: boolean;
}

export const AdminSchoolScheduleCycleSpecialDaysPage = observer(
  ({ sx, className, schoolId, scheduleCycleId, isReadOnly }: AdminSchoolScheduleCycleSpecialDaysPageProps) => {
    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createScheduleCycleSpecialDaysPageViewModel(scheduleCycleId, { kind: 'school', id: schoolId }),
      [scheduleCycleId]
    );

    return (
      <Stack sx={sx} className={className} overflow="hidden">
        <Stack width="100%" height="100%" spacing={1} sx={{ maxWidth: 1000 }} overflow="hidden">
          <Typography variant="h6">{LocalizedStrings.admin.schedule.calendarTitle()}</Typography>
          <Divider />
          <ScheduleCycleSpecialDays
            sx={{ overflow: 'hidden' }}
            viewModel={viewModel.contentViewModel}
            isReadOnly={isReadOnly}
          />
        </Stack>
      </Stack>
    );
  }
);
