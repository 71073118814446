import { Box, ChipProps, FormHelperText, Grid } from '@mui/material';
import { ReactNode } from 'react';
import { ChoicePickerOption } from './ChoicePicker';
import { ChoicePickerLabel } from './ChoicePickerLabel';
import { ChoicePickerOptionChip } from './ChoicePickerOptionChip';

export interface ChoicePickerNormalProps<T> {
  className?: string;

  /**
   * The options to display
   */
  options: ChoicePickerOption<T>[];

  /**
   * The selected option. Optional.
   */
  selectedOption?: string;

  /**
   * The label. Optional.
   */
  label?: string | ReactNode;

  /**
   * Indicates to disable the choice picker. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * Callback when clicking on an option.
   * @param option The clicked option.
   */
  onOptionClick: (option: ChoicePickerOption<T>) => void;

  /**
   * The props to pass to the option chip. Optional.
   * @param option The option for which to get the props.
   * @return MUI.ChipProps The option's chip props.
   */
  chipProps?: (option: ChoicePickerOption<T>) => ChipProps;

  helperText?: string;
}

export const ChoicePickerNormal = <T,>({
  className,
  label,
  disabled,
  options,
  onOptionClick,
  chipProps,
  selectedOption,
  helperText
}: ChoicePickerNormalProps<T>) => {
  return (
    <Box className={className}>
      {label && (
        <Box mb={1}>
          <ChoicePickerLabel label={label} />
        </Box>
      )}
      <Grid container spacing={1}>
        {options.map((option) => (
          <Grid item key={`choice-picker-option-${option.key}`}>
            <ChoicePickerOptionChip
              option={option}
              disabled={disabled}
              onOptionClick={onOptionClick}
              chipProps={chipProps}
              isSelected={option.key === selectedOption}
            />
          </Grid>
        ))}
      </Grid>

      {helperText != null && <FormHelperText sx={{ mt: 1 }}>{helperText}</FormHelperText>}
    </Box>
  );
};
