import { useServices, useViewModel } from '@/hooks';
import { Picture } from '@/models';
import { css } from '@emotion/css';
import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { Dialog, NavigationButton, UnsplashAttribution } from '../utils';
import { UnsplashDialog } from './UnsplashDialog';

interface LocationState {
  backgroundImageShowUnsplashPicker?: boolean;
}

export interface BackgroundImageDialogProps {
  isOpen: boolean;
}

export const BackgroundImageDialog = observer(({ isOpen }: BackgroundImageDialogProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.settings;

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigate();

  function close() {
    navigate(-1);
    return Promise.resolve();
  }

  const viewModel = useViewModel((viewModels) => viewModels.createBackgroundImageViewModel(close));
  const theme = useTheme();

  const buttonStyleContainer = css({
    width: 400,
    [theme.breakpoints.only('xs')]: {
      width: '100%'
    }
  });

  function showUnsplashPicker() {
    const newState: LocationState = { ...state, backgroundImageShowUnsplashPicker: true };
    navigate(location, { state: newState });
  }

  function onUnsplashPickerClose(result: Picture | undefined) {
    if (result != null) {
      viewModel.setImage(result);
    }
  }

  return (
    <>
      <Dialog
        viewModel={viewModel}
        isOpen={isOpen}
        title={strings.backgroundImageDialogTitle}
        width="md"
        renderData={() => (
          <Stack direction="column" spacing={3} alignItems="center">
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ minHeight: 300 }}>
              {viewModel.currentBackgroundImage != null && (
                <Stack direction="column" spacing={1} alignItems="center">
                  <img
                    src={viewModel.currentBackgroundImage.urls.regular}
                    alt={strings.backgroundImageDialogCurrentImageAlt}
                    style={{ height: 300, borderRadius: theme.shape.borderRadius, width: 'auto' }}
                  />

                  <UnsplashAttribution picture={viewModel.currentBackgroundImage} />
                </Stack>
              )}

              {viewModel.currentBackgroundImage == null && (
                <Typography fontStyle="italic" color="textSecondary">
                  {strings.backgroundImageDialogNoImage}
                </Typography>
              )}
            </Box>

            <Stack direction="column" spacing={2} className={buttonStyleContainer}>
              <NavigationButton
                title={strings.backgroundImageDialogSelectFromUnsplash}
                onClick={() => showUnsplashPicker()}
              />

              <Button onClick={() => viewModel.removeImage()} color="error">
                {strings.backgroundImageDialogRemove}
              </Button>
            </Stack>
          </Stack>
        )}
      />

      {(state.backgroundImageShowUnsplashPicker ?? false) && (
        <UnsplashDialog isOpen={true} onClose={onUnsplashPickerClose} />
      )}
    </>
  );
});
