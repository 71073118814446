import { useActivePlannerId, useServices } from '@/hooks';
import { WorkDetailsViewModel } from '@/viewmodels';
import { CustomActionEffect } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_action_effect_pb';
import { CustomWorkAction } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_work_action_pb';
import { Box, Button, Card, Divider, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { WorkIcon } from '../../lists';
import { WorkDetailsDueDate } from './WorkDetailsDueDate';

export interface WorkDescriptionCardProps {
  viewModel: WorkDetailsViewModel;
  className?: string;
  sx?: SxProps;
}

export const WorkDescriptionCard = observer(({ viewModel, className, sx }: WorkDescriptionCardProps) => {
  const { localization, route } = useServices();
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const plannerId = useActivePlannerId();
  const strings = localization.localizedStrings.work.details;
  const navigate = useNavigate();

  async function performCustomAction(action: CustomWorkAction) {
    switch (action.effect) {
      case CustomActionEffect.CONNECT_GOOGLE_CALENDAR:
      case CustomActionEffect.CONNECT_GOOGLE_CLASSROOM: {
        const destination = route.resolvePlannerSettingsLocation('connected-apps', plannerId);
        navigate(destination);
        break;
      }

      case CustomActionEffect.CREATE_SCHEDULE: {
        const targetLocation = route.resolvePlannerSettingsLocation('schedules', plannerId);
        navigate(targetLocation, { replace: true });
        break;
      }

      default:
        await viewModel.performCustomAction(action.effect);
        break;
    }
  }

  return (
    <Card className={className} sx={{ ...sx, p: 2 }} elevation={theme.palette.mode === 'light' ? 1 : 8}>
      <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} display="flex" alignItems="stretch">
        <Stack direction="column" spacing={1} flex={1}>
          <Stack direction="row" spacing={1} display="flex" flex={1} height="100%">
            <WorkIcon icon={viewModel.workIcon} color={viewModel.courseColor} size={40} displayCourseSectionColor />
            <Stack direction="column">
              <Stack direction="column" flex={1}>
                <Typography variant="h6">{viewModel.workTitle}</Typography>

                {viewModel.workDescription.length === 0 ? (
                  <Typography variant="body2" color="textSecondary">
                    {strings.noDescription}
                  </Typography>
                ) : (
                  <Typography variant="body2" color="textPrimary" whiteSpace="pre-line">
                    {viewModel.workDescription}
                  </Typography>
                )}
              </Stack>

              {/* Custom actions */}
              {!viewModel.isReadOnly && viewModel.customActions.length > 0 && (
                <Stack
                  direction={isExtraSmallScreen ? 'column' : 'row'}
                  rowGap={1}
                  columnGap={1}
                  paddingTop={2}
                  flexWrap="wrap"
                >
                  {viewModel.customActions.map((action) => (
                    <Button
                      key={`custom-action-${action.effect}`}
                      variant="contained-grey"
                      fullWidth={isExtraSmallScreen}
                      disabled={viewModel.isCompleted || viewModel.isCancelled}
                      onClick={() => void performCustomAction(action)}
                    >
                      {action.label}
                    </Button>
                  ))}
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Box>
          <Divider orientation={isSmallScreen ? 'horizontal' : 'vertical'} />
        </Box>

        <WorkDetailsDueDate
          sx={{ minWidth: 180 }}
          viewModel={viewModel.dueDateViewModel}
          disabled={viewModel.isApplying}
        />
      </Stack>
    </Card>
  );
});
