import { CheckRounded } from '@mui/icons-material';
import { Box, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { scaleLinear } from 'd3';
import { arc } from 'd3-shape';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../hooks';

export interface GaugeProps {
  sx?: SxProps;
  className?: string;
  size: number;
  innerRadius?: number;
  outerRadius?: number;
  value: number;
  maxValue: number;
  lineColor: (percentage: number) => string;
  showFullIcon?: boolean;
  showPercentage?: boolean;
}

export const Gauge = observer(
  ({
    sx,
    className,
    size,
    innerRadius = 0.65,
    outerRadius = 1,
    lineColor,
    maxValue,
    value,
    showPercentage = false,
    showFullIcon = false
  }: GaugeProps) => {
    const { localization } = useServices();
    const theme = useTheme();

    const percentScale = scaleLinear().domain([0, maxValue]).range([0, 1]);
    const percent = maxValue > 0 ? percentScale(value) : 0;
    const resolvedShowFullIcon = showFullIcon && percent === 1;
    const resolvedInnerRadius = resolvedShowFullIcon ? 0 : innerRadius;

    const angleScale = scaleLinear()
      .domain([0, 1])
      .range([0, Math.PI * 2])
      .clamp(true);

    const backgroundArc = arc();
    const backgroundArcPath = backgroundArc({ innerRadius, outerRadius, startAngle: 0, endAngle: Math.PI * 2 }) ?? '';

    const angle = angleScale(percent);
    const valueArc = arc().cornerRadius(1);
    const valueArcPath =
      valueArc({ innerRadius: resolvedInnerRadius, outerRadius, startAngle: 0, endAngle: angle }) ?? '';

    const formatter = new Intl.NumberFormat(localization.currentLocale, { style: 'percent' });
    const formattedPercentage = formatter.format(percent);

    const valueColor = lineColor(percent);
    const iconColor = theme.palette.getContrastText(valueColor);

    return (
      <Stack sx={{ ...sx, width: size }} className={className} spacing={0.5}>
        <Tooltip title={formattedPercentage} disableInteractive>
          <Box sx={{ width: size, height: size, position: 'relative' }}>
            <svg width="100%" height="100%" viewBox="-1 -1 2 2" style={{ overflow: 'visible', position: 'absolute' }}>
              <g>
                <path d={backgroundArcPath} fill={theme.palette.divider} />
                <path d={valueArcPath} fill={valueColor} />
              </g>
            </svg>

            {resolvedShowFullIcon && (
              <CheckRounded
                sx={{
                  position: 'absolute',
                  color: iconColor,
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: size * 0.65
                }}
              />
            )}
          </Box>
        </Tooltip>

        {showPercentage && (
          <Typography variant="caption" textAlign="center" fontWeight="500" color="textSecondary" sx={{ fontSize: 10 }}>
            {formattedPercentage}
          </Typography>
        )}
      </Stack>
    );
  }
);
