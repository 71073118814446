import { useServices } from '@/hooks';
import { WorkDetailsGradeViewModel } from '@/viewmodels';
import { GradeRounded } from '@mui/icons-material';
import { Box, Card, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { WorkDetailsCardHeader } from './WorkDetailsCardHeader';

export interface WorkDetailsGradeCardProps {
  viewModel: WorkDetailsGradeViewModel;
  className?: string;
  sx?: SxProps;
}

export const WorkDetailsGradeCard = observer(({ viewModel, className, sx }: WorkDetailsGradeCardProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.work.details;
  const theme = useTheme();
  const subtitle = useMemo(() => {
    if (!viewModel.isMarked) {
      return strings.unmarkedGradeSubtitle;
    }

    return viewModel.isGraded ? strings.gradedSubtitle : strings.notGradedSubtitle;
  }, [viewModel.isGraded, viewModel.isMarked]);

  return (
    <Card className={className} sx={{ ...sx, p: 2, pt: 1.5 }} elevation={theme.palette.mode === 'light' ? 1 : 8}>
      <WorkDetailsCardHeader icon={<GradeRounded />} title={strings.gradeSectionTitle} subtitle={subtitle} />

      {viewModel.isMarked && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <Tooltip title={viewModel.percentage != null ? `${viewModel.percentage}%` : undefined}>
            <Stack display="flex" alignItems="baseline" mt={1} direction="row" spacing={0.5}>
              <Typography variant="h5" fontWeight="600">
                {strings.gradeText(viewModel.grade)}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                {'/'}
              </Typography>
              <Typography variant="h5" color="textSecondary">
                {strings.maxPointsText(viewModel.maxPoints!)}
              </Typography>
            </Stack>
          </Tooltip>
        </Box>
      )}
    </Card>
  );
});
