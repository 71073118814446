import { useViewModel } from '@/hooks';
import { formatTermDates } from '@/models';
import { ArrowDropDownRounded, DateRangeRounded } from '@mui/icons-material';
import {
  Box,
  FormControl,
  InputLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Stack
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { ScheduleCycleActivitySchedulesCoursesFilter } from '../../../../../viewmodels';
import { FormPopoverHeader } from '../../../../utils';
import { useScheduleCycleUserDashboard } from '../../UseScheduleCycleUserDashboardHook';
import { ScheduleCyclePeriodScheduleGrid } from './ScheduleCyclePeriodScheduleGrid';

export interface ScheduleCycleMasterSchedulePageProps {
  sx?: SxProps;
  className?: string;
  filters?: ScheduleCycleActivitySchedulesCoursesFilter;
  isReadOnly?: boolean;
}

export const ScheduleCycleMasterSchedulePage = observer(
  ({ sx, className, filters, isReadOnly }: ScheduleCycleMasterSchedulePageProps) => {
    const { plannerId, dashboard, scheduleCycleId } = useScheduleCycleUserDashboard();

    const [searchParams, setSearchParams] = useSearchParams();
    const termId = searchParams.get('term') ?? '';

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createScheduleCycleMasterScheduleViewModel(dashboard, scheduleCycleId, plannerId, termId, filters),
      [scheduleCycleId, plannerId, filters, termId]
    );

    const currentTerm = viewModel.getCurrentTermForId(termId);
    const gridViewModel = useMemo(
      () => viewModel.getGridViewModelForTermId(termId, viewModel.scheduleTag),
      [viewModel, termId, viewModel.scheduleTag]
    );

    const [termPickerButtonRef, setTermPickerButtonRef] = useState<HTMLDivElement | undefined>();
    const strings = LocalizedStrings.scheduleCycle.edit.masterSchedule;

    function selectTermId(termId: string) {
      setTermPickerButtonRef(undefined);
      searchParams.set('term', termId);
      setSearchParams(searchParams);
    }

    return (
      <Stack width="100%" height="100%" sx={sx} className={className} alignItems="flex-start" spacing={0.5} pt={1}>
        <Stack direction="row" spacing={2} alignItems="center">
          <Box>
            <ListItemButton dense onClick={(e) => setTermPickerButtonRef(e.currentTarget)} className="rounded">
              {currentTerm.name != null && (
                <ListItemIcon>
                  <DateRangeRounded fontSize="small" color="primary" />
                </ListItemIcon>
              )}

              <ListItemText
                primary={currentTerm.name ?? strings.allYearTermName()}
                primaryTypographyProps={{ color: currentTerm.name != null ? 'primary' : undefined }}
                secondary={formatTermDates(currentTerm.startDay, currentTerm.endDay)}
              />
              <ArrowDropDownRounded fontSize="small" sx={{ ml: 1 }} />
            </ListItemButton>

            <Popover
              open={termPickerButtonRef != null}
              anchorEl={termPickerButtonRef}
              onClose={() => setTermPickerButtonRef(undefined)}
              slotProps={{ paper: { sx: { width: 320 } } }}
            >
              <FormPopoverHeader title={strings.termPickerTitle()} />
              <List sx={{ p: 2 }} dense>
                <ListItemButton className="rounded" onClick={() => selectTermId('')} selected={termId === ''}>
                  <ListItemText
                    primary={strings.allYearTermName()}
                    secondary={formatTermDates(viewModel.scheduleCycleStartDay, viewModel.scheduleCycleEndDay)}
                  />
                </ListItemButton>

                {viewModel.allTerms.map((term) => (
                  <ListItemButton
                    key={term.id}
                    className="rounded"
                    onClick={() => selectTermId(term.id)}
                    selected={termId === term.id}
                  >
                    <ListItemIcon>
                      <DateRangeRounded fontSize="small" />
                    </ListItemIcon>
                    <ListItemText primary={term.name} secondary={formatTermDates(term.startDay, term.endDay)} />
                  </ListItemButton>
                ))}
              </List>
            </Popover>
          </Box>

          {viewModel.allScheduleTags.filter((t) => t.length > 0).length > 0 && (
            <FormControl size="small" sx={{ width: 250 }}>
              <InputLabel id="master-schedule-schedule-tag-picker-label">{strings.scheduleTagPickerLabel()}</InputLabel>
              <Select
                label={strings.scheduleTagPickerLabel()}
                labelId="master-schedule-schedule-tag-picker-label"
                value={viewModel.scheduleTag}
                onChange={(e) => (viewModel.scheduleTag = e.target.value)}
              >
                {viewModel.allScheduleTags.map((tag) => (
                  <MenuItem value={tag} key={tag}>
                    {tag}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <Box></Box>
        </Stack>

        <ScheduleCyclePeriodScheduleGrid
          sx={{ flex: 1 }}
          viewModel={gridViewModel}
          supportsActivitySchedules
          isReadOnly={isReadOnly}
        />
      </Stack>
    );
  }
);
