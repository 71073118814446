import { AccessAlarmRounded } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PublishedWorkScheduledTimeBannerProps {
  sx?: SxProps;
  className?: string;
  scheduledTime: Date;
}

export const PublishedWorkScheduledTimeBanner = observer(
  ({ sx, className, scheduledTime }: PublishedWorkScheduledTimeBannerProps) => {
    const strings = LocalizedStrings.publishedWork.details;

    return (
      <Alert sx={sx} className={className} severity="info" icon={<AccessAlarmRounded />}>
        {strings.scheduleTimeBannerLabel(format(scheduledTime, 'PPPp'))}
      </Alert>
    );
  }
);
