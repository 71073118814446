import { UserDashboardCalendarItemState, UserDashboardCalendarMonthCalendarEventInfo } from '@/viewmodels';
import { Box, CardActionArea, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { CalendarEventIcon, ColorIndicator } from '../../../lists';
import { MultilineTooltipTitle } from '../../../utils';
import { ContextMenuHandler } from '../../ContextMenuHandler';
import { formatCalendarItemTimesLong, formatItemTime } from '../shared';

export interface UserDashboardCalendarMonthCalendarEventViewProps {
  sx?: SxProps;
  className?: string;
  event: UserDashboardCalendarMonthCalendarEventInfo;
}

export const UserDashboardCalendarMonthCalendarEventView = observer(
  ({ sx, className, event }: UserDashboardCalendarMonthCalendarEventViewProps) => {
    const theme = useTheme();
    const color = event.color || theme.palette.action.focus;
    const [showTooltip, setShowTooltip] = useState(false);

    return (
      <ContextMenuHandler actions={event.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Tooltip
            title={
              <MultilineTooltipTitle
                lines={
                  event.times != null
                    ? [event.title, formatCalendarItemTimesLong(event.times.startTime, event.times.endTime)]
                    : [event.title]
                }
              />
            }
            disableInteractive
            open={showTooltip}
            disableHoverListener
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <Box width="100%" display="flex" justifyContent="stretch" className={className} sx={sx}>
              <CardActionArea
                className={className}
                sx={{
                  ...sx,
                  borderRadius: 0.5,
                  px: 0.5,
                  py: '2px',
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: 'hidden'
                }}
                disabled={event.externalUrl == null}
                component="a"
                href={event.externalUrl}
                target="_blank"
                onContextMenu={contextMenuHandler}
              >
                <ColorIndicator color={color} isRounded sx={{ height: '100%', mr: 0.5 }} />

                <Stack direction="row" alignItems="center" spacing={1} flex={1} overflow="hidden">
                  <CalendarEventIcon size={18} />

                  <Typography fontSize={12} noWrap flex={1} color={getColorForTitle(event.state)}>
                    {event.title}
                  </Typography>

                  {event.times != null && (
                    <Typography variant="caption" color="textSecondary" noWrap>
                      {formatItemTime(event.times.startTime)}
                    </Typography>
                  )}
                </Stack>
              </CardActionArea>
            </Box>
          </Tooltip>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
