import { css } from '@emotion/css';
import { Link as MUILink, Typography } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export interface AdminTableCellLinkProps {
  style?: CSSProperties;
  className?: string;
  value: string;
  link: string;
  isExternal?: boolean;
}

export const AdminTableCellLink = observer(
  ({ style, link, isExternal = false, className, value }: AdminTableCellLinkProps) => {
    const renderText = () => <Typography color="inherit">{value}</Typography>;

    if (isExternal) {
      return (
        <MUILink
          style={{ ...style, width: '100%' }}
          className={className}
          href={link}
          target="_blank"
          rel="noreferrer"
          color="inherit"
          underline="hover"
        >
          {renderText()}
        </MUILink>
      );
    } else {
      const linkClassName = css({
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline'
        }
      });

      return (
        <RouterLink style={{ ...style, width: '100%' }} className={clsx(className, linkClassName)} to={link}>
          {renderText()}
        </RouterLink>
      );
    }
  }
);
