import { useServices } from '@/hooks';
import { UserDashboardCalendarNoteInfo } from '@/viewmodels';
import { ListItemButton, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { NoteIcon } from '../../../../lists';
import { ContextMenuHandler } from '../../../ContextMenuHandler';

export interface UserDashboardPeriodInfoPopoverNoteItemProps {
  sx?: SxProps;
  className?: string;
  note: UserDashboardCalendarNoteInfo;
}

export const UserDashboardPeriodInfoPopoverNoteItem = observer(
  ({ sx, className, note }: UserDashboardPeriodInfoPopoverNoteItemProps) => {
    const { navigation } = useServices();
    const location = useLocation();
    const navigate = useNavigate();

    function showDetails() {
      navigation.navigateToNoteEdit(navigate, location, note.id);
    }

    return (
      <ContextMenuHandler actions={note.contextMenuActions()}>
        {(contextMenuHandler) => (
          <ListItemButton
            onClick={showDetails}
            sx={{ ...sx, alignItems: 'stretch', py: 1 }}
            className={className}
            onContextMenu={contextMenuHandler}
          >
            <NoteIcon color={note.color} size={32} sx={{ mr: 1 }} />
            <ListItemText
              sx={{ my: 0 }}
              secondary={note.text}
              secondaryTypographyProps={{
                color: note.state === 'completed' ? 'textSecondary' : 'textPrimary',
                sx: { whiteSpace: 'pre-line' }
              }}
            />
          </ListItemButton>
        )}
      </ContextMenuHandler>
    );
  }
);
