import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import LocalizedStrings from 'strings';

export interface ScheduleCycleDetailsScheduleCycleDayOptionsProps {
  sx?: SxProps;
  className?: string;
  cycleDayCount: number;
  onCycleDayCountValueChanged: (value: number) => void;
  canEditFirstCycleDay: boolean;
  firstCycleDay: number;
  onFirstCycleDayValueChanged: (value: number) => void;
  isReadOnly?: boolean;
}

export const ScheduleCycleDetailsScheduleCycleDayOptions = observer(
  ({
    sx,
    className,
    cycleDayCount,
    onCycleDayCountValueChanged,
    canEditFirstCycleDay,
    firstCycleDay,
    onFirstCycleDayValueChanged,
    isReadOnly
  }: ScheduleCycleDetailsScheduleCycleDayOptionsProps) => {
    const [daysPerCycle, setDaysPerCycle] = useState<number | undefined>(cycleDayCount);

    useEffect(() => {
      if (daysPerCycle != null) {
        onCycleDayCountValueChanged(daysPerCycle);
      }
    }, [daysPerCycle]);

    return (
      <Stack sx={sx} className={className} spacing={2}>
        <TextField
          label={LocalizedStrings.scheduleCycle.create.cycleDayNumberLabel()}
          value={daysPerCycle ?? ''}
          type="number"
          inputProps={{ type: 'number', inputMode: 'numeric', pattern: 'd*' }}
          size="small"
          disabled={isReadOnly}
          onChange={(e) => {
            if (e.target.value === '') {
              setDaysPerCycle(undefined);
              return;
            }

            const value = +e.target.value;
            if (isNaN(value)) {
              return;
            }

            if (value < 1) {
              setDaysPerCycle(1);
            } else {
              setDaysPerCycle(value);
            }
          }}
          onBlur={() => {
            if ((daysPerCycle ?? 0) <= 0) {
              setDaysPerCycle(1);
            }
          }}
        />

        {canEditFirstCycleDay && (
          <FormControl fullWidth size="small" disabled={isReadOnly}>
            <InputLabel id="first-cycle-day-select-label">
              {LocalizedStrings.scheduleCycle.create.startCycleDayLabel()}
            </InputLabel>
            <Select
              labelId="first-cycle-day-select-label"
              value={firstCycleDay}
              label={LocalizedStrings.scheduleCycle.create.startCycleDayLabel()}
              onChange={(e) => onFirstCycleDayValueChanged(e.target.value as number)}
            >
              {Array(cycleDayCount)
                .fill(null)
                .map((_, index) => (
                  <MenuItem key={`start-day-option-${index + 1}`} value={index + 1}>
                    {index + 1}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </Stack>
    );
  }
);
