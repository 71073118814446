import { getOptionalDashConcatenation } from '@/models';
import { PublishedWorkDetailsViewModel } from '@/viewmodels';
import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { WorkAttachmentList } from '../../lists';
import { Subheader } from '../../utils';
import { PublishedWorkDetailsTitleCard } from './PublishedWorkDetailsTitleCard';
import { PublishedWorkScheduledTimeBanner } from './PublishedWorkScheduledTimeBanner';

export interface PublishedWorkDetailsProps {
  sx?: SxProps;
  className?: string;
  viewModel: PublishedWorkDetailsViewModel;
}

export const PublishedWorkDetails = observer(({ sx, className, viewModel }: PublishedWorkDetailsProps) => {
  return (
    <Box sx={sx} className={className}>
      {viewModel.publishedWork.scheduledPublishTime != null && (
        <PublishedWorkScheduledTimeBanner
          sx={{ mb: 1 }}
          scheduledTime={viewModel.publishedWork.scheduledPublishTime.toDate()}
        />
      )}

      <Box width="100%">
        <Grid container spacing={2}>
          <Grid xs={12}>
            {/* A PublishedWork will always have a CourseSection. */}
            <Subheader>{getOptionalDashConcatenation(viewModel.courseTitle, viewModel.section)}</Subheader>
            <PublishedWorkDetailsTitleCard
              publishedWork={viewModel.publishedWork}
              icon={viewModel.workIcon}
              courseColor={viewModel.courseColor}
            />
          </Grid>
        </Grid>
      </Box>

      {viewModel.attachments.length > 0 && (
        <Box mt={2}>
          <WorkAttachmentList attachments={viewModel.attachments} />
        </Box>
      )}
    </Box>
  );
});
