import { Snapshot } from '@buf/studyo_studyo-today-demo.bufbuild_es/studyo/today/demo/v1/resources/snapshot_pb';
import LessIcon from '@mui/icons-material/ArrowLeft';
import MoreIcon from '@mui/icons-material/ArrowRight';
import { LoadingButton } from '@mui/lab';
import { Box, Button, IconButton, List, ListItem, ListItemText, Popover, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';

export interface AdminDemoSchoolSnapshotListProps {
  sx?: SxProps;
  className?: string;
  snapshots: Snapshot[];
  onRestore: (snapshot: Snapshot, dayOffset: number) => Promise<void>;
}

export const AdminDemoSchoolSnapshotList = observer(
  ({ sx, className, snapshots, onRestore }: AdminDemoSchoolSnapshotListProps) => {
    const strings = LocalizedStrings.admin.general.manageActions.snapshots;

    const [dayOffset, setDayOffset] = useState(0);
    const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
    const [restoringSnapshot, setRestoringSnapshot] = useState<Snapshot | undefined>();
    const [isRestoring, setIsRestoring] = useState(false);

    function startRestore(element: HTMLButtonElement, snapshot: Snapshot) {
      const now = new Date();
      setDayOffset(now.getDay() - snapshot.time!.toDate().getDay());
      setRestoringSnapshot(snapshot);
      setAnchorElement(element);
    }

    function stopRestore() {
      if (!isRestoring) {
        setAnchorElement(null);
        setRestoringSnapshot(undefined);
      }
    }

    async function restore(snapshot: Snapshot, dayOffset: number) {
      setIsRestoring(true);

      try {
        await onRestore(snapshot, dayOffset);
      } finally {
        setAnchorElement(null);
        setRestoringSnapshot(undefined);
        setIsRestoring(false);
      }
    }

    return (
      <Box sx={sx} className={className}>
        {snapshots.length === 0 ? (
          <Typography variant="body2" color="textSecondary">
            {strings.noSnapshots()}
          </Typography>
        ) : (
          <List dense disablePadding>
            {snapshots.map((s) => (
              <ListItem key={`snapshot-${s.name}-in-${s.schoolId}`} disableGutters>
                <ListItemText primary={s.name} secondary={format(s.time!.toDate(), 'PPPpp')} />
                <Button
                  onClick={(e) => startRestore(e.currentTarget, s)}
                  sx={{ minWidth: 150, maxWidth: 250 }}
                  variant="contained-grey"
                  color="error"
                  disabled={isRestoring}
                >
                  {strings.restoreButton()}
                </Button>
              </ListItem>
            ))}
          </List>
        )}
        <Popover
          anchorEl={anchorElement}
          open={anchorElement != null}
          onClose={() => stopRestore()}
          anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        >
          {restoringSnapshot && (
            <Stack p={2} spacing={1} sx={{ minWidth: 300 }}>
              <Typography variant="body2" color="textSecondary">
                {strings.baseDateLabel()} {format(restoringSnapshot.time!.toDate(), 'PPPP')}
              </Typography>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography flex={1} variant="body2">
                  {strings.offsetDaysLabel(dayOffset)}
                </Typography>
                <IconButton size="small" disabled={dayOffset <= -6} onClick={() => setDayOffset(dayOffset - 1)}>
                  <LessIcon />
                </IconButton>
                <IconButton size="small" disabled={dayOffset >= 6} onClick={() => setDayOffset(dayOffset + 1)}>
                  <MoreIcon />
                </IconButton>
              </Stack>
              <LoadingButton
                variant="contained-grey"
                color="error"
                onClick={() => void restore(restoringSnapshot, dayOffset)}
                loading={isRestoring}
              >
                {strings.restoreButton()}
              </LoadingButton>
            </Stack>
          )}
        </Popover>
      </Box>
    );
  }
);
