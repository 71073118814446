import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { formatSharingCode } from '@/utils';
import { UserSharingInvitationCodeResult } from '@/viewmodels';
import { css } from '@emotion/css';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertDialog, ConfirmationDialog, Dialog, MaskedTextField } from '../../utils';

export interface UseSharingInvitationCodeDialogProps {
  isOpen: boolean;
}

export const UseSharingInvitationCodeDialog = observer(({ isOpen }: UseSharingInvitationCodeDialogProps) => {
  const { localization, route } = useServices();
  const strings = localization.localizedStrings.sharingInvitationCode;
  const plannerId = useActivePlannerId();

  const navigate = useNavigate();

  function onSuccess(result: UserSharingInvitationCodeResult) {
    const newLocation = route.resolvePlannerLocation(result.plannerId);
    navigate(newLocation);
    return Promise.resolve();
  }

  function onCancel() {
    navigate(-1);
    return Promise.resolve();
  }

  const viewModel = useViewModel((viewModels) =>
    viewModels.createUseSharingInvitationCodeViewModel(plannerId, onSuccess, onCancel)
  );

  const apply = useCallback(() => viewModel.apply(true), [viewModel]);

  return (
    <Dialog
      viewModel={viewModel}
      isOpen={isOpen}
      title={strings.useCodeDialogTitle}
      width="sm"
      onSubmit={() => void apply()}
      renderData={() => (
        <>
          <Typography mb={3}>{strings.useCodeHeader}</Typography>

          <MaskedTextField
            className={field}
            label={strings.useCodeFieldLabel}
            value={viewModel.sharingInvitationCode}
            required
            disabled={viewModel.isApplying}
            autoFocus
            onChange={(value) => (viewModel.sharingInvitationCode = value)}
            formatValue={formatSharingCode}
          />

          {viewModel.showNoMatchingPlannerAlert && (
            <AlertDialog
              isOpen={true}
              title={strings.noMatchingPlannerAlertTitle}
              message={strings.noMatchingPlannerAlertMessage}
              onSubmit={() => viewModel.dismissNoMatchingPlannerAlert()}
            />
          )}

          {viewModel.showParticipationCodeAlert && (
            <ConfirmationDialog
              isOpen={true}
              title={strings.participationCodeConfirmationAlertTitle}
              message={strings.participationCodeConfirmationAlertMessage(
                viewModel.validationResponse?.senderDisplayName
              )}
              confirmButtonLabel={strings.participationCodeConfirmationAlertConfirmButtonTitle}
              onSubmit={(result) => void viewModel.dismissParticipationCodeAlert(result)}
            />
          )}
        </>
      )}
    />
  );
});

const field = css([{ width: '100%' }]);
