import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';

export interface RadioChoicePickerOptionLabelProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  icon?: (sx: SxProps) => ReactNode;
  isSelected: boolean;
  disabled?: boolean;
}

export function RadioChoicePickerOptionLabel({
  sx,
  className,
  title,
  subtitle,
  icon,
  isSelected,
  disabled = false
}: RadioChoicePickerOptionLabelProps) {
  return (
    <Stack sx={sx} className={className} direction="row" spacing={2.5} alignItems="center" py={1}>
      {icon?.({ width: 24, color: 'inherit' })}

      <Stack>
        <Typography variant="body1" color="inherit">
          {title}
        </Typography>

        <Typography variant="body2" color={isSelected || disabled ? 'inherit' : 'textSecondary'}>
          {subtitle ?? ''}
        </Typography>
      </Stack>
    </Stack>
  );
}
