import { AdminClassSchedulesFilterOption } from '@/viewmodels';
import { CloseRounded, PersonRounded } from '@mui/icons-material';
import { Box, IconButton, ListItemIcon, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface AdminSchoolScheduleCycleClassSchedulesCurrentSelectionProps {
  sx?: SxProps;
  className?: string;
  option: AdminClassSchedulesFilterOption | undefined;
  clearOption: () => void;
}

export const AdminSchoolScheduleCycleClassSchedulesCurrentSelection = observer(
  ({ sx, className, option, clearOption }: AdminSchoolScheduleCycleClassSchedulesCurrentSelectionProps) => {
    const strings = LocalizedStrings.admin.schedule.classSchedules;

    return (
      <Stack sx={sx} className={className} direction="row" alignItems="center" spacing={1}>
        {option != null && (
          <Typography variant="subtitle2" color="textSecondary">
            {strings.currentSelectionLabel()}
          </Typography>
        )}

        {option?.case === 'teacher' && (
          <ListItemIcon>
            <PersonRounded />
          </ListItemIcon>
        )}

        {option?.case === 'course' && (
          <Box
            sx={{
              height: option.value.section.length > 0 ? 40 : 24,
              width: 4,
              borderRadius: 2,
              backgroundColor: option.value.color,
              mr: 1
            }}
          />
        )}

        {option?.case === 'course' ? (
          <Stack>
            <Typography variant="body1" fontWeight="500" color="textPrimary">
              {option.value.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {option.value.section}
            </Typography>
          </Stack>
        ) : (
          <Typography
            variant="body1"
            fontWeight="500"
            color={option != null ? 'textPrimary' : 'textSecondary'}
            fontStyle={option == null ? 'italic' : undefined}
          >
            {option == null
              ? strings.noSelectionMessage()
              : option.value.fullName || option.value.emailAddress || strings.teacherDefaultName()}
          </Typography>
        )}

        {option != null && (
          <IconButton onClick={clearOption} size="small">
            <CloseRounded fontSize="small" />
          </IconButton>
        )}
      </Stack>
    );
  }
);
