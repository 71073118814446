import { dateToPBDate, UserDashboardKind } from '@/models';
import { ServiceContainer } from '@/providers';
import { PlannerDataStore, UserDataStore } from '@/stores';
import { CustomActionEffect } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_action_effect_pb';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import {
  BaseDialogActionButtonConfiguration,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  StaticDialogViewModel
} from '../../utils';
import { AppScheduleCycleCreateViewModel, ScheduleCycleCreateViewModel } from './ScheduleCycleCreateViewModel';

export interface ScheduleCycleCreateDialogViewModel extends StaticDialogViewModel, ScheduleCycleCreateViewModel {}

export class AppScheduleCycleCreateDialogViewModel
  extends AppScheduleCycleCreateViewModel
  implements ScheduleCycleCreateDialogViewModel
{
  private readonly _cancelButtonConfiguration: CancelDialogActionButtonConfiguration;
  private readonly _saveButtonConfiguration: SaveDialogActionButtonConfiguration;

  @observable private _isSaving = false;

  readonly kind = 'static';
  readonly supplementaryActions: SaveDialogActionButtonConfiguration[] = [];

  constructor(
    dashboardId: string,
    dashboardKind: UserDashboardKind,
    private readonly _plannerId: string | undefined,
    private readonly _onDismiss: (scheduleId: string | undefined) => void,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super(dashboardId, dashboardKind, undefined);
    makeObservable(this);

    this._cancelButtonConfiguration = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );

    this._saveButtonConfiguration = new BaseDialogActionButtonConfiguration(
      'main',
      'both',
      'right',
      'check',
      'start',
      () => LocalizedStrings.scheduleCycle.create.saveButtonTitle(),
      'contained',
      () => this.save()
    );
  }

  @computed
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfiguration.isEnabled = !this._isSaving;
    this._saveButtonConfiguration.isEnabled = this.canSave && !this._isSaving;
    this._saveButtonConfiguration.showLoading = this._isSaving;
    return [this._cancelButtonConfiguration, this._saveButtonConfiguration];
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  clearError() {}

  async dismiss() {
    this._onDismiss(undefined);
    return await Promise.resolve();
  }

  private async save() {
    if (this.name == null || this.name.length === 0) {
      throw new Error('A name is required to create a schedule cycle.');
    }

    runInAction(() => {
      this._isSaving = true;
      this._createError = undefined;
    });

    try {
      const scheduleCycle = await this._userStore.createScheduleCycle(
        this.name,
        dateToPBDate(this.startDate),
        dateToPBDate(this.endDate),
        this.daysPerCycle,
        this.scheduleKind !== 'cycle-day',
        this.firstCycleDay,
        this.terms,
        this.isPerDayPeriodSchedules,
        { kind: this._dashboardKind, id: this._dashboardId }
      );

      if (this._plannerId != null) {
        void this._plannerStore.ensureCustomWorksCompleted(this._plannerId, CustomActionEffect.CREATE_SCHEDULE);
      }

      this._onDismiss(scheduleCycle.id);
    } catch (e) {
      runInAction(() => {
        this._isSaving = false;
        this._createError = (e as Error).message;
      });
    }
  }
}
