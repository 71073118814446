import { CircleRounded } from '@mui/icons-material';
import { Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface UserDashboardCalendarHeaderCurrentDateViewProps {
  sx?: SxProps;
  className?: string;
  isToday: boolean;
  title: string;
  subtitle?: string;
}

export const UserDashboardCalendarHeaderCurrentDateView = observer(
  ({ sx, className, isToday, subtitle, title }: UserDashboardCalendarHeaderCurrentDateViewProps) => {
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    return (
      <Stack sx={sx} className={className} direction="row" alignItems="center" spacing={2}>
        {isToday && <CircleRounded color="error" sx={{ width: 26, height: 26 }} />}

        <Stack flex={1}>
          <Typography variant={isExtraSmallScreen ? 'h6' : 'h5'} fontWeight={isExtraSmallScreen ? '500' : '400'}>
            {title}
          </Typography>

          {subtitle != null && (
            <Typography variant="subtitle2" color="textSecondary">
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>
    );
  }
);
