import { useServices } from '@/hooks';
import { InfoRounded } from '@mui/icons-material';
import { Button, IconButton, Popover, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { Subheader } from './Subheader';

export interface LegendItem {
  readonly title: string;
  readonly subtitle?: string;
  readonly icon: (size: number) => JSX.Element;
  readonly iconSize?: number;
  readonly hidden?: boolean;
}

export interface LegendSection {
  readonly title?: string;
  readonly items: LegendItem[];
}

export interface LegendButtonProps {
  sx?: SxProps;
  className?: string;
  buttonTitle?: string;
  popoverTitle?: string;
  isIconOnly?: boolean;
  sections: LegendSection[];
}

export const LegendButton = observer(
  ({ sx, className, buttonTitle, popoverTitle, sections, isIconOnly }: LegendButtonProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    const buttonRef = useRef<HTMLButtonElement | null>(null);
    const [showPopover, setShowPopover] = useState(false);

    return (
      <>
        {isIconOnly ? (
          <IconButton ref={buttonRef} sx={sx} className={className} onClick={() => setShowPopover(true)}>
            <InfoRounded />
          </IconButton>
        ) : (
          <Button
            ref={buttonRef}
            endIcon={<InfoRounded />}
            sx={sx}
            className={className}
            onClick={() => setShowPopover(true)}
          >
            {buttonTitle ?? strings.legendButtonDefaultTitle}
          </Button>
        )}

        <Popover
          open={showPopover}
          anchorEl={buttonRef.current}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          onClose={() => setShowPopover(false)}
        >
          <Stack m={2} sx={{ width: 320 }} spacing={1} mt={1}>
            <Typography variant="h6" fontWeight="500">
              {popoverTitle ?? strings.legendButtonDefaultTitle}
            </Typography>

            {sections.map((section, index) => (
              <Stack key={`section-${index}`} spacing={0}>
                {section.title && <Subheader disableGutters>{section.title}</Subheader>}

                <Stack key={`section-${index}`} spacing={3}>
                  {section.items.map((item, itemIndex) =>
                    item.hidden !== true ? (
                      <Stack key={`item-${itemIndex}-${index}`} spacing={1}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          {item.icon(item.iconSize ?? 24)}
                          <Typography flex={1} variant="subtitle1">
                            {item.title}
                          </Typography>
                        </Stack>

                        {item.subtitle != null && (
                          <Typography flex={1} variant="body2" color="textSecondary">
                            {item.subtitle}
                          </Typography>
                        )}
                      </Stack>
                    ) : null
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Popover>
      </>
    );
  }
);
