import { ConnectedAppService } from '@/services';
import { CustomActionEffect } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/custom_action_effect_pb';
import CheckedIcon from '@mui/icons-material/CheckRounded';
import RetryIcon from '@mui/icons-material/RefreshRounded';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { useActivePlannerId, useServices } from '../../../hooks';
import { ConnectedAppConnectButton } from '../../connected-apps';

export interface PlannerNewSchoolsClassroomConnectionStateProps {
  sx?: SxProps;
  className?: string;
  isCompact: boolean;
  service: ConnectedAppService<string>;
  onConnectErrorChange: (error: Error | undefined) => void;
}

export const PlannerNewSchoolsClassroomConnectionState = observer(
  ({ sx, className, service, isCompact, onConnectErrorChange }: PlannerNewSchoolsClassroomConnectionStateProps) => {
    const { plannerStore } = useServices();
    const plannerId = useActivePlannerId();

    const [isConnecting, setIsConnecting] = useState(false);
    const strings = LocalizedStrings.planner.newSchools.classroomConnection;

    async function connect() {
      setIsConnecting(true);
      onConnectErrorChange(undefined);

      try {
        await service.connect(true);
        await service.refreshSyncStatus();

        void plannerStore.ensureCustomWorksCompleted(plannerId, CustomActionEffect.CONNECT_GOOGLE_CLASSROOM);
        // Not waiting for sync to complete.
        void onSuccessfulConnection();
      } catch (e) {
        if (e !== 'popup_closed') {
          onConnectErrorChange(e as Error);
        }
      } finally {
        setIsConnecting(false);
      }
    }

    async function onSuccessfulConnection() {
      await service.sync();
      await plannerStore.getCourseSectionsInPlanner(plannerId).fetch(true);
    }

    const { hasSyncError, hasSyncStatus, isConnected } = service;
    const showIsLoading = !hasSyncStatus && !hasSyncError;
    const showConnectButton = hasSyncStatus && !hasSyncError && !isConnected;
    const showConnected = hasSyncStatus && isConnected;
    const showSyncError = !hasSyncStatus && hasSyncError;

    return (
      <Box sx={sx} className={className}>
        {showIsLoading && <CircularProgress size={22} />}

        {showConnectButton &&
          (isConnecting ? (
            <LoadingButton loading disabled sx={{ width: 180 }} variant="contained-grey">
              &nbsp;
            </LoadingButton>
          ) : (
            <ConnectedAppConnectButton kind="classroom" onClick={() => void connect()} />
          ))}

        {showConnected && (
          <Button disabled endIcon={<CheckedIcon />}>
            {strings.connected()}
          </Button>
        )}

        {showSyncError && (
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Typography variant="caption" fontWeight="500" color="error">
              {isCompact ? strings.syncStatusErrorCompact() : strings.syncStatusError()}
            </Typography>

            <IconButton size="small" color="error" onClick={() => void service.refreshSyncStatus()}>
              <RetryIcon fontSize="small" />
            </IconButton>
          </Stack>
        )}
      </Box>
    );
  }
);
