import { useServices } from '@/hooks';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface StudentDetailsUnknownProps {
  sx?: SxProps;
  className?: string;
}

export const StudentDetailsUnknown = observer(({ sx, className }: StudentDetailsUnknownProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.schoolCourseSection;

  return (
    <Stack sx={sx} className={className} p={3}>
      <Typography variant="subtitle2" color="textSecondary" fontWeight="500">
        {strings.studentDetailsUnknown}
      </Typography>
    </Stack>
  );
});
