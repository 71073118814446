import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import { IconButton } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';

export type ScheduleCyclePeriodScheduleGridNavigationButtonDirection = 'left' | 'right';

export interface ScheduleCyclePeriodScheduleGridNavigationButtonProps {
  sx?: SxProps;
  direction: ScheduleCyclePeriodScheduleGridNavigationButtonDirection;
  onClick: () => void;
}

export const ScheduleCyclePeriodScheduleGridNavigationButton = observer(
  ({ sx, direction, onClick }: ScheduleCyclePeriodScheduleGridNavigationButtonProps) => {
    return (
      <IconButton
        sx={{ ...sx, zIndex: ScheduleCyclePeriodScheduleGridConstants.navigationButtonZIndex }}
        onClick={() => onClick()}
      >
        {direction === 'left' ? <ArrowCircleLeftRoundedIcon /> : <ArrowCircleRightRoundedIcon />}
      </IconButton>
    );
  }
);
