import { useActivePlannerId, useServices } from '@/hooks';
import { UserDashboardCalendarPublishedWorkInfo, UserDashboardCalendarWorkInfo } from '@/viewmodels';
import { ListItemButton, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { WorkIcon } from '../../../../lists';
import { ContextMenuHandler } from '../../../ContextMenuHandler';

export interface UserDashboardPeriodInfoPopoverWorkItemProps {
  sx?: SxProps;
  className?: string;
  work:
    | { case: 'work'; value: UserDashboardCalendarWorkInfo }
    | { case: 'publishedWork'; value: UserDashboardCalendarPublishedWorkInfo };
}

export const UserDashboardPeriodInfoPopoverWorkItem = observer(
  ({ sx, className, work }: UserDashboardPeriodInfoPopoverWorkItemProps) => {
    const { route } = useServices();
    const plannerId = useActivePlannerId();
    const hasDescription = work.value.description.length > 0;

    const location = useLocation();
    const navigate = useNavigate();

    function showDetails() {
      const newLocation =
        work.case === 'work'
          ? route.resolvePlannerWorkLocation(plannerId, work.value.id)
          : route.resolvePublishedWorkLocation(plannerId, work.value.id, work.value.schoolId);

      navigate(newLocation, { state: { backgroundLocation: location } });
    }

    return (
      <ContextMenuHandler actions={work.value.contextMenuActions()}>
        {(contextMenuHandler) => (
          <ListItemButton
            onClick={showDetails}
            sx={{ ...sx, alignItems: hasDescription ? 'stretch' : 'center' }}
            className={className}
            onContextMenu={contextMenuHandler}
          >
            <WorkIcon
              icon={work.value.icon}
              color={work.value.color}
              size={32}
              displayCourseSectionColor
              sx={{ mr: 1 }}
            />
            <ListItemText
              sx={{ my: hasDescription ? 0 : undefined }}
              primary={work.value.title}
              secondary={hasDescription ? work.value.description : ''}
            />
          </ListItemButton>
        )}
      </ContextMenuHandler>
    );
  }
);
