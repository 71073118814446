import { CheckRounded, EditCalendarRounded } from '@mui/icons-material';
import { IconButton, Tooltip, alpha, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { MouseEvent, ReactNode } from 'react';
import { useServices } from '../../../../hooks';
import { PlannerListItemInfoInlineAction } from '../../../../viewmodels';

export interface PlannerListItemInlineActionProps {
  sx?: SxProps;
  className?: string;
  action: PlannerListItemInfoInlineAction;
  onClick: (event: MouseEvent) => void;
}

export const PlannerListItemInlineAction = ({ sx, className, action, onClick }: PlannerListItemInlineActionProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;
  const theme = useTheme();

  function renderButton(title: string, icon: ReactNode, color: 'primary' | 'success') {
    const backgroundColor = alpha(theme.palette[color].main, 0.15);

    return (
      <Tooltip title={title}>
        <IconButton size="small" sx={{ ...sx, backgroundColor }} className={className} color={color} onClick={onClick}>
          {icon}
        </IconButton>
      </Tooltip>
    );
  }

  if (action.hidden === true) {
    return null;
  }

  switch (action.kind) {
    case 'plan-work-session':
      return renderButton(strings.createPlannedWork, <EditCalendarRounded fontSize="small" />, 'primary');

    case 'complete':
      return renderButton(strings.completeWork, <CheckRounded fontSize="small" />, 'success');

    default:
      return null;
  }
};
