import { ApplicationSettingsStorage, DateService, LocalizationService, UserService } from '@/services';
import { DemoTransportService, SchoolTransportService, UserTransportService, WorkTransportService } from '@/transports';
import { AccountInfo } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_info_pb';
import { AccountKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_kind_pb';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { TeacherPreferences } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/teacher_preferences_pb';
import { action, makeObservable, observable, reaction } from 'mobx';
import { getOrCreateInMap } from '../../utils';
import { SchoolDataStore, StoreInvalidator } from '../contracts';
import {
  AppDemoSchoolSnapshotsLoadable,
  AppSchoolAccountCourseSectionsLoadable,
  AppSchoolAccountLoadable,
  AppSchoolCourseSectionLoadable,
  AppSchoolCourseSectionsLoadable,
  AppSchoolDemoCourseSectionsLoadable,
  AppSchoolParticipationCodeLoadable,
  AppSchoolStudentAccountsLoadable,
  AppSchoolTeacherAccountsLoadable,
  AppStudentInsightsLoadable,
  AppTeacherPreferencesLoadable,
  DemoSchoolSnapshotsLoadable,
  SchoolAccountCourseSectionsLoadable,
  SchoolAccountLoadable,
  SchoolCourseSectionLoadable,
  SchoolCourseSectionsLoadable,
  SchoolDemoCourseSectionsLoadable,
  SchoolParticipationCodeLoadable,
  SchoolStudentAccountsLoadable,
  SchoolTeacherAccountsLoadable,
  StudentInsightsLoadable,
  TeacherPreferencesLoadable
} from '../loadables';

export class AppSchoolDataStore implements SchoolDataStore {
  private readonly _courseSectionsBySchoolId = observable.map<string, SchoolCourseSectionsLoadable>();
  private readonly _demoCourseSectionsBySchoolId = observable.map<string, SchoolCourseSectionsLoadable>();
  private readonly _courseSectionsById = observable.map<string, SchoolCourseSectionLoadable>();
  private readonly _courseSectionsByAccountId = observable.map<string, SchoolAccountCourseSectionsLoadable>();
  private readonly _teachersBySchoolId = observable.map<string, SchoolTeacherAccountsLoadable>();
  private readonly _studentsBySchoolId = observable.map<string, SchoolStudentAccountsLoadable>();
  private readonly _accountsById = observable.map<string, SchoolAccountLoadable>();
  private readonly _teacherPreferencesLoadable: TeacherPreferencesLoadable;
  private readonly _teacherParticipationCodeBySchoolId = observable.map<string, SchoolParticipationCodeLoadable>();

  constructor(
    private readonly _schoolTransport: SchoolTransportService,
    private readonly _user: UserService,
    private readonly _userTransport: UserTransportService,
    private readonly _workTransport: WorkTransportService,
    private readonly _demoTransport: DemoTransportService,
    private readonly _storeInvalidator: StoreInvalidator,
    private readonly _localization: LocalizationService,
    private readonly _dateService: DateService,
    settingsStorage: ApplicationSettingsStorage
  ) {
    makeObservable(this);
    this._teacherPreferencesLoadable = new AppTeacherPreferencesLoadable(_userTransport);

    reaction(
      () => ({ user: _user.currentUser, isDemoMode: settingsStorage.isDemoMode }),
      (value, oldValue) => {
        if (value.user?.userId !== oldValue.user?.userId || value.isDemoMode !== oldValue.isDemoMode) {
          this.invalidateAll();
        }
      }
    );

    reaction(
      () => _localization.currentLocale,
      (value, oldValue) => {
        if (_user.currentUser != null && value !== oldValue) {
          this._demoCourseSectionsBySchoolId.forEach((l) => void l.fetch(true));
        }
      }
    );

    reaction(
      () => _storeInvalidator.schoolsRevision,
      () => this.invalidateAll()
    );
  }

  getCourseSections(schoolId: string): SchoolCourseSectionsLoadable {
    return getOrCreateInMap(
      this._courseSectionsBySchoolId,
      schoolId,
      () => new AppSchoolCourseSectionsLoadable(schoolId, this._schoolTransport)
    );
  }

  getDemoCourseSections(schoolId: string): SchoolDemoCourseSectionsLoadable {
    return getOrCreateInMap(
      this._demoCourseSectionsBySchoolId,
      schoolId,
      () => new AppSchoolDemoCourseSectionsLoadable(schoolId, this._schoolTransport)
    );
  }

  getCourseSection(schoolId: string, courseSectionId: string): SchoolCourseSectionLoadable {
    return getOrCreateInMap(
      this._courseSectionsById,
      courseSectionId,
      () => new AppSchoolCourseSectionLoadable(schoolId, courseSectionId, this._schoolTransport, this._localization)
    );
  }

  getCourseSectionsForAccount(schoolId: string, accountId: string): SchoolAccountCourseSectionsLoadable {
    const key = `${schoolId}-${accountId}`;

    return getOrCreateInMap(
      this._courseSectionsByAccountId,
      key,
      () => new AppSchoolAccountCourseSectionsLoadable(accountId, schoolId, this._schoolTransport)
    );
  }

  async updateCourseSectionColor(
    courseSectionId: string,
    schoolId: string,
    backgroundColor: string
  ): Promise<CourseSection> {
    const result = await this._schoolTransport.updateCourseSectionColorInSchool(courseSectionId, backgroundColor);
    this._courseSectionsBySchoolId.get(schoolId)?.addOrReplace(result.id, result);
    return result;
  }

  getStudentInsightsData(schoolId: string, courseSectionId: string, studentId: string): StudentInsightsLoadable {
    return new AppStudentInsightsLoadable(
      schoolId,
      courseSectionId,
      studentId,
      this._schoolTransport,
      this._workTransport,
      this._localization,
      this._dateService
    );
  }

  getTeachers(schoolId: string): SchoolTeacherAccountsLoadable {
    return getOrCreateInMap(
      this._teachersBySchoolId,
      schoolId,
      () => new AppSchoolTeacherAccountsLoadable(schoolId, this._schoolTransport)
    );
  }

  getStudents(schoolId: string): SchoolStudentAccountsLoadable {
    return getOrCreateInMap(
      this._studentsBySchoolId,
      schoolId,
      () => new AppSchoolStudentAccountsLoadable(schoolId, this._schoolTransport)
    );
  }

  getAccount(accountId: string, schoolId: string): SchoolAccountLoadable {
    const key = `${schoolId}-${accountId}`;

    return getOrCreateInMap(
      this._accountsById,
      key,
      () => new AppSchoolAccountLoadable(accountId, schoolId, this._schoolTransport)
    );
  }

  getTeacherPreferences(): TeacherPreferencesLoadable {
    return this._teacherPreferencesLoadable;
  }

  async inviteTeachersToSchool(schoolId: string, teacherEmails: string[]) {
    const teachers = await this._schoolTransport.batchCreateSchoolAccounts(
      schoolId,
      teacherEmails.map((email) => new AccountInfo({ emailAddress: email })),
      AccountKind.TEACHER
    );
    const teachersLoadable = this.getTeachers(schoolId);

    if (teachersLoadable.hasData) {
      teachersLoadable.addMultiple(teachers.map((t) => ({ key: t.id, value: t })));
    }
  }

  async sendTeacherInvitationEmail(schoolId: string, accountIds: string[]) {
    await this._schoolTransport.sendTeacherInvitationEmail(schoolId, accountIds);
  }

  getTeacherParticipationCode(schoolId: string): SchoolParticipationCodeLoadable {
    return getOrCreateInMap(
      this._teacherParticipationCodeBySchoolId,
      schoolId,
      () => new AppSchoolParticipationCodeLoadable(schoolId, AccountKind.TEACHER, this._schoolTransport)
    );
  }

  async updateTeacherPreferences(showDemoCourseSection: boolean): Promise<TeacherPreferences> {
    const userId = this._user.currentUser?.userId;

    if (userId == null) {
      throw new Error('Cannot update TeacherPreferences when there are no active user.');
    }

    const newPreferences = await this._userTransport.updateTeacherPreferences(userId, showDemoCourseSection);
    this._teacherPreferencesLoadable.setValue(newPreferences);
    return newPreferences;
  }

  async createDemoSchoolSnapshot(schoolId: string, name: string): Promise<void> {
    await this._demoTransport.createDemoSchoolSnapshot(schoolId, name);
  }

  getDemoSchoolSnapshots(schoolId: string): DemoSchoolSnapshotsLoadable {
    // This loadable is never cached.
    return new AppDemoSchoolSnapshotsLoadable(schoolId, this._demoTransport, this._schoolTransport);
  }

  async restoreDemoSchoolSnapshot(schoolId: string, name: string, dayOffset: number): Promise<void> {
    await this._demoTransport.restoreDemoSchoolSnapshot(schoolId, name, dayOffset);

    this._storeInvalidator.invalidateAll();
  }

  @action
  private invalidateAll() {
    this._courseSectionsBySchoolId.clear();
    this._demoCourseSectionsBySchoolId.clear();
  }
}
