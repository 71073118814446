import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { chain } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { UserDataStore } from '../../stores';
import { localizedCompare, propertiesHaveMatchForSearchText } from '../../utils';

export interface AdminSchoolsListViewModel {
  searchText: string;
  readonly filteredSchools: SchoolInformation[];
  readonly schools: SchoolInformation[];
}

export class AppAdminSchoolsListViewModel implements AdminSchoolsListViewModel {
  @observable private _searchText = '';

  constructor(
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);
  }

  @computed
  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
  }

  @computed
  get schools(): SchoolInformation[] {
    const { userId } = this._userStore.user;

    return (
      chain(this._userStore.schools)
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        .filter((s) => s.school?.sharingMode === SchoolSharingMode.SHARED && s.owners.some((o) => o.userId === userId))
        .sort((s1, s2) => localizedCompare(s1.school!.name, s2.school!.name, this._localization.currentLocale))
        .value()
    );
  }

  @computed
  get filteredSchools(): SchoolInformation[] {
    if (this._searchText.length === 0) {
      return this.schools;
    }

    return chain(this.schools)
      .filter((s) =>
        propertiesHaveMatchForSearchText(this._searchText, [
          s.school!.name,
          s.school!.subtitle,
          { value: s.school!.id, method: 'exact', caseSensitive: true }
        ])
      )
      .value();
  }
}
