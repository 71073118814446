import { localizedExternalSourceName } from '@/resources/strings/utils/ExternalSourceStringsUtils';
import { css } from '@emotion/css';
import AddIcon from '@mui/icons-material/AddRounded';
import { Box, Button, colors, Divider, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { CourseSectionInfo, getOptionalDashConcatenation } from '../../../models';

export interface CourseSectionPickerProps {
  sx?: SxProps;
  className?: string;

  /**
   * The selected course section.
   */
  selectedCourseId: string | undefined;

  /**
   * All available course sections.
   */
  allCourseSections: CourseSectionInfo[];

  /*
  The label. Optional.
   */
  label?: string;

  /**
   * Indicates whether to disable the picker. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * If true, a none option will be available. This option returns an `undefined` courseSectionId in `onChange`.
   * Defaults to true.
   */
  canSelectNone?: boolean;

  /**
   * Determines if the external source of a course section, if any, be displayed next to the section.
   */
  showExternalSource?: boolean;

  /**
   * Callback when the selected course section changes.
   * @param courseSectionId The id for the new selected course section.
   */
  onChange: (courseSectionId: string | undefined) => void;

  /**
   * Callback called when the create course button is clicked.
   */
  onCreateCourseClick?: () => void;

  /**
   * Elevation for the select menu.
   */
  elevation?: number;
}

export const CourseSectionPicker = observer(
  ({
    sx,
    className,
    selectedCourseId,
    allCourseSections,
    label,
    disabled,
    showExternalSource = false,
    onChange,
    onCreateCourseClick,
    canSelectNone = true,
    elevation
  }: CourseSectionPickerProps) => {
    const [open, setOpen] = useState(false);
    const strings = LocalizedStrings.work.edit;

    const handleClose = () => {
      setOpen(false);
    };

    const handleOpen = () => {
      setOpen(true);
    };

    const handleCreateCourse = () => {
      handleClose();
      onCreateCourseClick?.();
    };

    return (
      <FormControl className={className} sx={{ width: '100%', ...sx }} disabled={disabled} size="small">
        <InputLabel id="work-course-section-picker-label">{label}</InputLabel>
        <Select
          classes={{ select: selected }}
          value={selectedCourseId != null && selectedCourseId.length > 0 ? selectedCourseId : 'none'}
          label={label}
          labelId="work-course-section-picker-label"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          MenuProps={{ elevation }}
          onChange={(event) => {
            const value = event.target.value;

            if (value === 'none') {
              onChange(undefined);
            } else {
              onChange(value);
            }
          }}
        >
          {canSelectNone && (
            <MenuItem value="none">
              <CourseItem
                title={strings.courseNoneValue()}
                section=""
                color={undefined}
                externalSourceName={undefined}
              />
            </MenuItem>
          )}

          {allCourseSections.map((course) => (
            <MenuItem value={course.id} key={`work-course-section-menu-item-${course.id}`}>
              <CourseItem
                title={course.title}
                section={course.section}
                externalSourceName={showExternalSource ? course.externalSource?.sourceName : undefined}
                color={course.color}
              />
            </MenuItem>
          ))}

          {onCreateCourseClick != null && (
            <div>
              <Divider sx={{ mt: 2 }} />
              <Box sx={{ px: 1, pt: 1, mb: 0, width: '100%' }}>
                <Button startIcon={<AddIcon />} sx={{ width: '100%' }} onClick={handleCreateCourse}>
                  {strings.addCourseTitle()}
                </Button>
              </Box>
            </div>
          )}
        </Select>
      </FormControl>
    );
  }
);

interface CourseItemProps {
  title: string;
  section: string;
  externalSourceName: string | undefined;
  color?: string;
}

const CourseItem = observer(({ title, section, externalSourceName, color }: CourseItemProps) => {
  const strings = LocalizedStrings.work.edit;
  const secondaryText = getOptionalDashConcatenation(
    section,
    externalSourceName != null
      ? strings.courseExternalSourceName(localizedExternalSourceName(externalSourceName))
      : undefined,
    true
  );

  return (
    <>
      <Box
        sx={{
          borderRadius: 2,
          width: 4,
          height: secondaryText != null ? 36 : 22,

          backgroundColor: color ?? colors.grey['300']
        }}
      />
      <ListItemText
        sx={{ ml: 1, my: 0 }}
        primary={title}
        secondary={secondaryText}
        secondaryTypographyProps={{ variant: 'caption' }}
      ></ListItemText>
    </>
  );
});

// If we don't specify "!important" for the display property, the value is not respected.
const selected = css([{ display: 'flex !important', alignItems: 'center', flexDirection: 'row' }]);
