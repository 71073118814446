import { AuthProvider } from '@/models';
import { ServiceContainer } from '@/providers';
import { AuthenticationService, SignInActionKind } from '@/services';
import { CredentialsAlreadyLinkedError } from '@/utils';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface LoginViewModel {
  readonly error?: string;
  readonly showExistingCredentialsAlertConfirmationForProvider: AuthProvider | undefined;

  signIn(authProvider: AuthProvider): Promise<void>;
  forceSignIn(authProvider: AuthProvider): Promise<void>;
}

export class AppLoginViewModel implements LoginViewModel {
  @observable private _error: string | undefined;
  @observable private _showExistingCredentialsAlertConfirmationForProvider: AuthProvider | undefined;

  constructor(
    private readonly _actionKind: SignInActionKind,
    private readonly _authentication: AuthenticationService = ServiceContainer.services.authentication
  ) {
    makeObservable(this);
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get showExistingCredentialsAlertConfirmationForProvider(): AuthProvider | undefined {
    return this._showExistingCredentialsAlertConfirmationForProvider;
  }

  signIn(authProvider: AuthProvider): Promise<void> {
    return this.innerSignIn(authProvider, this._actionKind);
  }

  forceSignIn(authProvider: AuthProvider): Promise<void> {
    runInAction(() => (this._showExistingCredentialsAlertConfirmationForProvider = undefined));
    return this.innerSignIn(authProvider, 'sign-in');
  }

  @action
  private async innerSignIn(authProvider: AuthProvider, kind: SignInActionKind): Promise<void> {
    try {
      this._error = undefined;
      await this._authentication.signIn(authProvider, kind);
    } catch (e) {
      const error = e as Error;

      if (e instanceof CredentialsAlreadyLinkedError && kind === 'link') {
        // Give the user a chance to switch. They will lose the current planner.
        runInAction(() => (this._showExistingCredentialsAlertConfirmationForProvider = authProvider));
      } else {
        runInAction(() => (this._error = error.message));
      }
    }
  }
}
