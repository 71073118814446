import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { UserDataStore } from '@/stores';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { Entitlement } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/entitlement_pb';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import { UpdatableViewModelState } from '../shared';
import { AppProductsListViewModel, ProductsListViewModel } from '../subscriptions';
import {
  AppBaseUpdatableDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';
import { SchoolEditViewModel } from './SchoolEditViewModel';

export type CreateSchoolDialogTab = 'picker' | 'subscribe' | 'details';

export interface CreateSchoolDialogViewModel extends UpdatableDialogViewModel, SchoolEditViewModel {
  readonly tab: CreateSchoolDialogTab | undefined;
  readonly canCreateSharedSchool: boolean;
  readonly isCreatingSharedSchool: boolean;
  readonly subscribeViewModel: ProductsListViewModel;
  selectSchoolKind(kind: 'personal' | 'shared'): void;
}

export class AppCreateSchoolDialogViewModel
  extends AppBaseUpdatableDialogViewModel
  implements CreateSchoolDialogViewModel
{
  @observable private _isApplying = false;
  @observable private _editSchoolError: string | undefined;
  @observable private _tab: CreateSchoolDialogTab = 'picker';
  @observable private _name = '';
  @observable private _subtitle = '';
  @observable private _shareSchool = false;

  private readonly _saveButtonConfig: SaveDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;

  constructor(
    private readonly _plannerId: string | undefined,
    private readonly _close:
      | ((schoolId: string | undefined, attachError: Error | undefined) => Promise<void>)
      | undefined,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(localization, () => _close?.(undefined, undefined) ?? new Promise((resolve) => resolve()));
    makeObservable(this);

    this._saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.apply());
    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );

    this._saveButtonConfig.title = () => this._localization.localizedStrings.school.createSchoolSaveButtonLabel;
  }

  @computed
  private get subscriptionLoadable() {
    return this._userStore.subscription;
  }

  @computed
  get canCreateSharedSchool(): boolean {
    const subscription = this.subscriptionLoadable.data;

    if (subscription == null) {
      return false;
    }

    return (
      subscription.subscription.isActive && subscription.subscription.entitlements.includes(Entitlement.SHARED_SCHOOLS)
    );
  }

  @computed
  get isCreatingSharedSchool(): boolean {
    return this._shareSchool;
  }

  readonly isReadOnly = false;

  @computed
  get state(): UpdatableViewModelState {
    return this.subscriptionLoadable.state;
  }

  readonly hasChanges = false;

  @computed
  get isSubmitting(): boolean {
    return this._isApplying;
  }

  @computed
  get hasData(): boolean {
    return this.subscriptionLoadable.hasData;
  }

  @computed
  get canApply(): boolean {
    const canApplyChanges = this._name.length > 0;
    return !this.isApplying && canApplyChanges;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    if (this.tab != 'details') {
      return [this._cancelButtonConfig];
    }

    this._cancelButtonConfig.isEnabled = !this._isApplying;
    this._saveButtonConfig.isEnabled = this.canApply;
    this._saveButtonConfig.showLoading = this.isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get editSchoolError(): string | undefined {
    return this._editSchoolError;
  }

  @computed
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  @computed
  get subtitle(): string {
    return this._subtitle;
  }

  set subtitle(value: string) {
    this._subtitle = value;
  }

  @computed
  get tab(): CreateSchoolDialogTab {
    return this._tab;
  }

  @computed
  get subscribeViewModel() {
    // Should not be used in this context, but redirecting to '/' in the case that it does.
    const redirectUrl = window.location.origin;
    return new AppProductsListViewModel(() => {
      // Making sure we are now subscribed.
      if (this.canCreateSharedSchool) {
        runInAction(() => (this._tab = 'details'));
      }
    }, redirectUrl);
  }

  @action
  selectSchoolKind(kind: 'personal' | 'shared') {
    this._shareSchool = kind === 'shared';
    this._tab = kind === 'personal' || this.canCreateSharedSchool ? 'details' : 'subscribe';
  }

  @action
  clearCreateSchoolError() {
    this._editSchoolError = undefined;
  }

  async apply() {
    if (this.name.length === 0) {
      return;
    }

    runInAction(() => {
      this._isApplying = true;
      this._editSchoolError = undefined;
    });

    try {
      const { schoolId, attachError } = await this._userStore.createSchool(
        this.name,
        this.subtitle,
        this.canCreateSharedSchool && this._shareSchool ? SchoolSharingMode.SHARED : SchoolSharingMode.NOT_SHARED,
        true,
        this._plannerId
      );
      await this._close?.(schoolId, attachError);
    } catch (e) {
      const error = e as Error;
      runInAction(() => (this._editSchoolError = error.message));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  reloadData(): Promise<void> {
    return this.subscriptionLoadable.fetch(false);
  }

  @action
  resetChanges() {
    // Nothing to reset
  }
}
