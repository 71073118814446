import { useServices } from '@/hooks';
import { Locale } from '@/utils';
import { css } from '@emotion/css';
import { DarkModeRounded, LightModeRounded, TranslateRounded } from '@mui/icons-material';
import {
  Box,
  BoxProps,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { MouseEvent, ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { Logo, MediaQuery } from './utils';

export interface SplashProps extends BoxProps {
  className?: string;
  children: ReactNode;
  showOptions?: boolean;
  /**
   * The content width. Optional. Default is `300`.
   */
  contentWidth?: number | string;

  logoIsLink?: boolean;

  /**
   * The header element. Optional.
   */
  header?: () => JSX.Element;
}

export const Splash = observer(
  ({
    className,
    children,
    header,
    contentWidth = 300,
    logoIsLink = true,
    showOptions = true,
    ...props
  }: SplashProps) => {
    const { environment, theme, userStore, localization } = useServices();
    const muiTheme = useTheme();

    const linkLogoStyle = css({
      position: 'absolute',
      left: muiTheme.spacing(8),
      top: muiTheme.spacing(4),

      [muiTheme.breakpoints.down('sm')]: {
        top: 'unset',
        left: 'unset',
        bottom: `calc(100% + ${muiTheme.spacing(2)})`
      }
    });

    const logoStyle = css([
      {
        height: '100%',
        width: 200,

        [muiTheme.breakpoints.down('sm')]: {
          width: 150
        }
      }
    ]);

    const [localeAnchorEl, setLocaleAnchorEl] = useState<null | HTMLElement>(null);

    function handleLocaleButtonClick(event: MouseEvent<HTMLButtonElement>) {
      setLocaleAnchorEl(event.currentTarget);
    }

    function handleLocaleMenuClose(locale: Locale | undefined) {
      if (locale) {
        void userStore.updateCurrentUserLocale(locale);
      }

      setLocaleAnchorEl(null);
    }

    return (
      <Box
        {...props}
        sx={{ ...props.sx, maxHeight: '100%', overflow: 'hidden' }}
        className={className}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
          <LinkLogo linkClassName={linkLogoStyle} logoClassName={logoStyle} isLink={logoIsLink} />
        </MediaQuery>

        {showOptions && (
          <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
            <Stack
              direction="row"
              position="absolute"
              right={muiTheme.spacing(8)}
              top={muiTheme.spacing(4)}
              spacing={2}
              zIndex={1_000}
            >
              <IconButton onClick={handleLocaleButtonClick}>
                <TranslateRounded color={localeAnchorEl != null ? 'primary' : 'inherit'} />
              </IconButton>

              {theme.variant === 'light' && (
                <Tooltip title={localization.localizedStrings.utils.splashSwitchToDarkModeTooltip}>
                  <IconButton onClick={() => theme.setVariant('dark')}>
                    <DarkModeRounded />
                  </IconButton>
                </Tooltip>
              )}

              {theme.variant === 'dark' && (
                <Tooltip title={localization.localizedStrings.utils.splashSwitchToLightModeTooltip}>
                  <IconButton onClick={() => theme.setVariant('light')}>
                    <LightModeRounded />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </MediaQuery>
        )}

        <Box
          sx={{ position: 'relative', width: '100%', height: '100%' }}
          display="flex"
          alignItems="center"
          justifyContent="center"
          zIndex={1}
        >
          <MediaQuery mediaQuery={(th) => th.breakpoints.down('sm')}>
            <LinkLogo linkClassName={linkLogoStyle} logoClassName={logoStyle} isLink={logoIsLink} />
          </MediaQuery>

          <Box height="100%" width="100%" position="absolute" sx={{ overflowY: 'auto', overflowX: 'none' }}>
            <Box display="table" height="100%" width="100%">
              <Box
                sx={{
                  display: 'table-cell',
                  verticalAlign: 'middle'
                }}
              >
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', minHeight: 125, width: '100%', alignItems: 'center' }}
                  p={3}
                >
                  <Stack direction="column" width={contentWidth}>
                    {header?.()}
                    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                      {children}
                    </Box>
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="column"
          sx={{ position: 'absolute', bottom: muiTheme.spacing(4), left: muiTheme.spacing(4) }}
          alignItems="flex-start"
          zIndex={0}
        >
          <Typography variant="caption" color="textSecondary">
            <code style={{ fontFamily: 'inherit' }}>
              {environment?.isLoaded
                ? `${environment.version}.${environment.buildNumber}-${environment.environment}`
                : ''}
            </code>
          </Typography>
          <Typography variant="caption" color="textSecondary">
            © 2020-2024 Intuitic Inc.
          </Typography>
        </Box>

        {showOptions && (
          <Menu
            open={localeAnchorEl != null}
            anchorEl={localeAnchorEl}
            onClose={() => handleLocaleMenuClose(undefined)}
            slotProps={{ paper: { sx: { width: 200 } } }}
          >
            <MenuItem onClick={() => handleLocaleMenuClose('en')}>
              <ListItemIcon sx={{ color: muiTheme.palette.text.primary }}>🇺🇸</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ color: localization.currentLocale === 'en' ? 'primary' : 'inherit' }}
              >
                {/* Not localized */}
                English
              </ListItemText>
            </MenuItem>

            <MenuItem onClick={() => handleLocaleMenuClose('fr')}>
              <ListItemIcon sx={{ color: muiTheme.palette.text.primary }}>🇫🇷</ListItemIcon>
              <ListItemText
                primaryTypographyProps={{ color: localization.currentLocale === 'fr' ? 'primary' : 'inherit' }}
              >
                {/* Not localized */}
                Français
              </ListItemText>
            </MenuItem>
          </Menu>
        )}
      </Box>
    );
  }
);

function LinkLogo({
  linkClassName,
  logoClassName,
  isLink
}: {
  linkClassName: string;
  logoClassName: string;
  isLink: boolean;
}) {
  return (
    <div className={linkClassName}>
      {isLink && (
        <Link to="/">
          <Logo className={logoClassName} />
        </Link>
      )}

      {!isLink && <Logo className={logoClassName} />}
    </div>
  );
}
