import { useServices } from '@/hooks';
import { Box, Grid, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Outlet } from 'react-router-dom';
import { BackgroundImageScreenPaper } from '../../utils';
import { SettingsHeader } from './SettingsHeader';
import { SettingsSection } from './SettingsSection';
import { SettingsSideBar } from './SettingsSideBar';

export interface SettingsLayoutProps {
  sx?: SxProps;
  className?: string;
  sections: SettingsSection[];
}

export const SettingsLayout = observer(({ sx, className, sections }: SettingsLayoutProps) => {
  const { settingsStorage } = useServices();
  const theme = useTheme();
  const showSideBar = useMediaQuery(() => theme.breakpoints.up(settingsStorage.isSidebarCollapsed ? 'md' : 'lg'));

  return (
    <Box sx={sx} className={className} width="100%" height="100%" display="flex">
      <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <Grid container sx={{ width: '100%', height: '100%' }}>
          <Grid item xs={12} height="100%">
            <BackgroundImageScreenPaper sx={{ height: '100%' }}>
              <Stack direction={{ sm: 'column', md: 'row' }} sx={{ height: '100%', width: '100%' }} overflow="hidden">
                {showSideBar && <SettingsSideBar sections={sections} sx={{ width: 200, height: '100%' }} />}

                <Stack flex={1} p={{ xs: 0, sm: 2 }} sx={{ overflowY: 'hidden' }}>
                  <SettingsHeader
                    sections={sections}
                    showsMenu={!showSideBar}
                    sx={{ p: { xs: 2, sm: 0 }, pb: !showSideBar ? 2 : { xs: 0 } }}
                  />
                  <Stack flex={1} overflow="hidden">
                    <Outlet />
                  </Stack>
                </Stack>
              </Stack>
            </BackgroundImageScreenPaper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
