import { Day, dateToPBDate, dayToDate } from '@/models';
import { CheckRounded } from '@mui/icons-material';
import { Stack, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { DateCalendar, PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ComponentType, useState } from 'react';
import LocalizedStrings from 'strings';
import { FormPopoverActions, FormPopoverHeader } from '../../../../utils';

export interface ScheduleCycleSpecialDayOccurrenceDayPickerProps {
  sx?: SxProps;
  className?: string;
  onSubmit: (days: Day[]) => void;
  minDay: Day | undefined;
  maxDay: Day | undefined;
}

export const ScheduleCycleSpecialDayOccurrenceDayPicker = observer(
  ({ sx, className, onSubmit, minDay, maxDay }: ScheduleCycleSpecialDayOccurrenceDayPickerProps) => {
    const [selectedDays, setSelectedDays] = useState<Day[]>([]);
    const strings = LocalizedStrings.scheduleCycle.edit.specialDays.edit;

    function didSelectDate(date: Date) {
      const day = dateToPBDate(date);
      const selectedDaysCopy = [...selectedDays];
      const dayIndex = selectedDaysCopy.findIndex((d) => isEqual(d, day));

      if (dayIndex >= 0) {
        selectedDaysCopy.splice(dayIndex, 1);
      } else {
        selectedDaysCopy.push(day);
      }

      setSelectedDays(selectedDaysCopy);
    }

    const PickerDay = (pickersDayProps: PickersDayProps<Date>) => {
      if (!selectedDays) {
        return <PickersDay {...pickersDayProps} />;
      }

      const day = dateToPBDate(pickersDayProps.day);
      const dayIndex = selectedDays.findIndex((d) => isEqual(d, day));
      return <CustomPickersDay {...pickersDayProps} selected={dayIndex >= 0} />;
    };

    const submit = async (days: Day[]) => {
      onSubmit(days);
      return Promise.resolve();
    };

    return (
      <Stack sx={sx} className={className}>
        <FormPopoverHeader title={strings.datePickerTitle()} />

        <DateCalendar
          minDate={minDay ? dayToDate(minDay) : undefined}
          maxDate={maxDay ? dayToDate(maxDay) : undefined}
          onChange={(date: Date) => date != null && didSelectDate(date)}
          slots={{ day: PickerDay }}
          showDaysOutsideCurrentMonth
          disableHighlightToday
        />

        <FormPopoverActions
          onCancel={() => submit([])}
          onSubmit={() => submit(selectedDays)}
          canSubmit={selectedDays.length > 0}
          customSubmitTitle={strings.datePickerSubmitButtonTitle()}
          customSubmitIcon={<CheckRounded />}
        />
      </Stack>
    );
  }
);

interface CustomPickerDayProps extends PickersDayProps<Date> {
  selected: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'selected'
})<CustomPickerDayProps>(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark
    }
  })
})) as ComponentType<CustomPickerDayProps>;
