import { CourseSectionInfo } from '@/models';
import { CheckRounded } from '@mui/icons-material';
import { Box, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { localizedExternalSourceName } from '../../../../resources/strings/utils/ExternalSourceStringsUtils';

export interface PlannerDistributeItemCourseCellProps {
  sx?: SxProps;
  className?: string;
  course: CourseSectionInfo;
  isSelected: boolean;
  onClick: () => void;
}

export const PlannerDistributeItemCourseCell = observer(
  ({ sx, course, className, onClick, isSelected }: PlannerDistributeItemCourseCellProps) => {
    const theme = useTheme();

    return (
      <CardActionArea
        sx={{ px: 1, display: 'flex', alignItems: 'stretch', borderRadius: 1, minHeight: 40, py: 0.5, ...sx }}
        className={className}
        onClick={() => onClick()}
      >
        <Box
          sx={{
            backgroundColor: course.color.length > 0 ? course.color : theme.palette.action.disabled,
            width: 4,
            borderRadius: 2,
            mr: 1
          }}
        />

        <Stack flex={1} alignSelf="center">
          <Typography variant="body2">{course.title}</Typography>
          <Typography variant="caption" color="textSecondary">
            {course.section}
          </Typography>

          {course.externalSource != null && (
            <Typography variant="caption" color="textSecondary">
              {LocalizedStrings.planner.distributeItem.courseSectionExternalSource(
                localizedExternalSourceName(course.externalSource.sourceName)
              )}
            </Typography>
          )}
        </Stack>

        {isSelected && <CheckRounded color="primary" sx={{ ml: 1, alignSelf: 'center' }} />}
      </CardActionArea>
    );
  }
);
