import { Box, lighten, Stack, styled } from '@mui/material';
import { SxProps } from '@mui/system';

export interface ColorPaletteProps {
  className?: string;

  sx?: SxProps;

  /**
   * The hex colors.
   */
  palette: string[];

  /**
   * The size of the colors. Optional. Default is `25`.
   */
  size?: number;

  /**
   * Callback when changing color.
   * @param color The hex color.
   */
  onChange(color: string): void;
}

export const ColorPalette = ({ className, sx, palette, size = 25, onChange }: ColorPaletteProps) => (
  <Stack className={className} sx={sx} direction="row" flexWrap="wrap">
    {palette.map((c, i) => (
      <Color
        key={`palette-color-${i}`}
        width={size}
        height={size}
        onClick={() => onChange(c)}
        sx={{
          backgroundColor: c,
          '&:hover': {
            backgroundColor: lighten(c, 0.2)
          }
        }}
      />
    ))}
  </Stack>
);

const Color = styled(Box)(({ theme }) => ({
  transition: theme.transitions.create('background-color', {
    duration: theme.transitions.duration.shortest
  }),
  margin: theme.spacing(0.5),
  cursor: 'pointer',
  borderRadius: theme.shape.borderRadius * 0.5
}));
