import { useActivePlannerId, useActiveSubscription, useServices } from '@/hooks';
import {
  UserDashboardInfo,
  plannerHasRelationshipKindsForUser,
  plannerHasScheduleCycle,
  plannerSchools
} from '@/models';
import { UserDashboardPlannerItemsLocationState } from '@/services';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { EditCalendarRounded, LightbulbCircleRounded, SchoolRounded } from '@mui/icons-material';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { PlannerNewCurriculumCoursesDialog, PlannerNewSchoolsDialog } from '../planner';
import {
  MainAppBar,
  ScheduleCycleCreateDialog,
  ScheduleCycleCreationUserDashboardPicker,
  UserDashboardBanner,
  UserDashboardSideBar
} from '../shared';
import { SubscriptionAlertBanner, SubscriptionsDialog } from '../subscriptions';
import { MediaQuery, UnsplashAttribution } from '../utils';
import { PasteboardContentBanner } from './PasteboardContentBanner';
import { StudentPlannerBanner } from './StudentPlannerBanner';
import { UserDashboardLayoutDialogs } from './UserDashboardLayoutDialogs';

interface LocationState {
  userDashboardLayoutShowCreateScheduleCycle?: UserDashboardInfo;
  userDashboardLayoutShowSubscribe?: boolean;
}

export interface UserDashboardLayoutProps {
  sx?: SxProps;
  className?: string;
  canShowCreateScheduleBanner?: boolean;
  children: ReactNode;
}

export const UserDashboardLayout = observer(
  ({ sx, className, canShowCreateScheduleBanner = false, children }: UserDashboardLayoutProps) => {
    const { analytics, localization, route, settings, settingsStorage, syncService, userStore } = useServices();
    const plannerId = useActivePlannerId();
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';
    const strings = localization.localizedStrings.utils;
    const { isSubscribed, isEntitledToSharedSchools } = useActiveSubscription();

    const [showScheduleUserDashboardPicker, setShowScheduleUserDashboardPicker] = useState(false);

    const location = useLocation();
    const state = (location.state ?? {}) as LocationState & UserDashboardPlannerItemsLocationState;
    const navigate = useNavigate();

    useEffect(() => {
      settingsStorage.activeDashboard = { id: plannerId, kind: 'planner' };
    }, [plannerId]);

    useEffect(() => {
      const sync = async () => {
        await syncService.syncPlanner(plannerId, true);

        // Waiting for initial sync to complete before starting the auto sync.
        syncService.startAutoSyncForPlanner(plannerId);
      };

      void sync();

      settingsStorage.activeDashboard = { id: plannerId, kind: 'planner' };
      userStore.addPlannerToHistory(plannerId);
      return () => syncService.stopAutoSyncForPlanner(plannerId);
    }, [plannerId]);

    useEffect(() => analytics.logScreenView_Dashboard(), []);

    const planner = userStore.getPlannerForId(plannerId);
    const showViewingStudentPlannerBanner =
      planner != null &&
      plannerHasRelationshipKindsForUser(userStore.user.userId, planner, PlannerRelationshipKind.TEACHER);

    const showNoScheduleCycleBanner =
      planner != null && !plannerHasScheduleCycle(planner, userStore.schools) && canShowCreateScheduleBanner;

    const userId = userStore.user.userId;

    const schools = planner != null ? plannerSchools(planner, userStore.schools) : [];

    const ownedSchools = schools.filter(
      (s) =>
        s.school != null &&
        s.owners.some((o) => o.userId === userId) &&
        !s.school.isArchived &&
        !(planner?.ignoredSchoolIds.includes(s.school.id) ?? true)
    );

    const showCanShareSchoolBanner =
      (!isSubscribed || !isEntitledToSharedSchools) &&
      ownedSchools.length > 0 &&
      // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
      !ownedSchools.some((s) => s.school?.sharingMode === SchoolSharingMode.SHARED);

    function goBackToOwnedPlanner() {
      const destinationPlannerId = userStore.idOfLastOwnedPlannerShowed;
      if (destinationPlannerId != null) {
        const destination = route.resolvePlannerLocation(destinationPlannerId);
        navigate(destination);
      }
    }

    function showCreateScheduleCycle() {
      if (planner == null) {
        return null;
      }

      // Not checking for presence of schedule as the banner should not be displayed in the first place.
      if (schools.length > 0) {
        setShowScheduleUserDashboardPicker(true);
      } else {
        // Creating schedule in planner if no owned schools
        createScheduleCycleForUserDashboard({ kind: 'planner', id: plannerId });
      }
    }

    function onCreateScheduleCycleUserDashboardSelection(dashboard: UserDashboardInfo | undefined) {
      setShowScheduleUserDashboardPicker(false);
      if (dashboard != null) {
        createScheduleCycleForUserDashboard(dashboard);
      }
    }

    function createScheduleCycleForUserDashboard(dashboard: UserDashboardInfo) {
      const newState: LocationState = {
        ...state,
        userDashboardLayoutShowCreateScheduleCycle: dashboard
      };
      navigate(location, { state: newState });
    }

    function shareSchool() {
      const newState: LocationState = { ...state, userDashboardLayoutShowSubscribe: true };
      navigate(location, { state: newState });
    }

    return (
      <Box className={className} sx={sx} height="100%" width="100%" display="flex">
        <Stack
          height="100%"
          width="100%"
          flex={1}
          spacing={0}
          sx={{
            backgroundImage: { xs: undefined, sm: `url(${settings.backgroundImage?.urls.large})` },
            backgroundPosition: 'center',
            backgroundSize: 'cover'
          }}
        >
          {showViewingStudentPlannerBanner && <StudentPlannerBanner onClick={goBackToOwnedPlanner} />}

          <MainAppBar />

          <Box
            className={className}
            sx={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'row',
              backgroundColor:
                settings.backgroundImage == null && theme.palette.mode === 'light' ? theme.palette.grey.A200 : undefined
            }}
          >
            <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
              <UserDashboardSideBar id="user-dashboard-layout" />
            </MediaQuery>

            <Box
              sx={{
                flex: 1,
                height: '100%',
                width: '100%',
                overflowY: 'auto',
                overflowX: 'hidden',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {settingsStorage.isDemoMode && (
                <Box
                  p={0.5}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    backgroundColor: theme.palette.info.main,
                    color: theme.palette.getContrastText(theme.palette.info.main)
                  }}
                >
                  <Typography variant="subtitle2">{strings.demoMode}</Typography>
                </Box>
              )}

              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  '.banner': { marginBottom: 0.5 },
                  '.banner:first-of-type': { marginTop: 1 },
                  '.banner:last-child': { marginBottom: 0 },
                  mx: 1
                }}
              >
                <UserDashboardBanner
                  className="banner"
                  identifier="share-school"
                  isVisible={showCanShareSchoolBanner}
                  title={strings.shareSchoolBannerTitle}
                  color="info"
                  icon={(color) => <SchoolRounded sx={{ color }} />}
                  actions={[
                    {
                      case: 'icon-text',
                      value: { text: strings.shareSchoolBannerLearnMore, icon: <LightbulbCircleRounded /> },
                      onClick: shareSchool
                    }
                  ]}
                />

                <SubscriptionAlertBanner plannerId={plannerId} />

                <UserDashboardBanner
                  className="banner"
                  identifier="no-personal-schedule"
                  isVisible={showNoScheduleCycleBanner}
                  title={strings.createScheduleCycleBannerLabel}
                  actions={[
                    {
                      case: 'icon-text',
                      value: {
                        text: strings.createScheduleCycleBannerActionLabel,
                        icon: <EditCalendarRounded />
                      },
                      onClick: showCreateScheduleCycle
                    }
                  ]}
                />
              </Box>

              <Box
                flex={1}
                sx={{
                  overflowY: 'auto',
                  overflowX: 'hidden',
                  display: 'flex',
                  flexDirection: 'row',
                  padding: {
                    xs: 0,
                    sm: settings.backgroundImage == null && isDarkTheme ? 0 : 1,
                    md: settings.backgroundImage == null && isDarkTheme ? 0 : 2
                  },
                  paddingBottom: {
                    xs: 0,
                    sm: settings.backgroundImage != null || isDarkTheme ? 0 : 1,
                    md: settings.backgroundImage != null || isDarkTheme ? 0 : 2
                  }
                }}
              >
                {children}
              </Box>

              <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
                {settings.backgroundImage != null && (
                  <UnsplashAttribution picture={settings.backgroundImage} withGradient />
                )}
              </MediaQuery>
            </Box>
          </Box>
        </Stack>

        <PasteboardContentBanner />

        <PlannerNewSchoolsDialog />
        <PlannerNewCurriculumCoursesDialog />

        {state.userDashboardLayoutShowCreateScheduleCycle != null && (
          <ScheduleCycleCreateDialog isOpen={true} userDashboard={state.userDashboardLayoutShowCreateScheduleCycle} />
        )}

        {showScheduleUserDashboardPicker && (
          <ScheduleCycleCreationUserDashboardPicker
            isOpen={true}
            onClose={onCreateScheduleCycleUserDashboardSelection}
          />
        )}

        {state.userDashboardLayoutShowSubscribe === true && <SubscriptionsDialog isOpen={true} />}

        <UserDashboardLayoutDialogs />
      </Box>
    );
  }
);
