import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { PlannerDataStore, UserDataStore } from '@/stores';
import { ValidateSharingCodeResponse } from '@/transports';
import { NoMatchingPlannerError, isSharingCodeComplete } from '@/utils';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { SharingCodeKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/sharing_code_kind_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface UserSharingInvitationCodeResult {
  schoolId: string | undefined;
  plannerId: string;
  impliedUserPersona: UserPersona;
}

export interface BaseUseSharingInvitationCodeViewModel {
  sharingInvitationCode?: string;

  readonly validationResponse?: ValidateSharingCodeResponse;
  readonly isApplying: boolean;
  readonly canApply: boolean;
  readonly error?: string;
  readonly showNoMatchingPlannerAlert: boolean;
  readonly showParticipationCodeAlert: boolean;

  dismissNoMatchingPlannerAlert(): void;
  dismissParticipationCodeAlert(userConfirmed: boolean): Promise<void>;
  apply(shouldUpdateDashboards: boolean): Promise<void>;
}

export class AppBaseUseSharingInvitationCodeViewModel implements BaseUseSharingInvitationCodeViewModel {
  @observable protected _sharingInvitationCode: string | undefined;
  @observable protected _validationResponse: ValidateSharingCodeResponse | undefined;
  @observable protected _isApplying = false;
  @observable protected _error: string | undefined;
  @observable private _showNoMatchingPlannerAlert = false;
  @observable private _participationCodeRelationshipKind: PlannerRelationshipKind | undefined;

  constructor(
    private readonly _plannerId: string | undefined,
    private readonly _onSuccess: (result: UserSharingInvitationCodeResult, hasCreatedPlanner: boolean) => Promise<void>,
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization,
    protected readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    protected readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);
  }

  @computed
  get canApply(): boolean {
    return !this.isApplying && isSharingCodeComplete(this._sharingInvitationCode);
  }

  @computed
  get sharingInvitationCode(): string | undefined {
    return this._sharingInvitationCode;
  }

  set sharingInvitationCode(value: string | undefined) {
    this._sharingInvitationCode = value;
  }

  @computed
  get validationResponse(): ValidateSharingCodeResponse | undefined {
    return this._validationResponse;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get error(): string | undefined {
    return this._error;
  }

  @computed
  get showNoMatchingPlannerAlert(): boolean {
    return this._showNoMatchingPlannerAlert;
  }

  @computed
  get showParticipationCodeAlert(): boolean {
    return this._participationCodeRelationshipKind != null;
  }

  @action
  dismissNoMatchingPlannerAlert() {
    this._showNoMatchingPlannerAlert = false;
  }

  async dismissParticipationCodeAlert(userConfirmed: boolean) {
    // Alert needs to be presented before calling this method.
    if (this._participationCodeRelationshipKind == null) {
      return;
    }

    const relationshipKind = this._participationCodeRelationshipKind;
    const persona = this.userPersonaForRelationshipKind(relationshipKind);
    runInAction(() => (this._participationCodeRelationshipKind = undefined));

    if (!userConfirmed || this._sharingInvitationCode == null) {
      return;
    }

    runInAction(() => (this._isApplying = true));

    try {
      let plannerId = this._plannerId;

      if (plannerId == null) {
        // If there is no planner, we first create one.
        plannerId = await this._userStore.createPlanner(relationshipKind, undefined, false);
      }

      await this._userStore.usePlannerParticipationCode(this._sharingInvitationCode, plannerId);
      await this._onSuccess({ plannerId, schoolId: undefined, impliedUserPersona: persona }, true);
    } catch (e) {
      const strings = this._localization.localizedStrings.sharingInvitationCode;
      const error = e as Error;

      runInAction(() => {
        if (error instanceof NoMatchingPlannerError) {
          // UseSharingInvitationCode was successful, but we couldn't fetch the new planner.
          this._showNoMatchingPlannerAlert = true;
        } else {
          this._error = strings.unexpectedError(error.message);
        }
      });
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  @action
  async apply(shouldUpdateDashboards: boolean): Promise<void> {
    if (this._sharingInvitationCode == null) {
      return;
    }

    this._isApplying = true;
    this._error = undefined;
    const strings = this._localization.localizedStrings.sharingInvitationCode;

    try {
      const validation = await this._userStore.validatePlannerSharingCode(this._sharingInvitationCode);
      runInAction(() => (this._validationResponse = validation));

      if (validation.isValid && validation.kind !== SharingCodeKind.UNSPECIFIED) {
        await this.usePlannerCode(
          this._sharingInvitationCode,
          validation.kind,
          validation.relationshipKind,
          shouldUpdateDashboards
        );
      } else {
        const schoolId = await this._userStore.useSchoolParticipationCode(
          this._sharingInvitationCode,
          shouldUpdateDashboards
        );

        const plannerId =
          this._plannerId ??
          (await this._userStore.createPlanner(PlannerRelationshipKind.INDIVIDUAL, undefined, shouldUpdateDashboards));

        if (shouldUpdateDashboards) {
          // Updating course sections following new school.
          void this._plannerStore.getCourseSectionsInPlanner(plannerId).fetch(true);
        }

        await this._onSuccess({ plannerId, schoolId, impliedUserPersona: UserPersona.TEACHER }, true);
      }
    } catch (e) {
      const error = e as Error;

      runInAction(() => {
        if (error instanceof NoMatchingPlannerError) {
          // UseSharingInvitationCode was successful, but we couldn't fetch the new planner.
          this._showNoMatchingPlannerAlert = true;
        } else {
          this._error = strings.unexpectedError(error.message);
        }
      });
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  private async usePlannerCode(
    code: string,
    kind: SharingCodeKind,
    relationshipKind: PlannerRelationshipKind,
    shouldUpdateDashboards: boolean
  ) {
    switch (kind) {
      case SharingCodeKind.INVITATION: {
        const result = await this._userStore.usePlannerSharingInvitationCode(code, shouldUpdateDashboards);
        await this._onSuccess(
          {
            plannerId: result.id,
            schoolId: undefined,
            impliedUserPersona: this.userPersonaForRelationshipKind(relationshipKind)
          },
          false
        );
        break;
      }

      case SharingCodeKind.PARTICIPATION:
        // User needs to confirm they accept to share their planner.
        runInAction(() => (this._participationCodeRelationshipKind = relationshipKind));
        break;
    }
  }

  private userPersonaForRelationshipKind(relationshipKind: PlannerRelationshipKind): UserPersona {
    switch (relationshipKind) {
      case PlannerRelationshipKind.CREATOR:
      case PlannerRelationshipKind.INDIVIDUAL:
      case PlannerRelationshipKind.UNSPECIFIED:
      case PlannerRelationshipKind.STUDENT:
        return UserPersona.STUDENT;

      case PlannerRelationshipKind.PARENT:
        return UserPersona.PARENT;

      case PlannerRelationshipKind.TEACHER:
        return UserPersona.TEACHER;
    }
  }
}
