import { useServices } from '@/hooks';
import { Tab, Tabs } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface StudentInsightsTabsProps {
  sx?: SxProps;
  className?: string;
  currentTabIndex: number;
  onTabIndexChange: (index: number) => void;
}

export const StudentInsightsTabs = observer(
  ({ sx, className, currentTabIndex, onTabIndexChange }: StudentInsightsTabsProps) => {
    const { localization } = useServices();
    const { schoolCourseSection: teacherStrings, workList: workListStrings } = localization.localizedStrings;

    const handleTabChange = (newValue: number) => {
      onTabIndexChange(newValue);
    };

    return (
      <Tabs
        value={currentTabIndex}
        centered
        onChange={(_, value: number) => handleTabChange(value)}
        sx={sx}
        className={className}
      >
        <Tab wrapped label={teacherStrings.studentsInsightsInformationTabTitle} />
        <Tab wrapped label={workListStrings.studentWorksTableTitle} />
      </Tabs>
    );
  }
);
