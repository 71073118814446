import { Box, Divider, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { useSync, useViewModel } from '../../../../hooks';
import { UpdatablePresenter } from '../../../utils';
import { AdminSchoolClassDetailsHeader } from './AdminSchoolClassDetailsHeader';
import { AdminSchoolClassStudentsList } from './AdminSchoolClassStudentsList';
import { AdminSchoolClassTeachersList } from './AdminSchoolClassTeachersList';

export interface AdminSchoolClassDetailsPageProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolClassDetailsPage = observer(({ sx, className }: AdminSchoolClassDetailsPageProps) => {
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const courseSectionId = params.courseSectionId ?? '';

  const viewModel = useViewModel(
    (viewModels) => viewModels.createAdminClassDetailsViewModel(courseSectionId, schoolId),
    [courseSectionId, schoolId]
  );

  useSync('admin-class-details', () => viewModel.reloadData(), [viewModel]);

  return (
    <Box sx={sx} className={className} overflow="hidden">
      <UpdatablePresenter
        sx={{ height: '100%', overflow: 'hidden' }}
        viewModel={viewModel}
        renderData={() => (
          <Stack sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
            <AdminSchoolClassDetailsHeader courseSection={viewModel.courseSection} sx={{ flexShrink: 0 }} />
            <Divider />

            <Stack p={2} spacing={2} overflow="auto">
              <AdminSchoolClassTeachersList
                teachers={viewModel.teachers}
                scheduleCycleId={viewModel.currentScheduleCycleId}
                sx={{ flexShrink: 0 }}
                isReadOnly={viewModel.isReadOnly}
              />
              <AdminSchoolClassStudentsList students={viewModel.students} sx={{ flexShrink: 0 }} />
            </Stack>
          </Stack>
        )}
      />
    </Box>
  );
});
