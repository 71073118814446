import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { PartialMessage } from '@bufbuild/protobuf';
import {
  ArchiveRounded,
  ContentCopyRounded,
  DifferenceRounded,
  LowPriorityRounded,
  RepeatRounded
} from '@mui/icons-material';
import { Box, Grid, Stack, TextField } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useServices, useViewModel } from '../../hooks';
import { plannerCourseSectionDetailsToInfo } from '../../models';
import { CourseSectionEditDialog, CourseSectionPicker } from '../shared';
import { ConfirmationDialog, DateTimePicker, Dialog, DialogAdditionalAction, Subheader } from '../utils';

interface LocationState {
  noteEditShowCreateCourseSection?: boolean;
}

export interface NoteEditDialogProps {
  /**
   * Id of the note we wish to edit. Optional. If undefined, it creates a new note.
   */
  noteId: string | undefined;
  /**
   * Initial values for a new work. Optional.
   */
  newNoteDefaultValues?: PartialMessage<Note>;
  isOpen: boolean;
}

export const NoteEditDialog = observer(({ noteId, newNoteDefaultValues, isOpen }: NoteEditDialogProps) => {
  const { featureFlag, route } = useServices();

  const params = useParams();
  const plannerId = params.plannerId ?? '';
  const strings = LocalizedStrings.note.edit;

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigate();
  const [showCancelWorkConfirmAlert, setShowCancelNoteConfirmAlert] = useState(false);

  function onSuccess() {
    navigate(-1);
    return Promise.resolve();
  }

  function close() {
    navigate(-1);
    return Promise.resolve();
  }

  // Using a ref to prevent the viewModel from being recreated when hot-reloading.
  const viewModelRef = useRef(
    useViewModel(
      (viewModels) => viewModels.createNoteEditViewModel(noteId, newNoteDefaultValues, plannerId, onSuccess, close),
      [plannerId]
    )
  );

  const viewModel = viewModelRef.current;

  function showCreateCourseSection() {
    const newState: LocationState = { ...state, noteEditShowCreateCourseSection: true };
    navigate(location, { state: newState });
  }

  function onCreateCourseClose(courseSectionId: string | undefined) {
    if (courseSectionId != null) {
      viewModel.setCourseSection(courseSectionId);
    }
  }

  async function dismissCancelNoteConfirmAlert(hasConfirmed: boolean) {
    setShowCancelNoteConfirmAlert(false);

    if (hasConfirmed) {
      const wasCancelled = await viewModel.cancelNote();
      if (wasCancelled) {
        navigate(route.resolvePlannerLocation(plannerId), { replace: true });
      }
    }
  }

  function duplicate() {
    viewModel.duplicate(navigate, location);
    return Promise.resolve();
  }

  function distribute() {
    viewModel.distribute(navigate, location);
    return Promise.resolve();
  }

  function repeat() {
    viewModel.repeat(navigate, location);
    return Promise.resolve();
  }

  const isItemDuplicationEnabled = featureFlag.isEnabled('item-duplication');

  const actions: DialogAdditionalAction[] =
    viewModel.hasData && !viewModel.isNewNote
      ? [
          {
            title: strings.copyNote(),
            icon: <ContentCopyRounded />,
            isHidden: !isItemDuplicationEnabled,
            action: () => {
              viewModel.copyToPasteboard();
              return Promise.resolve();
            }
          },
          {
            title: strings.duplicateNote(),
            icon: <DifferenceRounded />,
            isHidden: !isItemDuplicationEnabled || viewModel.hasChanges,
            action: duplicate
          },
          {
            title: strings.distributeNote(),
            icon: <LowPriorityRounded />,
            isHidden:
              !isItemDuplicationEnabled ||
              viewModel.hasChanges ||
              viewModel.time == null ||
              !viewModel.courseSection != null,
            action: distribute
          },
          {
            title: strings.repeatNote(),
            icon: <RepeatRounded />,
            isHidden: !isItemDuplicationEnabled || viewModel.hasChanges,
            action: repeat
          },
          {
            title: strings.archiveNote(),
            icon: <ArchiveRounded />,
            isDestructive: true,
            action: () => {
              setShowCancelNoteConfirmAlert(true);
              return Promise.resolve();
            }
          }
        ]
      : [];

  return (
    <>
      <Dialog
        isOpen={isOpen}
        canToggleFullscreen
        width="lg"
        viewModel={viewModel}
        title={noteId != null ? strings.editTitle() : strings.addTitle()}
        fullScreenWidth="md"
        submit={() => void viewModel.save()}
        additionalActions={actions}
        renderData={() => (
          <Grid container spacing={3} pb={viewModel.error != null ? 3 : 0}>
            <Grid item xs={12} sm={12} md={8}>
              <Stack direction="column" spacing={2}>
                <Box>
                  <TextField
                    autoFocus
                    label={strings.textLabel()}
                    value={viewModel.text}
                    fullWidth
                    multiline
                    onChange={(e) => (viewModel.text = e.target.value)}
                    minRows={20}
                    disabled={viewModel.isApplying}
                  />
                </Box>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Stack direction="column" spacing={2}>
                <Box>
                  <Stack direction="column" spacing={2}>
                    <CourseSectionPicker
                      label={strings.courseTitle()}
                      disabled={viewModel.isApplying}
                      selectedCourseId={viewModel.courseSection?.courseSection?.id}
                      allCourseSections={viewModel.allCourseSections.map(plannerCourseSectionDetailsToInfo)}
                      onChange={(id) => viewModel.setCourseSection(id)}
                      onCreateCourseClick={() => showCreateCourseSection()}
                      elevation={2}
                    />
                  </Stack>
                </Box>

                <Box>
                  <Subheader>{strings.timeSectionTitle()}</Subheader>
                  <DateTimePicker
                    direction="column"
                    value={viewModel.time}
                    disabled={viewModel.isApplying}
                    hasTime={!viewModel.isAllDay}
                    datePickerLabel={strings.dateInputLabel()}
                    timePickerLabel={strings.timeInputLabel()}
                    datePickerKind="user-dashboard-calendar"
                    highlightedDates={(from, to) => viewModel.getDatesWithOccurrenceForCourseSection(from, to)}
                    getPeriodTimesForDate={(date) => viewModel.getCourseSectionOccurrencesStartTimeForDate(date)}
                    dateFormat={strings.dateInputDateFormat()}
                    onChange={(value, hasTime) => {
                      viewModel.time = value;
                      viewModel.isAllDay = !hasTime;
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
        )}
      />

      {state.noteEditShowCreateCourseSection === true && (
        <CourseSectionEditDialog isOpen={true} courseSectionId={undefined} onClose={onCreateCourseClose} />
      )}

      {showCancelWorkConfirmAlert && (
        <ConfirmationDialog
          isOpen={true}
          title={strings.archiveNoteConfirmationTitle()}
          message={strings.archiveNoteConfirmationMessage()}
          confirmButtonLabel={strings.archiveNoteConfirmationButton()}
          onSubmit={(hasConfirmed) => void dismissCancelNoteConfirmAlert(hasConfirmed)}
        />
      )}
    </>
  );
});
