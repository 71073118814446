import { WorkStepEditViewModel } from '@/viewmodels';
import { CheckCircleRounded, CircleOutlined, DeleteRounded } from '@mui/icons-material';
import { Button, Card, IconButton, InputBase, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../hooks';
import { WorkStepDateSelectionControl } from './WorkStepDateSelectionControl';
import { WorkStepDurationControl } from './WorkStepDurationControl';

export interface WorkDetailsStepEditCardProps {
  sx?: SxProps;
  className?: string;
  step: WorkStepEditViewModel;
}

export const WorkDetailsStepEditCard = observer(({ sx, className, step }: WorkDetailsStepEditCardProps) => {
  const { localization } = useServices();
  const theme = useTheme();
  const strings = localization.localizedStrings.work.details;
  const isDarkMode = theme.palette.mode === 'dark';

  return (
    <Card sx={sx} className={className} elevation={isDarkMode ? 2 : 4}>
      <Stack p={1} pt={0.5} spacing={1}>
        <Stack spacing={1} direction="row" alignItems={'flex-start'}>
          <IconButton
            color={step.isCompleted ? 'primary' : undefined}
            size="small"
            onClick={() => (step.isCompleted = !step.isCompleted)}
          >
            {step.isCompleted ? <CheckCircleRounded fontSize="small" /> : <CircleOutlined fontSize="small" />}
          </IconButton>

          <InputBase
            sx={{ flex: 1, ...theme.typography.body2 }}
            autoFocus
            placeholder={strings.stepEditTitlePlaceholder}
            multiline
            value={step.title}
            onChange={(e) => (step.title = e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey && step.canSave) {
                e.preventDefault();
                step.save();
              }
            }}
          />

          {step.canDelete && (
            <IconButton color="error" size="small" onClick={() => step.delete()}>
              <DeleteRounded fontSize="small" />
            </IconButton>
          )}
        </Stack>

        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          alignItems={{ xs: 'stretch', sm: 'last baseline' }}
          flexWrap="wrap"
          rowGap={1}
          columnGap={1}
        >
          <Stack flex={1} direction="row" alignItems="center" flexWrap="wrap" rowGap={1} columnGap={1}>
            <WorkStepDurationControl duration={step.duration} onDurationChange={(d) => (step.duration = d)} />
            <WorkStepDateSelectionControl viewModel={step} />
          </Stack>

          <Stack spacing={1} direction="row" alignItems="center" justifyContent="flex-end">
            <Button size="small" color="error" onClick={() => step.cancel()}>
              {strings.stepEditCancelButtonTitle}
            </Button>
            <Button size="small" onClick={() => step.save()} disabled={!step.canSave}>
              {strings.stepEditSaveButtonLabel}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
});
