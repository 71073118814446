import { useViewModel } from '@/hooks';
import { AllDayOfWeek, DayOfWeek } from '@/models';
import { AddRounded, CheckRounded, DeleteRounded, MoreVertRounded } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
  createFilterOptions,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { ActionMenu, ConfirmationDialog, FormPopoverActions, FormPopoverHeader } from '../../../../utils';
import { useScheduleCycleUserDashboard } from '../../UseScheduleCycleUserDashboardHook';
import { iconForSpecialDayOccurrence } from '../special-days';

interface ScheduleTagOption {
  inputValue?: string;
  tag: string;
}

const scheduleTagsFilter = createFilterOptions<ScheduleTagOption>();

export interface ScheduleCycleBellTimesAddProps {
  sx?: SxProps;
  className?: string;
  periodScheduleId?: string;
  scheduleCycleId: string;
  onDismiss: () => void;
}

export const ScheduleCyclePeriodScheduleDetailsEdit = observer(
  ({ sx, className, periodScheduleId, scheduleCycleId, onDismiss }: ScheduleCycleBellTimesAddProps) => {
    const theme = useTheme();
    const strings = LocalizedStrings.scheduleCycle.edit.bellTimes.edit;
    const { dashboard } = useScheduleCycleUserDashboard();

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createScheduleCyclePeriodScheduleDetailsEditViewModel(periodScheduleId, scheduleCycleId, dashboard),
      [periodScheduleId, scheduleCycleId]
    );

    async function save() {
      await viewModel.save();
      onDismiss();
    }

    const [addOccurrenceButtonRef, setAddOccurrenceButtonRef] = useState<HTMLDivElement | undefined>();
    const [addCycleDayOccurrenceButtonRef, setAddCycleDayOccurrenceButtonRef] = useState<HTMLLIElement | undefined>();
    const [addDayOfWeekOccurrenceButtonRef, setAddDayOfWeekOccurrenceButtonRef] = useState<HTMLLIElement | undefined>();
    const [optionsMenuButtonRef, setOptionsMenuButtonRef] = useState<HTMLButtonElement | undefined>();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    function addOccurrenceForCycleDay(cycleDay: number) {
      setAddCycleDayOccurrenceButtonRef(undefined);
      setAddOccurrenceButtonRef(undefined);
      viewModel.addOccurrence({ case: 'cycleDay', value: cycleDay });
    }

    function addOccurrenceForDayOfWeek(dow: DayOfWeek) {
      setAddDayOfWeekOccurrenceButtonRef(undefined);
      setAddOccurrenceButtonRef(undefined);
      viewModel.addOccurrence({ case: 'dayOfWeek', value: dow });
    }

    function onDeleteOptionPressed() {
      setOptionsMenuButtonRef(undefined);
      setShowDeleteConfirmation(true);
    }

    async function onDeleteConfirmationSubmit(hasConfirmation: boolean) {
      setShowDeleteConfirmation(false);

      if (hasConfirmation) {
        setIsDeleting(true);
        await viewModel.delete();
        onDismiss();
      }
    }

    return (
      <Stack sx={sx} className={className}>
        <Box>
          <FormPopoverHeader
            title={viewModel.isNew ? strings.addTitle() : strings.editTitle()}
            actions={
              !viewModel.isNew
                ? [{ icon: <MoreVertRounded />, onClick: (e) => setOptionsMenuButtonRef(e.currentTarget) }]
                : undefined
            }
          />

          <ActionMenu
            open={optionsMenuButtonRef != null}
            anchorEl={optionsMenuButtonRef}
            onClose={() => setOptionsMenuButtonRef(undefined)}
          >
            <MenuItem onClick={onDeleteOptionPressed}>
              <ListItemIcon>
                <DeleteRounded color="error" />
              </ListItemIcon>
              <ListItemText
                primary={strings.deleteOptionTitle()}
                primaryTypographyProps={{ color: theme.palette.error.main }}
              />
            </MenuItem>
          </ActionMenu>

          <ConfirmationDialog
            isOpen={showDeleteConfirmation}
            title={strings.deleteConfirmationTitle()}
            message={strings.deleteConfirmationMessage()}
            confirmButtonLabel={strings.deleteConfirmationSubmitLabel()}
            isDestructive
            onSubmit={(hasConfirmed) => void onDeleteConfirmationSubmit(hasConfirmed)}
          />
        </Box>

        <Stack p={2}>
          <Stack spacing={2}>
            <TextField
              value={viewModel.name}
              label={strings.nameLabel()}
              onChange={(e) => (viewModel.name = e.currentTarget.value)}
            />

            {viewModel.supportsScheduleTag && (
              <Autocomplete
                multiple
                fullWidth
                freeSolo
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={viewModel.existingScheduleTags.map((tag) => ({ tag }) as ScheduleTagOption)}
                value={viewModel.scheduleTags.map((tag) => ({ tag }) as ScheduleTagOption)}
                onChange={(_, values) => {
                  viewModel.scheduleTags = values.map((v) => {
                    return typeof v === 'string' ? v : v.tag;
                  });
                }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.tag;
                }}
                filterOptions={(options, params) => {
                  const filtered = scheduleTagsFilter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some((option) => inputValue === option.tag);
                  if (inputValue !== '' && !isExisting) {
                    filtered.push({ inputValue: strings.scheduleTagsNew(inputValue), tag: inputValue });
                  }

                  return filtered;
                }}
                renderInput={(p) => <TextField {...p} label={strings.scheduleTagsInputLabel()} />}
              />
            )}

            {viewModel.isNew && (
              <FormControl>
                <InputLabel id="period-schedule-add-source-picker-label">{strings.copyFromLabel()}</InputLabel>
                <Select
                  value={viewModel.sourceScheduleId}
                  label={strings.copyFromLabel()}
                  labelId="period-schedule-add-source-picker-label"
                  onChange={(e) => (viewModel.sourceScheduleId = e.target.value)}
                  MenuProps={{ elevation: 2 }}
                >
                  <MenuItem value="">{strings.copyFromNoneOption()}</MenuItem>
                  {viewModel.possibleSourceSchedules.map((s) => (
                    <MenuItem key={s.id} value={s.id}>
                      {s.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Stack>

          <Stack>
            <Box sx={{ height: 40, overflow: 'hidden' }}>
              <ListSubheader>{strings.assignedToSectionTitle()}</ListSubheader>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', listStyle: 'none' }}>
              {viewModel.occurrences.map((o, index) => (
                <Tooltip
                  key={`special-day-occurrence-${index}`}
                  title={o.hasOtherOccurrencesWithSameTarget ? strings.hasOtherOccurrencesWithSameTarget() : ''}
                >
                  <Chip
                    sx={{ m: 0.5 }}
                    icon={o.occurrence.case != null ? iconForSpecialDayOccurrence(o.occurrence.case) : undefined}
                    color={o.hasOtherOccurrencesWithSameTarget ? 'warning' : 'default'}
                    label={o.title}
                    onDelete={() => viewModel.removeOccurrence(o.occurrence)}
                  />
                </Tooltip>
              ))}

              <Chip
                sx={{ m: 0.5 }}
                label={strings.assignedToAddButtonTitle()}
                icon={<AddRounded />}
                color={addOccurrenceButtonRef != null ? 'primary' : undefined}
                onClick={(e) => setAddOccurrenceButtonRef(e.currentTarget)}
              />

              <Menu
                open={addOccurrenceButtonRef != null}
                anchorEl={addOccurrenceButtonRef}
                onClose={() => setAddOccurrenceButtonRef(undefined)}
                elevation={2}
                slotProps={{ paper: { sx: { minWidth: 250 } } }}
              >
                <MenuItem disabled>{strings.assignToPickerTitle()}</MenuItem>

                <MenuItem onClick={(e) => setAddCycleDayOccurrenceButtonRef(e.currentTarget)}>
                  <ListItemIcon>{iconForSpecialDayOccurrence('cycleDay')}</ListItemIcon>
                  <ListItemText>{strings.assignToPickerRotatingDay()}</ListItemText>
                </MenuItem>

                <MenuItem onClick={(e) => setAddDayOfWeekOccurrenceButtonRef(e.currentTarget)}>
                  <ListItemIcon>{iconForSpecialDayOccurrence('dayOfWeek')}</ListItemIcon>
                  <ListItemText>{strings.assignToPickerDayOfWeek()}</ListItemText>
                </MenuItem>
              </Menu>

              <Menu
                open={addCycleDayOccurrenceButtonRef != null}
                anchorEl={addCycleDayOccurrenceButtonRef}
                anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                onClose={() => setAddCycleDayOccurrenceButtonRef(undefined)}
                elevation={theme.palette.mode === 'light' ? 1 : 8}
                slotProps={{ paper: { sx: { minWidth: 250 } } }}
              >
                <MenuItem disabled>{strings.assignToPickerRotatingDayPickerTitle()}</MenuItem>
                {viewModel.possibleCycleDays.map((c) => (
                  <MenuItem
                    key={`add-cycle-day-occurrence-${c.value}`}
                    onClick={() => addOccurrenceForCycleDay(c.value)}
                  >
                    <ListItemText>{c.title}</ListItemText>
                  </MenuItem>
                ))}
              </Menu>

              <Menu
                open={addDayOfWeekOccurrenceButtonRef != null}
                anchorEl={addDayOfWeekOccurrenceButtonRef}
                anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
                transformOrigin={{ vertical: 'center', horizontal: 'center' }}
                onClose={() => setAddDayOfWeekOccurrenceButtonRef(undefined)}
                elevation={theme.palette.mode === 'light' ? 1 : 8}
                slotProps={{ paper: { sx: { minWidth: 250 } } }}
              >
                <MenuItem disabled>{strings.assignToPickerDayOfWeekPickerTitle()}</MenuItem>
                {AllDayOfWeek.map((dow) => (
                  <MenuItem key={`add-day-of-week-occurrence-${dow}`} onClick={() => addOccurrenceForDayOfWeek(dow)}>
                    <ListItemText>{LocalizedStrings.dateTime.dayOfWeekTitle[dow]()}</ListItemText>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Stack>
        </Stack>

        <FormPopoverActions
          onCancel={() => {
            onDismiss();
            return Promise.resolve();
          }}
          onSubmit={save}
          canSubmit={viewModel.canSave && !isDeleting}
          customSubmitTitle={viewModel.isNew ? strings.addSubmitButton() : strings.editSubmitButton()}
          customSubmitIcon={<CheckRounded />}
        />
      </Stack>
    );
  }
);
