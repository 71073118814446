import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { AdminContentCard } from '../shared';
import { AdminSchoolScheduleCyclePage } from './AdminSchoolScheduleCyclePage';

export interface AdminSchoolSchedulePageProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolSchedulePage = observer(({ sx, className }: AdminSchoolSchedulePageProps) => {
  const { userStore } = useServices();
  const strings = LocalizedStrings.admin.schedule;
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const scheduleCycleId = params.scheduleCycleId ?? '';
  const school = userStore.getSchoolForId(schoolId);
  const isReadOnly = school?.school?.isArchived === true;

  const scheduleCycleStore = userStore.getScheduleCycleStore(scheduleCycleId, { id: schoolId, kind: 'school' });
  const subtitle = scheduleCycleStore.state === 'fulfilled' ? scheduleCycleStore.scheduleCycle.name : ' ';

  return (
    <Box sx={sx} className={className}>
      <AdminContentCard title={strings.title()} subtitle={subtitle} sx={{ height: '100%', overflow: 'hidden' }}>
        <AdminSchoolScheduleCyclePage
          sx={{ mb: 2, mx: 2, flex: 1, overflow: 'hidden' }}
          schoolId={schoolId}
          scheduleCycleId={scheduleCycleId}
          isReadOnly={isReadOnly}
        />
      </AdminContentCard>
    </Box>
  );
});
