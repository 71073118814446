import { useActivePlannerId, useSync, useViewModel } from '@/hooks';
import { Box, Unstable_Grid2 as Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { BackgroundImageScreenPaper, UpdatablePresenter } from '../../utils';
import { PlannerItems } from './PlannerItems';

export interface PlannerItemsPageProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerItemsPage = observer(({ sx, className }: PlannerItemsPageProps) => {
  const params = useParams();
  const plannerId = useActivePlannerId();
  const courseSectionId = params.courseSectionId;

  const viewModel = useViewModel(
    (viewModels) => viewModels.createPlannerItemsViewModel(plannerId, courseSectionId),
    [plannerId, courseSectionId]
  );

  useEffect(() => {
    void viewModel.reloadData();
  }, [plannerId, courseSectionId]);

  useSync(
    'PlannerItems',
    async () => {
      await viewModel.reloadData();
    },
    [plannerId, courseSectionId]
  );

  return (
    <Box sx={sx} className={className} width="100%" height="100%">
      <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <Grid container sx={{ width: '100%', height: '100%' }}>
          <Grid xs={12} mdOffset={1} md={10} lgOffset={2} lg={8} xlOffset={3} xl={6} height="100%">
            <BackgroundImageScreenPaper sx={{ height: 'calc(100% - 2px)' }}>
              <Box display="flex" flexDirection="column" height="100%">
                <UpdatablePresenter
                  sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
                  viewModel={viewModel}
                  renderData={() => (
                    <PlannerItems sx={{ width: '100%', height: '100%', overflow: 'hidden' }} viewModel={viewModel} />
                  )}
                />
              </Box>
            </BackgroundImageScreenPaper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
