import { useServices } from '@/hooks';
import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface SchoolCourseDetailsNoStudentSelectedProps {
  sx?: SxProps;
  className?: string;
}

export const SchoolCourseDetailsNoStudentSelected = observer(
  ({ sx, className }: SchoolCourseDetailsNoStudentSelectedProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.schoolCourseSection;

    return (
      <Box sx={{ ...sx, display: 'flex', alignItems: 'center', justifyContent: 'center', p: 3 }} className={className}>
        <Typography variant="subtitle2" color="textSecondary" fontWeight="500">
          {strings.detailsNoSelectedStudent}
        </Typography>
      </Box>
    );
  }
);
