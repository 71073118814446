import { useServices, useViewModel } from '@/hooks';
import ServerErrorImageFallback from '@/resources/images/error/illustration_server_error.png';
import ServerErrorImage from '@/resources/images/error/illustration_server_error.webp';
import { css } from '@emotion/css';
import RetryIcon from '@mui/icons-material/RefreshRounded';
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Splash } from '../Splash';
import { ConfirmationDialog, Image, MediaQuery } from '../utils';

export interface ServerErrorScreenProps {
  className?: string;

  /**
   * The server error.
   */
  error: Error;
}

export const ServerErrorScreen = observer(({ className, error }: ServerErrorScreenProps) => {
  const { localization, firebase } = useServices();
  const strings = localization.localizedStrings.utils;
  const mainScreenStrings = localization.localizedStrings.mainScreen;
  const [showSignOutAlert, setShowSignOutAlert] = useState(false);

  const viewModel = useViewModel((viewModels) => viewModels.createServerErrorScreenViewModel(error));

  useEffect(() => viewModel.logError(), [viewModel]);

  async function signOut(force: boolean) {
    if (!force) {
      setShowSignOutAlert(true);
    } else {
      await viewModel.signOut();
    }
  }

  function onSignOutAlertSubmit(result: boolean) {
    setShowSignOutAlert(false);

    if (result) {
      void signOut(true);
    }
  }

  const imageClassName = css([{ width: '100%', height: 'auto', margin: 0 }]);

  const content = (
    <>
      <Stack width="100%" height="100%" direction="column" spacing={6}>
        <Box>
          <Image
            sources={[
              { src: ServerErrorImage, type: 'image/webp' },
              { src: ServerErrorImageFallback, type: 'image/png' }
            ]}
            fallback={ServerErrorImageFallback}
            alt="Server error illustration"
            className={imageClassName}
          />
        </Box>

        <Stack spacing={2}>
          <Typography variant="h6" align="center">
            {strings.serverErrorTitle}
          </Typography>

          <ErrorText>
            <>
              {strings.serverErrorContent}
              <ContactUsLink
                href="/#"
                onClick={(e) => {
                  e.preventDefault();
                  viewModel.contactSupport();
                }}
              >
                {strings.serverErrorContactUs}
              </ContactUsLink>
              .
            </>
          </ErrorText>
        </Stack>

        <Stack spacing={2}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: '100%' }}
            startIcon={<RetryIcon />}
            onClick={() => viewModel.retry()}
          >
            {strings.serverErrorRetryButtonLabel}
          </Button>

          <Button sx={{ width: '100%' }} onClick={() => void signOut(false)}>
            {strings.logoutLabel}
          </Button>

          <Stack spacing={1}>
            {firebase.user && (
              <Typography variant="caption" align="center" color="textSecondary">
                <code style={{ fontFamily: 'inherit' }}>FBUID: {firebase.user.uid}</code>
              </Typography>
            )}

            {viewModel.traceId != null && (
              <Typography variant="caption" align="center" color="textSecondary">
                <code style={{ fontFamily: 'inherit' }}>TraceId: {viewModel.traceId}</code>
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>

      {showSignOutAlert && (
        <ConfirmationDialog
          isOpen={true}
          message={mainScreenStrings.anonymousLogoutMessage}
          confirmButtonLabel={mainScreenStrings.anonymousLogoutConfirmButtonLabel}
          onSubmit={onSignOutAlertSubmit}
        />
      )}
    </>
  );

  return (
    <>
      <MediaQuery mediaQuery={(th) => th.breakpoints.up('sm')}>
        <Splash className={className} width="100%" height="100%" contentWidth={400}>
          {content}
        </Splash>
      </MediaQuery>

      <MediaQuery mediaQuery={(th) => th.breakpoints.only('xs')}>
        <Box className={className} width="100%" height="100%" p={2}>
          {content}
        </Box>
      </MediaQuery>
    </>
  );
});

const ErrorText = styled('pre')(({ theme }) => ({
  textAlign: 'center',
  color: theme.palette.error.main,
  font: theme.typography.body2.font,
  fontFamily: theme.typography.body2.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight
}));

const ContactUsLink = styled('a')(({ theme }) => ({
  color: theme.palette.error.main
}));
