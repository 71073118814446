import { AttachmentInfo } from '@/models';
import { LinkRounded, UploadRounded } from '@mui/icons-material';
import { Box, Card, ListItemButton, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { Subheader } from '../../utils';
import { WorkAddFileAttachmentDialog } from './WorkAddFileAttachmentDialog';
import { WorkAddLinkAttachmentDialog } from './WorkAddLinkAttachmentDialog';

export interface WorkEditAddAttachmentProps {
  sx?: SxProps;
  className?: string;
  onCreateAttachment: (newAttachments: AttachmentInfo[]) => void;
}

export const WorkEditAddAttachment = observer(({ sx, className, onCreateAttachment }: WorkEditAddAttachmentProps) => {
  const theme = useTheme();
  const strings = LocalizedStrings.work.attachments;
  const [showAddFile, setShowAddFile] = useState(false);
  const [showAddLink, setShowAddLink] = useState(false);

  function onFileImportPopoverClose(attachmentInfos: AttachmentInfo[] | undefined) {
    if (attachmentInfos != null) {
      onCreateAttachment(attachmentInfos);
    }
    setShowAddFile(false);
  }

  function onLinkPopoverClose(attachmentInfo: AttachmentInfo | undefined) {
    if (attachmentInfo != null) {
      onCreateAttachment([attachmentInfo]);
    }

    setShowAddLink(false);
  }

  return (
    <Box sx={sx} className={className}>
      <Subheader>{strings.addAttachmentSectionTitle()}</Subheader>

      <Grid2 container spacing={2}>
        <Grid2 xs={12} md={6}>
          <Card elevation={theme.palette.mode === 'light' ? 1 : 8}>
            <ListItemButton sx={{ py: 1.5 }} onClick={() => setShowAddFile(true)} dense>
              <ListItemIcon>
                <UploadRounded color="primary" />
              </ListItemIcon>

              <ListItemText
                primary={strings.addFileAttachmentOptionTitle()}
                primaryTypographyProps={{ fontWeight: '500' }}
              />
            </ListItemButton>
          </Card>
        </Grid2>

        <Grid2 xs={12} md={6}>
          <Card elevation={theme.palette.mode === 'light' ? 1 : 8}>
            <ListItemButton sx={{ py: 1.5 }} onClick={() => setShowAddLink(true)} dense>
              <ListItemIcon>
                <LinkRounded color="primary" />
              </ListItemIcon>

              <ListItemText
                primary={strings.addLinkAttachmentOptionTitle()}
                primaryTypographyProps={{ fontWeight: '500' }}
              />
            </ListItemButton>
          </Card>
        </Grid2>
      </Grid2>

      {showAddFile && <WorkAddFileAttachmentDialog isOpen onClose={onFileImportPopoverClose} />}
      {showAddLink && <WorkAddLinkAttachmentDialog isOpen onClose={onLinkPopoverClose} />}
    </Box>
  );
});
