import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { CalendarMonthRounded, CoPresentRounded, EmailRounded, InfoRounded, PersonRounded } from '@mui/icons-material';
import { Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import LocalizedStrings from 'strings';
import { useServices } from '../../../../hooks';
import { AdminTeachersListViewModel } from '../../../../viewmodels';
import { Table, TableRowActions } from '../../../lists';
import { AdminTableCellLink } from '../../shared';

type RenderCellData = GridRenderCellParams<Account, Account>;

export interface AdminSchoolTeachersTableProps {
  sx?: SxProps;
  className?: string;
  viewModel: AdminTeachersListViewModel;
  showImportTeachers: () => void;
  sendTeacherInviteEmail: (teacherId: string) => Promise<void>;
}

export const AdminSchoolTeachersTable = observer(
  ({ sx, className, viewModel, showImportTeachers, sendTeacherInviteEmail }: AdminSchoolTeachersTableProps) => {
    const { route } = useServices();
    const strings = LocalizedStrings.admin.teachersList;
    const theme = useTheme();

    function onInviteTeachersClick() {
      showImportTeachers();
      return Promise.resolve();
    }

    const baseStatColumn: Partial<GridColDef> = {
      sortable: false,
      disableColumnMenu: true,
      headerAlign: 'left',
      align: 'left'
    };

    const columns = useMemo(
      () =>
        [
          {
            ...baseStatColumn,
            field: 'connectionState',
            headerName: '',
            align: 'center',
            width: 32,
            renderCell: (data: RenderCellData) =>
              data.row.userId.length > 0 ? (
                <Tooltip title={strings.hasUserTooltip()} disableInteractive>
                  <PersonRounded color={theme.palette.mode === 'light' ? 'primary' : undefined} />
                </Tooltip>
              ) : undefined
          },
          {
            ...baseStatColumn,
            headerName: strings.nameColumnHeader(),
            field: 'fullName',
            minWidth: 200,
            renderCell: (data: RenderCellData) => (
              <AdminTableCellLink
                value={data.row.fullName.length > 0 ? data.row.fullName : `(${strings.noNameValue()})`}
                link={route.resolveAdminTeacherDetailsLocation(viewModel.schoolId, data.row.id)}
              />
            ),
            sortable: true,
            flex: 1
          },
          {
            ...baseStatColumn,
            headerName: strings.emailColumnHeader(),
            field: 'emailAddress',
            flex: 1,
            sortable: true,
            renderCell: (data: RenderCellData) => (
              <AdminTableCellLink value={data.row.emailAddress} link={`mailto:${data.row.emailAddress}`} isExternal />
            )
          },
          {
            field: 'actions',
            headerName: '',
            type: 'actions',
            renderCell: (data: RenderCellData) => (
              <TableRowActions
                actions={[
                  {
                    icon: <InfoRounded />,
                    text: strings.showDetailsAction(),
                    selection: {
                      case: 'link',
                      to: route.resolveAdminTeacherDetailsLocation(data.row.schoolId, data.row.id)
                    }
                  },
                  {
                    icon: <CalendarMonthRounded />,
                    text: strings.editScheduleAction(),
                    selection: {
                      case: 'link',
                      to: route.resolveAdminTeacherScheduleEditLocation(
                        viewModel.schoolId,
                        viewModel.currentScheduleCycleId ?? '',
                        data.row.id
                      )
                    },
                    isHidden: viewModel.isReadOnly || viewModel.currentScheduleCycleId == null
                  },
                  {
                    icon: <EmailRounded />,
                    text: strings.inviteTeacherActionTitle(),
                    selection: { case: 'action', onSelect: () => void sendTeacherInviteEmail(data.row.id) },
                    isHidden: viewModel.isReadOnly || data.row.userId.length > 0
                  }
                ]}
              />
            )
          }
        ] as GridColDef[],
      []
    );

    return (
      <Table
        sx={sx}
        className={className}
        tableKey="admin_school_teachers"
        rows={viewModel.teachers}
        columns={columns}
        searchText={viewModel.searchText}
        emptyOverlayParams={{
          title: strings.emptyTitle(),
          renderIcon: (className) => <CoPresentRounded className={className} />,
          action: { title: strings.emptyButtonTitle(), icon: <EmailRounded />, action: onInviteTeachersClick }
        }}
      />
    );
  }
);
