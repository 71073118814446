import { WorkIconInfo } from '@/models';
import { Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { WorkIcon } from '../lists';

export interface PlannedWorkIconProps {
  sx?: SxProps;
  className?: string;
  id?: string;
  color: string | undefined;
  icon: WorkIconInfo;
}

export const PlannedWorkIcon = observer(({ sx, className, id, color, icon }: PlannedWorkIconProps) => {
  const theme = useTheme();
  const resolvedColor = color != null && color.length > 0 ? color : theme.palette.action.disabled;

  return (
    <Box
      sx={{
        ...sx,
        maxWidth: 32,
        position: 'relative',
        borderRadius: 1,
        p: 0.25,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        aspectRatio: '1/1'
      }}
      id={id}
      className={className}
    >
      <Box
        sx={{
          backgroundColor: resolvedColor,
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: '40%',
          borderRadius: 0.5
        }}
      />

      <Box
        sx={{
          zIndex: 1,
          flex: 1,
          maxHeight: 22,
          aspectRatio: '1/1',
          borderRadius: '50%',
          backgroundColor: theme.palette.background.default,
          p: '1px',
          overflow: 'hidden'
        }}
      >
        <WorkIcon
          sx={{ width: '100%', height: '100%', overflow: 'hidden' }}
          icon={icon}
          displayCourseSectionColor={false}
          size="100%"
        />
      </Box>
    </Box>
  );
});
