import { useServices } from '@/hooks';
import { EditableGoogleCalendarConnectedAppViewModel } from '@/viewmodels';
import EmptyIcon from '@mui/icons-material/AccountCircleRounded';
import { List } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { EmptyListIndicator, Subheader } from '../../utils';
import { GoogleCalendar } from './GoogleCalendar';

export interface EditableGoogleCalendarConnectedAppProps {
  viewModel: EditableGoogleCalendarConnectedAppViewModel;
  className?: string;
  sx?: SxProps;
}

export const EditableGoogleCalendarConnectedApp = observer(
  ({ className, sx, viewModel }: EditableGoogleCalendarConnectedAppProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.connectedApps;

    return (
      <List className={className} sx={sx}>
        {viewModel.calendars.length === 0 ? (
          <EmptyListIndicator
            subtitle={strings.noGoogleCalendarsSubtitle}
            renderIcon={(className) => <EmptyIcon className={className} />}
          />
        ) : (
          <>
            <Subheader>{strings.googleCalendarListTitle}</Subheader>
            {viewModel.calendars.map((calendar, i) => (
              <GoogleCalendar key={`google-calendar-${i}`} viewModel={calendar} disabled={viewModel.isApplying} />
            ))}
          </>
        )}
      </List>
    );
  }
);
