import i18n from 'translate';

export const ScheduleCycleListStrings = {
  activeScheduleCycleTooltip: () => i18n.t('scheduleCycle.list.activeScheduleCycleTooltip'),
  activeScheduleSubtitle: (endDate: string) => i18n.t('scheduleCycle.list.activeScheduleSubtitle', { endDate }),
  createSchoolSchedule: () => i18n.t('scheduleCycle.list.createSchoolSchedule'),
  futureScheduleSubtitle: (startDate: string) => i18n.t('scheduleCycle.list.futureScheduleSubtitle', { startDate }),
  hidePastSchedulesTooltip: () => i18n.t('scheduleCycle.list.hidePastSchedulesTooltip'),
  ignoredScheduleCycleTooltip: () => i18n.t('scheduleCycle.list.ignoredScheduleCycleTooltip'),
  noActiveSchedule: () => i18n.t('scheduleCycle.list.noActiveSchedule'),
  pastScheduleLabel: () => i18n.t('scheduleCycle.list.pastScheduleLabel'),
  pastScheduleSubtitle: (startDate: string, endDate: string) =>
    i18n.t('scheduleCycle.list.pastScheduleSubtitle', { startDate, endDate }),
  pendingChangesTooltip: () => i18n.t('scheduleCycle.list.pendingChangesTooltip'),
  personalScheduleLabel: () => i18n.t('scheduleCycle.list.personalScheduleLabel'),
  schoolScheduleLabel: (schoolName: string) => i18n.t('scheduleCycle.list.schoolScheduleLabel', { schoolName }),
  showPastSchedulesTooltip: () => i18n.t('scheduleCycle.list.showPastSchedulesTooltip'),
  titlePlanner: () => i18n.t('scheduleCycle.list.titlePlanner'),
  titleSchool: () => i18n.t('scheduleCycle.list.titleSchool')
};
