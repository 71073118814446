import RefreshIcon from '@mui/icons-material/RefreshRounded';
import { Box, CircularProgress, IconButton } from '@mui/material';
import { SxProps } from '@mui/system';

export interface RefreshButtonProps {
  sx?: SxProps;
  className?: string;
  isRefreshing: boolean;
  reloadData: () => void;
}

export function RefreshButton({ sx, className, isRefreshing, reloadData }: RefreshButtonProps) {
  return (
    <Box sx={sx} className={className}>
      {isRefreshing && (
        <CircularProgress sx={{ m: 1, position: 'absolute', alignSelf: 'center' }} color="primary" size="1.5em" />
      )}

      {/* Using opacity to hide so that the size of the component doesn't change when reloading. */}
      <IconButton
        onClick={() => reloadData()}
        sx={{ opacity: !isRefreshing ? 1 : 0, color: 'inherit' }}
        disabled={isRefreshing}
      >
        <RefreshIcon />
      </IconButton>
    </Box>
  );
}
