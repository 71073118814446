import { dateToPBDate, dayToDate, TimeOfDay } from '@/models';
import { PlannedWorkEditViewModel } from '@/viewmodels';
import { CalendarMonthRounded } from '@mui/icons-material';
import GoPreviousPageIcon from '@mui/icons-material/ArrowBackRounded';
import GoNextPageIcon from '@mui/icons-material/ArrowForwardRounded';
import { Box, Card, IconButton, Popover, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { addDays } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import LocalizedStrings from 'strings';
import { UserDashboardCalendarDatePicker } from '../../shared';
import { DayTimesColumn } from '../../utils';
import { PlannedWorkEditComponentIds } from './PlannedWorkEditComponentIds';
import { PlannedWorkEditInteractionContext } from './PlannedWorkEditInteractionContext';
import { PlannedWorkEditWeekDayHeaders } from './PlannedWorkEditWeekDayHeaders';
import { PlanningWorkEditWorkInfo } from './PlannedWorkEditWorkInfo';
import { PlannedWorkEditScheduleBody } from './PlannerWorkEditWeekBody';

export interface PlannedWorkEditScheduleProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannedWorkEditViewModel;
}

export const PlannedWorkEditSchedule = observer(({ sx, className, viewModel }: PlannedWorkEditScheduleProps) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const dividerStyle = `1px solid ${theme.palette.divider}`;
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const isSmallScreen = useMediaQuery(() => theme.breakpoints.only('sm'));
  const hasScrolledInitially = useRef(false);

  const [isCreatingNewInfo, setIsCreatingNewInfo] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [datePickerButtonRef, setDatePickerButtonRef] = useState<HTMLButtonElement | undefined>();

  const numberOfDatesPerPage = useMemo(() => {
    if (isExtraSmallScreen) {
      return 1;
    } else if (isSmallScreen) {
      return 4;
    }

    return 7;
  }, [isSmallScreen, isExtraSmallScreen]);

  const dates = viewModel.getDates(numberOfDatesPerPage);

  useLayoutEffect(() => {
    const scrollableArea = document.getElementById(PlannedWorkEditComponentIds.weekScrollableView);
    if (scrollableArea != null && !hasScrolledInitially.current) {
      const startTime = new TimeOfDay({ hours: 7, minutes: 30 });
      const offset = viewModel.verticalOffsetFromTimeOfDay(startTime);
      scrollableArea.scroll({ top: offset, behavior: 'instant' });
      hasScrolledInitially.current = true;
    }
  }, []);

  function changePage(value: number) {
    viewModel.currentDate = addDays(viewModel.currentDate, value * numberOfDatesPerPage);
  }

  function goToDate(date: Date) {
    viewModel.currentDate = date;
    setDatePickerButtonRef(undefined);
  }

  return (
    <Stack sx={sx} className={className} spacing={1} overflow="hidden">
      <Stack direction="row" spacing={2} px={1} alignItems="center" overflow="hidden">
        <Stack flex={1} spacing={1} overflow="hidden">
          <PlanningWorkEditWorkInfo
            sx={{ flex: 1 }}
            work={viewModel.work}
            color={viewModel.color}
            icon={viewModel.workIcon}
          />

          <Typography color="textSecondary" variant="caption">
            {LocalizedStrings.plannedWork.edit.subtitle()}
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center">
          <IconButton onClick={() => changePage(-1)} size="small">
            <GoPreviousPageIcon fontSize="small" />
          </IconButton>

          <Box>
            <IconButton onClick={(e) => setDatePickerButtonRef(e.currentTarget)} size="small">
              <CalendarMonthRounded fontSize="small" />
            </IconButton>

            {datePickerButtonRef != null && (
              <Popover
                open={true}
                anchorEl={datePickerButtonRef}
                slotProps={{ paper: { sx: { width: 400 } } }}
                onClose={() => setDatePickerButtonRef(undefined)}
              >
                <UserDashboardCalendarDatePicker
                  initialDate={viewModel.currentDate}
                  selectedDates={() => [viewModel.currentDate]}
                  highlightedDates={() => viewModel.infos.map((i) => dayToDate(i.day))}
                  onSelectDate={(date) => goToDate(date)}
                />
              </Popover>
            )}
          </Box>

          <IconButton onClick={() => changePage(1)} size="small">
            <GoNextPageIcon fontSize="small" />
          </IconButton>
        </Stack>
      </Stack>

      <Card
        sx={{
          flex: 1,
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          height: '400px',
          border: !isDarkMode ? dividerStyle : undefined,
          borderRadius: 1,
          backgroundColor: isDarkMode ? theme.palette.grey['900'] : undefined,
          boxShadow: 'none'
        }}
      >
        <PlannedWorkEditWeekDayHeaders
          leftPadding={46}
          dates={dates}
          sx={{ position: 'sticky', top: 0, zIndex: 2, flexShrink: 0 }}
          getDayViewModel={(d) => viewModel.getViewModelForDay(dateToPBDate(d))}
        />

        <Box
          id={PlannedWorkEditComponentIds.weekScrollableView}
          sx={{
            display: 'flex',
            flex: 1,
            overflowY: 'auto',
            overflowX: 'hidden'
          }}
        >
          <Box flex={1} display="flex" flexDirection="row" height={viewModel.pointsPerHour * 24}>
            <DayTimesColumn pointsPerHour={viewModel.pointsPerHour} sx={{ width: 46 }} />

            <Box flex={1}>
              <AutoSizer>
                {(size) => (
                  <PlannedWorkEditInteractionContext.Provider
                    value={{
                      containerSize: size,
                      isCreatingNewInfo,
                      isResizing,
                      isDragging,
                      setIsCreatingNewInfo,
                      setIsResizing,
                      setIsDragging
                    }}
                  >
                    <PlannedWorkEditScheduleBody viewModel={viewModel} dates={dates} />
                  </PlannedWorkEditInteractionContext.Provider>
                )}
              </AutoSizer>
            </Box>
          </Box>
        </Box>
      </Card>
    </Stack>
  );
});
