import { useActivePlannerId, useServices } from '@/hooks';
import { PlannerSideBarCourseSectionItemViewModel } from '@/viewmodels';
import { AssignmentRounded, SchoolRounded } from '@mui/icons-material';
import { Collapse, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { SideBarButton } from '../../shared';
import { MainScreenPlannerSideBarSchoolCourseItem } from './MainScreenPlannerSideBarSchoolCourseItem';
import { MainScreenPlannerSideBarSchoolCourseItemCompact } from './MainScreenPlannerSideBarSchoolCourseItemCompact';

export interface MainScreenPlannerSideBarSchoolCourseProps {
  sx?: SxProps;
  className?: string;
  course: PlannerSideBarCourseSectionItemViewModel;
  isCompact: boolean;
  close: () => void;
}

export const MainScreenPlannerSideBarSchoolCourse = observer(
  ({ sx, className, course, isCompact, close }: MainScreenPlannerSideBarSchoolCourseProps) => {
    const { route } = useServices();
    const plannerId = useActivePlannerId();
    const params = useParams();
    const courseSectionId = params.courseSectionId;
    const courseId = params.courseId;

    const studentsRoute = route.resolveSchoolCourseSectionDetailsLocation(
      plannerId,
      course.schoolId,
      course.schoolCourseSectionId
    );

    const worksRoute = route.resolvePlannerCourseSectionDetailLocation(plannerId, course.id);

    const isActive = course.id === courseSectionId || course.schoolCourseSectionId === courseId;
    const strings = LocalizedStrings.sidebar.school;

    return (
      <Stack sx={sx} className={className}>
        {isCompact ? (
          <MainScreenPlannerSideBarSchoolCourseItemCompact course={course} plannerId={plannerId} onSelect={close} />
        ) : (
          <MainScreenPlannerSideBarSchoolCourseItem course={course} plannerId={plannerId} onSelect={close} />
        )}

        <Collapse in={isActive}>
          <Stack px={isCompact ? 0 : 1} pb={1}>
            <SideBarButton
              isCompact={isCompact}
              title={strings.courseStudents()}
              icon={<SchoolRounded />}
              link={{ to: studentsRoute, mathPattern: { path: studentsRoute, end: false } }}
              onSelect={close}
            />

            <SideBarButton
              isCompact={isCompact}
              title={strings.courseWorks()}
              icon={<AssignmentRounded />}
              link={{ to: worksRoute, mathPattern: { path: worksRoute, end: false } }}
              onSelect={close}
            />
          </Stack>
        </Collapse>
      </Stack>
    );
  }
);
