import { ServiceContainer } from '@/providers';
import { ConnectedAppsService, LocalizationService } from '@/services';
import { PlannerDataStore, PlannerDetailedCourseSectionsLoadable } from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { computed, makeObservable } from 'mobx';
import { AppBaseCourseSectionListViewModel } from './CourseSectionListViewModel';

export class AppSchoolCourseSectionListViewModel extends AppBaseCourseSectionListViewModel<PlannerDetailedCourseSectionsLoadable> {
  constructor(
    schoolId: string,
    plannerId: string,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    localization: LocalizationService = ServiceContainer.services.localization,
    connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    super(plannerId, schoolId, localization, connectedApps);
    makeObservable(this);
  }

  @computed
  protected get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  protected get courseSectionsInfos(): CourseSectionDetails[] {
    return this.courseSectionsLoadable.values.filter((cs) => cs.schoolsCourseSection?.schoolId === this._schoolId);
  }

  readonly canEditCourseSections = false;

  deleteCourseSection(): Promise<void> {
    // Not supported for now as we only have external courses in schools.
    throw new Error('Deletion of school course in supported.');
  }
}
