import { useServices } from '@/hooks';
import { AttachmentInfo } from '@/models';
import { AddRounded } from '@mui/icons-material';
import { Box, Dialog, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { FormPopoverActions, FormPopoverHeader } from '../../utils';

interface LinkInfo {
  readonly value: string;
  readonly isValid: boolean;
}
export interface WorkAddLinkAttachmentDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  onClose: (attachment: AttachmentInfo | undefined) => void;
}

export const WorkAddLinkAttachmentDialog = observer(
  ({ sx, className, onClose, isOpen }: WorkAddLinkAttachmentDialogProps) => {
    const strings = LocalizedStrings.work.attachments.addLinkDialog;
    const { attachmentService } = useServices();
    const [link, setLink] = useState<LinkInfo>({ isValid: false, value: '' });

    function close(submit: boolean) {
      if (submit && link.isValid) {
        const attachment = attachmentService.createLinkAttachmentForUrl(link.value);
        onClose(attachment);
      } else {
        onClose(undefined);
      }

      setLink({ isValid: false, value: '' });
      return Promise.resolve();
    }

    return (
      <Dialog sx={sx} className={className} open={isOpen} maxWidth="sm" fullWidth onClose={() => void close(false)}>
        <FormPopoverHeader title={strings.title()} />

        <Box p={2}>
          <TextField
            fullWidth
            value={link.value}
            onChange={(e) => setLink({ value: e.currentTarget.value, isValid: e.currentTarget.checkValidity() })}
            placeholder={strings.inputPlaceholder()}
            type="url"
          />
        </Box>

        <FormPopoverActions
          onSubmit={() => close(true)}
          canSubmit={link.isValid}
          onCancel={() => close(false)}
          customSubmitTitle={strings.submitButtonTitle()}
          customSubmitIcon={<AddRounded />}
        />
      </Dialog>
    );
  }
);
