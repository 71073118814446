import { useServices } from '@/hooks';
import { ScheduleCycleActivitySchedulesDayColumnInfo } from '@/viewmodels';
import { alpha, Divider, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Fragment, MouseEvent } from 'react';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';
import { ScheduleCyclePeriodScheduleGridDayHeader } from './ScheduleCyclePeriodScheduleGridDayHeader';
import { ScheduleCyclePeriodScheduleGridTopLeftView } from './ScheduleCyclePeriodScheduleGridTopLeftView';

export interface ScheduleCyclePeriodScheduleGridHeaderRowProps {
  sx?: SxProps;
  columns: ScheduleCycleActivitySchedulesDayColumnInfo[];
  onColumnHeaderClick?: (column: ScheduleCycleActivitySchedulesDayColumnInfo, event: MouseEvent<HTMLElement>) => void;
  isReadOnly: boolean;
}

export const ScheduleCyclePeriodScheduleGridHeaderRow = observer(
  ({ sx, columns, onColumnHeaderClick, isReadOnly }: ScheduleCyclePeriodScheduleGridHeaderRowProps) => {
    const { settingsStorage } = useServices();
    const theme = useTheme();

    return (
      <Stack
        sx={{
          ...sx,
          backgroundColor: alpha(theme.palette.background.paper, settingsStorage.reduceTransparency ? 1 : 0.95)
        }}
        direction="row"
        position="sticky"
        top={0}
        zIndex={ScheduleCyclePeriodScheduleGridConstants.headerZIndex}
      >
        <ScheduleCyclePeriodScheduleGridTopLeftView />

        {columns.map((column, index) => (
          <Fragment key={`grid-header-column-${index}`}>
            <ScheduleCyclePeriodScheduleGridDayHeader
              column={column}
              onClick={onColumnHeaderClick}
              isReadOnly={isReadOnly}
            />
            {index < columns.length - 1 && <Divider orientation="vertical" />}
          </Fragment>
        ))}
      </Stack>
    );
  }
);
