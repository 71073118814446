import { useViewModel } from '@/hooks';
import { Day, DayOfWeek } from '@/models';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Dialog } from '../../../../../utils';
import { useScheduleCycleUserDashboard } from '../../../UseScheduleCycleUserDashboardHook';
import { SpecialDayEdit } from './SpecialDayEdit';

export interface SpecialDayEditDialogProps {
  sx?: SxProps;
  className?: string;
  specialDayId: string | undefined;
  scheduleCycleId: string;
  initialOccurrence?:
    | { case: 'cycleDay'; value: number }
    | { case: 'dayOfWeek'; value: DayOfWeek }
    | { case: 'day'; value: Day };
  isOpen: boolean;
  close: () => void;
}

export const SpecialDayEditDialog = observer(
  ({ sx, scheduleCycleId, specialDayId, initialOccurrence, isOpen, close }: SpecialDayEditDialogProps) => {
    const { dashboard } = useScheduleCycleUserDashboard();

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createSpecialDayEditDialogViewModel(
          specialDayId,
          scheduleCycleId,
          dashboard,
          initialOccurrence,
          () => {
            close();
            return Promise.resolve();
          }
        ),
      [scheduleCycleId, specialDayId]
    );

    const strings = LocalizedStrings.scheduleCycle.edit.specialDays.edit;

    return (
      <Dialog
        sx={sx}
        viewModel={viewModel}
        isOpen={isOpen}
        title={specialDayId ? strings.editTitle() : strings.addTitle()}
        width="sm"
        renderData={() => <SpecialDayEdit sx={{ width: '100%', height: '100%' }} viewModel={viewModel} />}
      />
    );
  }
);
