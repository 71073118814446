import { useActivePlannerId, useServices } from '@/hooks';
import { List, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { DrawerItem, Subheader } from '../../utils';
import { SettingsSection } from './SettingsSection';

export interface SettingsSideBarProps {
  sx?: SxProps;
  className?: string;
  sections: SettingsSection[];
}

export const SettingsSideBar = observer(({ sx, className, sections }: SettingsSideBarProps) => {
  const { route } = useServices();
  const plannerId = useActivePlannerId();

  return (
    <List sx={{ ...sx, py: 2, overflow: 'auto' }} className={className}>
      <Stack spacing={1}>
        {sections.map((section, sectionIndex) => (
          <Stack key={`settings-section-${sectionIndex}`}>
            <Subheader>{section.title}</Subheader>
            <Stack>
              {section.items.map((item, itemIndex) => {
                const path = route.resolvePlannerSettingsLocation(item.link, plannerId);

                return (
                  <DrawerItem
                    key={`settings-section-${sectionIndex}-${itemIndex}`}
                    title={item.title}
                    dense
                    link={{
                      to: path,
                      highlightPattern: { path, end: false }
                    }}
                  />
                );
              })}
            </Stack>
          </Stack>
        ))}
      </Stack>
    </List>
  );
});
