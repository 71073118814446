import { ListAltRounded, ViewWeekRounded } from '@mui/icons-material';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useViewModel } from '../../../../hooks';
import { ToggleGroup, UpdatablePresenter } from '../../../utils';
import { AdminSchoolScheduleCycleClassSchedules } from './AdminSchoolScheduleCycleClassSchedules';
import { AdminSchoolScheduleCycleClassSchedulesViewKind } from './AdminSchoolScheduleCycleClassSchedulesViewKind';

export interface AdminSchoolScheduleCycleClassSchedulesPageProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  scheduleCycleId: string;
  isReadOnly: boolean;
}

export const AdminSchoolScheduleCycleClassSchedulesPage = observer(
  ({ sx, className, schoolId, scheduleCycleId, isReadOnly }: AdminSchoolScheduleCycleClassSchedulesPageProps) => {
    const viewModel = useViewModel((viewModels) => viewModels.createAdminClassSchedulesViewModel(schoolId), [schoolId]);
    const [searchParams, setSearchParams] = useSearchParams();
    const viewKind = (searchParams.get('view') as AdminSchoolScheduleCycleClassSchedulesViewKind) ?? 'list';
    const strings = LocalizedStrings.admin.schedule.classSchedules;

    const handleDisplayKindChange = (newValue: string | null) => {
      if (newValue != null) {
        searchParams.set('view', newValue);
        setSearchParams(searchParams);
      }
    };

    return (
      <Stack width="100%" height="100%" sx={sx} className={className} overflow="hidden">
        <Box width="100%" height="100%" overflow="hidden" display="flex" flexDirection="column">
          <Stack spacing={1}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography flex={1} variant="h6">
                {strings.title()}
              </Typography>

              <Typography variant="subtitle2" color="textSecondary">
                {strings.viewAs()}
              </Typography>

              <ToggleGroup
                value={viewKind}
                onChange={handleDisplayKindChange}
                size="small"
                options={[
                  { value: 'list', text: strings.viewAsListOption(), icon: (sx) => <ListAltRounded sx={sx} /> },
                  {
                    value: 'grid',
                    text: strings.viewAsMasterScheduleOption(),
                    icon: (sx) => <ViewWeekRounded sx={sx} />
                  }
                ]}
              />
            </Stack>

            <Divider />
          </Stack>

          <UpdatablePresenter
            sx={{ flex: 1, overflow: 'hidden' }}
            viewModel={viewModel}
            renderData={() => (
              <AdminSchoolScheduleCycleClassSchedules
                sx={{ width: '100%', height: '100%', pt: 2 }}
                schoolId={schoolId}
                scheduleCycleId={scheduleCycleId}
                viewModel={viewModel}
                viewKind={viewKind}
                isReadOnly={isReadOnly}
              />
            )}
          />
        </Box>
      </Stack>
    );
  }
);
