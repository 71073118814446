import { plannerHasAccessKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { ConnectedAppsService, LocalizationService } from '@/services';
import { PlannerDataStore, PlannerDetailedCourseSectionsLoadable, UserDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { computed, makeObservable, runInAction } from 'mobx';
import { AppBaseCourseSectionListViewModel } from './CourseSectionListViewModel';

export class AppPlannerCourseSectionListViewModel extends AppBaseCourseSectionListViewModel<PlannerDetailedCourseSectionsLoadable> {
  constructor(
    plannerId: string,
    private readonly _kind: 'personal' | 'external',
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    localization: LocalizationService = ServiceContainer.services.localization,
    connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    super(plannerId, undefined, localization, connectedApps);
    makeObservable(this);
  }

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  protected get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  protected get courseSectionsInfos(): CourseSectionDetails[] {
    return this.courseSectionsLoadable.values.filter((cs) =>
      this._kind === 'personal' ? cs.courseSection?.externalSource == null : cs.courseSection?.externalSource != null
    );
  }

  @computed
  get canEditCourseSections(): boolean {
    return plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  async deleteCourseSection(courseSection: CourseSectionDetails, shouldAlsoCancelWorks: boolean): Promise<void> {
    try {
      await this._plannerStore.deleteCourseSection(
        courseSection.courseSection!.id,
        courseSection.courseSection!.syncToken,
        this._plannerId,
        shouldAlsoCancelWorks
      );
    } catch (e) {
      const error = e as Error;
      runInAction(() => (this._error = error.message));
    }
  }
}
