import {
  comparePlannerCourseSectionInfos,
  CourseSectionInfo,
  courseSectionOccurrenceToInfo,
  dateToPBDate,
  plannerCourseSectionDetailsToInfo
} from '@/models';
import { ServiceContainer } from '@/providers';
import { AllPlannerItemsPasteboardContentKind, PasteboardPasteContext } from '@/services';
import { PlannerCalendarStore } from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { getHours, getMinutes, isSameDay, set, startOfDay } from 'date-fns';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import {
  AppBaseStaticDialogViewModel,
  BaseDialogActionButtonConfiguration,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  StaticDialogViewModel
} from '../utils';
import {
  AppPlannerPastePlannerItemTimeOfDaySelectionViewModel,
  PlannerPastePlannerItemTimeOfDay,
  PlannerPastePlannerItemTimeOfDaySelectionViewModel
} from './PlannerPastePlannerItemTimeOfDaySelectionViewModel';

export interface PlannerPastePlannerItemViewModel extends StaticDialogViewModel {
  readonly showDateSelection: boolean;
  readonly showTimeSelection: boolean;
  readonly showGeneralOptions: boolean;

  date: Date | undefined;
  readonly timeOfDaySelectionViewModel: PlannerPastePlannerItemTimeOfDaySelectionViewModel;
  readonly timeOfDaySelection: PlannerPastePlannerItemTimeOfDay | undefined;
  readonly targetCourseId: string | undefined;
  linkToOriginalItem: boolean;

  readonly courseSections: CourseSectionInfo[];
  readonly courseSectionsById: Map<string, CourseSectionDetails>;
  readonly isPeriodCourseSelected: boolean;
  readonly isOriginalItemCourseSelected: boolean;

  setCourseId(id: string): void;
  setPeriodCourseId(): void;
  setOriginalItemCourseId(): void;
}

export class AppPlannerPlannerPasteItemViewModel
  extends AppBaseStaticDialogViewModel
  implements PlannerPastePlannerItemViewModel
{
  @observable private _isSaving = false;

  @observable private _date: Date | undefined;
  @observable private _timeOfDaySelection: PlannerPastePlannerItemTimeOfDay | undefined;
  @observable private _targetCourseId = '';
  @observable private _linkToOriginalItem = true;
  private readonly _calendarStore: PlannerCalendarStore;

  private readonly _saveButtonConfig: BaseDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;

  constructor(
    private readonly _plannerId: string,
    private readonly _context: PasteboardPasteContext,
    onDismiss: () => Promise<void>,
    private readonly _localization = ServiceContainer.services.localization,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _pasteboard = ServiceContainer.services.pasteboard,
    private readonly _workStore = ServiceContainer.services.workStore
  ) {
    super(onDismiss);
    makeObservable(this);
    this._calendarStore = _plannerStore.getCalendarStore(_plannerId);
    this._date = _context.date;
    this._timeOfDaySelection =
      _context.date != null && _context.period != null
        ? { case: 'period', value: courseSectionOccurrenceToInfo(_context.period, _context.date) }
        : undefined;

    // TODO: https://linear.app/studyoco/issue/TOD-671/support-copy-of-published-works
    this._linkToOriginalItem = this.originalItem?.case !== 'published-work';
    this.setInitialTargetCourseId();

    this._saveButtonConfig = new BaseDialogActionButtonConfiguration(
      'main',
      'both',
      'right',
      'check',
      'start',
      () => LocalizedStrings.planner.pastePlannerItem.saveButtonTitle(),
      'contained',
      () => this.save()
    );

    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );
  }

  @computed
  private get originalItem() {
    return this._pasteboard.currentContentForKinds(AllPlannerItemsPasteboardContentKind);
  }

  @computed
  private get displayedCourseSections(): CourseSectionDetails[] {
    let courses = this._plannerStore.getCourseSectionsInPlanner(this._plannerId).values;

    if (this.originalItem?.case === 'published-work') {
      courses = courses.filter((cs) => cs.schoolsCourseSection != null && cs.role === CourseSectionRole.TEACHER);
    }
    return courses;
  }

  @computed
  get courseSections(): CourseSectionInfo[] {
    return this.displayedCourseSections
      .map(plannerCourseSectionDetailsToInfo)
      .sort((cs1, cs2) => comparePlannerCourseSectionInfos(cs1, cs2, this._localization.currentLocale));
  }

  @computed
  get courseSectionsById(): Map<string, CourseSectionDetails> {
    return this.displayedCourseSections.reduce(
      (value, c) => value.set(c.courseSection!.id, c),
      new Map<string, CourseSectionDetails>()
    );
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isSaving;
    this._saveButtonConfig.showLoading = this._isSaving;
    this._saveButtonConfig.isEnabled =
      !this._isSaving &&
      this._timeOfDaySelection != null &&
      (this._timeOfDaySelection.case !== 'time' || !this._timeOfDaySelection.hasValidationError);
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get showDateSelection() {
    return this._context.date == null;
  }

  @computed
  get showTimeSelection() {
    return this._date != null && (this.showDateSelection || this._context.period == null);
  }

  @computed
  get showGeneralOptions() {
    if (this.originalItem == null) {
      return false;
    }

    if (this.originalItem.case === 'published-work') {
      return false;
    } else {
      return this.originalItem.value.plannerId === this._plannerId;
    }
  }

  @computed
  get date(): Date | undefined {
    return this._date;
  }

  set date(value: Date | undefined) {
    if (this._date == null && value == null) {
      return;
    }

    if (this._date != null && value != null && isSameDay(value, this._date)) {
      return;
    }

    this._date = value;

    if (value == null || this._timeOfDaySelection?.case === 'period') {
      this._timeOfDaySelection = { case: 'none' };
    }
  }

  @computed
  get timeOfDaySelectionViewModel() {
    return new AppPlannerPastePlannerItemTimeOfDaySelectionViewModel(
      this.date != null ? dateToPBDate(this.date) : undefined,
      this._calendarStore,
      () => this._timeOfDaySelection,
      (selection) => this.setTimeOfDaySelection(selection)
    );
  }

  @computed
  get timeOfDaySelection() {
    return this._timeOfDaySelection;
  }

  @computed
  get targetCourseId(): string {
    return this._targetCourseId;
  }

  @computed
  get isPeriodCourseSelected() {
    if (this._context.period?.courseSectionId != null && this._context.period.courseSectionId.length > 0) {
      return this._context.period.courseSectionId === this.targetCourseId;
    } else if (this._timeOfDaySelection?.case === 'period') {
      return this._timeOfDaySelection.value.courseSectionId === this.targetCourseId;
    } else {
      return false;
    }
  }

  @computed
  get isOriginalItemCourseSelected(): boolean {
    return this.originalItem?.value.courseSectionId === this.targetCourseId;
  }

  @computed
  get linkToOriginalItem() {
    return this._linkToOriginalItem;
  }

  set linkToOriginalItem(value: boolean) {
    this._linkToOriginalItem = value;
  }

  @action
  setCourseId(id: string): void {
    this._targetCourseId = id;
  }

  @action
  setPeriodCourseId() {
    if (this._timeOfDaySelection?.case === 'period') {
      this._targetCourseId = this._timeOfDaySelection.value.courseSectionId;
    }
  }

  @action
  setOriginalItemCourseId() {
    this._targetCourseId = this.originalItem?.value.courseSectionId ?? '';
  }

  private async save() {
    if (this.originalItem == null) {
      await this.dismiss();
      return;
    }

    const time = this._timeOfDaySelection?.case === 'time' ? this._timeOfDaySelection.value : undefined;
    const resolvedDueTime =
      this.date != null
        ? set(startOfDay(this.date), {
            hours: time != null ? getHours(time) : 0,
            minutes: time != null ? getMinutes(time) : 0
          })
        : undefined;

    try {
      switch (this.originalItem.case) {
        case 'work':
          await this.copyWork(this.originalItem.value, resolvedDueTime, time == null);
          break;

        case 'note':
          await this.copyNote(this.originalItem.value, resolvedDueTime, time == null);
          break;

        case 'published-work': {
          await this.copyPublishedWork(this.originalItem.value, resolvedDueTime, time == null);
          break;
        }
      }

      await this.dismiss();
    } catch {
      runInAction(() => (this._error = LocalizedStrings.planner.pastePlannerItem.saveErrorAlertMessage()));
    }
  }

  @action
  private setTimeOfDaySelection(value: PlannerPastePlannerItemTimeOfDay) {
    this._timeOfDaySelection = value;

    if (value.case === 'period') {
      this.setCourseId(value.value.courseSectionId);
    }
  }

  @action
  private setInitialTargetCourseId() {
    const originalCourseId = this.originalItem?.value.courseSectionId ?? '';

    if (this._context.period != null) {
      this._targetCourseId = this._context.period.courseSectionId;

      if (this._targetCourseId.length === 0) {
        this._targetCourseId = originalCourseId;
      }
    } else if (this._context.courseSectionId != null && this._context.courseSectionId.length > 0) {
      this._targetCourseId = this._context.courseSectionId;
    } else {
      this._targetCourseId = originalCourseId;
    }
  }

  private async copyWork(work: Work, dueTime: Date | undefined, isAllDay: boolean) {
    await this._workStore.createCopyOfWork(
      this._plannerId,
      work.id,
      dueTime,
      isAllDay,
      this._targetCourseId,
      this._linkToOriginalItem
    );
  }

  private async copyNote(note: Note, dueTime: Date | undefined, isAllDay: boolean) {
    await this._workStore.createCopyOfNote(
      this._plannerId,
      note.id,
      dueTime,
      isAllDay,
      this._targetCourseId,
      this._linkToOriginalItem
    );
  }

  private async copyPublishedWork(publishedWork: PublishedWork, dueTime: Date | undefined, isAllDay: boolean) {
    const courseSection = this.courseSectionsById.get(this._targetCourseId);
    if (courseSection?.schoolsCourseSection == null || courseSection.role !== CourseSectionRole.TEACHER) {
      throw new Error('Cannot publish work in non taught course section.');
    }

    await this._workStore.createCopyOfPublishedWork(
      courseSection.schoolsCourseSection.schoolId,
      publishedWork.id,
      dueTime,
      isAllDay,
      courseSection.schoolsCourseSection.id,
      // TODO: https://linear.app/studyoco/issue/TOD-671/support-copy-of-published-works
      false
    );
  }
}
