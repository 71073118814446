import { useActivePlannerId, useViewModel } from '@/hooks';
import { PlannerCopyItemKind, PlannerRepeatItemViewModel } from '@/viewmodels';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { CalendarDatePicker, Dialog, Subheader } from '../../../utils';
import { PlannerCopyOriginalItemCard } from '../PlannerCopyOriginalItemCard';
import { PlannerRepeatItemCycleDayPatternOptions } from './PlannerRepeatItemCycleDayPatternOptions';
import { PlannerRepeatItemPatternKindSelection } from './PlannerRepeatItemPatternKindSelection';
import { PlannerRepeatItemWeekPatternOptions } from './PlannerRepeatItemWeekPatternOptions';

export interface PlannerRepeatItemDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  itemKind: PlannerCopyItemKind;
}

export const PlannerRepeatItemDialog = observer(({ sx, className, isOpen, itemKind }: PlannerRepeatItemDialogProps) => {
  const strings = LocalizedStrings.planner.repeatPlannerItem;
  const navigate = useNavigate();
  const plannerId = useActivePlannerId();

  const viewModel = useViewModel(
    (viewModels) => viewModels.createPlannerRepeatItemViewModel(plannerId, itemKind, close),
    [plannerId, itemKind, close]
  );

  async function close() {
    navigate(-1);
    return Promise.resolve();
  }

  return (
    <Dialog
      title={strings.title()}
      isOpen={isOpen}
      sx={sx}
      className={className}
      width="sm"
      viewModel={viewModel}
      renderData={() => (
        <Stack width="100%" spacing={1}>
          <PlannerCopyOriginalItemCard item={viewModel.item} />

          <Stack>
            <Subheader>{strings.untilDateLabel()}</Subheader>
            <CalendarDatePicker
              value={viewModel.untilDate}
              minDate={viewModel.minimumDate}
              highlightedDates={(startDate, endDate) =>
                viewModel.getDatesWithOccurrenceForCourseSection(startDate, endDate)
              }
              onChange={(date) => {
                if (date != null) {
                  viewModel.untilDate = date;
                }
              }}
              kind={'user-dashboard-calendar'}
            />
          </Stack>

          <Stack>
            <Subheader>{strings.patternSelectionSectionTitle()}</Subheader>

            <Stack spacing={2}>
              <PlannerRepeatItemPatternKindSelection viewModel={viewModel} />
              {renderPatternOptions(viewModel)}
            </Stack>
          </Stack>
        </Stack>
      )}
    />
  );
});

function renderPatternOptions(viewModel: PlannerRepeatItemViewModel) {
  switch (viewModel.pattern?.case) {
    case 'week':
      return (
        <PlannerRepeatItemWeekPatternOptions
          pattern={viewModel.pattern}
          onChange={(pattern) => (viewModel.pattern = pattern)}
        />
      );

    case 'cycleDay':
      return (
        <PlannerRepeatItemCycleDayPatternOptions
          scheduleCycles={viewModel.scheduleCycles}
          pattern={viewModel.pattern}
          onChange={(pattern) => (viewModel.pattern = pattern)}
        />
      );

    default:
      return null;
  }
}
