import { alpha, Avatar, Badge, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

export interface UserProfileIconProps {
  sx?: SxProps;
  className?: string;
  url: string;
  userFullName: string;
  emotionalStatusEmoji?: string;
}

export function UserProfileIcon({ sx, className, url, userFullName, emotionalStatusEmoji }: UserProfileIconProps) {
  const theme = useTheme();
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      badgeContent={
        <Typography variant="h6" sx={{ color: alpha(theme.palette.text.primary, 1) }}>
          {emotionalStatusEmoji}
        </Typography>
      }
      invisible={!emotionalStatusEmoji}
    >
      <Avatar sx={sx} className={className} src={url} alt={userFullName} />
    </Badge>
  );
}
