import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Table, TableProps } from './Table';
import { TableHeader } from './TableHeader';

export interface TableWithHeaderProps<V> extends Omit<TableProps<V>, 'searchText'> {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  showSearchBar?: boolean;
  searchBarPlaceholder?: string;
  onSearchTextChange?: (searchText: string) => void;
}

export const TableWithHeader = observer(
  <V,>({
    sx,
    className,
    title,
    subtitle,
    showSearchBar = false,
    searchBarPlaceholder,
    onSearchTextChange,
    ...props
  }: TableWithHeaderProps<V>) => {
    const [searchText, setSearchText] = useState('');

    function onSearchTextValueChanged(searchText: string) {
      onSearchTextChange?.(searchText);
      setSearchText(searchText);
    }

    return (
      <Stack sx={sx} className={className} direction="column">
        <TableHeader
          title={title}
          subtitle={subtitle}
          displaySearchBar={showSearchBar}
          onSearchTextChange={onSearchTextChange != null ? onSearchTextValueChanged : undefined}
          searchBarPlaceholder={searchBarPlaceholder}
        />

        <Table sx={{ width: '100%' }} {...props} searchText={searchText} />
      </Stack>
    );
  }
);
