import { ProductsListViewModel } from '@/viewmodels';
import { Button, Divider, Unstable_Grid2 as Grid, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from '../../resources/strings';
import { UpdatablePresenter } from '../utils';
import { Product } from './Product';

export interface ProductsListProps {
  sx?: SxProps;
  className?: string;
  viewModel: ProductsListViewModel;
}

export const ProductsList = observer(({ sx, className, viewModel }: ProductsListProps) => {
  const theme = useTheme();
  const strings = LocalizedStrings.subscriptions.manage;

  return (
    <UpdatablePresenter
      sx={sx}
      className={className}
      viewModel={viewModel}
      renderData={() => (
        <Grid container spacing={1} px={2}>
          {viewModel.products.map((product) => (
            <Grid key={`subscription-product-${product.id}`} xs={12} sm={10} smOffset={1}>
              <Product sx={{ height: '100%' }} viewModel={product} />
            </Grid>
          ))}

          <Grid xs={12} sm={10} smOffset={1}>
            <Stack px={2} spacing={1}>
              <Typography textAlign="center" color="textSecondary" variant="caption">
                {strings.legalText()}
              </Typography>

              <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                <Button
                  size="small"
                  onClick={() => (viewModel.currency = 'usd')}
                  color="inherit"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {`🇺🇸 ${strings.usdPriceOptionTitle()}`}
                </Button>

                <Divider orientation="vertical" sx={{ height: 22 }} />

                <Button
                  size="small"
                  onClick={() => (viewModel.currency = 'cad')}
                  color="inherit"
                  sx={{ color: theme.palette.text.secondary }}
                >
                  {`🇨🇦 ${strings.cadPriceOptionTitle()}`}
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      )}
    />
  );
});
