import { AuthProvider } from '@/models';
import { Stack } from '@mui/material';
import { AppleLoginButton, GoogleLoginButton, MicrosoftLoginButton } from '../utils';

export interface LoginProvidersProps {
  className?: string;
  signIn: (provider: AuthProvider) => Promise<void>;
}

export const LoginProviders = ({ className, signIn }: LoginProvidersProps) => {
  return (
    <Stack display="flex" flexDirection="column" alignItems="center" className={className} spacing={3}>
      <GoogleLoginButton onClick={() => void signIn('google')} />
      <MicrosoftLoginButton onClick={() => void signIn('microsoft')} />
      <AppleLoginButton onClick={() => void signIn('apple')} />
    </Stack>
  );
};
