import { useServices } from '@/hooks';
import { PasteboardContentKind } from '@/services';
import { ContentPasteRounded } from '@mui/icons-material';
import { Fade, IconButton, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useMemo } from 'react';
import LocalizedStrings from 'strings';

export interface FloatingPasteButtonProps {
  sx?: SxProps;
  className?: string;
  supportedContentKind: PasteboardContentKind[];
  action: (e: MouseEvent<HTMLButtonElement>) => void;
}

export const FloatingPasteButton = observer(
  ({ sx, className, supportedContentKind, action }: FloatingPasteButtonProps) => {
    const { pasteboard } = useServices();

    const content = useMemo(() => {
      return pasteboard.currentContentForKinds(supportedContentKind);
    }, [supportedContentKind, pasteboard.content]);

    return (
      <Fade in={content != null} appear={false}>
        <Tooltip title={LocalizedStrings.utils.pasteButtonTooltip()} disableInteractive>
          <IconButton size="medium" sx={sx} className={className} onClick={(e) => action(e)}>
            <ContentPasteRounded color="primary" fontSize="small" />
          </IconButton>
        </Tooltip>
      </Fade>
    );
  }
);
