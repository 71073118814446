import { Popover, PopoverProps, Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { FormPopoverActions } from '../FormPopoverActions';
import { FormPopoverHeader } from '../FormPopoverHeader';

export interface OptionsPopoverProps extends PopoverProps {
  title: string;
  close: () => void;
  onResetOptions?: () => void;
}

export const OptionsPopover = observer(({ title, close, children, onResetOptions, ...props }: OptionsPopoverProps) => {
  const strings = LocalizedStrings.utils;

  return (
    <Popover {...props} slotProps={{ paper: { sx: { width: 300 } } }} onClose={() => close()}>
      <FormPopoverHeader title={title} />

      <Stack flex={1} pt={1} pb={2} spacing={1}>
        {children}
      </Stack>

      <FormPopoverActions
        onSubmit={() => {
          close();
          return Promise.resolve();
        }}
        canSubmit
        customSubmitTitle={strings.optionsPopoverCloseButtonTitle()}
        additionalActions={
          onResetOptions != null
            ? [
                {
                  id: 'reset',
                  title: strings.optionsPopoverResetButtonTitle(),
                  kind: 'button',
                  onClick: () => {
                    onResetOptions();
                    return Promise.resolve();
                  }
                }
              ]
            : undefined
        }
      />
    </Popover>
  );
});
