import { CardActionArea, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ContentCard } from '../../../shared';

export interface StudentPlannerListSummaryProps {
  sx?: SxProps;
  className?: string;
  title: string;
  itemCount: number;
  onSelect: () => void;
}

export const StudentPlannerListSummary = observer(
  ({ sx, className, title, itemCount, onSelect }: StudentPlannerListSummaryProps) => {
    return (
      <ContentCard sx={{ ...sx, minWidth: 100 }} className={className} onClick={onSelect}>
        <CardActionArea
          sx={{
            width: '100%',
            height: '100%',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start'
          }}
        >
          <Stack alignItems="center" spacing={1}>
            <Typography variant="subtitle2">{title}</Typography>
            <Typography>{itemCount}</Typography>
          </Stack>
        </CardActionArea>
      </ContentCard>
    );
  }
);
