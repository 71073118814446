import { useActivePlannerId, useServices, useSync, useViewModel } from '@/hooks';
import { dateToPBDate } from '@/models';
import { Box, Unstable_Grid2 as Grid, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { parse } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BackgroundImageScreenPaper, UpdatablePresenter } from '../../../utils';
import { UserDashboardCalendarDaySpecificDateView } from './UserDashboardCalendarDaySpecificDateView';

export interface UserDashboardCalendarDayViewProps {
  sx?: SxProps;
  className?: string;
}

export const UserDashboardCalendarDayView = observer(({ sx, className }: UserDashboardCalendarDayViewProps) => {
  const { dateService, storeInvalidator } = useServices();
  const plannerId = useActivePlannerId();
  const [searchParams] = useSearchParams();

  const viewModel = useViewModel(
    (viewModels) => viewModels.createUserDashboardCalendarDayViewModel(plannerId),
    [plannerId]
  );

  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const dateParam = searchParams.get('date');
  const currentDate = dateParam ? parse(dateParam, 'y-MM-dd', new Date()) : dateService.now;

  useEffect(() => {
    void viewModel.fetchDays(currentDate, false);
  }, [currentDate]);

  useEffect(() => {
    void viewModel.fetchDays(currentDate, true);
  }, [storeInvalidator.calendarRevision]);

  useSync('UserDashboardCalendarDay', () => viewModel.fetchDays(currentDate, true), [plannerId, currentDate]);

  return (
    <Box sx={sx} className={className} width="100%" height="100%">
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex'
        }}
      >
        <Grid container width="100%" height="100%">
          <Grid xs={12} lgOffset={1} lg={10} xlOffset={2} xl={8} height="100%">
            <BackgroundImageScreenPaper
              sx={{
                maxHeight: !isExtraSmallScreen ? '100%' : undefined,
                height: isExtraSmallScreen ? '100%' : undefined,
                width: '100%',
                overflow: 'hidden',
                display: 'flex'
              }}
            >
              <UpdatablePresenter
                isFlex
                sx={{ flex: 1, overflow: 'hidden' }}
                viewModel={viewModel}
                renderData={() => (
                  <UserDashboardCalendarDaySpecificDateView
                    viewModel={viewModel.getViewModelForDay(dateToPBDate(currentDate))}
                    hasCalendarSyncError={viewModel.hasCalendarSyncError}
                    retryFetchData={() => viewModel.fetchDays(currentDate, true)}
                  />
                )}
              />
            </BackgroundImageScreenPaper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
