import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import {
  ArchiveRounded,
  ContentCopyRounded,
  DifferenceRounded,
  LowPriorityRounded,
  RepeatRounded
} from '@mui/icons-material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate, useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { BackgroundLocationState } from '../../../BackgroundLocationState';
import { ConfirmationDialog, Dialog, DialogAdditionalAction, onDialogClose } from '../../utils';
import { PublishedWorkEditDialog } from '../edit';
import { PublishedWorkDetails } from './PublishedWorkDetails';

interface LocationState {
  publishedWorkDetailsShowWorkEdit?: boolean;
}

export interface PublishedWorkDetailsDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
}

export const PublishedWorkDetailsDialog = observer(({ sx, className, isOpen }: PublishedWorkDetailsDialogProps) => {
  const { featureFlag, pasteboard, route } = useServices();
  const plannerId = useActivePlannerId();
  const location = useLocation();
  const state = (location.state ?? {}) as LocationState & BackgroundLocationState;
  const navigate = useNavigate();
  const strings = LocalizedStrings.publishedWork.details;

  const params = useParams();
  const id = params.workId ?? '';
  const schoolId = params.schoolId ?? '';

  const [showCancelWorkConfirmAlert, setShowCancelWorkConfirmAlert] = useState(false);

  function close() {
    onDialogClose(route, navigate, state, plannerId);
    return Promise.resolve();
  }

  const viewModel = useViewModel(
    (viewModels) => viewModels.createPublishedWorkDetailsViewModel(id, schoolId, plannerId, close),
    [schoolId, id]
  );

  function duplicate() {
    viewModel.duplicate(navigate, location);
    return Promise.resolve();
  }

  function distribute() {
    viewModel.distribute(navigate, location);
    return Promise.resolve();
  }

  function repeat() {
    viewModel.repeat(navigate, location);
    return Promise.resolve();
  }

  const isItemDuplicationEnabled = featureFlag.isEnabled('item-duplication');

  const actions: DialogAdditionalAction[] = viewModel.hasData
    ? [
        {
          title: strings.copyWorkOptionLabel(),
          icon: <ContentCopyRounded />,
          // TODO: https://linear.app/studyoco/issue/TOD-671/support-copy-of-published-works
          isHidden: !isItemDuplicationEnabled || true,
          action: () => {
            pasteboard.setContent({ case: 'published-work', value: viewModel.publishedWork });
            return Promise.resolve();
          }
        },
        {
          title: strings.duplicateWorkOptionLabel(),
          icon: <DifferenceRounded />,
          // TODO: https://linear.app/studyoco/issue/TOD-671/support-copy-of-published-works
          isHidden: !isItemDuplicationEnabled || true,
          action: duplicate
        },
        {
          title: strings.distributeWorkOptionLabel(),
          icon: <LowPriorityRounded />,
          // TODO: https://linear.app/studyoco/issue/TOD-671/support-copy-of-published-works
          isHidden: !isItemDuplicationEnabled || true,
          // isHidden:
          //   !isItemDuplicationEnabled ||
          //   viewModel.publishedWork.dueTime == null ||
          //   viewModel.publishedWork.courseSectionId.length === 0,
          action: distribute
        },
        {
          title: strings.repeatWorkOptionLabel(),
          icon: <RepeatRounded />,
          // TODO: https://linear.app/studyoco/issue/TOD-671/support-copy-of-published-works
          isHidden: !isItemDuplicationEnabled || true,
          action: repeat
        },
        {
          title: strings.cancelWorkOptionLabel(),
          icon: <ArchiveRounded />,
          isHidden: !viewModel.canCancelWork,
          isDestructive: true,
          action: () => {
            setShowCancelWorkConfirmAlert(true);
            return Promise.resolve();
          }
        }
      ]
    : [];

  async function dismissCancelWorkConfirmAlert(hasConfirmed: boolean) {
    setShowCancelWorkConfirmAlert(false);

    if (hasConfirmed) {
      await viewModel.cancelWork();
      await close();
    }
  }

  useEffect(() => {
    route.onDialogPresent();
    return () => route.onDialogDismiss();
  }, []);

  return (
    <>
      <Dialog
        sx={sx}
        className={className}
        isOpen={isOpen}
        canToggleFullscreen
        width="md"
        viewModel={viewModel}
        title={strings.title()}
        fullScreenWidth="md"
        submit={() => void close()}
        contentPadding={{ top: 2 }}
        additionalActions={actions}
        renderData={() => <PublishedWorkDetails sx={{ flex: 1, width: '100%' }} viewModel={viewModel} />}
      />

      {state.publishedWorkDetailsShowWorkEdit && (
        <PublishedWorkEditDialog ids={{ publishedWorkId: id, schoolId }} isOpen={true} />
      )}

      {showCancelWorkConfirmAlert && (
        <ConfirmationDialog
          isOpen={true}
          title={strings.cancelConfirmationDialog.title()}
          message={strings.cancelConfirmationDialog.message()}
          confirmButtonLabel={strings.cancelConfirmationDialog.confirmButtonLabel()}
          onSubmit={(hasConfirmed) => void dismissCancelWorkConfirmAlert(hasConfirmed)}
          isDestructive
        />
      )}
    </>
  );
});
