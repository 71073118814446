import { useServices } from '@/hooks';
import { AllFulfilledUserPersonas } from '@/models';
import { UserPersonaStepViewModel } from '@/viewmodels';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { Avatar, Box, BoxProps, Button, Divider, Stack, styled, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ChoicePicker, RadioChoicePickerOptionLabel, TypographyParagraphs } from '../utils';
import { OnboardingStepActions } from './OnboardingStepActions';

export interface UserPersonaStepProps {
  className?: string;
  viewModel: UserPersonaStepViewModel;
}

export const UserPersonaStep = observer(({ className, viewModel }: UserPersonaStepProps) => {
  const { localization, analytics } = useServices();
  const strings = localization.localizedStrings.onboarding;

  useEffect(() => analytics.logScreenView_Onboarding_PersonaSelection(), []);

  return (
    <Root className={className} width="100%" display="flex" flexDirection="column">
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2} mb={1}>
        <Avatar src={viewModel.pictureUrl} alt={viewModel.name} sx={{ width: 40, height: 40 }} />

        <TypographyParagraphs variant="h6" align="center" fontWeight="500">
          {strings.welcomeUserName(viewModel.name)}
        </TypographyParagraphs>
      </Stack>

      <Stack>
        <ChoicePicker
          label={
            <Typography color="textPrimary" mb={1}>
              {strings.personaLabel}
            </Typography>
          }
          variant="radio"
          disabled={viewModel.isSubmitting}
          onSelect={(option) => (viewModel.persona = option?.value)}
          options={AllFulfilledUserPersonas.map((persona) => ({
            key: UserPersona[persona],
            value: persona,
            label: (
              <RadioChoicePickerOptionLabel
                title={strings.persona(persona)}
                subtitle={strings.personaDetail(persona)}
                isSelected={viewModel.persona === persona}
              />
            ),
            isDefaultSelected: viewModel.persona === persona
          }))}
        />

        {/* Error message */}
        {viewModel.error != null && (
          <Typography mt={3} mx={2} variant="caption" color="error" align="center">
            {strings.applyPersonaErrorMessage(viewModel.error)}
          </Typography>
        )}

        <OnboardingStepActions mt={3} viewModel={viewModel} showAuthStateButton />
      </Stack>

      <Divider variant="middle" sx={{ my: 2 }} />

      <Button size="small" onClick={() => void viewModel.showUseCode()}>
        {strings.expectSharingInvitationCodeLabel}
      </Button>
    </Root>
  );
});

const PREFIX = 'UserPersonaStep';
const classes = {
  picker: `${PREFIX}-picker`
};

const Root = styled((props: BoxProps) => <Box {...props} />)(() => ({
  [`& .${classes.picker}`]: {
    margin: 0
  }
}));
