import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useActivePlannerId, useServices, useViewModel } from '../../../../hooks';
import { AllPlannerItemsPasteboardContentKind, PasteboardPasteContext } from '../../../../services';
import { Dialog } from '../../../utils';
import { PlannerPastePlannerItemCourseSelection } from './PlannerPastePlannerItemCourseSelection';
import { PlannerPastePlannerItemDateHeader } from './PlannerPastePlannerItemDateHeader';
import { PlannerPastePlannerItemDateSelection } from './PlannerPastePlannerItemDateSelection';
import { PlannerPastePlannerItemGeneralOptions } from './PlannerPastePlannerItemGeneralOptions';
import { PlannerPastePlannerItemTimeHeader } from './PlannerPastePlannerItemTimeHeader';
import { PlannerPastePlannerItemTimeOfDaySelection } from './PlannerPastePlannerItemTimeOfDaySelection';

export interface PlannerPastePlannerItemDialogProps {
  sx?: SxProps;
  className?: string;
  context: PasteboardPasteContext;
}

export const PlannerPastePlannerItemDialog = observer(
  ({ sx, className, context }: PlannerPastePlannerItemDialogProps) => {
    const { pasteboard } = useServices();
    const plannerId = useActivePlannerId();
    const strings = LocalizedStrings.planner.pastePlannerItem;

    function close() {
      pasteboard.clearPasteContext();
      return Promise.resolve();
    }

    const viewModel = useViewModel(
      (viewModels) => viewModels.createPlannerPastePlannerItemViewModel(plannerId, context, close),
      [plannerId, context, close]
    );

    const item = pasteboard.currentContentForKinds(AllPlannerItemsPasteboardContentKind);
    if (item == null) {
      return null;
    }

    return (
      <Dialog
        sx={sx}
        className={className}
        viewModel={viewModel}
        isOpen
        title={strings.title(pasteboard.getSummaryTextForContent(item))}
        width="sm"
        renderData={() => (
          <Stack sx={{ overflowY: 'auto', width: '100%' }} spacing={1}>
            {viewModel.showDateSelection ? (
              <PlannerPastePlannerItemDateSelection viewModel={viewModel} />
            ) : (
              <PlannerPastePlannerItemDateHeader sx={{ px: 1 }} date={viewModel.date} />
            )}

            {viewModel.showTimeSelection ? (
              <PlannerPastePlannerItemTimeOfDaySelection
                viewModel={viewModel.timeOfDaySelectionViewModel}
                courseSectionsById={viewModel.courseSectionsById}
              />
            ) : (
              <PlannerPastePlannerItemTimeHeader viewModel={viewModel} sx={{ px: 1 }} />
            )}

            <PlannerPastePlannerItemCourseSelection viewModel={viewModel} />

            {viewModel.showGeneralOptions && <PlannerPastePlannerItemGeneralOptions viewModel={viewModel} />}
          </Stack>
        )}
      />
    );
  }
);
