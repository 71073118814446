import { useServices } from '@/hooks';
import ScreenshotPlannerEn from '@/resources/images/welcome/screenshot_planner.en.jpg';
import ScreenshotPlannerFr from '@/resources/images/welcome/screenshot_planner.fr.jpg';
import ScreenshotTeacherEn from '@/resources/images/welcome/screenshot_teacher.en.jpg';
import ScreenshotTeacherFr from '@/resources/images/welcome/screenshot_teacher.fr.jpg';
import ScreenshotTodayEn from '@/resources/images/welcome/screenshot_today.en.jpg';
import ScreenshotTodayFr from '@/resources/images/welcome/screenshot_today.fr.jpg';
import ScreenshotWeekEn from '@/resources/images/welcome/screenshot_week.en.jpg';
import ScreenshotWeekFr from '@/resources/images/welcome/screenshot_week.fr.jpg';
import { css } from '@emotion/css';
import { Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

interface WelcomeScreenshot {
  readonly en: string;
  readonly fr: string;
}

const screenshots: WelcomeScreenshot[] = [
  {
    en: ScreenshotPlannerEn,
    fr: ScreenshotPlannerFr
  },
  { en: ScreenshotTodayEn, fr: ScreenshotTodayFr },
  { en: ScreenshotWeekEn, fr: ScreenshotWeekFr },
  { en: ScreenshotTeacherEn, fr: ScreenshotTeacherFr }
];

export interface WelcomeMainScreenshotProps {
  sx?: SxProps;
  className?: string;
}

export const WelcomeMainScreenshot = observer(({ sx, className }: WelcomeMainScreenshotProps) => {
  const { localization } = useServices();
  const theme = useTheme();
  const [currentScreenshotIndex, setCurrentScreenshotIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (currentScreenshotIndex === screenshots.length - 1) {
        setCurrentScreenshotIndex(0);
      } else {
        setCurrentScreenshotIndex((v) => v + 1);
      }
    }, 5_000);

    return () => clearInterval(interval);
  }, [currentScreenshotIndex]);

  const currentScreenshot = screenshots[currentScreenshotIndex];
  const resolvedSrc = localization.currentLocale === 'fr' ? currentScreenshot.fr : currentScreenshot.en;

  const imageStyle = css([
    {
      height: 'auto',
      borderRadius: 12,
      maxWidth: '150%',
      maxHeight: '70%',
      boxShadow: theme.shadows['10'],
      aspectRatio: 1.6,

      [theme.breakpoints.down('md')]: {
        width: '100%'
      }
    }
  ]);

  return (
    <Box
      className={className}
      sx={{
        ...sx,
        display: 'flex',
        justifyContent: { xs: 'center', md: 'flex-start' },
        alignItems: 'center'
      }}
    >
      <AnimatePresence initial={false} mode="wait">
        <motion.img
          key={currentScreenshotIndex}
          src={resolvedSrc}
          className={imageStyle}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ opacity: { duration: 0.3 }, ease: 'easeInOut' }}
        />
      </AnimatePresence>
    </Box>
  );
});
