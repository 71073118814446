import i18n from 'translate';
import { ScheduleCycleActivitySchedulesEditStrings } from './ScheduleCycleActivitySchedulesEditStrings';

export const ScheduleCycleActivitySchedulesStrings = {
  addActivityScheduleButtonTitle: () => i18n.t('scheduleCycle.edit.activitySchedules.addActivityScheduleButtonTitle'),
  classesListTitle: () => i18n.t('scheduleCycle.edit.activitySchedules.classesListTitle'),
  classesSchedulesCount: (count: number) =>
    i18n.t('scheduleCycle.edit.activitySchedules.classesSchedulesCount', { count }),
  deleteScheduleOption: () => i18n.t('scheduleCycle.edit.activitySchedules.deleteScheduleOption'),
  deleteScheduleTooltip: () => i18n.t('scheduleCycle.edit.activitySchedules.deleteScheduleTooltip'),
  edit: ScheduleCycleActivitySchedulesEditStrings,
  editScheduleOption: () => i18n.t('scheduleCycle.edit.activitySchedules.editScheduleOption'),
  editScheduleTooltip: () => i18n.t('scheduleCycle.edit.activitySchedules.editScheduleTooltip'),
  manageClassesButtonTitle: () => i18n.t('scheduleCycle.edit.activitySchedules.manageClassesButtonTitle'),
  noClasses: () => i18n.t('scheduleCycle.edit.activitySchedules.noClasses'),
  noSchedulesForCourseMessage: () => i18n.t('scheduleCycle.edit.activitySchedules.noSchedulesForCourseMessage'),
  selectedActivityClassTitle: () => i18n.t('scheduleCycle.edit.activitySchedules.selectedActivityClassTitle'),
  selectedActivitySchedulesListTitle: () =>
    i18n.t('scheduleCycle.edit.activitySchedules.selectedActivitySchedulesListTitle'),
  schedulePeriodLabel: (label: string) => i18n.t('scheduleCycle.edit.activitySchedules.schedulePeriodLabel', { label }),
  schedulePeriodTimes: (startTime: string, endTime: string) =>
    i18n.t('scheduleCycle.edit.activitySchedules.schedulePeriodTimes', { startTime, endTime }),
  scheduleTerm: (termName: string) => i18n.t('scheduleCycle.edit.activitySchedules.scheduleTerm', { termName }),
  scheduleDisplayedLabel: (label: string) =>
    i18n.t('scheduleCycle.edit.activitySchedules.scheduleDisplayedLabel', { label }),
  scheduleRoomName: (roomName: string) => i18n.t('scheduleCycle.edit.activitySchedules.scheduleRoomName', { roomName }),
  scheduleTagLabel: (scheduleTag: string) =>
    i18n.t('scheduleCycle.edit.activitySchedules.scheduleTagLabel', { scheduleTag })
};
