import { Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';

export interface ScheduleCyclePeriodScheduleGridTopLeftViewProps {
  sx?: SxProps;
  className?: string;
}

export function ScheduleCyclePeriodScheduleGridTopLeftView({
  sx,
  className
}: ScheduleCyclePeriodScheduleGridTopLeftViewProps) {
  const theme = useTheme();

  return (
    <Stack
      className={className}
      width={ScheduleCyclePeriodScheduleGridConstants.timesColumnWidth}
      sx={{ ...sx, backgroundColor: theme.palette.background.paper }}
      zIndex={ScheduleCyclePeriodScheduleGridConstants.topLeftViewZIndex}
      position="sticky"
      left={0}
    />
  );
}
