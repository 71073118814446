import { Divider, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ScheduleCycleBellTimesPage } from '../../shared';

export interface AdminSchoolScheduleCycleBellTimesPageProps {
  sx?: SxProps;
  className?: string;
  isReadOnly: boolean;
}

export const AdminSchoolScheduleCycleBellTimesPage = observer(
  ({ sx, className, isReadOnly }: AdminSchoolScheduleCycleBellTimesPageProps) => {
    return (
      <Stack width="100%" height="100%" sx={sx} className={className}>
        <Stack width="100%" height="100%" spacing={1} sx={{ maxWidth: 1000 }}>
          <Typography variant="h6">{LocalizedStrings.admin.schedule.bellTimesTitle()}</Typography>
          <Divider />

          <ScheduleCycleBellTimesPage isReadOnly={isReadOnly} />
        </Stack>
      </Stack>
    );
  }
);
