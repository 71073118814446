import { PersonRounded } from '@mui/icons-material';
import { List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { UserProfileIcon } from '../../shared';

export interface AdminSchoolAdminUsersListProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
}

export const AdminSchoolAdminUsersList = observer(({ sx, className, schoolId }: AdminSchoolAdminUsersListProps) => {
  const { userStore } = useServices();
  const school = userStore.getSchoolForId(schoolId)!;

  return (
    <List sx={sx} className={className}>
      {school.owners.map((owner) => (
        <ListItem key={owner.userId}>
          <ListItemIcon>
            <UserProfileIcon url={owner.pictureUrl} userFullName={owner.fullName} />
          </ListItemIcon>

          <ListItemText primary={owner.fullName} secondary={owner.emailAddress} />

          {owner.userId === userStore.user.userId && (
            <Tooltip title={LocalizedStrings.admin.general.adminsListCurrentUserTooltip()}>
              <PersonRounded color="primary" />
            </Tooltip>
          )}
        </ListItem>
      ))}
    </List>
  );
});
