import { Entitlement } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/entitlement_pb';
import { Product } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/product_pb';
import { Subscription } from '@buf/studyo_studyo-today-subscriptions.bufbuild_es/studyo/today/subscriptions/v1/resources/subscription_pb';
import { Subscriptions } from '@buf/studyo_studyo-today-subscriptions.connectrpc_es/studyo/today/subscriptions/v1/subscriptions_connect';
import { find } from 'lodash';
import { SchoolEntitlementInfo, SubscriptionInfo } from '../../models';
import { SubscriptionsTransportService } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppSubscriptionsTransportService extends AppBaseTransportService implements SubscriptionsTransportService {
  async getSubscription(): Promise<SubscriptionInfo | undefined> {
    const response = await this.performReadRequest(
      'getSubscription',
      Subscriptions,
      Subscriptions.methods.getSubscription,
      {}
    );
    const subscription = response.subscription;

    if (subscription == null) {
      return undefined;
    }

    const products = await this.getProducts();
    const subscriptionProduct = products.find((p) => find(p.prices, { id: subscription.productPriceId }) != null);
    return subscriptionProduct != null ? { subscription, product: subscriptionProduct } : undefined;
  }

  async getProducts(): Promise<Product[]> {
    try {
      const response = await this.performReadRequest(
        'getProducts',
        Subscriptions,
        Subscriptions.methods.getProducts,
        {}
      );
      return response.products;
    } catch (e) {
      console.warn(e);
      throw e;
    }
  }

  async getCheckoutUrl(productPriceId: string, successReturnUrl: string, cancelReturnUrl: string): Promise<string> {
    const response = await this.performReadRequest(
      'getCheckoutUrl',
      Subscriptions,
      Subscriptions.methods.getCheckoutUrl,
      { productPriceId, successReturnUrl, cancelReturnUrl }
    );
    return response.checkoutUrl;
  }

  async getPortalUrl(returnUrl: string): Promise<string> {
    const response = await this.performReadRequest('getPortalUrl', Subscriptions, Subscriptions.methods.getPortalUrl, {
      returnUrl
    });
    return response.portalUrl;
  }

  async createInvoicedSubscription(productPriceId: string): Promise<Subscription> {
    const response = await this.performWriteRequest(
      'createInvoicedSubscription',
      Subscriptions,
      Subscriptions.methods.createInvoicedSubscription,
      { productPriceId }
    );

    return response.subscription!;
  }

  async getIsSchoolEntitled(schoolId: string, entitlement: Entitlement): Promise<SchoolEntitlementInfo> {
    const response = await this.performReadRequest(
      'getIsSchoolEntitled',
      Subscriptions,
      Subscriptions.methods.getIsSchoolEntitled,
      { schoolId, entitlement }
    );

    return {
      schoolId,
      isEntitled: response.isEntitled,
      notEntitledReasons: response.notEntitledReasons,
      isTrial: response.isTrial
    };
  }
}
