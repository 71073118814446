import { RouteTemplates } from '@/RouteTemplates';
import { useServices, useViewModel } from '@/hooks';
import { Alert, Button, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertDialog, ConfirmationDialog, Subheader } from '../../utils';
import { PreferredMaxWidth } from './SettingsConstants';
import { SettingsContent } from './SettingsContent';

export interface AccountManagementProps {
  sx?: SxProps;
  className?: string;
}

export const AccountManagement = observer(({ sx, className }: AccountManagementProps) => {
  const { localization } = useServices();
  const [showConfirmation, setShowConfirmation] = useState(false);
  const viewModel = useViewModel((viewModels) => viewModels.createAccountManagementViewModel());
  const navigate = useNavigate();

  async function deleteAccount(hasConfirmed: boolean) {
    if (hasConfirmed) {
      await viewModel.deleteAccount();
      navigate(RouteTemplates.welcome, { replace: true });
    } else {
      setShowConfirmation(false);
    }
  }

  function manageCookies() {
    window.displayPreferenceModal();
  }

  const strings = localization.localizedStrings.user;

  return (
    <SettingsContent sx={sx} className={className}>
      <Stack spacing={2} alignItems="flex-start">
        <Stack spacing={1} alignItems="flex-start">
          <Subheader>{strings.cookiesHeader}</Subheader>

          <Button variant="contained-grey" onClick={manageCookies}>
            {strings.cookiePreferencesButton}
          </Button>
        </Stack>

        <Stack spacing={1} alignItems="flex-start">
          <Subheader>{strings.deleteAccountHeader}</Subheader>

          <Alert severity="error" sx={{ maxWidth: PreferredMaxWidth }}>
            {strings.deleteAccountMessage}
          </Alert>
          <Button variant="contained" color="error" onClick={() => setShowConfirmation(true)}>
            {strings.deleteAccountButton}
          </Button>
        </Stack>
      </Stack>

      {viewModel.error != null && (
        <AlertDialog isOpen={true} {...viewModel.error} onSubmit={() => viewModel.dismissError()} />
      )}

      {showConfirmation && (
        <ConfirmationDialog
          isOpen={true}
          title={strings.deleteAccountConfirmationTitle}
          message={strings.deleteAccountConfirmationMessage}
          confirmButtonLabel={strings.deleteAccountConfirmationDeleteButton}
          onSubmit={(hasConfirmed) => void deleteAccount(hasConfirmed)}
        />
      )}
    </SettingsContent>
  );
});
