import { getOptionalDashConcatenation } from '@/models';
import { ScheduleCycleActivitySchedulesCourseSectionInfo } from '@/viewmodels';
import { SchoolRounded } from '@mui/icons-material';
import { List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../../../lists';

export interface ScheduleCycleActivitySchedulesCoursesListProps {
  sx?: SxProps;
  className?: string;
  courses: ScheduleCycleActivitySchedulesCourseSectionInfo[];
  selectedCourseId?: string;
  onCourseSelect: (id: string) => void;
  manageClassesLocation?: string;
}

export const ScheduleCycleActivitySchedulesCoursesList = observer(
  ({
    sx,
    className,
    courses,
    selectedCourseId,
    onCourseSelect,
    manageClassesLocation
  }: ScheduleCycleActivitySchedulesCoursesListProps) => {
    const strings = LocalizedStrings.scheduleCycle.edit.activitySchedules;

    return (
      <List sx={{ ...sx, overflowY: 'auto' }} className={className} dense>
        {courses.map((course, index) => (
          <ListItemButton
            className="rounded"
            key={course.id}
            selected={selectedCourseId === course.id || ((selectedCourseId?.length ?? 0) === 0 && index === 0)}
            onClick={() => onCourseSelect(course.id)}
          >
            <ColorIndicator color={course.color} sx={{ height: 36, mr: 1 }} />
            <ListItemText
              primary={course.title}
              secondary={getOptionalDashConcatenation(
                course.section,
                strings.classesSchedulesCount(course.numberOfSchedules)
              )}
            />
          </ListItemButton>
        ))}

        {courses.length === 0 && (
          <ListItem>
            <ListItemText
              primary={strings.noClasses()}
              primaryTypographyProps={{ color: 'textSecondary', fontStyle: 'italic' }}
            />
          </ListItem>
        )}

        {manageClassesLocation != null && (
          <ListItemButton className="rounded" component={Link} to={manageClassesLocation}>
            <ListItemIcon>
              <SchoolRounded fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={strings.manageClassesButtonTitle()} />
          </ListItemButton>
        )}
      </List>
    );
  }
);
