export function getOrCreateInMap<T>(map: Map<string, T>, key: string, createFn: () => T): T {
  const existingValue = map.get(key);

  if (existingValue != null) {
    return existingValue;
  }

  const newValue = createFn();
  map.set(key, newValue);
  return newValue;
}
