import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { ServiceContainer } from '../../providers';
import { LocalizationService } from '../../services';
import { UserDataStore } from '../../stores';

export interface SchoolEditViewModel {
  name: string;
  subtitle: string;
  readonly canApply: boolean;
  readonly isApplying: boolean;
  readonly editSchoolError: string | undefined;
  readonly isReadOnly: boolean;

  apply(): Promise<void>;
  resetChanges(): void;
  clearCreateSchoolError(): void;
}

export class AppSchoolEditViewModel implements SchoolEditViewModel {
  @observable private _isApplying = false;
  @observable private _editSchoolError: string | undefined;

  readonly isReadOnly: boolean;
  @observable private _originalName: string;
  @observable private _originalSubtitle: string;
  @observable private _name: string;
  @observable private _subtitle: string;

  constructor(
    private readonly _schoolId: string,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    makeObservable(this);

    const school = _userStore.getSchoolForId(_schoolId);
    const userId = _userStore.user.userId;
    this.isReadOnly = !(school?.owners.some((o) => o.userId === userId) ?? false);

    this._originalName = school?.school?.name ?? '';
    this._originalSubtitle = school?.school?.subtitle ?? '';
    this._name = this._originalName;
    this._subtitle = this._originalSubtitle;
  }

  @computed
  get canApply(): boolean {
    const hasChanges = this._originalName != this._name || this._originalSubtitle != this._subtitle;
    const canApplyChanges = this._name.length > 0;
    return !this.isApplying && hasChanges && canApplyChanges;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get editSchoolError(): string | undefined {
    return this._editSchoolError;
  }

  @computed
  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  @computed
  get subtitle(): string {
    return this._subtitle;
  }

  set subtitle(value: string) {
    this._subtitle = value;
  }

  @action
  clearCreateSchoolError() {
    this._editSchoolError = undefined;
  }

  async apply() {
    if (this.name.length === 0) {
      return;
    }

    runInAction(() => {
      this._isApplying = true;
      this._editSchoolError = undefined;
    });

    try {
      await this._userStore.editSchool(this._schoolId, this.name, this.subtitle);

      runInAction(() => {
        this._originalName = this._name;
        this._originalSubtitle = this._subtitle;
      });
    } catch (e) {
      const error = e as Error;
      runInAction(() => (this._editSchoolError = error.message));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  @action
  resetChanges() {
    this._name = this._originalName;
    this._subtitle = this._originalSubtitle;
  }
}
