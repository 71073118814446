import { useActivePlannerId, usePlannerUserHasAccess, useServices, useViewModel } from '@/hooks';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { AddRounded, SyncRounded } from '@mui/icons-material';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { CourseSectionEditDialog, CourseSectionList, SettingsCard, SettingsContent } from '../../shared';
import { UpdatablePresenter } from '../../utils';

interface LocationState {
  courseSectionListEditCourseId?: string;
  personalCourseSectionListAdd?: boolean;
}

export interface PlannerCourseSectionsProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerCourseSections = observer(({ sx, className }: PlannerCourseSectionsProps) => {
  const { connectedApps } = useServices();
  const plannerId = useActivePlannerId();

  const personalCoursesViewModel = useViewModel(
    (viewModels) => viewModels.createPlannerPersonalCourseSectionListViewModel(plannerId),
    [plannerId]
  );

  const externalCoursesViewModel = useViewModel(
    (viewModels) => viewModels.createPlannerExternalCourseSectionListViewModel(plannerId),
    [plannerId]
  );

  const hasAccess = usePlannerUserHasAccess([AccessKind.FULL_ACCESS]);
  const strings = LocalizedStrings.settings.planner.classes;

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigate();

  function editCourseSection(id: string) {
    const newState: LocationState = { ...state, courseSectionListEditCourseId: id };
    navigate(location, { state: newState });
  }

  function showAddCourse() {
    const newState: LocationState = { ...state, personalCourseSectionListAdd: true };
    navigate(location, { state: newState });
    return Promise.resolve();
  }

  return (
    <SettingsContent sx={sx} className={className}>
      <Box>
        <SettingsCard
          title={strings.personalTitle()}
          subtitle={strings.personalSubtitle()}
          canBeCollapsed
          actions={hasAccess ? [{ icon: <AddRounded />, action: showAddCourse }] : undefined}
        >
          <UpdatablePresenter
            viewModel={personalCoursesViewModel}
            renderData={() => (
              <CourseSectionList
                sx={{ height: '100%', width: '100%' }}
                viewModel={personalCoursesViewModel}
                editCourse={editCourseSection}
                canManageConnectedApps={false}
              />
            )}
          />

          {state.courseSectionListEditCourseId != null && (
            <CourseSectionEditDialog isOpen={true} courseSectionId={state.courseSectionListEditCourseId} />
          )}
        </SettingsCard>

        <SettingsCard
          sx={{ mt: 2 }}
          title={strings.externalTitle()}
          subtitle={strings.externalSubtitle()}
          canBeCollapsed
          actions={[
            {
              icon: <SyncRounded />,
              action: async () => connectedApps.sync(plannerId),
              showLoading: true
            }
          ]}
        >
          <UpdatablePresenter
            viewModel={externalCoursesViewModel}
            renderData={() => (
              <CourseSectionList
                sx={{ height: '100%', width: '100%' }}
                viewModel={externalCoursesViewModel}
                editCourse={editCourseSection}
                canManageConnectedApps
              />
            )}
          />

          {state.courseSectionListEditCourseId != null && (
            <CourseSectionEditDialog isOpen={true} courseSectionId={state.courseSectionListEditCourseId} />
          )}

          {state.personalCourseSectionListAdd === true && (
            <CourseSectionEditDialog isOpen={true} courseSectionId={undefined} />
          )}
        </SettingsCard>
      </Box>

      {state.personalCourseSectionListAdd === true && (
        <CourseSectionEditDialog isOpen={true} courseSectionId={undefined} />
      )}
    </SettingsContent>
  );
});
