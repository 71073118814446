import { useActivePlannerId, useViewModel } from '@/hooks';
import { Stack } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { Dialog } from '../../utils';
import { PlannedWorkEditSchedule } from './PlannedWorkEditSchedule';

export interface PlannedWorkEditDialogProps {
  /**
   * Id of the work associated to the planned work to edit.
   */
  workId: string;

  /**
   * Id of the planned work to edit. Optional. If undefined, it creates a new planned work.
   */
  plannedWorkId: string | undefined;

  isOpen: boolean;
}

export const PlannedWorkEditDialog = observer(({ workId, plannedWorkId, isOpen }: PlannedWorkEditDialogProps) => {
  const navigate = useNavigate();
  const plannerId = useActivePlannerId();

  function dismiss() {
    navigate(-1);
    return Promise.resolve();
  }

  const strings = LocalizedStrings.plannedWork.edit;
  const viewModel = useViewModel((viewModels) =>
    viewModels.createPlannedWorkEditViewModel(plannerId, workId, plannedWorkId, dismiss, dismiss)
  );

  return (
    <Dialog
      isOpen={isOpen}
      width="md"
      viewModel={viewModel}
      title={strings.title()}
      fullScreenWidth="md"
      submit={() => void viewModel.save()}
      fullHeight
      isContentDisplayFlex
      disableOverflow
      renderData={() => (
        <Stack width="100%" height="100%" overflow="hidden" spacing={1}>
          <PlannedWorkEditSchedule sx={{ flex: 1 }} viewModel={viewModel} />
        </Stack>
      )}
    />
  );
});
