import { useServices, useViewModel } from '@/hooks';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { Dialog, EmptyListIndicator } from '../../utils';
import { PlannerParticipationRequestListItem } from './PlannerParticipationRequestListItem';

export interface PlannerParticipationRequestsDialogProps {
  isOpen: boolean;
  plannerId: string;
  sx?: SxProps;
}

export const PlannerParticipationRequestsDialog = observer(
  ({ isOpen, plannerId, sx }: PlannerParticipationRequestsDialogProps) => {
    const { localization } = useServices();
    const navigate = useNavigate();
    const strings = localization.localizedStrings.participationRequests;

    function dismiss() {
      navigate(-1);
      return Promise.resolve();
    }

    const viewModel = useViewModel((viewModels) =>
      viewModels.createPlannerParticipationRequestsViewModel(plannerId, dismiss)
    );

    return (
      <Dialog
        viewModel={viewModel}
        isOpen={isOpen}
        title={strings.title}
        width="sm"
        sx={sx}
        renderData={() => (
          <Stack direction="column" spacing={2}>
            <Typography variant="subtitle2" fontWeight="500" color="textSecondary">
              {strings.subtitle}
            </Typography>

            <Stack spacing={1}>
              {viewModel.unreadRequests.map((request, i) => (
                <PlannerParticipationRequestListItem
                  key={`participation-request-${i}`}
                  request={request}
                  dismiss={() => void dismiss()}
                />
              ))}

              {viewModel.unreadRequests.length === 0 && (
                <EmptyListIndicator
                  sx={{ mt: 3 }}
                  renderIcon={(iconClassName) => <PersonRoundedIcon className={iconClassName} />}
                  title={strings.emptyLabel}
                />
              )}
            </Stack>

            {viewModel.unreadRequests.some((req) => req.error != null) && (
              <Typography variant="subtitle2" color="error">
                {strings.errorMessage}
              </Typography>
            )}
          </Stack>
        )}
      />
    );
  }
);
