import { useActivePlannerId, useAppBarColors, useServices, useViewModel } from '@/hooks';
import { AddRounded } from '@mui/icons-material';
import DrawerIcon from '@mui/icons-material/MenuRounded';
import {
  AppBar,
  Badge,
  Box,
  Button,
  IconButton,
  Stack,
  SwipeableDrawer,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Logo, RefreshButton, getBlurredClassName } from '../utils';
import { CreateWorkOptionsMenu } from './CreateWorkOptionsMenu';
import { UserDashboardSideBar } from './sidebar';
import { UserDashboardPicker } from './user-dashboard-picker';

export interface MainAppBarProps {
  className?: string;
  /**
   * Tabs element. Optional. Accepting null as we might want to render tabs only on certain screen sizes.
   */
  renderTabs?: () => JSX.Element | null;
}

export const MainAppBar = observer(({ className, renderTabs }: MainAppBarProps) => {
  const { localization, navigation, pasteboard, settings } = useServices();
  const theme = useTheme();
  const strings = localization.localizedStrings.utils;

  const [showUserDashboardPicker, setShowUserDashboardPicker] = useState(false);
  const activeDashboardButtonRef = useRef<HTMLDivElement | null>(null);
  const plannerId = useActivePlannerId();
  const params = useParams();
  const courseSectionId = params.courseSectionId;

  const viewModel = useViewModel((viewModels) => viewModels.createMainAppBarViewModel(plannerId), [plannerId]);

  const isSmallScreen = useMediaQuery(() => theme.breakpoints.down('sm'));
  const blurredStyle = getBlurredClassName(
    'paper',
    theme,
    settings.backgroundImage?.color ?? (theme.palette.mode === 'light' ? theme.palette.common.white : undefined)
  );
  const hasBackgroundImage = settings.backgroundImage != null;
  const backgroundShouldBeBlurred = hasBackgroundImage && !settings.reduceTransparency && !isSmallScreen;

  const location = useLocation();
  const navigate = useNavigate();

  const [showSideBar, setShowSideBar] = useState(false);
  const [createWorkMenuAnchor, setCreateWorkMenuAnchor] = useState<HTMLButtonElement | undefined>();

  function onCreateWorkButtonPressed(e: MouseEvent<HTMLButtonElement>) {
    setCreateWorkMenuAnchor(e.currentTarget);
  }

  function showCreateWork() {
    setCreateWorkMenuAnchor(undefined);
    navigation.navigateToWorkEdit(navigate, location, undefined, { courseSectionId });
  }

  function showCreateNote() {
    setCreateWorkMenuAnchor(undefined);
    navigation.navigateToNoteEdit(navigate, location, undefined, { courseSectionId });
  }

  function showCreatePublishedWork() {
    setCreateWorkMenuAnchor(undefined);
    navigation.navigateToPublishedWorkEdit(navigate, location, undefined, { courseSectionId });
  }

  function onPaste() {
    setCreateWorkMenuAnchor(undefined);
    pasteboard.setPasteContext({});
  }

  const isIOS = typeof navigator !== 'undefined' && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const { backgroundColor, textColor } = useAppBarColors(true);

  return (
    <>
      <AppBar
        className={clsx(className, backgroundShouldBeBlurred && blurredStyle)}
        position="relative"
        color={backgroundColor != null ? 'transparent' : 'primary'}
        sx={{
          backgroundColor,
          color: textColor
        }}
      >
        <Toolbar>
          {isSmallScreen && (
            <IconButton color="inherit" size="small" onClick={() => setShowSideBar(true)}>
              <Badge
                badgeContent={viewModel.connectedAppsAlert}
                color={viewModel.connectedAppsAlert ?? 'default'}
                variant="dot"
              >
                <DrawerIcon />
              </Badge>
            </IconButton>
          )}

          <Stack
            direction="row"
            ref={activeDashboardButtonRef}
            onClick={() => setShowUserDashboardPicker(true)}
            sx={{
              cursor: 'pointer',
              py: 1,
              pl: isSmallScreen ? 2 : 1,
              pr: 2,
              borderRadius: 1,
              [':hover']: {
                backgroundColor: theme.palette.action.hover
              },
              [':focus']: {
                backgroundColor: theme.palette.action.focus
              }
            }}
            spacing={2}
            alignItems="center"
          >
            {!isSmallScreen && (
              <Logo
                style={{ width: 90, height: 'auto' }}
                forcedVariant={textColor === 'rgba(0, 0, 0, 0.87)' ? 'light' : 'dark'}
              />
            )}

            <Typography
              sx={{
                width: 'fit-content',
                textDecoration: 'none',
                color: 'inherit',
                fontWeight: isSmallScreen ? '500' : undefined
              }}
              variant={isSmallScreen ? 'body1' : 'h6'}
              noWrap
              color="inherit"
            >
              {viewModel.title}
            </Typography>
          </Stack>

          {/* Spacer */}
          <Box flex={1} />

          <Stack direction="row" spacing={2} justifyContent="center" alignItems="center">
            {viewModel.canCreateWork && (
              <Box>
                {isSmallScreen ? (
                  <Tooltip title={strings.appBarCreateWorkButtonTitle}>
                    <IconButton color="inherit" onClick={onCreateWorkButtonPressed}>
                      <AddRounded />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Button color="inherit" variant="outlined" size="small" onClick={onCreateWorkButtonPressed}>
                    {strings.appBarCreateWorkButtonTitle}
                  </Button>
                )}

                <CreateWorkOptionsMenu
                  open={createWorkMenuAnchor != null}
                  anchorEl={createWorkMenuAnchor}
                  onClose={() => setCreateWorkMenuAnchor(undefined)}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  canPublishWork={viewModel.canPublishWork}
                  onCreatePersonalWorkOptionSelect={showCreateWork}
                  onCreateNoteOptionSelect={showCreateNote}
                  onPublishWorkOptionSelect={showCreatePublishedWork}
                  onPaste={onPaste}
                />
              </Box>
            )}

            <RefreshButton isRefreshing={viewModel.isRefreshing} reloadData={() => void viewModel.reloadData()} />
          </Stack>
        </Toolbar>

        {renderTabs?.()}
      </AppBar>

      {/* Drawers */}

      <SwipeableDrawer
        open={showSideBar && isSmallScreen}
        onOpen={() => setShowSideBar(true)}
        onClose={() => setShowSideBar(false)}
        disableDiscovery
        // Swipe to open prevents scroll on the edge of the screen so disabling on larger screens. Also disabling on iOS
        // as it is the default value.
        disableSwipeToOpen={isIOS}
        PaperProps={{ sx: { width: 320 } }}
      >
        <UserDashboardSideBar id="main-app-bar" forceFullScreenWidth onClose={() => setShowSideBar(false)} />
      </SwipeableDrawer>

      <UserDashboardPicker
        id="main-app-bar-picker"
        isOpen={showUserDashboardPicker}
        anchorEl={activeDashboardButtonRef.current}
        onClose={() => setShowUserDashboardPicker(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      />
    </>
  );
});
