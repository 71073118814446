import { WorkStepEditViewModel } from '@/viewmodels';
import { EditCalendarRounded } from '@mui/icons-material';
import { Box, Chip, Divider, Popover, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { Observer, observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useServices } from '../../../hooks';
import { WorkStepDateSelectionControlPlannedWorkItem } from './WorkStepDateSelectionControlPlannedWorkItem';

export interface WorkStepDateSelectionControlProps {
  sx?: SxProps;
  className?: string;
  viewModel: WorkStepEditViewModel;
}

export const WorkStepDateSelectionControl = observer(
  ({ sx, className, viewModel }: WorkStepDateSelectionControlProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.work.details;
    const [pickerAnchor, setPickerAnchor] = useState<HTMLElement | undefined>(undefined);

    if (viewModel.availablePlannedWorks.upcoming.length === 0 && viewModel.availablePlannedWorks.past.length === 0) {
      // No date available, so not showing the control.
      return null;
    }

    const showDivider =
      viewModel.availablePlannedWorks.upcoming.length > 0 && viewModel.availablePlannedWorks.past.length > 0;

    return (
      <Box sx={sx} className={className}>
        <Chip
          size="small"
          icon={<EditCalendarRounded color={viewModel.selectedPlannedWorkIds.length > 0 ? 'primary' : undefined} />}
          label={
            viewModel.selectedPlannedWorkIds.length > 0
              ? strings.stepEditDatePickerValueLabel(viewModel.selectedPlannedWorkIds.length)
              : strings.stepEditDatePickerNoValueLabel
          }
          onClick={(e) => setPickerAnchor(e.currentTarget)}
        />

        <Popover
          open={pickerAnchor != null}
          anchorEl={pickerAnchor}
          onClose={() => setPickerAnchor(undefined)}
          elevation={1}
          slotProps={{ paper: { sx: { minWidth: 320 } } }}
        >
          <Stack py={1.5} spacing={1}>
            <Typography px={1.5} variant="subtitle2" fontWeight="600">
              {strings.stepEditDatePickerTitle}
            </Typography>

            {viewModel.availablePlannedWorks.upcoming.length > 0 && (
              <Stack>
                <SectionTitle>{strings.stepEditDatePickerUpcomingDatesSectionTitle}</SectionTitle>

                {viewModel.availablePlannedWorks.upcoming.map((pw) => (
                  <Observer key={pw.id}>
                    {() => (
                      <WorkStepDateSelectionControlPlannedWorkItem
                        plannedWork={pw}
                        onSelect={(pw) => viewModel.toggleSelectionOfPlannedWork(pw.id)}
                        isSelected={viewModel.selectedPlannedWorkIds.includes(pw.id)}
                        isCompleted={false}
                      />
                    )}
                  </Observer>
                ))}
              </Stack>
            )}

            {showDivider && <Divider />}

            {viewModel.availablePlannedWorks.past.length > 0 && (
              <Stack>
                <SectionTitle>{strings.stepEditDatePickerPastDatesSectionTitle}</SectionTitle>

                {viewModel.availablePlannedWorks.past.map((pw) => (
                  <Observer key={pw.id}>
                    {() => (
                      <WorkStepDateSelectionControlPlannedWorkItem
                        plannedWork={pw}
                        onSelect={(pw) => viewModel.toggleSelectionOfPlannedWork(pw.id)}
                        isSelected={viewModel.selectedPlannedWorkIds.includes(pw.id)}
                        isCompleted
                      />
                    )}
                  </Observer>
                ))}
              </Stack>
            )}
          </Stack>
        </Popover>
      </Box>
    );
  }
);

function SectionTitle({ children }: { children: string }) {
  return (
    <Typography key="upcoming-title" variant="caption" color="textSecondary" px={2} fontWeight="500">
      {children}
    </Typography>
  );
}
