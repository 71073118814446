import { useServices } from '@/hooks';
import { localizedExternalSourceName } from '@/resources/strings/utils/ExternalSourceStringsUtils';
import { StudentInsightsWorksViewModel, StudentInsightsWorkViewModel } from '@/viewmodels';
import { OpenInNewRounded } from '@mui/icons-material';
import EmptyListIcon from '@mui/icons-material/AssignmentRounded';
import { Theme, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { TableRowActions, TableTextCell, TableWithHeader } from '../../../lists';
import { StudentInsightsWorksTableWorkDetailsCell } from './StudentInsightsWorksTableWorkDetailsCell';

export interface StudentInsightsWorksTableProps {
  sx?: SxProps;
  className?: string;
  viewModel: StudentInsightsWorksViewModel;
}

type RenderCellData = GridRenderCellParams<StudentInsightsWorkViewModel, StudentInsightsWorkViewModel>;

export const StudentInsightsWorksTable = observer(({ sx, className, viewModel }: StudentInsightsWorksTableProps) => {
  const { localization, dateService } = useServices();
  const strings = localization.localizedStrings.workList;
  const theme = useTheme();

  const baseStatColumn: Partial<GridColDef> = {
    sortable: false,
    disableColumnMenu: true,
    headerAlign: 'center',
    align: 'center',
    minWidth: 120,
    flex: 1
  };

  const columns = useMemo(
    () =>
      [
        {
          headerName: '',
          field: 'workDetails',
          minWidth: 200,
          renderCell: (data: RenderCellData) => <StudentInsightsWorksTableWorkDetailsCell work={data.row} />,
          flex: 5,
          disableColumnMenu: true,
          sortable: false
        },
        {
          ...baseStatColumn,
          headerName: strings.studentWorksTableInboxDaysColumnHeader,
          field: 'inboxDays',
          renderCell: (data: RenderCellData) => <TableTextCell>{data.row.inboxDays}</TableTextCell>
        },
        {
          ...baseStatColumn,
          headerName: strings.studentWorksTablePlannedSessionsColumnHeader,
          field: 'numberOfPlannedSessions',
          renderCell: (data: RenderCellData) => <TableTextCell>{data.row.numberOfPlannedSessions}</TableTextCell>
        },
        {
          ...baseStatColumn,
          headerName: strings.studentWorksTablePlannedTimeColumnHeader,
          field: 'totalPlannedMinutes',
          renderCell: (data: RenderCellData) => (
            <TableTextCell>{strings.studentWorksTablePlannedTimeValue(data.row.totalPlannedMinutes)}</TableTextCell>
          )
        },
        {
          ...baseStatColumn,
          headerName: strings.studentWorksTableCompletionDateColumnHeader,
          field: 'completionDate',
          renderCell: (data: RenderCellData) => (
            <TableTextCell>{strings.completionDate(data.row.completionDate, dateService)}</TableTextCell>
          )
        },
        {
          ...baseStatColumn,
          headerName: strings.studentWorksTableDueDateColumnHeader,
          field: 'dueDate',
          renderCell: (data: RenderCellData) => (
            <TableTextCell color={dueDateTextColor(data.row, theme)}>
              {data.row.dueDate != null ? strings.dueDate(data.row.dueDate, dateService) : undefined}
            </TableTextCell>
          )
        },
        {
          field: 'actions',
          headerName: '',
          type: 'actions',
          renderCell: (data: RenderCellData) => <RowActions data={data} />
        }
      ] as GridColDef[],
    [theme]
  );

  const initialWorkIndex = useMemo(() => viewModel.initialWorkIndex ?? null, [viewModel.initialWorkIndex]);

  return (
    <TableWithHeader
      sx={sx}
      className={className}
      tableKey="student_insights_works"
      title={strings.studentWorksTableTitle}
      subtitle={strings.studentWorksTableSubtitle(viewModel.courseSectionTitle)}
      rows={viewModel.works}
      columns={columns}
      initialRowIndex={initialWorkIndex ?? undefined}
      showSearchBar
      searchBarPlaceholder={strings.studentWorksTableSearchBarPlaceholder}
      onSearchTextChange={(searchText) => (viewModel.searchText = searchText)}
      scrollToCurrentInitialRowActionTitle={strings.studentWorksTableScrollToInitialPageLabel}
      emptyOverlayParams={{
        title: strings.studentWorksTableEmptyTitle,
        subtitle: strings.studentWorksTableEmptySubtitle,
        renderIcon: (className) => <EmptyListIcon className={className} />
      }}
    />
  );
});

function dueDateTextColor(work: StudentInsightsWorkViewModel, theme: Theme): string {
  switch (work.state) {
    case 'late':
      return theme.palette.error.main;
    case 'today':
      return theme.palette.warning.main;
    default:
      return theme.palette.text.primary;
  }
}

function RowActions({ data }: { data: RenderCellData }) {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;
  const externalSource = data.row.externalSource;

  return (
    <TableRowActions
      actions={[
        { text: strings.studentWorksOpenInPlanner, selection: { case: 'link', to: data.row.urlInPlanner } },
        externalSource != null && externalSource.sourceName !== 'today-schools'
          ? {
              text: strings.studentWorksOpenInExternalSource(localizedExternalSourceName(externalSource.sourceName)),
              selection: { case: 'external-link', to: externalSource.url },
              icon: <OpenInNewRounded color="primary" fontSize="small" />
            }
          : undefined
      ]}
    />
  );
}
