import { dateToPBDate, dateToTimeOfDay } from '@/models';
import { PlannedWorkEditViewModel, PlannedWorkEditViewModelDayItem } from '@/viewmodels';
import { Box, Divider, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { isSameDay } from 'date-fns';
import { times } from 'lodash';
import { Observer, observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useServices } from '../../../hooks';
import { PlannedWorkEditSchedulePeriodView } from './PlannedWorkEditSchedulePeriodView';
import { PlannedWorkEditSchedulePlannedWorkView } from './PlannedWorkEditSchedulePlannedWorkView';

export interface PlannedWorkEditDayColumnProps {
  sx?: SxProps;
  className?: string;
  date: Date;
  viewModel: PlannedWorkEditViewModel;
}

export const PlannedWorkEditDayColumn = observer(
  ({ date, sx, className, viewModel }: PlannedWorkEditDayColumnProps) => {
    const { dateService } = useServices();
    const theme = useTheme();

    const dueDateStyle = useMemo<CSSProperties | undefined>(() => {
      if (viewModel.dueDate == null || !isSameDay(viewModel.dueDate, date)) {
        return undefined;
      }

      if (viewModel.isDueAllDay) {
        return { top: 0, bottom: 0, right: 0, width: 3 };
      } else {
        return {
          top: viewModel.verticalOffsetFromTimeOfDay(dateToTimeOfDay(viewModel.dueDate)),
          left: 0,
          right: 0,
          height: 3,
          transform: 'translate(0, -50%)'
        };
      }
    }, [viewModel.dueDate, viewModel.isDueAllDay, date]);

    const nowIndicatorStyle = useMemo<CSSProperties | undefined>(() => {
      if (!isSameDay(dateService.now, date)) {
        return undefined;
      }

      return {
        top: viewModel.verticalOffsetFromTimeOfDay(dateToTimeOfDay(dateService.now)),
        left: 0,
        right: 0,
        transform: 'translate(0, -50%)'
      };
    }, [dateService.now]);

    const dayViewModel = useMemo(() => {
      return viewModel.getViewModelForDay(dateToPBDate(date));
    }, [date, viewModel]);

    function getPositionStyleForPosition(item: PlannedWorkEditViewModelDayItem): SxProps {
      const itemOffset = item.rank / (item.maxRank + 1);
      const width = item.maxRank === 0 ? 'calc((100% - 0px) - 4px)' : `calc(${(1 / (item.maxRank + 1)) * 100}% - 2px)`;

      return {
        position: 'absolute',
        top: item.item.position.top,
        left: `calc((100% - 0px) * ${itemOffset})`,
        width,
        height: item.item.position.height,
        zIndex: item.rank
      };
    }

    return (
      <Box
        sx={{
          ...sx,
          position: 'relative',
          boxSizing: 'border-box'
        }}
        className={className}
      >
        <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
          {times(24).map((i) => (
            <Divider
              key={i}
              sx={{
                position: 'absolute',
                top: viewModel.pointsPerHour * i,
                left: 0,
                right: 0,
                opacity: i > 0 ? 1 : 0
              }}
            />
          ))}

          <Observer>
            {() => (
              <>
                {dayViewModel.items.map((item, i) => {
                  switch (item.item.kind) {
                    case 'course-occurrence':
                      return (
                        <PlannedWorkEditSchedulePeriodView
                          key={i}
                          sx={getPositionStyleForPosition(item)}
                          color={item.item.color}
                        />
                      );

                    case 'planned-work':
                      return (
                        <PlannedWorkEditSchedulePlannedWorkView
                          key={i}
                          sx={getPositionStyleForPosition(item)}
                          icon={item.item.icon}
                          color={item.item.color}
                        />
                      );
                  }
                })}
              </>
            )}
          </Observer>
        </Box>

        {dueDateStyle != null && (
          <Box
            sx={{
              position: 'absolute',
              ...dueDateStyle,
              backgroundColor: theme.palette.error.main
            }}
          />
        )}

        {nowIndicatorStyle != null && (
          <Stack
            sx={{
              position: 'absolute',
              ...nowIndicatorStyle
            }}
            direction="row"
            spacing={'-2px'}
            alignItems="center"
          >
            <Box sx={{ backgroundColor: theme.palette.primary.main, width: 8, height: 8, borderRadius: '50%' }} />

            <Box sx={{ backgroundColor: theme.palette.primary.main, flex: 1, height: 2 }} />
          </Stack>
        )}
      </Box>
    );
  }
);
