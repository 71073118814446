import {
  UserDashboardCalendarDayTimedCalendarEventInfo,
  UserDashboardCalendarEventInfo,
  UserDashboardCalendarItemState
} from '@/viewmodels';
import { CardActionArea, ListItem, ListItemText } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { CalendarEventIcon, ColorIndicator } from '../../../lists';

export interface UserDashboardCalendarDayCalendarEventViewProps {
  sx?: SxProps;
  className?: string;
  calendarEvent: UserDashboardCalendarEventInfo | UserDashboardCalendarDayTimedCalendarEventInfo;
}

export const UserDashboardCalendarDayCalendarEventView = observer(
  ({ sx, className, calendarEvent }: UserDashboardCalendarDayCalendarEventViewProps) => {
    return (
      <CardActionArea
        sx={{
          ...sx,
          flexDirection: 'row',
          display: 'flex',
          alignItems: 'stretch',
          p: 1,
          borderRadius: 1
        }}
        className={className}
        component="a"
        href={calendarEvent.externalUrl}
        target="_blank"
      >
        <ColorIndicator color={calendarEvent.color} isRounded sx={{ mr: 1 }} />

        <ListItem disablePadding disableGutters sx={{ flex: 1 }}>
          <CalendarEventIcon size={32} sx={{ mr: 1 }} />

          <ListItemText
            primary={calendarEvent.title}
            primaryTypographyProps={{
              noWrap: false,
              fontWeight: '500',
              color: getColorForTitle(calendarEvent.state)
            }}
          />
        </ListItem>
      </CardActionArea>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'active':
      return 'primary';
    default:
      return 'textPrimary';
  }
}
