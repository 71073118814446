import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { CopyText } from '../../utils';

export interface AdminSchoolParticipationCodeProps {
  sx?: SxProps;
  className?: string;
  code: string;
}

export function AdminSchoolParticipationCode({ sx, code, className }: AdminSchoolParticipationCodeProps) {
  return (
    <Stack sx={sx} className={className} spacing={1} direction="row" alignItems="center">
      <CopyText text={code}>
        <Typography variant="h6" fontWeight="400">
          {code}
        </Typography>
      </CopyText>
    </Stack>
  );
}
