import { useServices } from '@/hooks';
import { timeOfDayToDate } from '@/models';
import {
  UserDashboardCalendarDayTimedNoteInfo,
  UserDashboardCalendarItemState,
  UserDashboardCalendarNoteInfo
} from '@/viewmodels';
import { CardActionArea, ListItemText, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ColorIndicator, NoteIcon } from '../../../lists';
import { ContextMenuHandler } from '../../ContextMenuHandler';

function isTimedNote(
  note: UserDashboardCalendarNoteInfo | UserDashboardCalendarDayTimedNoteInfo
): note is UserDashboardCalendarDayTimedNoteInfo {
  return (note as UserDashboardCalendarDayTimedNoteInfo)?.startTime != null;
}

export interface UserDashboardCalendarDayNoteViewProps {
  sx?: SxProps;
  className?: string;
  note: UserDashboardCalendarNoteInfo | UserDashboardCalendarDayTimedNoteInfo;
  showTime: boolean;
}

export const UserDashboardCalendarDayNoteView = observer(
  ({ sx, className, note, showTime }: UserDashboardCalendarDayNoteViewProps) => {
    const { navigation, settings } = useServices();
    const location = useLocation();
    const navigate = useNavigate();

    function showNoteDetails() {
      navigation.navigateToNoteEdit(navigate, location, note.id);
    }

    return (
      <ContextMenuHandler actions={note.contextMenuActions()}>
        {(contextMenuHandler) => (
          <CardActionArea
            sx={{
              ...sx,
              flexDirection: 'row',
              display: 'flex',
              alignItems: 'stretch',
              p: 1,
              borderRadius: 1
            }}
            className={className}
            onClick={showNoteDetails}
            onContextMenu={contextMenuHandler}
          >
            <ColorIndicator color={note.color} isRounded sx={{ mr: 1 }} />

            <Stack spacing={1} direction="row" alignItems={'flex-start'} flex={1} overflow="hidden">
              <NoteIcon size={32} sx={{ mr: 1 }} />

              <ListItemText
                primary={note.text}
                primaryTypographyProps={{
                  noWrap: !settings.calendarDayOptions.expandWorkDetails,
                  variant: 'body2',
                  color: getColorForTitle(note.state),
                  sx: { whiteSpace: !settings.calendarDayOptions.expandWorkDetails ? 'pre-line' : undefined }
                }}
              />
            </Stack>

            {showTime && isTimedNote(note) && (
              <Typography variant="body2" color="textSecondary">
                {format(timeOfDayToDate(note.startTime), 'p')}
              </Typography>
            )}
          </CardActionArea>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'active':
      return 'primary';
    default:
      return 'textPrimary';
  }
}
