import { useServices } from '@/hooks';
import { Clipboard } from '@capacitor/clipboard';
import { CheckRounded } from '@mui/icons-material';
import CopyIcon from '@mui/icons-material/ContentCopyRounded';
import { Fade, IconButton, Stack, Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode, useCallback, useState } from 'react';
import { AutoHide } from './AutoHide';

export interface CopyTextProps {
  className?: string;
  sx?: SxProps;

  /**
   * The text to copy.
   */
  text: string;

  children: ReactNode;
}

export const CopyText = observer(({ className, children, text, sx }: CopyTextProps) => {
  const [showCopied, setShowCopied] = useState(false);
  const { localization } = useServices();
  const theme = useTheme();

  const strings = localization.localizedStrings.utils;

  const copy = useCallback(async () => {
    await Clipboard.write({ string: text });
    setShowCopied(true);
  }, [text]);

  return (
    <Stack sx={sx} className={className} direction="row" alignItems="center" spacing={0.5}>
      {children}

      {showCopied ? (
        // Copied icon
        <AutoHide durationMs={2000} onHide={() => setShowCopied(false)}>
          <Fade in={showCopied}>
            <IconButton disabled>
              <CheckRounded sx={{ color: theme.palette.success.main }} />
            </IconButton>
          </Fade>
        </AutoHide>
      ) : (
        // Copy button
        <Fade in={!showCopied}>
          <Tooltip title={strings.copyToClipboard}>
            <IconButton onClick={() => void copy()}>
              <CopyIcon />
            </IconButton>
          </Tooltip>
        </Fade>
      )}
    </Stack>
  );
});
