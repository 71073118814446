import { css } from '@emotion/css';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Welcome } from './Welcome';

export interface WelcomePageProps {
  sx?: SxProps;
  className?: string;
}

export const WelcomePage = observer(({ sx, className }: WelcomePageProps) => {
  const childrenClassName = css([{ width: '100%', height: '100%' }]);

  return (
    <Box sx={sx} className={className} width="100%" height="100%">
      <Welcome className={childrenClassName} />
    </Box>
  );
});
