import { useServices } from '@/hooks';
import { RenamePlannerViewModel } from '@/viewmodels';
import { Stack, StackProps, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';

export interface RenamePlannerProps extends StackProps {
  className?: string;
  viewModel: RenamePlannerViewModel;
  readonly isReadOnly?: boolean;
}

export const RenamePlanner = observer(({ className, viewModel, isReadOnly = false, ...props }: RenamePlannerProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.planners;

  return (
    <Stack {...props} className={className} spacing={3}>
      <TextField
        label={strings.editionPlannerTitleLabel}
        value={viewModel.title}
        placeholder={strings.defaultPlannerTitlePlaceholder(viewModel.relationshipKind)}
        fullWidth
        disabled={viewModel.isApplying || isReadOnly}
        onChange={(e) => (viewModel.title = e.target.value)}
      />

      {isReadOnly && (
        <Typography variant="subtitle2" color="textSecondary">
          {strings.editionPlannerReadOnlyLabel}
        </Typography>
      )}
    </Stack>
  );
});
