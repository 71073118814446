import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { CalendarDatePicker, Subheader } from '../../../../utils';

export interface ScheduleCycleDetailsEditDateSectionProps {
  sx?: SxProps;
  className?: string;
  startDate: Date;
  endDate: Date;
  onStartDateValueChanged: (value: Date) => void;
  onEndDateValueChanged: (value: Date) => void;
  isReadOnly?: boolean;
}

export const ScheduleCycleDetailsEditDateSection = observer(
  ({
    sx,
    className,
    startDate,
    endDate,
    onStartDateValueChanged,
    onEndDateValueChanged,
    isReadOnly
  }: ScheduleCycleDetailsEditDateSectionProps) => {
    const strings = LocalizedStrings.scheduleCycle.create;

    return (
      <Stack sx={sx} className={className} spacing={1}>
        <Subheader>{strings.datesSectionTitle()}</Subheader>

        <Stack direction="row" spacing={2}>
          <CalendarDatePicker
            autoFocus
            value={startDate}
            label={strings.startDatePickerLabel()}
            maxDate={endDate}
            format="PPP"
            onChange={(v) => onStartDateValueChanged(v!)}
            showDaysOutsideCurrentMonth
            disabled={isReadOnly}
          />

          <CalendarDatePicker
            value={endDate ?? null}
            label={strings.endDatePickerLabel()}
            minDate={startDate}
            format="PPP"
            onChange={(v) => onEndDateValueChanged(v!)}
            showDaysOutsideCurrentMonth
            disabled={isReadOnly}
          />
        </Stack>
      </Stack>
    );
  }
);
