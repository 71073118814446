import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { ServiceContainer } from '../../providers';
import { ConnectedAppService, ConnectedAppsService } from '../../services';
import { PlannerDataStore, UserDataStore } from '../../stores';
import {
  AppBaseStaticDialogViewModel,
  BaseDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  StaticDialogViewModel
} from '../utils';

export type PlannerNewSchoolsDialogTab = 'selection' | 'classroom-connection';

export interface PlannerNewSchoolsDialogViewModel extends StaticDialogViewModel {
  readonly isOpen: boolean;
  readonly schools: SchoolInformation[];
  readonly ignoredSchoolIds: string[];
  readonly hasSubmitError: boolean;
  readonly isTeacher: boolean;
  readonly tab: PlannerNewSchoolsDialogTab;
  readonly plannerConnectedAppService: ConnectedAppService<string>;
  toggleSelectionOfSchool(id: string): void;
  ignoreAll(): void;
  getConnectedAppService(schoolId: string): ConnectedAppService<string>;
}

export class AppPlannerNewSchoolsDialogViewModel
  extends AppBaseStaticDialogViewModel
  implements PlannerNewSchoolsDialogViewModel
{
  private _ignoredSchoolIds = observable.set<string>();

  @observable private _isConfirming = false;
  @observable private _submitError: Error | undefined;
  @observable private _tab: PlannerNewSchoolsDialogTab = 'selection';

  private readonly _ignoreAllButtonConfig: BaseDialogActionButtonConfiguration;
  private readonly _backButtonConfig: BaseDialogActionButtonConfiguration;
  protected readonly _confirmButtonConfig: SaveDialogActionButtonConfiguration;

  constructor(
    private readonly _plannerId: string,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    // Cannot be dismissed without passing by confirm button.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    super(async () => {});
    makeObservable(this);

    const strings = LocalizedStrings.planner.newSchools;
    this._ignoreAllButtonConfig = new BaseDialogActionButtonConfiguration(
      'secondary',
      'bottom-only',
      'hidden',
      undefined,
      undefined,
      () => strings.unSelectAllButtonLabel(),
      'contained-grey',
      async () => {
        this.ignoreAll();
        await Promise.resolve();
      }
    );

    this._backButtonConfig = new BaseDialogActionButtonConfiguration(
      'secondary',
      'bottom-only',
      'hidden',
      'back',
      'start',
      () => strings.backButtonLabel(),
      'contained-grey',
      async () => {
        runInAction(() => (this._tab = 'selection'));
        await Promise.resolve();
      }
    );

    this._confirmButtonConfig = new BaseDialogActionButtonConfiguration(
      'main',
      'both',
      'right',
      'close',
      undefined,
      () =>
        this._tab === 'selection' && this.shouldShowClassroomConnection
          ? strings.nextButtonLabel()
          : strings.submitButtonLabel(),
      'contained',
      async () => this.onConfirmButtonClick()
    );

    if (!this.isTeacher) {
      void this.plannerConnectedAppService.refreshSyncStatus();
    }
  }

  @computed
  private get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  private get hasAtLeastOneSchoolSelected() {
    return this.schools.length !== this._ignoredSchoolIds.size;
  }

  @computed
  private get shouldShowClassroomConnection() {
    // If user is not a teacher, we check if classroom is already connected to planner. If it is, no need to show
    // connection tab.
    return this.hasAtLeastOneSchoolSelected && (this.isTeacher || !this.plannerConnectedAppService.isConnected);
  }

  @computed
  get plannerConnectedAppService() {
    return this._connectedApps.getConnectedAppService('classroom', this._plannerId, undefined)!;
  }

  @computed
  get isOpen(): boolean {
    const isOwnedPlanner = this._userStore.ownedPlanners.includes(this.planner);
    return isOwnedPlanner ? this.schools.length > 0 : false;
  }

  @computed
  get hasSubmitError(): boolean {
    return this._submitError != null;
  }

  @computed
  get schools(): SchoolInformation[] {
    return this._userStore.schools.filter((s) => !this.planner.schoolIds.includes(s.school!.id));
  }

  @computed
  get ignoredSchoolIds(): string[] {
    return Array.from(this._ignoredSchoolIds.values());
  }

  @computed
  get isTeacher(): boolean {
    return this._userStore.user.persona === UserPersona.TEACHER;
  }

  @computed
  get tab(): PlannerNewSchoolsDialogTab {
    return this._tab;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._confirmButtonConfig.showLoading = this._isConfirming;
    this._confirmButtonConfig.isEnabled = !this._isConfirming;
    const showForwardIcon = this._tab === 'selection' && this.shouldShowClassroomConnection ? 'forward' : undefined;
    this._confirmButtonConfig.icon = showForwardIcon ? 'forward' : 'close';
    this._confirmButtonConfig.desktopIconPlacement = showForwardIcon ? 'end' : undefined;

    return [this._confirmButtonConfig];
  }

  @override
  get supplementaryActions(): DialogActionButtonConfiguration[] {
    return this._tab === 'selection' ? [this._ignoreAllButtonConfig] : [this._backButtonConfig];
  }

  @action
  toggleSelectionOfSchool(id: string) {
    if (this._ignoredSchoolIds.has(id)) {
      this._ignoredSchoolIds.delete(id);
    } else {
      this._ignoredSchoolIds.add(id);
    }
  }

  @action
  ignoreAll() {
    this.schools.forEach((s) => this._ignoredSchoolIds.add(s.school!.id));
  }

  getConnectedAppService(schoolId: string): ConnectedAppService<string> {
    return this._connectedApps.getConnectedAppService('classroom', this._plannerId, schoolId)!;
  }

  private async onConfirmButtonClick() {
    if (this._tab === 'selection' && this.shouldShowClassroomConnection) {
      runInAction(() => (this._tab = 'classroom-connection'));
    } else {
      await this.onConfirm();
    }
  }

  private async onConfirm() {
    runInAction(() => (this._isConfirming = true));

    try {
      await Promise.all(
        this.schools.map((s) =>
          this._ignoredSchoolIds.has(s.school!.id)
            ? this._userStore.ignoreSchoolInPlanner(this._plannerId, s.school!.id, false)
            : this._userStore.attachSchoolToPlanner(s.school!.id, this._plannerId, false)
        )
      );

      await Promise.all([
        this._userStore.plannersLoadable.fetch(true),
        this._userStore.participatingSchools.fetch(true)
      ]);

      // Updating course sections to reflect selection.
      await this._plannerStore.getCourseSectionsInPlanner(this._plannerId).fetch(true);
    } catch (e) {
      runInAction(() => (this._submitError = e as Error));
    } finally {
      runInAction(() => (this._isConfirming = false));
    }
  }
}
