import { TimeOfDay, WorkIconInfo } from '@/models';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { WorkIcon } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_icon_pb';
import { ImportanceLevel } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/importance_level_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { PublishedWorkStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_status_pb';
import { RichText } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/rich_text_pb';
import { TextFormat } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/text_format_pb';
import { differenceInCalendarDays, isBefore, startOfDay } from 'date-fns';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import {
  AttachmentInfo,
  EditableAttachmentInfo,
  EditablePublishedWorkEditInfo,
  PublishedWorkEditInfo,
  urlForExternalSourceBadge,
  urlForPublishedWorkWorkIcon
} from '../../models';
import { ServiceContainer } from '../../providers';
import { DateService, LocalizationService } from '../../services';
import {
  LoadableState,
  PlannerCalendarStore,
  PlannerDataStore,
  PlannerDetailedCourseSectionsLoadable,
  WorkDataStore,
  WorkIconsLoadable,
  mergeLoadableStates
} from '../../stores';
import { isError } from '../../utils';
import {
  AppBaseUpdatableDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';

export interface PublishedWorkEditViewModel extends UpdatableDialogViewModel {
  readonly isApplying: boolean;
  readonly hasChanges: boolean;
  readonly allCourseSections: CourseSectionDetails[];
  readonly workIcons: WorkIconInfo[];
  title: string;
  readonly icon: WorkIcon;
  description: string;
  readonly courseSection: CourseSectionDetails;
  importanceLevel: ImportanceLevel;
  dueDate: Date;
  isAllDay: boolean;
  alsoPublishInExternalSource: boolean;
  maxGrade: number | undefined;
  scheduledPublishTime: Date | undefined;
  readonly isNewWork: boolean;
  readonly canSchedulePublishTime: boolean;
  readonly canPublishInExternalSource: boolean;
  readonly isDateValid: boolean;
  readonly attachments: EditableAttachmentInfo[];

  setCourseSection(courseSectionId: string | undefined): void;
  addAttachments(attachments: AttachmentInfo[]): void;
  setWorkIcon(iconId: string): void;
  getDatesWithOccurrenceForCourseSection(fromDate: Date, toDate: Date): Date[];
  getCourseSectionOccurrencesStartTimeForDate(date: Date): TimeOfDay[];
  save(): Promise<void>;
}

export class AppPublishedWorkEditViewModel
  extends AppBaseUpdatableDialogViewModel
  implements PublishedWorkEditViewModel
{
  private _existingWork: PublishedWork | undefined;
  @observable private _editablePublishedWork: EditablePublishedWorkEditInfo | undefined;
  @observable private _state: LoadableState = 'pending';
  @observable private _isApplying = false;
  private readonly _now: Date;

  private readonly _calendarStore: PlannerCalendarStore;
  private _saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.save());
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.dismiss()
  );

  constructor(
    private readonly _ids: { publishedWorkId: string; schoolId: string } | undefined,
    private readonly _newWorkDefaultValues: Partial<PublishedWorkEditInfo> | undefined,
    private readonly _plannerId: string,
    private readonly _onSuccess: (work: PublishedWork) => Promise<void>,
    onCancel: () => Promise<void>,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    dateService: DateService = ServiceContainer.services.dateService
  ) {
    super(localization, onCancel, true);
    this._calendarStore = _plannerStore.getCalendarStore(_plannerId);
    makeObservable(this);
    this._now = dateService.now;
  }

  @computed
  private get courseSections(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get taughtSections(): CourseSectionDetails[] {
    return this.courseSections.values.filter(
      (c) => c.schoolsCourseSection != null && c.role === CourseSectionRole.TEACHER
    );
  }

  @computed
  private get courseSectionsById(): Map<string, CourseSectionDetails> {
    return new Map(this.taughtSections.map((c) => [c.courseSection!.id, c]));
  }

  @computed
  private get workIconsLoadable(): WorkIconsLoadable {
    return this._workStore.workIcons;
  }

  @computed
  private get editableWork(): EditablePublishedWorkEditInfo {
    return this._editablePublishedWork!;
  }

  @computed
  get isNewWork() {
    return this._ids == null;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this.isApplying;
    // We always want the save button enabled for a new task (except while saving obviously).
    this._saveButtonConfig.isEnabled =
      !this.isApplying && (this._ids == null || this.hasChanges) && this.title.length > 0 && this.isDateValid;
    this._saveButtonConfig.showLoading = this.isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get state(): LoadableState {
    return mergeLoadableStates([this._state, this.courseSections.state, this.workIconsLoadable.state]);
  }

  @computed
  get hasData(): boolean {
    return (
      this._state === 'fulfilled' &&
      this._editablePublishedWork != null &&
      this.courseSections.hasData &&
      this.workIconsLoadable.hasData
    );
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get isSubmitting(): boolean {
    return this.isApplying;
  }

  @computed
  get hasChanges(): boolean {
    return this.editableWork.hasChanges;
  }

  @computed
  get title(): string {
    return this.editableWork.title;
  }

  set title(value: string) {
    this.editableWork.title = value;
  }

  @computed
  get icon(): WorkIcon {
    const workIcons = this.workIconsLoadable.data;
    const defaultIcon = workIcons.iconsById.get(workIcons.defaultIconId)!;
    return workIcons.iconsById.get(this.editableWork.iconId) ?? defaultIcon;
  }

  @computed
  get description(): string {
    return this.editableWork.description;
  }

  set description(value: string) {
    this.editableWork.description = value;
  }

  @computed
  get allCourseSections(): CourseSectionDetails[] {
    return this.taughtSections.slice().sort(
      (cs1, cs2) =>
        cs1.courseSection!.title.localeCompare(cs2.courseSection!.title, this._localization.currentLocale, {
          sensitivity: 'base'
        }) ||
        cs1.courseSection!.section.localeCompare(cs2.courseSection!.section, this._localization.currentLocale, {
          sensitivity: 'base'
        })
    );
  }

  @computed
  get workIcons(): WorkIconInfo[] {
    return Array.from(this.workIconsLoadable.data.iconsById.values() ?? [])
      .filter((icon) => !icon.isHidden)
      .map((icon) => ({
        id: icon.iconId,
        title: icon.iconName,
        lightUrl: urlForPublishedWorkWorkIcon(icon, this.editableWork.importance, 'light'),
        darkUrl: urlForPublishedWorkWorkIcon(icon, this.editableWork.importance, 'dark'),
        externalBadgeUrl: urlForExternalSourceBadge(
          this._existingWork?.externalSource?.sourceName,
          this.workIconsLoadable.data
        )
      }));
  }

  @computed
  get courseSection(): CourseSectionDetails {
    return this.courseSectionsById.get(this.editableWork.courseSectionId)!;
  }

  @computed
  get importanceLevel(): ImportanceLevel {
    return this.editableWork.importance;
  }

  set importanceLevel(value: ImportanceLevel) {
    this.editableWork.importance = value;
  }

  @computed
  get dueDate(): Date {
    return this.editableWork.dueDate;
  }

  set dueDate(value: Date) {
    this.editableWork.dueDate = value;
  }

  @computed
  get isAllDay(): boolean {
    return this.editableWork.isDueAllDay;
  }

  set isAllDay(value: boolean) {
    this.editableWork.isDueAllDay = value;
  }

  setWorkIcon(iconId: string) {
    this.editableWork.iconId = iconId;
  }

  setCourseSection(courseSectionId: string | undefined) {
    this.editableWork.courseSectionId = courseSectionId ?? '';
  }

  @computed
  get alsoPublishInExternalSource(): boolean {
    return this.editableWork.alsoPublishInExternalSource;
  }

  set alsoPublishInExternalSource(value: boolean) {
    this.editableWork.alsoPublishInExternalSource = value;
  }

  @computed
  get maxGrade(): number | undefined {
    return this.editableWork.maxGrade;
  }

  set maxGrade(value: number | undefined) {
    this.editableWork.maxGrade = value;
  }

  @computed
  get scheduledPublishTime(): Date | undefined {
    return this.editableWork.scheduledPublishTime;
  }

  set scheduledPublishTime(value: Date | undefined) {
    this.editableWork.scheduledPublishTime = value;
  }

  @computed
  get canSchedulePublishTime(): boolean {
    return this._existingWork == null || this._existingWork.status !== PublishedWorkStatus.PUBLISHED;
  }

  @computed
  get canPublishInExternalSource(): boolean {
    return (
      this._existingWork == null &&
      this.courseSection.schoolsCourseSection?.externalSource != null &&
      this.courseSection.schoolsCourseSection.externalSource.sourceName !== 'today-schools'
    );
  }

  @computed
  get isDateValid(): boolean {
    if (!this.alsoPublishInExternalSource) {
      return true;
    }

    if (this.isAllDay) {
      return differenceInCalendarDays(this.dueDate, this._now) >= 0;
    }

    return !isBefore(this.dueDate, this._now);
  }

  @computed
  get attachments(): EditableAttachmentInfo[] {
    return this.editableWork.attachmentsInfos.filter((a) => !a.markedAsDeleted);
  }

  addAttachments(attachments: AttachmentInfo[]) {
    attachments.forEach((a) => this.editableWork.addAttachment(a));
  }

  getDatesWithOccurrenceForCourseSection(fromDate: Date, toDate: Date): Date[] {
    return this._calendarStore
      .getOccurrencesForCourseSection(this.editableWork.courseSectionId ?? '', fromDate, toDate)
      .map((o) => o.date);
  }

  getCourseSectionOccurrencesStartTimeForDate(date: Date): TimeOfDay[] {
    return this._calendarStore.getCourseSectionOccurrencesStartTimeForDate(
      this.editableWork.courseSectionId ?? '',
      date
    );
  }

  @action
  async save() {
    if (this.isApplying || (!this.hasChanges && this._ids != null)) {
      return;
    }

    try {
      this._error = undefined;
      this._isApplying = true;

      const result = await (this._ids == null
        ? this.createPublishedWork()
        : this.updatePublishWork(this._ids.publishedWorkId, this._ids.schoolId));

      runInAction(() => (this._isApplying = false));
      await this._onSuccess(result);
    } catch (e) {
      runInAction(() => {
        this._error = (e as Error).message;
        this._isApplying = false;
      });
    }
  }

  async reloadData() {
    await this.loadData();
  }

  private async loadData() {
    runInAction(() => (this._state = 'pending'));

    try {
      await Promise.all([this.courseSections.fetch(false), this.workIconsLoadable.fetch(false)]);

      if (isError(this.workIconsLoadable.state)) {
        throw new Error("Couldn't load work icons");
      }

      let work: PublishedWork | undefined;
      const defaultIconId = this.workIconsLoadable.data.defaultIconId;

      if (this._ids != null) {
        const publishedWorkLoadable = this._workStore.getPublishedWorkLoadable(
          this._ids.publishedWorkId,
          this._ids.schoolId
        );
        await publishedWorkLoadable.fetch(true);

        if (publishedWorkLoadable.hasData) {
          work = publishedWorkLoadable.data;
        }
      }

      const info = this.makeInfos(work, this._newWorkDefaultValues, defaultIconId);
      const editableWork = new EditablePublishedWorkEditInfo(defaultIconId, this._newWorkDefaultValues, info);

      runInAction(() => {
        this._editablePublishedWork = editableWork;
        this._existingWork = work;
        this._state = 'fulfilled';
      });
    } catch (e) {
      console.error(e);
      runInAction(() => (this._state = e as Error));
    }
  }

  private makeInfos(
    work: PublishedWork | undefined,
    newWorkInfo: Partial<PublishedWorkEditInfo> | undefined,
    defaultIconId: string
  ): PublishedWorkEditInfo {
    if (work != null) {
      return {
        title: work.title,
        iconId: work.iconId,
        description: work.description?.text ?? '',
        importance: work.importance,
        dueDate: work.dueTime!.toDate(),
        isDueAllDay: work.isDueAllDay,
        courseSectionId:
          this.courseSections.values.find((cs) => cs.schoolsCourseSection?.id === work?.courseSectionId)?.courseSection
            ?.id ?? '',
        maxGrade: work.maxGrade || undefined,
        schedulePublishTime: work.scheduledPublishTime?.toDate(),
        attachments: work.attachments,
        alsoPublishInExternalSource: work.canExternalSourceBeUpdated
      };
    } else {
      let courseSection = this.taughtSections[0].courseSection!;

      if (newWorkInfo?.courseSectionId != null) {
        const match = this.courseSections.values.find((cs) => cs.courseSection?.id === newWorkInfo?.courseSectionId);

        if (match?.schoolsCourseSection != null) {
          courseSection = match.courseSection!;
        }
      }

      return {
        title: newWorkInfo?.title ?? '',
        iconId: newWorkInfo?.iconId ?? defaultIconId,
        description: newWorkInfo?.description ?? '',
        importance: newWorkInfo?.importance ?? ImportanceLevel.REGULAR,
        dueDate: newWorkInfo?.dueDate ?? startOfDay(new Date()),
        isDueAllDay: newWorkInfo?.isDueAllDay ?? true,
        courseSectionId: courseSection.id,
        maxGrade: newWorkInfo?.maxGrade,
        schedulePublishTime: newWorkInfo?.schedulePublishTime,
        attachments: newWorkInfo?.attachments,
        alsoPublishInExternalSource: newWorkInfo?.alsoPublishInExternalSource ?? false
      };
    }
  }

  private async createPublishedWork(): Promise<PublishedWork> {
    const schoolCourse = this.courseSection.schoolsCourseSection!;
    const externalSourceName = schoolCourse.externalSource?.sourceName;

    return await this._workStore.createPublishedWork(schoolCourse.id, schoolCourse.schoolId, this._plannerId, {
      title: this.editableWork.title,
      iconId: this.editableWork.iconId,
      description:
        this.editableWork.description.length > 0
          ? new RichText({ text: this.editableWork.description, format: TextFormat.PLAIN_TEXT })
          : undefined,
      importanceLevel: this.editableWork.importance,
      dueTime: this.editableWork.dueDate,
      isDueAllDay: this.editableWork.isDueAllDay,
      maxGrade: this.editableWork.maxGrade,
      recipientIds: [],
      status:
        this.editableWork.scheduledPublishTime != null ? PublishedWorkStatus.SCHEDULED : PublishedWorkStatus.PUBLISHED,
      scheduledPublishedTime: this.editableWork.scheduledPublishTime,
      attachments: this.editableWork.attachments,
      alsoPublishToExternalSourceName:
        this.editableWork.alsoPublishInExternalSource &&
        externalSourceName != null &&
        externalSourceName !== 'today-schools'
          ? externalSourceName
          : undefined
    });
  }

  private async updatePublishWork(id: string, schoolId: string): Promise<PublishedWork> {
    return await this._workStore.updatePublishedWork(
      id,
      schoolId,
      {
        title: this.editableWork.title,
        iconId: this.editableWork.iconId,
        description:
          this.editableWork.description.length > 0
            ? new RichText({ text: this.editableWork.description, format: TextFormat.PLAIN_TEXT })
            : undefined,
        importanceLevel: this.editableWork.importance,
        dueTime: this.editableWork.dueDate,
        isDueAllDay: this.editableWork.isDueAllDay,
        maxGrade: this.editableWork.maxGrade,
        recipientIds: [],
        attachments: this.editableWork.attachments,
        status:
          this.editableWork.scheduledPublishTime != null
            ? PublishedWorkStatus.SCHEDULED
            : PublishedWorkStatus.PUBLISHED,
        scheduledPublishedTime: this.editableWork.scheduledPublishTime
      },
      this._existingWork!.syncToken
    );
  }
}
