import { useServices } from '@/hooks';
import { handleItemAction, PlannerListItemInfo } from '@/viewmodels';
import { Box, ListItemText, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { ContextMenuHandler } from '../../../shared';
import { PlannerListItemAvatar } from './PlannerListItemAvatar';
import { PlannerListItemInlineAction } from './PlannerListItemInlineAction';
import { PlannerListItemRoot } from './PlannerListItemRoot';
import { PlannerListItemStepCount } from './PlannerListItemStepCount';

export interface PlannerInboxListItemProps {
  sx?: SxProps;
  className?: string;
  item: PlannerListItemInfo;
}

export const PlannerInboxListItem = observer(({ sx, className, item }: PlannerInboxListItemProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const dateColor = useMemo(() => {
    switch (item.primaryDetailKind) {
      case 'warning':
        return theme.palette.warning.main;
      case 'late-icon':
      case 'error':
        return theme.palette.error.main;
      case 'default':
        return theme.palette.text.secondary;
    }
  }, [item.primaryDetailKind]);

  return (
    <ContextMenuHandler actions={item.contextMenuActions}>
      {(contextMenuHandler) => (
        <PlannerListItemRoot
          className={className}
          sx={{ ...sx, padding: '0' }}
          isHighlighted={false}
          disableGutters
          url={item.url}
          onContextMenu={contextMenuHandler}
        >
          <Stack direction="row" spacing={1} m={1} flex={1} overflow="hidden" px={1}>
            <PlannerListItemAvatar color={item.color} icon={item.icon} />

            <Stack flex={1} overflow="hidden" spacing={0.5}>
              <Stack direction="row" alignItems="baseline">
                <ListItemText
                  sx={{ mr: 1 }}
                  primaryTypographyProps={{ noWrap: true }}
                  primary={item.title}
                  secondaryTypographyProps={{ noWrap: true, component: 'span' }}
                  secondary={item.description ?? strings.noDescription}
                />

                {item.primaryDetail != null && (
                  <Typography color={dateColor} fontWeight="500" variant="body2" noWrap>
                    {item.primaryDetail}
                  </Typography>
                )}
              </Stack>

              <Stack direction="row" columnGap={1} alignItems="center" justifyContent="flex-end">
                {item.stepsInfo != null && item.stepsInfo.totalStepsCount > 0 && (
                  <Box display="flex" flex={1}>
                    <PlannerListItemStepCount stepsInfo={item.stepsInfo} />
                  </Box>
                )}

                {item.inlineActions.map((action) => (
                  <PlannerListItemInlineAction
                    key={action.kind}
                    action={action}
                    onClick={(e) => handleItemAction(e, action.onClick, navigate, location)}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </PlannerListItemRoot>
      )}
    </ContextMenuHandler>
  );
});
