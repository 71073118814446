import { ScheduleCycleKind, ScheduleCycleSpecialDayInfo } from '@/viewmodels';
import { Box, List, ListItem, ListItemText, ListSubheader, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Draggable } from '../../../../utils';
import { ScheduleCycleSpecialDaysListItem } from './ScheduleCycleSpecialDaysListItem';

export interface ScheduleCycleSpecialDaysListProps {
  sx?: SxProps;
  className?: string;
  mainSpecialDays: ScheduleCycleSpecialDayInfo[];
  otherSpecialDays: ScheduleCycleSpecialDayInfo[];
  scheduleCycleKind: ScheduleCycleKind;
  cycleDayNames: string[];
  isEditing: boolean;
  onEditAction: (specialDayId: string) => void;
  onDelete: (specialDayId: string) => void;
  isReadOnly: boolean;
}

export const ScheduleCycleSpecialDaysList = observer(
  ({
    sx,
    className,
    mainSpecialDays,
    otherSpecialDays,
    isEditing,
    onEditAction,
    scheduleCycleKind,
    cycleDayNames,
    onDelete,
    isReadOnly
  }: ScheduleCycleSpecialDaysListProps) => {
    const theme = useTheme();
    const isDenseList = useMediaQuery(() => theme.breakpoints.down('lg'));
    const strings = LocalizedStrings.scheduleCycle.edit.specialDays.list;

    function renderItem(info: ScheduleCycleSpecialDayInfo, actionKind: 'add' | 'remove', forceReadOnly: boolean) {
      return (
        <ScheduleCycleSpecialDaysListItem
          specialDayInfo={info}
          action={isEditing ? { role: actionKind, onClick: () => onEditAction(info.specialDay.id) } : undefined}
          scheduleCycleKind={scheduleCycleKind}
          isReadOnly={forceReadOnly || isReadOnly}
          onDelete={onDelete}
          canDisplayTooltip={!forceReadOnly}
          cycleDayNames={cycleDayNames}
        />
      );
    }

    return (
      <List sx={{ ...sx, overflow: 'auto' }} className={className} dense={isDenseList}>
        {mainSpecialDays.map((info) => (
          <Draggable
            key={`special-day-${info.specialDay.id}`}
            type="special-day"
            id={info.specialDay.id}
            renderPreview={() => renderItem(info, 'remove', true)}
            disabled={isReadOnly}
          >
            {renderItem(info, 'remove', false)}
          </Draggable>
        ))}

        {mainSpecialDays.length === 0 && (
          <ListItem>
            <ListItemText
              primary={isEditing ? strings.noAssignedSpecialDays() : strings.noSpecialDays()}
              primaryTypographyProps={{ variant: 'body1', color: 'textSecondary', fontStyle: 'italic' }}
            />
          </ListItem>
        )}

        {otherSpecialDays.length > 0 && (
          <Stack mt={0}>
            <Box sx={{ height: 40, overflow: 'hidden' }}>
              <ListSubheader>{strings.otherSpecialDaysSectionTitle()}</ListSubheader>
            </Box>

            {otherSpecialDays.map((info) => (
              <Draggable
                key={`special-day-${info.specialDay.id}`}
                type="special-day"
                id={info.specialDay.id}
                disabled={isReadOnly}
                renderPreview={() => renderItem(info, 'add', true)}
              >
                {renderItem(info, 'add', false)}
              </Draggable>
            ))}
          </Stack>
        )}
      </List>
    );
  }
);
