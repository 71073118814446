import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CheckCircleRounded } from '@mui/icons-material';
import { Box, Card, CardActionArea, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useServices } from '../../../../hooks';
import { CourseSectionOccurrenceInfo } from '../../../../models';
import { formatCalendarItemTimesLong } from '../../../shared';

export interface PlannerPastePlannerItemPeriodViewProps {
  sx?: SxProps;
  className?: string;
  isSelected: boolean;
  period: CourseSectionOccurrenceInfo;
  courseSection: CourseSectionDetails | undefined;
  onSelect: () => void;
}

export const PlannerPastePlannerItemPeriodView = observer(
  ({ sx, className, period, courseSection, onSelect, isSelected }: PlannerPastePlannerItemPeriodViewProps) => {
    const { settings } = useServices();
    const theme = useTheme();
    const strings = LocalizedStrings.planner.pastePlannerItem;

    const color = courseSection?.courseSection?.color ?? theme.palette.action.disabled;
    const courseTitle = courseSection?.courseSection?.title ?? strings.noClassLabel();
    const title = settings.calendarShowPeriodLabels ? `${period.periodLabel} - ${courseTitle}` : courseTitle;

    return (
      <Card
        sx={{
          ...sx,
          border: `1px solid ${isSelected ? 'transparent' : color}`,
          backgroundColor: isSelected ? color : undefined,
          backgroundImage: isSelected ? 'none' : undefined,
          color: isSelected ? theme.palette.getContrastText(color) : undefined
        }}
        className={className}
        elevation={0}
      >
        <CardActionArea onClick={onSelect} sx={{ borderRadius: 1 }}>
          <Stack direction="row" spacing={1}>
            <Box sx={{ backgroundColor: isSelected ? 'transparent' : color, width: 6 }} />

            <Stack direction="row" alignItems="center" spacing={1} flex={1} py={0.5} pr={2}>
              <Stack flex={1}>
                <Typography fontWeight="500">{title}</Typography>
                <Typography variant="body2" color={isSelected ? 'inherit' : 'textSecondary'}>
                  {formatCalendarItemTimesLong(period.startTime, period.endTime)}
                </Typography>
              </Stack>

              {isSelected && <CheckCircleRounded color="inherit" />}
            </Stack>
          </Stack>
        </CardActionArea>
      </Card>
    );
  }
);
