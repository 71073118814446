import { WorkIconInfo } from '@/models';
import { alpha, Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { PlannedWorkIcon } from '../../shared';

export interface PlannedWorkEditSchedulePlannedWorkViewProps {
  sx?: SxProps;
  className?: string;
  color: string;
  icon: WorkIconInfo;
}

export function PlannedWorkEditSchedulePlannedWorkView({
  sx,
  className,
  color,
  icon
}: PlannedWorkEditSchedulePlannedWorkViewProps) {
  const theme = useTheme();
  const hasColor = color.length > 0;
  const resolvedColor = hasColor ? color : theme.palette.action.disabled;

  return (
    <Box
      sx={{
        ...sx,
        borderRadius: 0.5,
        overflow: 'hidden',
        backgroundColor: alpha(theme.palette.action.disabled, 0.05),
        display: 'flex',
        alignItems: 'stretch'
      }}
      className={className}
    >
      <PlannedWorkIcon color={resolvedColor} icon={icon} sx={{ my: '4px', ml: 0.5, opacity: 0.4 }} />
    </Box>
  );
}
