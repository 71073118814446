import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';

export type LoadableState = 'fulfilled' | 'pending' | Error | undefined;

export interface Loadable<T> {
  readonly data: T;
  readonly hasData: boolean;
  readonly state: LoadableState;

  /**
   * Fetches data.
   * @param force If false, method will return the value stored in `data` if there is some. Will otherwise fetch
   * data from the API.
   */
  fetch(force: boolean): Promise<void>;
  invalidate(fetchNewData: boolean): Promise<void>;
}

export interface LoadableValue<T> extends Loadable<T> {
  setValue(newValue: T): void;
}

export interface LoadableMap<T> extends Loadable<Map<string, T>> {
  readonly values: T[];
  addOrReplace(key: string, value: T): void;
  addMultiple(values: { key: string; value: T }[]): void;
  remove(key: string): void;
}

abstract class BaseLoadable<T> implements LoadableValue<T> {
  @observable protected _hasData: boolean;
  @observable protected _state: LoadableState;

  private _fetch: Promise<void> | undefined;

  protected constructor(initialValue?: T) {
    if (initialValue != null) {
      this._state = 'fulfilled';
      this._hasData = true;
    } else {
      this._state = undefined;
      this._hasData = false;
    }

    makeObservable(this);
  }

  abstract get data(): T;

  @computed
  get hasData(): boolean {
    return this._hasData;
  }

  @computed
  get state(): LoadableState {
    return this._state;
  }

  async fetch(ignoreExistingData: boolean) {
    const forceFetch = this._state !== 'pending' && ignoreExistingData;

    if (this._fetch == null || forceFetch) {
      this._fetch = this.innerFetch();
    }

    await this._fetch;
  }

  async invalidate(fetchNewData: boolean) {
    this.clearData();
    runInAction(() => (this._state = undefined));
    this._fetch = undefined;

    if (fetchNewData) {
      await this.fetch(true);
    }
  }

  setValue(newValue: T) {
    this.setNewData(newValue);
  }

  private async innerFetch() {
    runInAction(() => (this._state = 'pending'));

    try {
      const newData = await this.loadData();
      runInAction(() => {
        this.setNewData(newData);
        this._state = 'fulfilled';
        this._hasData = true;
      });
    } catch (e) {
      const error = e as Error;
      runInAction(() => (this._state = error));
    }
  }

  protected abstract loadData(): Promise<T>;
  protected abstract setNewData(data: T): void;
  protected abstract clearData(): void;
}

export abstract class BaseLoadableValue<T> extends BaseLoadable<T> {
  @observable protected _data: T | undefined;

  protected constructor(initialValue?: T) {
    super(initialValue);
    this._data = initialValue;
    makeObservable(this);
  }

  @override
  get hasData(): boolean {
    return this._data != null;
  }

  @computed
  get data(): T {
    if (this._data == null) {
      throw new Error('No data available.');
    }

    return this._data;
  }

  @action
  protected setNewData(data: T) {
    this._data = data;
  }

  @action
  protected clearData() {
    this._data = undefined;
    this._hasData = false;
  }
}

export abstract class BaseOptionalLoadableValue<T> extends BaseLoadable<T | undefined> {
  @observable protected _data: T | undefined;

  protected constructor(initialValue?: T) {
    super(initialValue);
    this._data = initialValue;
    makeObservable(this);
  }

  @computed
  get data(): T | undefined {
    if (!this.hasData) {
      throw new Error('No data available.');
    }

    return this._data;
  }

  @action
  protected setNewData(data: T) {
    this._data = data;
  }

  @action
  protected clearData() {
    this._data = undefined;
    this._hasData = false;
  }
}

export abstract class BaseLoadableMap<T> extends BaseLoadable<Map<string, T>> {
  protected _data = observable.map<string, T>();

  protected constructor(initialValue?: Map<string, T>) {
    super(initialValue);
    if (initialValue != null) {
      this._data.replace(initialValue);
    }
    makeObservable(this);
  }

  @computed
  get data(): Map<string, T> {
    if (!this.hasData) {
      throw new Error('No data available.');
    }

    return this._data;
  }

  @computed
  get values(): T[] {
    return Array.from(this.data.values());
  }

  @action
  addOrReplace(key: string, value: T) {
    this._data.set(key, value);
  }

  @action
  addMultiple(values: { key: string; value: T }[]) {
    for (const value of values) {
      this._data.set(value.key, value.value);
    }
  }

  @action
  remove(key: string) {
    this._data.delete(key);
  }

  @action
  protected setNewData(data: Map<string, T>) {
    this._data.replace(data);
  }

  @action
  protected clearData() {
    this._data.clear();
    this._hasData = false;
  }
}
