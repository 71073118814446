import { plannerHasAccessKindsForUser, plannerHasRelationshipKindsForUser } from '@/models';
import { ServiceContainer } from '@/providers';
import { ConnectedAppsService, LocalizationService, UserService } from '@/services';
import {
  InboxWorkCountLoadable,
  PlannerDataStore,
  PlannerDemoDetailedCourseSectionsLoadable,
  PlannerDetailedCourseSectionsLoadable,
  SchoolDataStore,
  TeacherPreferencesLoadable,
  UserDataStore
} from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { ParticipationRequestState } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/participation_request_state_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { chain, groupBy } from 'lodash';
import { computed, makeObservable } from 'mobx';
import LocalizedStrings from 'strings';
import { AppUserDashboardSideBarViewModel, UserDashboardSideBarViewModel } from '../shared';
import {
  AppPlannerSideBarCourseSectionItemViewModel,
  PlannerSideBarCourseSectionItemViewModel
} from './PlannerSideBarCourseSectionItemViewModel';

export type PlannerSideBarOption =
  | 'pending-requests'
  | 'configure'
  | 'show-demo-class'
  | 'hide-demo-class'
  | 'open-student-planner';

export interface PlannerSideBarViewModelCoursesSection {
  readonly isDemoClasses: boolean;
  readonly title: string;
  readonly courseSections: PlannerSideBarCourseSectionItemViewModel[];
}

export interface PlannerSideBarViewModel extends UserDashboardSideBarViewModel {
  readonly courseSectionsSections: PlannerSideBarViewModelCoursesSection[];
  readonly pendingParticipationRequestsCount: number;
  readonly inboxWorkCount: number;
  readonly showEmotionalSurveyReminder: boolean;
  readonly options: PlannerSideBarOption[];
  toggleDemoCoursesVisibility(): Promise<void>;
}

export class AppPlannerSideBarViewModel extends AppUserDashboardSideBarViewModel implements PlannerSideBarViewModel {
  constructor(
    private readonly _plannerId: string,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    localization: LocalizationService = ServiceContainer.services.localization,
    user: UserService = ServiceContainer.services.user,
    userStore: UserDataStore = ServiceContainer.services.userStore,
    connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    super(localization, user, userStore, connectedApps);
    makeObservable(this);
    void this.teacherPreferencesLoadable.fetch(false);
  }

  @computed
  private get planner(): Planner {
    const planner = this._userStore.getPlannerForId(this._plannerId);

    if (planner == null) {
      throw new Error(`No available planner matching id ${this._plannerId}.`);
    }

    return planner;
  }

  @computed
  private get isImpersonating(): boolean {
    return this._userStore.getIsImpersonatingInPlanner(this._plannerId);
  }

  @computed
  private get teacherPreferencesLoadable(): TeacherPreferencesLoadable {
    return this._schoolStore.getTeacherPreferences();
  }

  @computed
  private get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get demoCourseSectionsLoadable(): PlannerDemoDetailedCourseSectionsLoadable {
    return this._plannerStore.getDemoCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get inboxWorkCountLoadable(): InboxWorkCountLoadable {
    return this._plannerStore.getInboxWorkCountInPlanner(this._plannerId);
  }

  @computed
  private get courseSections(): CourseSectionDetails[] {
    return this.courseSectionsLoadable.values;
  }

  @computed
  private get isTeacher(): boolean {
    return this._userStore.user.persona === UserPersona.TEACHER;
  }

  @computed
  private get isViewingStudentPlanner(): boolean {
    return plannerHasRelationshipKindsForUser(
      this._userStore.user.userId,
      this.planner,
      PlannerRelationshipKind.TEACHER
    );
  }

  @computed
  private get canEditPlanner(): boolean {
    return plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get courseSectionsSections(): PlannerSideBarViewModelCoursesSection[] {
    const courseSections = this.courseSections;

    const demoCourseSections =
      this.teacherPreferencesLoadable.data.showDemoCourseSection &&
      this.isTeacher &&
      !this.isViewingStudentPlanner &&
      !this.isImpersonating
        ? this.demoCourseSectionsLoadable.values
        : [];

    const coursesBySchoolId = groupBy(courseSections, (t) => t.schoolsCourseSection?.schoolId ?? '');

    const sections: PlannerSideBarViewModelCoursesSection[] = chain(Object.keys(coursesBySchoolId))
      .map((schoolId) => {
        const courses = coursesBySchoolId[schoolId]
          .map((cs) => new AppPlannerSideBarCourseSectionItemViewModel(cs))
          .sort(
            (vm1, vm2) =>
              vm1.courseTitle.localeCompare(vm2.courseTitle, this._localization.currentLocale, {
                sensitivity: 'base'
              }) || vm1.section.localeCompare(vm2.section, this._localization.currentLocale, { sensitivity: 'base' })
          );

        const school = this._userStore.getSchoolForId(schoolId);
        return school != null
          ? { isDemoClasses: false, title: school.school!.name, courseSections: courses }
          : {
              isDemoClasses: false,
              title: LocalizedStrings.sidebar.planner.personalClassesSectionTitle(),
              courseSections: courses
            };
      })
      .compact()
      .value();

    if (demoCourseSections.length > 0) {
      const demoCoursesVms = demoCourseSections.map((cs) => new AppPlannerSideBarCourseSectionItemViewModel(cs));

      sections.unshift({
        isDemoClasses: true,
        title: LocalizedStrings.sidebar.planner.demoClassesSectionTitle(),
        courseSections: demoCoursesVms
      });
    }

    return sections;
  }

  @computed
  get inboxWorkCount(): number {
    const loadable = this.inboxWorkCountLoadable;
    return loadable.hasData ? loadable.data : 0;
  }

  @computed
  get pendingParticipationRequestsCount(): number {
    if (!plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS)) {
      return 0;
    }

    return this.planner.participationRequests.filter(
      (req) =>
        req.state === ParticipationRequestState.REQUESTED_PARTICIPATION_CODE_STATE_UNREAD ||
        req.state === ParticipationRequestState.REQUESTED_PARTICIPATION_CODE_STATE_UNSPECIFIED
    ).length;
  }

  @computed
  get showEmotionalSurveyReminder(): boolean {
    if (this._userStore.user.persona !== UserPersona.STUDENT || this.isImpersonating) {
      return false;
    }

    return this.emotionPulseRating == null;
  }

  @computed
  get options(): PlannerSideBarOption[] {
    if (this.isImpersonating) {
      return ['configure'];
    }

    const options: PlannerSideBarOption[] = [];

    if (this.pendingParticipationRequestsCount > 0) {
      options.push('pending-requests');
    }

    if (this.isTeacher) {
      if (!this.isViewingStudentPlanner && this.teacherPreferencesLoadable.hasData) {
        options.push(
          this.teacherPreferencesLoadable.data.showDemoCourseSection ? 'hide-demo-class' : 'show-demo-class'
        );
      }

      options.push('open-student-planner');
    }

    if (this.canEditPlanner) {
      options.push('configure');
    }

    return options;
  }

  @computed
  get hasConnectedAppsWarning(): boolean {
    return this._connectedApps.getHasWarning(this._plannerId) && !this.hasConnectedAppsError;
  }

  @computed
  get hasConnectedAppsError(): boolean {
    return this._connectedApps.getHasError(this._plannerId);
  }

  async toggleDemoCoursesVisibility() {
    const newValue = !this.teacherPreferencesLoadable.data.showDemoCourseSection;
    await this._schoolStore.updateTeacherPreferences(newValue);
  }
}
