import { useActivePlannerId, useServices, useSync, useViewModel } from '@/hooks';
import { SortedDayOfWeek, dateToPBDate } from '@/models';
import { computeDatesForMonthByWeek } from '@/utils';
import { Box, Grid, Stack, Typography, styled, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { isSameMonth, parse } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { BackgroundImageScreenPaper, UpdatablePresenter } from '../../../utils';
import { UserDashboardCalendarMonthDayView } from './UserDashboardCalendarMonthDayView';
import { UserDashboardCalendarMonthHeaderView } from './UserDashboardCalendarMonthHeaderView';

export interface UserDashboardCalendarMonthViewProps {
  sx?: SxProps;
  className?: string;
}

export const UserDashboardCalendarMonthView = observer(({ sx, className }: UserDashboardCalendarMonthViewProps) => {
  const { dateService, storeInvalidator } = useServices();
  const plannerId = useActivePlannerId();
  const [searchParams] = useSearchParams();

  const viewModel = useViewModel(
    (viewModels) => viewModels.createUserDashboardCalendarMonthViewModel(plannerId),
    [plannerId]
  );

  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const dateParam = searchParams.get('date');
  const currentDate = dateParam ? parse(dateParam, 'y-MM-dd', new Date()) : dateService.now;

  useEffect(() => {
    void viewModel.fetchDays(currentDate, false);
  }, [currentDate]);

  useEffect(() => {
    void viewModel.fetchDays(currentDate, true);
  }, [storeInvalidator.calendarRevision]);

  useSync('UserDashboardCalendarMonth', () => viewModel.fetchDays(currentDate, true), [plannerId, currentDate]);

  const weeks = computeDatesForMonthByWeek(currentDate);

  return (
    <Box sx={sx} className={className} width="100%" height="100%">
      <Box sx={{ width: '100%', height: '100%', display: 'flex' }}>
        <Grid container sx={{ width: '100%', height: '100%' }}>
          <Grid item xs={12} height="100%">
            <BackgroundImageScreenPaper sx={{ height: '100%' }} className={className}>
              <UpdatablePresenter
                sx={{ height: '100%', width: '100%' }}
                viewModel={viewModel}
                renderData={() => (
                  <Box display="flex" flexDirection="column" height="100%" width="100%" overflow="hidden">
                    <UserDashboardCalendarMonthHeaderView
                      currentDate={currentDate}
                      courseSections={viewModel.courseSections}
                      scheduleCycleWithDraftId={viewModel.scheduleCycleWithDraftId}
                      hasCalendarSyncError={viewModel.hasCalendarSyncError}
                      retryFetchData={() => viewModel.fetchDays(currentDate, true)}
                    />

                    <MonthTable style={{ width: '100%', height: '100%' }}>
                      <Stack direction="row" className="month-table-header-row">
                        {SortedDayOfWeek.map((dow) => (
                          <Typography
                            key={dow}
                            flex={1}
                            textAlign="center"
                            variant="subtitle2"
                            className="month-table-header"
                          >
                            {isExtraSmallScreen
                              ? LocalizedStrings.dateTime.dayOfWeekShortTitle[dow]()
                              : LocalizedStrings.dateTime.dayOfWeekTitle[dow]()}
                          </Typography>
                        ))}
                      </Stack>

                      <Box flex={1} overflow="hidden" display="flex" flexDirection="column">
                        {weeks.map((week, weekIndex) => (
                          <Box
                            key={`week-${weekIndex}`}
                            display="flex"
                            flexDirection="row"
                            className="month-table-row"
                            height={`${100 / weeks.length}%`}
                          >
                            {week.map((date) => {
                              const dayViewModel = viewModel.getViewModelForDay(dateToPBDate(date));

                              return (
                                <div key={date.toString()} className="month-table-day">
                                  <UserDashboardCalendarMonthDayView
                                    sx={{ height: '100%', width: '100%' }}
                                    viewModel={dayViewModel}
                                    isCurrentMonth={isSameMonth(currentDate, date)}
                                  />
                                </div>
                              );
                            })}
                          </Box>
                        ))}
                      </Box>
                    </MonthTable>
                  </Box>
                )}
              />
            </BackgroundImageScreenPaper>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});

const MonthTable = styled('div')(({ theme }) => {
  const border = `1px solid ${theme.palette.divider}`;

  return {
    display: 'flex',
    flexDirection: 'column',

    '.month-table-day': {
      borderRight: border,
      width: `${100 / 7}%`
    },

    '.month-table-day:last-child': {
      borderRight: 'none'
    },

    '.month-table-row': {
      borderBottom: border
    },

    '.month-table-row:last-child': {
      borderBottom: 'none'
    },

    '.month-table-header': {
      borderRight: '1px solid transparent'
    }
  };
});
