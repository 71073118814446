import { GroupsRounded, PersonRounded } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useViewModel } from '../../hooks';
import { Dialog } from '../utils';
import { OnboardingSchoolKindsExplanationSchoolKindPanel } from './OnboardingSchoolKindsExplanationSchoolKindPanel';

export interface SchoolKindsExplanationDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  close: () => void;
}

export const SchoolKindsExplanationDialog = observer(
  ({ sx, className, isOpen, close }: SchoolKindsExplanationDialogProps) => {
    const strings = LocalizedStrings.onboarding.teacherOptions.schoolKindExplanation;

    function onDismiss() {
      close();
      return Promise.resolve();
    }

    const viewModel = useViewModel(
      (viewModels) => viewModels.createSchoolKindsExplanationDialogViewModel(onDismiss),
      []
    );

    return (
      <Dialog
        sx={sx}
        className={className}
        viewModel={viewModel}
        isOpen={isOpen}
        title={strings.title()}
        width="sm"
        renderData={() => (
          <Stack spacing={2}>
            <OnboardingSchoolKindsExplanationSchoolKindPanel
              icon={(sx) => <PersonRounded sx={sx} />}
              title={strings.personalSchoolPanelTitle()}
              message={strings.personalSchoolPanelMessage()}
            />

            <OnboardingSchoolKindsExplanationSchoolKindPanel
              icon={(sx) => <GroupsRounded sx={sx} />}
              title={strings.sharedSchoolPanelTitle()}
              message={strings.sharedSchoolPanelMessage()}
            />
          </Stack>
        )}
      />
    );
  }
);
