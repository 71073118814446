import { ServiceContainer } from '@/providers';
import { LocalizationService, UserService } from '@/services';
import { UserDataStore } from '@/stores';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { Area } from 'react-easy-crop/types';

export interface UserPropertiesViewModel {
  readonly isForRequiredName: boolean;
  fullName: string;
  persona: UserPersona;
  readonly pictureUrl: string;
  readonly pictureFile?: string;
  readonly isEditingPicture: boolean;

  readonly canClear: boolean;
  readonly canApply: boolean;
  readonly isApplying: boolean;
  readonly error: { title: string; message: string } | undefined;

  onFileChanged(files: FileList | undefined): Promise<void>;
  onCropComplete(area: Area, areaPixels: Area): void;
  clearImage(): void;
  resetFile(): void;
  apply(): Promise<void>;
  dismissError(): void;
  cancel(): void;
}

export class AppUserPropertiesViewModel implements UserPropertiesViewModel {
  @observable private _originalFullName: string;
  @observable private _originalPersona: UserPersona;
  @observable private _originalPictureUrl: string;
  @observable private _fullName: string;
  @observable private _persona: UserPersona;
  @observable private _pictureUrl: string;
  @observable private _pictureFile: string | undefined;
  private _areaPixels: Area | undefined;
  @observable private _isApplying = false;
  @observable private _error: { title: string; message: string } | undefined;

  constructor(
    public readonly isForRequiredName: boolean,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _user: UserService = ServiceContainer.services.user,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    makeObservable(this);

    this._originalFullName = _user.currentUser?.fullName ?? '';
    this._originalPersona = _user.currentUser?.persona ?? UserPersona.UNSPECIFIED;
    this._originalPictureUrl = _user.currentUser?.pictureUrl ?? '';

    this._fullName = this._originalFullName;
    this._persona = this._originalPersona;
    this._pictureUrl = this._originalPictureUrl;
  }

  @computed
  get fullName(): string {
    return this._fullName;
  }

  set fullName(value: string) {
    this._fullName = value;
  }

  @computed
  get persona(): UserPersona {
    return this._persona;
  }

  set persona(value: UserPersona) {
    this._persona = value;
  }

  @computed
  get pictureUrl(): string {
    return this._pictureUrl;
  }

  @computed
  get pictureFile(): string | undefined {
    return this._pictureFile;
  }

  @computed
  get isEditingPicture(): boolean {
    return this._pictureFile != null;
  }

  @computed
  get canClear(): boolean {
    return this._pictureUrl.length > 0 && this._pictureFile == null;
  }

  @computed
  get canApply(): boolean {
    return (
      // has changes?
      (this._fullName != this._originalFullName ||
        this._pictureUrl != this._originalPictureUrl ||
        this._pictureFile != null ||
        this._persona != this._originalPersona) &&
      // Can apply those changes?
      this._fullName.length > 0 &&
      (this.isForRequiredName || this._persona !== UserPersona.UNSPECIFIED)
    );
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get error(): { title: string; message: string } | undefined {
    return this._error;
  }

  @action
  protected setIsApplying(value: boolean) {
    this._isApplying = value;
  }

  async onFileChanged(files: FileList | undefined): Promise<void> {
    if (files != null && files.length === 1) {
      const file = await this.readFile(files[0]);

      if (typeof file === 'string') {
        runInAction(() => (this._pictureFile = file));
      }
    }
  }

  onCropComplete(area: Area, areaPixels: Area): void {
    this._areaPixels = areaPixels;
  }

  @action
  clearImage(): void {
    this._pictureUrl = '';
  }

  @action
  resetFile(): void {
    this._pictureFile = undefined;
    this._areaPixels = undefined;
  }

  async apply(): Promise<void> {
    this.setIsApplying(true);

    try {
      if (this.isEditingPicture) {
        // This will update this.pictureUrl.
        await this.uploadPicture();
      }
      await this._userStore.customizeCurrentUser(this.fullName, this.pictureUrl);

      // Only updating persona if it has changed.
      if (this._originalPersona != this.persona) {
        await this._userStore.assignPersona(this.persona);
      }

      runInAction(() => {
        this._originalFullName = this.fullName;
        this._originalPersona = this.persona;
        this._originalPictureUrl = this.pictureUrl;
      });
    } catch (error) {
      const strings = this._localization.localizedStrings.user;

      runInAction(
        () =>
          (this._error = {
            title: strings.applyChangesErrorTitle,
            message: strings.applyChangesErrorMessage + (error as Error).message
          })
      );
    } finally {
      this.setIsApplying(false);
    }
  }

  @action
  dismissError() {
    this._error = undefined;
  }

  @action
  cancel() {
    this._fullName = this._originalFullName;
    this._persona = this._originalPersona;
    this._pictureUrl = this._originalPictureUrl;
  }

  protected async uploadPicture(): Promise<void> {
    if (this.isEditingPicture) {
      const { uploadUrl, downloadUrl } = await this._userStore.createProfilePictureDestination('profile.jpg');
      const blob = await this.getCroppedImg(this._pictureFile!, this._areaPixels!);

      await fetch(uploadUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'image/jpeg'
        },
        body: blob
      });

      runInAction(() => {
        this._pictureUrl = downloadUrl;
        this._pictureFile = undefined;
        this._areaPixels = undefined;
      });
    }
  }

  private readFile(file: Blob): Promise<string | ArrayBuffer | undefined> {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result ?? undefined), false);
      reader.readAsDataURL(file);
    });
  }

  private createImage(url: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener('load', () => resolve(image));
      image.addEventListener('error', (error) => reject(error));
      image.src = url;
    });
  }

  private getRadianAngle(degreeValue: number): number {
    return (degreeValue * Math.PI) / 180;
  }

  private rotateSize(width: number, height: number, rotation: number) {
    const rotRad = this.getRadianAngle(rotation);

    return {
      width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
    };
  }

  private async getCroppedImg(
    imageSrc: string,
    pixelCrop: Area,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ): Promise<Blob | undefined> {
    const image = await this.createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return undefined;
    }

    const rotRad = this.getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = this.rotateSize(image.width, image.height, rotation);

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((file) => resolve(file ?? undefined), 'image/jpeg');
    });
  }
}
