import { usePrevious } from '@/hooks';
import { UnsplashPictureViewModel } from '@/viewmodels';
import { Box, Grid } from '@mui/material';
import { range } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { UnsplashPicture } from './UnsplashPicture';

export type MuiGrid = boolean | 'auto' | 3 | 1 | 2 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;

export interface UnsplashGalleryProps {
  className?: string;
  pictures: UnsplashPictureViewModel[];
  nbColumns?: number;

  onSelectPicture?(picture?: UnsplashPictureViewModel): void;
}

export const UnsplashGallery = observer(
  ({ className, pictures, nbColumns = 3, onSelectPicture }: UnsplashGalleryProps) => {
    const [selectedPicture, setSelectedPicture] = useState<UnsplashPictureViewModel | undefined>();
    const columns = getColumns(pictures, nbColumns);

    const selectPicture = (picture?: UnsplashPictureViewModel) => {
      if (onSelectPicture != null) {
        if (picture == null || selectedPicture?.id === picture?.id) {
          setSelectedPicture(undefined);
          onSelectPicture?.();
        } else {
          setSelectedPicture(picture);
          onSelectPicture(picture);
        }
      }
    };

    useEffect(selectPicture, [didPicturesChange(pictures, usePrevious(pictures))]);

    return (
      <Grid className={className} container spacing={1}>
        {columns.map((p, i) => (
          <Grid key={`gallery-column-index-${i}`} item xs={Math.floor(12 / nbColumns) as MuiGrid}>
            {p.map((picture) => (
              <Box key={picture.id}>
                <UnsplashPicture
                  viewModel={picture}
                  selected={selectedPicture?.id === picture.id}
                  onClick={() => selectPicture(picture)}
                />
              </Box>
            ))}
          </Grid>
        ))}
      </Grid>
    );
  }
);

function getColumns(pictures: UnsplashPictureViewModel[], nbCols: number): UnsplashPictureViewModel[][] {
  return pictures.reduce(
    (ac: UnsplashPictureViewModel[][], value, index) => {
      const colIndex = index % nbCols;
      ac[colIndex].push(value);
      return ac;
    },
    range(nbCols).map(() => [])
  );
}

function didPicturesChange(
  newPictures: UnsplashPictureViewModel[],
  oldPictures: UnsplashPictureViewModel[] = []
): boolean {
  const oldPictureIds = oldPictures.map((p) => p.id).join(',');
  const newPictureIds = newPictures.map((p) => p.id).join(',');
  return !newPictureIds.startsWith(oldPictureIds);
}
