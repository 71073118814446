import { useViewModel } from '@/hooks';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useScheduleCycleUserDashboard } from '../../UseScheduleCycleUserDashboardHook';
import { ScheduleCycleSpecialDays } from './ScheduleCycleSpecialDays';

export interface ScheduleCycleSpecialDaysPageProps {
  sx?: SxProps;
  className?: string;
}

export const ScheduleCycleSpecialDaysPage = observer(({ sx, className }: ScheduleCycleSpecialDaysPageProps) => {
  const { dashboard, scheduleCycleId } = useScheduleCycleUserDashboard();
  const viewModel = useViewModel(
    (viewModels) => viewModels.createScheduleCycleSpecialDaysPageViewModel(scheduleCycleId, dashboard),
    [scheduleCycleId, dashboard]
  );

  return (
    <Stack sx={{ ...sx, width: '100%' }} className={className}>
      <ScheduleCycleSpecialDays viewModel={viewModel.contentViewModel} />
    </Stack>
  );
});
