import { Teacher } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/teacher_pb';
import { Divider, List } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Fragment } from 'react';
import LocalizedStrings from 'strings';
import { AdminContentCard } from '../../shared';
import { AdminSchoolClassTeachersListItem } from './AdminSchoolClassTeachersListItem';

export interface AdminSchoolClassTeachersListProps {
  sx?: SxProps;
  className?: string;
  teachers: Teacher[];
  scheduleCycleId?: string;
  isReadOnly: boolean;
}

export const AdminSchoolClassTeachersList = observer(
  ({ sx, className, teachers, scheduleCycleId, isReadOnly }: AdminSchoolClassTeachersListProps) => {
    return (
      <AdminContentCard
        sx={sx}
        className={className}
        title={LocalizedStrings.admin.classes.details.teachersCardTitle()}
      >
        <List disablePadding>
          {teachers.map((teacher, index) => (
            <Fragment key={teacher.accountId}>
              <AdminSchoolClassTeachersListItem
                teacher={teacher}
                scheduleCycleId={scheduleCycleId}
                isReadOnly={isReadOnly}
              />
              {index < teachers.length - 1 && <Divider variant="middle" />}
            </Fragment>
          ))}
        </List>
      </AdminContentCard>
    );
  }
);
