import { useActivePlannerId, useServices } from '@/hooks';
import {
  UserDashboardCalendarItemState,
  UserDashboardCalendarMonthPublishedWorkInfo,
  UserDashboardCalendarMonthWorkInfo
} from '@/viewmodels';
import { Box, CardActionArea, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { BackgroundLocationState } from '../../../../BackgroundLocationState';
import { ColorIndicator, WorkIcon } from '../../../lists';
import { MultilineTooltipTitle } from '../../../utils';
import { ContextMenuHandler } from '../../ContextMenuHandler';
import { formatItemStartTimeLong, formatItemTime } from '../shared';

export interface UserDashboardCalendarMonthWorkViewProps {
  sx?: SxProps;
  className?: string;
  work:
    | { case: 'work'; value: UserDashboardCalendarMonthWorkInfo }
    | { case: 'publishedWork'; value: UserDashboardCalendarMonthPublishedWorkInfo };
}

export const UserDashboardCalendarMonthWorkView = observer(
  ({ sx, className, work }: UserDashboardCalendarMonthWorkViewProps) => {
    const { route } = useServices();
    const plannerId = useActivePlannerId();
    const hasDescription = work.value.description.length > 0;
    const theme = useTheme();
    const color = work.value.color || theme.palette.action.focus;
    const [showTooltip, setShowTooltip] = useState(false);

    const location = useLocation();
    const state = (location.state ?? {}) as BackgroundLocationState;
    const navigate = useNavigate();

    function showWorkDetails() {
      const newLocation =
        work.case === 'work'
          ? route.resolvePlannerWorkLocation(plannerId, work.value.id)
          : route.resolvePublishedWorkLocation(plannerId, work.value.id, work.value.schoolId);

      navigate(newLocation, { state: { backgroundLocation: location } });
    }

    return (
      <ContextMenuHandler actions={work.value.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Tooltip
            title={
              <MultilineTooltipTitle
                lines={
                  work.value.startTime != null
                    ? [work.value.title, formatItemStartTimeLong(work.value.startTime)]
                    : [work.value.title]
                }
              />
            }
            disableInteractive
            disableHoverListener
            open={showTooltip && state.backgroundLocation == null}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <Box width="100%" display="flex" justifyContent="stretch" className={className} sx={sx}>
              <CardActionArea
                sx={{
                  borderRadius: 0.5,
                  px: 0.5,
                  py: '2px',
                  display: 'flex',
                  flexDirection: 'row',
                  overflow: 'hidden'
                }}
                onClick={showWorkDetails}
                onContextMenu={contextMenuHandler}
              >
                <ColorIndicator color={color} isRounded sx={{ height: '100%', mr: 0.5 }} />

                <Stack
                  flex={1}
                  direction="row"
                  alignItems={hasDescription ? 'flex-start' : 'center'}
                  spacing={1}
                  overflow="hidden"
                >
                  <WorkIcon icon={work.value.icon} color={color} displayCourseSectionColor={false} size={18} />

                  <Stack flex={1} overflow="hidden">
                    <Typography fontSize={12} noWrap color={getColorForTitle(work.value.state)}>
                      {work.value.title}
                    </Typography>

                    {hasDescription && (
                      <Typography fontSize={10} noWrap color="textSecondary">
                        {work.value.description}
                      </Typography>
                    )}
                  </Stack>

                  {work.value.startTime != null && (
                    <Typography variant="caption" color="textSecondary" noWrap>
                      {formatItemTime(work.value.startTime)}
                    </Typography>
                  )}
                </Stack>
              </CardActionArea>
            </Box>
          </Tooltip>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
