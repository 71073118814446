import { ServiceContainer } from '@/providers';
import { AnalyticsService, LocalizationService, UserService } from '@/services';
import { PlannerDataStore, UserDataStore } from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { PlannerRelationshipDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_details_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { chain } from 'lodash';
import { action, computed, makeObservable, observable } from 'mobx';
import { plannerHasAccessKindsForUser } from '../../models';
import { localizedCompareWithProperties } from '../../utils';
import { AppObservableDataViewModel, ObservableDataViewModel } from '../shared';

export interface SharePlannerStatusViewModel {
  readonly selectablePlannerRelationshipKinds: PlannerRelationshipKind[];
  selectedPlannerRelationshipKind: PlannerRelationshipKind | undefined;
  readonly userRelationshipKind: PlannerRelationshipKind;
  readonly existingRelationshipKinds: PlannerRelationshipKind[];

  getDisplayedFullName(details: PlannerRelationshipDetails): string;
  getDisplayedEmailAddress(details: PlannerRelationshipDetails): string;
  getPrimaryRelationshipKind(kinds: PlannerRelationshipKind[]): PlannerRelationshipKind;
  readonly relationshipDetails: ObservableDataViewModel<PlannerRelationshipDetails[]>;
}

export class AppSharePlannerStatusViewModel implements SharePlannerStatusViewModel {
  @observable private _selectedPlannerRelationshipKind: PlannerRelationshipKind | undefined;
  @observable private _selectablePlannerRelationshipKinds: PlannerRelationshipKind[] = [];
  private readonly _relationshipDetailsData: ObservableDataViewModel<PlannerRelationshipDetails[]>;

  constructor(
    protected readonly _plannerId: string,
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization,
    protected readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    protected readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    protected readonly _user: UserService = ServiceContainer.services.user,
    protected readonly _analytics: AnalyticsService = ServiceContainer.services.analytics
  ) {
    makeObservable(this);

    this._relationshipDetailsData = new AppObservableDataViewModel<PlannerRelationshipDetails[]>(() =>
      this.loadRelationshipDetails()
    );

    this.setSelectableKinds();
  }

  @computed
  protected get planner(): Planner {
    return this._userStore.getPlannerForId(this._plannerId)!;
  }

  @computed
  get selectedPlannerRelationshipKind(): PlannerRelationshipKind | undefined {
    return this._selectedPlannerRelationshipKind;
  }

  set selectedPlannerRelationshipKind(value: PlannerRelationshipKind | undefined) {
    this._selectedPlannerRelationshipKind = value;
  }

  @computed
  get selectablePlannerRelationshipKinds(): PlannerRelationshipKind[] {
    return this._selectablePlannerRelationshipKinds;
  }

  @computed
  get userRelationshipKind(): PlannerRelationshipKind {
    const relationship = this.planner.relationships.find(
      (r) => r.kind !== PlannerRelationshipKind.CREATOR && r.userId === this._user.currentUser?.userId
    );

    return relationship?.kind ?? PlannerRelationshipKind.UNSPECIFIED;
  }

  @computed
  get relationshipDetails(): ObservableDataViewModel<PlannerRelationshipDetails[]> {
    return this._relationshipDetailsData;
  }

  @computed
  get existingRelationshipKinds(): PlannerRelationshipKind[] {
    return chain(this.planner.relationships)
      .map((r) => r.kind)
      .uniq()
      .value();
  }

  getDisplayedFullName(details: PlannerRelationshipDetails): string {
    const currentUserSuffix =
      details.userId === this._userStore.user.userId
        ? this._localization.localizedStrings.sharingInvitationCode.currentUserLabel
        : '';

    if (details.emailAddress.length === 0) {
      return this._localization.localizedStrings.sharingInvitationCode.anonymousUserFullName + currentUserSuffix;
    }

    return details.fullName + currentUserSuffix;
  }

  getDisplayedEmailAddress(details: PlannerRelationshipDetails): string {
    if (details.emailAddress.length === 0) {
      return this._localization.localizedStrings.sharingInvitationCode.anonymousUserEmail;
    }

    return details.emailAddress;
  }

  getPrimaryRelationshipKind(kinds: PlannerRelationshipKind[]): PlannerRelationshipKind {
    return (
      kinds.filter((k) => k !== PlannerRelationshipKind.CREATOR && k !== PlannerRelationshipKind.UNSPECIFIED)[0] ??
      'unspecified'
    );
  }

  protected async loadRelationshipDetails(): Promise<PlannerRelationshipDetails[]> {
    const details = await this._plannerStore.getPlannerRelationshipDetails(this._plannerId);
    return details.sort((a, b) =>
      localizedCompareWithProperties(
        [
          { value1: a.fullName, value2: b.fullName, emptyValueOrder: 'desc' },
          { value1: a.emailAddress, value2: b.emailAddress, emptyValueOrder: 'desc' }
        ],
        this._localization.currentLocale
      )
    );
  }

  @action
  private setSelectableKinds() {
    switch (this.userRelationshipKind) {
      case PlannerRelationshipKind.PARENT: {
        if (plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS)) {
          const canInviteChild =
            !this.existingRelationshipKinds.includes(PlannerRelationshipKind.INDIVIDUAL) &&
            !this.existingRelationshipKinds.includes(PlannerRelationshipKind.STUDENT);

          if (canInviteChild) {
            this._selectablePlannerRelationshipKinds = [
              PlannerRelationshipKind.INDIVIDUAL,
              PlannerRelationshipKind.PARENT
            ];
            this._selectedPlannerRelationshipKind = PlannerRelationshipKind.INDIVIDUAL;
          } else {
            this._selectablePlannerRelationshipKinds = [PlannerRelationshipKind.PARENT];
            this._selectedPlannerRelationshipKind = PlannerRelationshipKind.PARENT;
          }
        } else {
          this.setEmptySelectableKinds();
        }
        break;
      }

      case PlannerRelationshipKind.TEACHER:
        this.setEmptySelectableKinds();
        break;

      default:
        switch (this._userStore.user.persona) {
          case UserPersona.TEACHER:
          case UserPersona.PARENT:
            this.setEmptySelectableKinds();
            break;

          default:
            this._selectablePlannerRelationshipKinds = [
              PlannerRelationshipKind.PARENT,
              PlannerRelationshipKind.TEACHER
            ];
            this._selectedPlannerRelationshipKind = PlannerRelationshipKind.PARENT;
            break;
        }
        break;
    }
  }

  @action
  private setEmptySelectableKinds() {
    this._selectablePlannerRelationshipKinds = [];
    this._selectedPlannerRelationshipKind = undefined;
  }
}
