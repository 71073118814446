import { PlannerNewSchoolsDialogViewModel } from '@/viewmodels';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, ListItemText, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ConfirmationDialogMessage } from '../../utils';

export interface PlannerNewSchoolsSelectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerNewSchoolsDialogViewModel;
}

export const PlannerNewSchoolsSelection = observer(({ sx, className, viewModel }: PlannerNewSchoolsSelectionProps) => {
  const strings = LocalizedStrings.planner.newSchools.selection;

  function getLabelForSchool(school: SchoolInformation): string {
    return school.school!.subtitle.length > 0
      ? `${school.school!.name} (${school.school!.subtitle})`
      : school.school!.name;
  }

  return (
    <Stack sx={sx} className={className} spacing={2}>
      <ConfirmationDialogMessage message={viewModel.isTeacher ? strings.messageTeacher() : strings.message()} />

      <FormControl component="fieldset" variant="standard" sx={{ px: 1 }}>
        {viewModel.schools.map((s) => {
          const label = getLabelForSchool(s);

          return (
            <FormControlLabel
              key={s.school!.id}
              control={
                <Checkbox
                  checked={!viewModel.ignoredSchoolIds.includes(s.school!.id)}
                  onChange={() => viewModel.toggleSelectionOfSchool(s.school!.id)}
                  name={label}
                />
              }
              label={
                <ListItemText
                  primary={label}
                  secondary={LocalizedStrings.planner.newSchools.schoolOwners(
                    s.owners.map((o) => o.fullName).join(', ')
                  )}
                />
              }
            />
          );
        })}

        <FormHelperText>{`* ${strings.selectionHelperText()}`}</FormHelperText>
      </FormControl>

      {viewModel.hasSubmitError && <FormHelperText error>{strings.errorMessage()}</FormHelperText>}
    </Stack>
  );
});
