import { YoutubeSearchedForRounded } from '@mui/icons-material';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Observer, observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useViewModel } from '../../../../hooks';
import { AdminClassSchedulesFilterOption, ScheduleCycleActivitySchedulesCoursesFilter } from '../../../../viewmodels';
import { ScheduleCycleActivitySchedulesCoursesList, ScheduleCycleActivitySchedulesList } from '../../../shared';
import { UpdatablePresenter } from '../../../utils';

export interface AdminSchoolScheduleCycleClassSchedulesListProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  scheduleCycleId: string;
  option: AdminClassSchedulesFilterOption | undefined;
  filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined;
  isReadOnly: boolean;
}

export const AdminSchoolScheduleCycleClassSchedulesList = observer(
  ({
    sx,
    className,
    schoolId,
    scheduleCycleId,
    option,
    isReadOnly,
    filters
  }: AdminSchoolScheduleCycleClassSchedulesListProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const courseId = searchParams.get('course');
    const theme = useTheme();
    const strings = LocalizedStrings.admin.schedule.classSchedules;

    const viewModel = useViewModel(
      (viewModels) => viewModels.createAdminClassSchedulesListViewModel(schoolId, scheduleCycleId, option),
      [schoolId, option]
    );

    return (
      <Box sx={sx} className={className} display="flex" flexDirection="row">
        <Stack flex={1} sx={{ height: '100%', overflow: 'hidden' }}>
          <UpdatablePresenter
            sx={{ flex: 1, overflow: 'hidden' }}
            viewModel={viewModel}
            renderData={() => (
              <Observer>
                {() => {
                  const resolvedCourseId = courseId ?? viewModel.courseInfos[0]?.id ?? '';
                  const selectedCourse = viewModel.getActivityScheduleInfosForCourseId(resolvedCourseId);

                  return (
                    <Stack sx={{ width: '100%', height: '100%' }} direction="row" spacing={2} overflow="hidden">
                      {option?.case === 'teacher' &&
                        (viewModel.courseInfos.length > 0 ? (
                          <>
                            <ScheduleCycleActivitySchedulesCoursesList
                              sx={{ minWidth: { sm: 175, md: 250 } }}
                              courses={viewModel.courseInfos}
                              selectedCourseId={resolvedCourseId}
                              onCourseSelect={(id) => {
                                searchParams.set('course', id);
                                setSearchParams(searchParams);
                              }}
                            />

                            <Divider orientation="vertical" />
                          </>
                        ) : (
                          <Stack direction="row" spacing={1}>
                            <YoutubeSearchedForRounded sx={{ color: theme.palette.text.secondary }} fontSize="medium" />

                            <Typography variant="body1" color="textSecondary" fontWeight="500">
                              {strings.noClassesForTeacherMessage()}
                            </Typography>
                          </Stack>
                        ))}

                      {selectedCourse != null && (
                        <Stack flex={1}>
                          <ScheduleCycleActivitySchedulesList
                            sx={{ flex: 1, maxWidth: 500 }}
                            activity={selectedCourse.activity}
                            activitySchedules={selectedCourse.activitySchedules}
                            removeActivitySchedule={(id) => void viewModel.removeActivitySchedule(id)}
                            isReadOnly={isReadOnly || !viewModel.canEditActivitySchedulesForCourseId(resolvedCourseId)}
                            filters={filters}
                          />
                        </Stack>
                      )}
                    </Stack>
                  );
                }}
              </Observer>
            )}
          />
        </Stack>
      </Box>
    );
  }
);
