import { useServices } from '@/hooks';
import { PlannerListViewModel } from '@/viewmodels';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { PlannerInboxListItem } from './PlannerInboxListItem';
import { PlannerList } from './PlannerList';
import { PlannerTodayListEmptyIndicator } from './PlannerTodayListEmptyIndicator';

export interface PlannerInboxListProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerListViewModel;
  /**
   * Callback to go to the Today list. Used in mobile.
   */
  goToTodayList?: () => void;
}

export const PlannerInboxList = observer(({ sx, className, viewModel, goToTodayList }: PlannerInboxListProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;

  return (
    <PlannerList
      sx={sx}
      className={className}
      viewModel={viewModel}
      title={strings.inboxTitle}
      subtitle={strings.inboxSubtitle}
      showCreateWorkIfDesktop
      renderItem={(item) => <PlannerInboxListItem item={item} />}
      rowHeightForItem={() => 102}
      bottomPadding={56}
      renderEmptyIndicator={() => <PlannerTodayListEmptyIndicator onButtonPress={goToTodayList} />}
    />
  );
});
