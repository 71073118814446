import { emotionalPulseRatingFromStatus, StudentEmotionalStatus } from '@/models';
import { CloseRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, IconButton, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useServices } from '../../hooks';
import { StudentEmotionalSurveyCardOption } from './StudentEmotionalSurveyCardOption';

interface PossibleAnswer {
  readonly kind: StudentEmotionalStatus;
  readonly emoji: string;
}

const PossibleAnswers: PossibleAnswer[] = [
  { kind: StudentEmotionalStatus.SAD, emoji: '🙁' },
  { kind: StudentEmotionalStatus.OK, emoji: '😐' },
  { kind: StudentEmotionalStatus.HAPPY, emoji: '😃' }
];

export interface StudentEmotionalSurveyCardProps {
  sx?: SxProps;
  className?: string;
  canDismiss?: boolean;
  close?: () => void;
}

export const StudentEmotionalSurveyCard = observer(
  ({ sx, className, canDismiss, close }: StudentEmotionalSurveyCardProps) => {
    const { localization, userStore } = useServices();
    const [selectedKind, setSelectedKind] = useState<StudentEmotionalStatus | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [hasError, setHasError] = useState(false);

    const theme = useTheme();
    const strings = localization.localizedStrings.utils;

    async function submit(kind: StudentEmotionalStatus | undefined) {
      const rating = emotionalPulseRatingFromStatus(kind);

      try {
        setIsSubmitting(true);
        setHasError(false);
        await userStore.setEmotionalState(rating);
        close?.();
      } catch (e) {
        setHasError(true);
      } finally {
        setIsSubmitting(false);
      }
    }

    return (
      <Box sx={{ ...sx, display: 'flex', flexDirection: 'column', pt: 1, pb: 1 }} className={className}>
        <Stack direction="row" alignItems="center" pl={canDismiss ? 2 : 1} pr={1}>
          <Typography flex={1} variant="body2" fontWeight="500" color="textSecondary">
            {strings.emotionalSurveyCardTitle}
          </Typography>

          {canDismiss && (
            <Tooltip title={strings.emotionalSurveyDismissTooltip} disableInteractive>
              <IconButton size="small" onClick={() => void submit(undefined)} disabled={isSubmitting}>
                <CloseRounded fontSize="small" sx={{ color: theme.palette.text.secondary }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        <Stack direction="row" spacing={1} px={1} mt={0.5}>
          {PossibleAnswers.map((answer) => (
            <StudentEmotionalSurveyCardOption
              key={answer.emoji}
              sx={{ flex: 1 }}
              isSelected={selectedKind === answer.kind}
              emoji={answer.emoji}
              onSelect={() => setSelectedKind(answer.kind)}
              disabled={isSubmitting}
            />
          ))}
        </Stack>

        {selectedKind != null && (
          <LoadingButton
            sx={{ mx: 1, mt: 1 }}
            variant="contained-grey"
            onClick={() => void submit(selectedKind)}
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            {strings.emotionalSurveySubmitButtonTitle}
          </LoadingButton>
        )}

        {hasError && (
          <Alert variant="standard" severity="error" sx={{ mx: 1, mt: 1 }}>
            An error occurred while submitting. Please try again later.
          </Alert>
        )}
      </Box>
    );
  }
);
