import { Box, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useSearchParams } from 'react-router-dom';
import { AdminClassSchedulesViewModel } from '../../../../viewmodels';
import { ScheduleCycleMasterSchedulePage } from '../../../shared';
import { AdminSchoolScheduleCycleClassSchedulesCurrentSelection } from './AdminSchoolScheduleCycleClassSchedulesCurrentSelection';
import { AdminSchoolScheduleCycleClassSchedulesList } from './AdminSchoolScheduleCycleClassSchedulesList';
import { AdminSchoolScheduleCycleClassSchedulesSearch } from './AdminSchoolScheduleCycleClassSchedulesSearch';
import { AdminSchoolScheduleCycleClassSchedulesViewKind } from './AdminSchoolScheduleCycleClassSchedulesViewKind';

export interface AdminSchoolScheduleCycleClassSchedulesProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  scheduleCycleId: string;
  viewModel: AdminClassSchedulesViewModel;
  viewKind: AdminSchoolScheduleCycleClassSchedulesViewKind;
  isReadOnly: boolean;
}

export const AdminSchoolScheduleCycleClassSchedules = observer(
  ({
    sx,
    className,
    schoolId,
    scheduleCycleId,
    viewModel,
    viewKind,
    isReadOnly
  }: AdminSchoolScheduleCycleClassSchedulesProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const teacherId = searchParams.get('teacher') ?? undefined;
    const courseId = searchParams.get('course') ?? undefined;
    const currentOption = viewModel.getOption(teacherId, courseId);

    function resetOption() {
      searchParams.delete('teacher');
      searchParams.delete('course');
      setSearchParams(searchParams);
    }

    return (
      <Box sx={sx} className={className} display="flex" flexDirection="column" overflow="hidden">
        <Stack direction="row" alignItems="center" spacing={2}>
          <AdminSchoolScheduleCycleClassSchedulesCurrentSelection
            sx={{ flex: 1 }}
            option={currentOption}
            clearOption={resetOption}
          />

          <AdminSchoolScheduleCycleClassSchedulesSearch
            sx={{ minWidth: 350 }}
            viewModel={viewModel}
            autoFocus={currentOption == null}
            currentOption={currentOption}
          />
        </Stack>

        {currentOption != null && (
          <>
            {viewKind === 'list' && (
              <AdminSchoolScheduleCycleClassSchedulesList
                scheduleCycleId={scheduleCycleId}
                schoolId={schoolId}
                option={currentOption}
                sx={{ flex: 1, mt: 2, overflow: 'hidden' }}
                filters={{ case: currentOption.case, value: currentOption.id }}
                isReadOnly={isReadOnly}
              />
            )}

            {viewKind === 'grid' && (
              <ScheduleCycleMasterSchedulePage
                filters={{ case: currentOption.case, value: currentOption.id }}
                isReadOnly={isReadOnly}
              />
            )}
          </>
        )}
      </Box>
    );
  }
);
