import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Navigate } from 'react-router';
import LocalizedStrings from 'strings';
import { useServices } from '../hooks';
import { Splash } from './Splash';

export interface NoPlannerPageProps {
  sx?: SxProps;
  className?: string;
}

export const NoPlannerPage = observer(({ sx, className }: NoPlannerPageProps) => {
  const { userStore } = useServices();
  const [isCreatingPlanner, setIsCreatingPlanner] = useState(false);
  const [error, setError] = useState<Error>();
  const strings = LocalizedStrings.planner.noPlanner;

  if (userStore.nonDemoNorTeacherPlanners.length > 0) {
    return <Navigate to="/" replace />;
  }
  async function createPlanner() {
    setIsCreatingPlanner(true);
    setError(undefined);

    try {
      await userStore.createPlanner(PlannerRelationshipKind.INDIVIDUAL, undefined, true);
    } catch (e) {
      setError(e as Error);
    } finally {
      setIsCreatingPlanner(false);
    }
  }

  return (
    <Splash sx={sx} className={className} width="100%" height="100%" contentWidth={600}>
      <Stack spacing={4}>
        <Stack spacing={1}>
          <Typography variant="h6" fontWeight="500" textAlign="center">
            {strings.title()}
          </Typography>

          <Typography textAlign="center">{strings.subtitle()}</Typography>
        </Stack>

        <Stack spacing={2}>
          <LoadingButton variant="contained" onClick={() => void createPlanner()} loading={isCreatingPlanner}>
            {strings.createButtonTitle()}
          </LoadingButton>

          {error != null && (
            <Typography variant="caption" color="error" fontWeight="500">
              {strings.errorMessage()}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Splash>
  );
});
