import { useServices, useViewModel } from '@/hooks';
import { LoadingButton } from '@mui/lab';
import { Box, Divider, Stack, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyText, Dialog } from '../../utils';

export interface TeacherInviteStudentsDialogProps {
  isOpen: boolean;
}

export const TeacherInviteStudentsDialog = observer(({ isOpen }: TeacherInviteStudentsDialogProps) => {
  const { localization } = useServices();
  const navigate = useNavigate();
  const theme = useTheme();
  const params = useParams();
  const schoolId = params.schoolId ?? '';

  function close() {
    navigate(-1);
    return Promise.resolve();
  }

  const viewModel = useViewModel((viewModels) => viewModels.createTeacherInviteStudentsViewModel(schoolId, close));
  const strings = localization.localizedStrings.school;

  return (
    <Dialog
      viewModel={viewModel}
      isOpen={isOpen}
      title={strings.inviteStudentsDialogTitle}
      width="sm"
      contentPadding={{ top: 4, bottom: 4, left: 4, right: 4 }}
      renderData={() => (
        <Stack spacing={4} width="100%">
          <Stack spacing={3} width="100%" alignItems="center">
            <Typography variant="body1" textAlign="center">
              {strings.inviteStudentsMessage}
            </Typography>

            {!viewModel.hasSentInvite && (
              <LoadingButton
                variant="contained"
                sx={{ width: { xs: '100%', sm: '80%' } }}
                size="large"
                loading={viewModel.isSendingInvite}
                disabled={viewModel.hasSentInvite}
                onClick={() => void viewModel.sendInviteToStudents()}
              >
                {strings.inviteStudentsInviteButtonTitle}
              </LoadingButton>
            )}

            {viewModel.hasSentInvite && (
              <Typography
                variant="subtitle2"
                textAlign="center"
                sx={{ color: theme.palette.success.main }}
                color="success"
              >
                {strings.inviteStudentsSuccessMessage}
              </Typography>
            )}

            {viewModel.sendInviteError && (
              <Typography
                variant="subtitle2"
                textAlign="center"
                sx={{ color: theme.palette.error.main }}
                color="success"
              >
                {strings.inviteStudentsErrorMessage}
              </Typography>
            )}
          </Stack>

          <Divider variant="middle" />

          <Stack spacing={2} width="100%" alignItems="center">
            <Typography variant="caption" textAlign="center">
              {strings.inviteStudentsWithCodeMessage}
            </Typography>

            <CopyText text={viewModel.participationCode}>
              <Box ml={6}>
                <Typography variant="h6">{viewModel.participationCode}</Typography>
              </Box>
            </CopyText>
          </Stack>
        </Stack>
      )}
    />
  );
});
