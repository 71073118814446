import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { SearchBar } from '../../utils';

export interface TableHeaderProps {
  sx?: SxProps;
  className?: string;
  title: string;
  subtitle?: string;
  displaySearchBar: boolean;
  searchBarPlaceholder?: string;
  onSearchTextChange?: (searchText: string) => void;
}

export function TableHeader({
  sx,
  className,
  title,
  subtitle,
  displaySearchBar,
  searchBarPlaceholder,
  onSearchTextChange
}: TableHeaderProps) {
  return (
    <Stack
      sx={sx}
      className={className}
      m={1}
      ml={3}
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
    >
      <Stack direction="column" mt={1.5} spacing={0}>
        <Typography variant="body1" fontWeight="500">
          {title}
        </Typography>

        {subtitle && <Typography variant="caption">{subtitle}</Typography>}
      </Stack>

      {displaySearchBar && (
        <SearchBar
          onSearch={(searchText) => onSearchTextChange?.(searchText ?? '')}
          placeholder={searchBarPlaceholder}
          sx={{ width: 420 }}
        />
      )}
    </Stack>
  );
}
