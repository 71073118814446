import { useServices } from '@/hooks';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { Inventory2Rounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, alpha, Box, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { ConfirmationDialog } from '../../utils';

export interface SchoolSettingsArchivedSchoolBannerProps {
  sx?: SxProps;
  className?: string;
  canUnarchive: boolean;
  school: SchoolInformation;
}

export const SchoolSettingsArchivedSchoolBanner = observer(
  ({ sx, school, canUnarchive, className }: SchoolSettingsArchivedSchoolBannerProps) => {
    const { userStore } = useServices();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isUnarchiving, setIsUnarchiving] = useState(false);
    const [hasError, setHasError] = useState(false);

    const theme = useTheme();
    const strings = LocalizedStrings.settings.school.details.archivedBanner;

    async function onConfirmation(hasConfirmed: boolean) {
      setShowConfirmation(false);

      if (hasConfirmed) {
        setHasError(false);
        setIsUnarchiving(true);

        try {
          await userStore.unarchiveSchool(school.school!.id);
        } catch (e) {
          setHasError(true);
        } finally {
          setIsUnarchiving(false);
        }
      }
    }

    return (
      <Box sx={sx} className={className}>
        <Alert
          severity="error"
          variant="outlined"
          icon={<Inventory2Rounded fontSize="inherit" color="inherit" />}
          sx={{ background: alpha(theme.palette.error.main, 0.05) }}
          action={
            canUnarchive ? (
              <LoadingButton
                color="inherit"
                size="small"
                onClick={() => setShowConfirmation(true)}
                loading={isUnarchiving}
              >
                {strings.unarchiveButtonLabel()}
              </LoadingButton>
            ) : undefined
          }
        >
          <AlertTitle>{strings.title()}</AlertTitle>
          {strings.message()}
        </Alert>

        {hasError && (
          <Typography color="error" variant="subtitle2" p={1}>
            {strings.unarchiveError()}
          </Typography>
        )}

        {showConfirmation && (
          <ConfirmationDialog
            isOpen={true}
            title={strings.unarchiveConfirmationTitle()}
            message={strings.unarchiveConfirmationMessage(school.school!.name)}
            confirmButtonLabel={strings.unarchiveConfirmationSubmit()}
            onSubmit={(hasConfirmed) => void onConfirmation(hasConfirmed)}
          />
        )}
      </Box>
    );
  }
);
