import CheckedIcon from '@mui/icons-material/CheckRounded';
import {
  alpha,
  FormControl,
  FormControlLabel,
  FormControlProps,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  styled,
  SvgIcon,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { find } from 'lodash';
import { ChoicePickerOption } from './ChoicePicker';

export interface RadioChoicePickerProps<T> {
  className?: string;
  sx?: SxProps;

  /**
   * The options to display
   */
  options: ChoicePickerOption<T>[];

  /**
   * The selected option. Optional.
   */
  selectedOption?: string;

  /**
   * The label. Optional.
   */
  label?: string | JSX.Element;

  /**
   * Indicates to disable the choice picker. Optional. Default is `false`.
   */
  disabled?: boolean;

  /**
   * Callback when clicking on an option.
   * @param option The clicked option.
   */
  onOptionClick: (option: ChoicePickerOption<T>) => void;

  helperText?: string;
}

export const RadioChoicePicker = <T,>({
  className,
  options,
  selectedOption,
  label,
  disabled,
  onOptionClick,
  sx,
  helperText
}: RadioChoicePickerProps<T>) => {
  const theme = useTheme();
  const isOptionEnabled = (option: ChoicePickerOption<T>) => disabled !== true && option.isEnabled !== false;

  return (
    <Root className={clsx(classes.formControl, className)} sx={sx} focused>
      {label && (
        <FormLabel className={classes.formLabel} sx={{ mb: 3, textAlign: 'center' }}>
          {label}
        </FormLabel>
      )}

      <RadioGroup value={selectedOption ?? ''} onChange={(e) => onOptionClick(find(options, { key: e.target.value })!)}>
        {options.map((option, index) => (
          <FormControlLabel
            key={`radio-choice-${option.key}`}
            className={clsx(
              classes.control,
              isOptionEnabled(option) && classes.controlEnabled,
              selectedOption === option.key && classes.controlChecked
            )}
            sx={{ mb: index < options.length - 1 ? theme.spacing(2) : undefined }}
            classes={{ label: classes.controlLabel }}
            value={option.key}
            label={option.label}
            labelPlacement="start"
            control={<Radio disableRipple icon={<SvgIcon />} checkedIcon={<CheckedIcon />} />}
            disabled={!isOptionEnabled(option)}
          />
        ))}
      </RadioGroup>

      {helperText != null && <FormHelperText sx={{ mt: 1 }}>{helperText}</FormHelperText>}
    </Root>
  );
};

const PREFIX = 'RadioChoicePicker';
const classes = {
  formControl: `${PREFIX}-formControl`,
  formLabel: `${PREFIX}-formLabel`,
  controlLabel: `${PREFIX}-controlLabel`,
  control: `${PREFIX}-control`,
  controlEnabled: `${PREFIX}-controlEnabled`,
  controlChecked: `${PREFIX}-controlChecked`
};

const Root = styled((props: FormControlProps) => <FormControl {...props} />)(({ theme }) => ({
  [`&.${classes.formControl}`]: {
    width: '100%'
  },

  [`& .${classes.formLabel}`]: {
    marginBottom: theme.spacing(2),
    textAlign: 'center'
  },

  [`& .${classes.controlLabel}`]: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    color: theme.palette.text.primary
  },

  [`& .${classes.control}`]: {
    width: '100%',
    marginLeft: 0,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.action.disabledBackground}`
  },

  [`& .${classes.controlEnabled}`]: {
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1)
    }
  },

  [`& .${classes.controlChecked}`]: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.2)
    }
  }
}));
