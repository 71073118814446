import { PlannedWorkEditDayViewModel } from '@/viewmodels';
import { Circle } from '@mui/icons-material';
import { alpha, Box, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { take } from 'lodash';
import { observer } from 'mobx-react-lite';
import { UserDashboardCalendarAnnotationView } from '../../shared';

export interface PlanningWorkEditWeekDayHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannedWorkEditDayViewModel;
}

export const PlanningWorkEditWeekDayHeader = observer(
  ({ sx, className, viewModel }: PlanningWorkEditWeekDayHeaderProps) => {
    const theme = useTheme();
    const dow = format(viewModel.date, 'EEE');
    const annotations = take(viewModel.annotations, 2);

    return (
      <Stack
        sx={{ ...sx, backgroundColor: viewModel.isToday ? alpha(theme.palette.error.main, 0.1) : undefined }}
        className={className}
        justifyContent="flex-start"
      >
        <Box
          p={0.5}
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          position="relative"
        >
          <Typography variant="caption" color="textSecondary">
            {viewModel.cycleDayName.length > 0 ? `${dow}  (${viewModel.cycleDayName})` : dow}
          </Typography>

          <Stack direction="row" spacing={0.5} alignItems="center">
            {viewModel.isToday && <Circle sx={{ fontSize: theme.typography.caption.fontSize }} color="error" />}

            <Typography variant="caption" fontWeight="600">
              {format(viewModel.date, 'd MMM')}
            </Typography>
          </Stack>
        </Box>

        {annotations.length > 0 && (
          <Stack px="2px" pb="2px" spacing="2px">
            {annotations.map((a) => (
              <UserDashboardCalendarAnnotationView key={a.id} annotation={a} />
            ))}
          </Stack>
        )}
      </Stack>
    );
  }
);
