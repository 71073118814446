import { useActivePlannerId, useServices } from '@/hooks';
import { isAdminOfSharedSchool } from '@/models';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { EditRounded, Inventory2Rounded } from '@mui/icons-material';
import { Checkbox, IconButton, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'strings';

export interface PlannerSchoolsListItemProps {
  sx?: SxProps;
  className?: string;
  isReadOnly: boolean;
  school: SchoolInformation;
}

export const PlannerSchoolsListItem = observer(({ sx, className, school, isReadOnly }: PlannerSchoolsListItemProps) => {
  const { plannerStore, route, userStore, storeInvalidator } = useServices();
  const userId = userStore.user.userId;
  const plannerId = useActivePlannerId();
  const planner = userStore.getPlannerForId(plannerId)!;
  const strings = LocalizedStrings.settings.school.list;
  const owners = school.owners
    .map((o) => (o.userId === userId ? `${o.fullName} (${strings.schoolOwnedByCurrentUser()})` : o.fullName))
    .join(',');

  const [isLinkingSchool, setIsLinkingSchool] = useState(false);
  const [hasLinkingError, setHasLinkingError] = useState(false);

  const schoolId = school.school!.id;
  const isLinkedToPlanner = planner.schoolIds.includes(schoolId) && !planner.ignoredSchoolIds.includes(schoolId);
  const isArchived = school.school!.isArchived;

  const detailsUrl = isAdminOfSharedSchool(userId, school)
    ? route.resolveAdminSchoolInformationLocation(schoolId)
    : route.resolvePlannerSettingsLocation(`schools/${schoolId}`, plannerId);

  const onLinkSchoolButtonClick = async () => {
    if (school == null) {
      return;
    }

    setIsLinkingSchool(true);
    setHasLinkingError(false);

    try {
      await (!isLinkedToPlanner
        ? userStore.attachSchoolToPlanner(schoolId, plannerId, true)
        : userStore.ignoreSchoolInPlanner(plannerId, schoolId, true));
      storeInvalidator.invalidateCalendar();
      void plannerStore.getCourseSectionsInPlanner(plannerId).fetch(true);
    } catch {
      setHasLinkingError(true);
    } finally {
      setIsLinkingSchool(false);
    }
  };

  return (
    <ListItem
      sx={sx}
      className={className}
      secondaryAction={
        <IconButton disabled={isLinkingSchool} component={Link} to={detailsUrl}>
          <EditRounded />
        </IconButton>
      }
    >
      <ListItemIcon>
        {!isArchived ? (
          <Tooltip title={isLinkedToPlanner ? strings.visibleSchool() : strings.hiddenSchool()} disableInteractive>
            <Checkbox
              disabled={isReadOnly || isLinkingSchool}
              checked={isLinkedToPlanner}
              onChange={() => void onLinkSchoolButtonClick()}
            />
          </Tooltip>
        ) : (
          <Tooltip title={strings.archivedSchoolTooltip()}>
            <Inventory2Rounded sx={{ m: 1 }} color="error" />
          </Tooltip>
        )}
      </ListItemIcon>

      <Stack>
        <ListItemText
          primary={school.school!.name}
          secondary={school.school!.subtitle}
          primaryTypographyProps={{ color: isArchived ? 'textSecondary' : undefined }}
        />

        <Typography variant="subtitle2" color="textSecondary">
          {strings.schoolOwners(owners)}
        </Typography>

        {hasLinkingError && (
          <Typography variant="caption" color="error" fontWeight="500">
            {isLinkedToPlanner ? strings.hideSchoolErrorMessage() : strings.showSchoolErrorMessage()}
          </Typography>
        )}
      </Stack>
    </ListItem>
  );
});
