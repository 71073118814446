import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import {
  PlannerDataStore,
  PlannerParticipationCodeLoadable,
  SchoolCourseSectionsLoadable,
  SchoolDataStore,
  mergeLoadableStates
} from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { computed, makeObservable, observable, runInAction } from 'mobx';
import { UpdatableViewModelState } from '../shared';
import { AppBaseUpdatableDialogViewModel, DialogActionButtonConfiguration, UpdatableDialogViewModel } from '../utils';

export interface TeacherInviteStudentsViewModel extends UpdatableDialogViewModel {
  readonly participationCode: string;
  readonly isSendingInvite: boolean;
  readonly hasSentInvite: boolean;
  readonly sendInviteError: string | undefined;
  sendInviteToStudents(): Promise<void>;
}

export class AppTeacherInviteStudentsViewModel
  extends AppBaseUpdatableDialogViewModel
  implements TeacherInviteStudentsViewModel
{
  @observable private _isSendingInvite = false;
  @observable private _hasSentInvite = false;
  @observable private _sendInviteError: string | undefined;

  constructor(
    private readonly _schoolId: string,
    onDismiss: () => Promise<void>,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(localization, onDismiss);
    makeObservable(this);
  }

  @computed
  private get teacherParticipationCodeLoadable(): PlannerParticipationCodeLoadable {
    return this._plannerStore.getTeacherParticipationCode();
  }

  @computed
  private get courseSectionsLoadable(): SchoolCourseSectionsLoadable {
    return this._schoolStore.getCourseSections(this._schoolId);
  }

  @computed
  private get missingAuthorizationStudentPlannerIds(): string[] {
    return this.courseSectionsLoadable.values.reduce<string[]>((plannerIds, courseSection) => {
      courseSection.details?.students.forEach((student) => {
        if (student.plannerId && !student.isPlannerAccessAuthorized) {
          plannerIds.push(student.plannerId);
        }
      });

      return plannerIds;
    }, []);
  }

  get supplementaryActions(): DialogActionButtonConfiguration[] {
    return [];
  }

  @computed
  get participationCode(): string {
    return this.teacherParticipationCodeLoadable.data;
  }

  readonly hasChanges = false;

  @computed
  get hasData(): boolean {
    return this.teacherParticipationCodeLoadable.hasData && this.courseSectionsLoadable.hasData;
  }

  @computed
  get state(): UpdatableViewModelState {
    return mergeLoadableStates([this.teacherParticipationCodeLoadable.state, this.courseSectionsLoadable.state]);
  }

  @computed
  get isSendingInvite(): boolean {
    return this._isSendingInvite;
  }

  @computed
  get hasSentInvite(): boolean {
    return this._hasSentInvite;
  }

  @computed
  get sendInviteError(): string | undefined {
    return this._sendInviteError;
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSendingInvite;
  }

  async sendInviteToStudents() {
    if (this._hasSentInvite) {
      return;
    }

    runInAction(() => {
      this._isSendingInvite = true;
      this._sendInviteError = undefined;
    });

    try {
      const plannerIds = this.missingAuthorizationStudentPlannerIds;

      // Not sending request if there is no planner ids. Still saying to the user that the invite was sent.
      // Might be confusing for the user if we tried to explain.
      if (plannerIds.length > 0) {
        await this._plannerStore.requestPlannerAccess(plannerIds, AccessKind.OBSERVER, PlannerRelationshipKind.TEACHER);
      }

      runInAction(() => {
        this._isSendingInvite = false;
        this._hasSentInvite = true;
      });
    } catch (e) {
      const error = e as Error;
      runInAction(() => {
        this._isSendingInvite = false;
        this._sendInviteError = error.message;
      });
    }
  }

  async reloadData(): Promise<void> {
    await Promise.all([this.teacherParticipationCodeLoadable.fetch(true), this.courseSectionsLoadable.fetch(false)]);
  }
}
