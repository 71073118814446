import { useActivePlannerId, useServices } from '@/hooks';
import { Box, Link, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router';
import { Link as RouterLink, useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { isAdminOfSharedSchool } from '../../../models';
import { SchoolSettings } from './SchoolSettings';
import { SchoolSettingsHeader } from './SchoolSettingsHeader';
import { SchoolSettingsNotFound } from './SchoolSettingsNotFound';

export interface SchoolSettingsPageProps {
  sx?: SxProps;
  className?: string;
}

export const SchoolSettingsPage = observer(({ sx, className }: SchoolSettingsPageProps) => {
  const { route, userStore } = useServices();
  const plannerId = useActivePlannerId();
  const userId = userStore.user.userId;

  const allSchoolsUrl = route.resolvePlannerSettingsLocation('schools', plannerId);
  const strings = LocalizedStrings.settings.school.details;

  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const school = userStore.getSchoolForId(schoolId);

  if (school != null && isAdminOfSharedSchool(userId, school)) {
    return <Navigate to={route.resolveAdminSchoolInformationLocation(schoolId)} replace />;
  }

  return (
    <Box sx={sx} className={className} height="100%" overflow="hidden">
      <Stack sx={sx} className={className} spacing={school != null ? 1 : 4} height="100%" overflow="hidden">
        <Stack spacing={0.5}>
          <Link underline="hover" component={RouterLink} to={allSchoolsUrl} variant="subtitle2" color="textSecondary">
            ← {strings.backButtonTitle()}
          </Link>

          <SchoolSettingsHeader school={school} />
        </Stack>

        {school != null ? (
          <SchoolSettings school={school} sx={{ flex: 1, flexShrink: 0 }} />
        ) : (
          <SchoolSettingsNotFound />
        )}
      </Stack>
    </Box>
  );
});
