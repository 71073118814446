import { useServices } from '@/hooks';
import { dayToDate } from '@/models';
import { Width } from '@/utils';
import { StudentInsightsDailyActivityViewModel } from '@/viewmodels';
import { TimelineRounded } from '@mui/icons-material';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { EmptyListIndicator, XYChart } from '../../../utils';

export interface StudentActivityChartProps {
  className?: string;
  viewModel: StudentInsightsDailyActivityViewModel;
  /**
   * The greatest width that is considered small. Used to determine if we use the desktop or mobile view.
   */
  smallScreenWidth: Width;
}

export const StudentActivityChart = observer(
  ({ className, viewModel, smallScreenWidth }: StudentActivityChartProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.schoolCourseSection;

    const isDesktop = useMediaQuery((th: Theme) => th.breakpoints.up(smallScreenWidth));

    return (
      <Box className={className}>
        {viewModel.userActivity.length == 0 ? (
          <Box m={4} display="flex" alignItems="center" justifyContent="center">
            <EmptyListIndicator
              renderIcon={(className) => <TimelineRounded className={className} />}
              title={strings.studentsInsightsNoActivityIndicatorTitle}
              subtitle={strings.studentsInsightsNoActivityIndicatorSubtitle}
            />
          </Box>
        ) : (
          <XYChart
            height={200}
            data={viewModel.userActivity}
            xAxis={{
              dataKey: 'date',
              kind: 'date',
              getValue: (item) => dayToDate(item.date),
              zoomOptions: { minZoomCount: isDesktop ? 14 : 5 }
            }}
            yAxis={{ dataKey: 'value', kind: 'value', minValue: 0 }}
            title={strings.studentsInsightsChartActiveMinutesLabel}
            titleAlignment={isDesktop ? undefined : 'center'}
            kind="bar"
          />
        )}
      </Box>
    );
  }
);
