import { useServices } from '@/hooks';
import { formatSharingCode } from '@/utils';
import { UseSharingInvitationCodeStepViewModel } from '@/viewmodels';
import { ArrowBackRounded } from '@mui/icons-material';
import { Box, Button, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { ConfirmationDialog, MaskedTextField } from '../utils';
import { OnboardingStepActions } from './OnboardingStepActions';

export interface UseSharingInvitationCodeStepProps {
  className?: string;
  viewModel: UseSharingInvitationCodeStepViewModel;
}

export const UseSharingInvitationCodeStep = observer(({ className, viewModel }: UseSharingInvitationCodeStepProps) => {
  const { localization, analytics } = useServices();
  const { onboarding: onboardingStrings, sharingInvitationCode: sharingInvitationCodeStrings } =
    localization.localizedStrings;
  const theme = useTheme();

  useEffect(() => analytics.logScreenView_Onboarding_UseCode(), []);

  return (
    <Box className={className} display="flex" flexDirection="column" alignItems="center">
      <Typography variant="h6">{onboardingStrings.useSharingInvitationCodeHeader}</Typography>
      <MaskedTextField
        style={{ width: '100%', marginTop: theme.spacing(4) }}
        label={sharingInvitationCodeStrings.useCodeFieldLabel}
        value={viewModel.sharingInvitationCode}
        required
        disabled={viewModel.isApplying}
        autoFocus
        onChange={(value) => (viewModel.sharingInvitationCode = value)}
        formatValue={formatSharingCode}
      />

      {viewModel.showParticipationCodeAlert && (
        <ConfirmationDialog
          isOpen={true}
          title={sharingInvitationCodeStrings.participationCodeConfirmationAlertTitle}
          message={sharingInvitationCodeStrings.participationCodeConfirmationAlertMessage(
            viewModel.validationResponse?.senderDisplayName
          )}
          confirmButtonLabel={sharingInvitationCodeStrings.participationCodeConfirmationAlertConfirmButtonTitle}
          onSubmit={(result) => void viewModel.dismissParticipationCodeAlert(result)}
        />
      )}

      {/* Error message */}
      {viewModel.error != null && (
        <Typography mx={2} mt={3} mb={0} variant="caption" color="error" align="center">
          {viewModel.error}
        </Typography>
      )}

      {/*<Stack spacing={2}>*/}
      <OnboardingStepActions
        mt={4}
        viewModel={viewModel}
        additionalActions={() => (
          <Button startIcon={<ArrowBackRounded />} onClick={() => viewModel.goBack()}>
            {onboardingStrings.backButtonLabel}
          </Button>
        )}
      />
      {/*</Stack>*/}
    </Box>
  );
});
