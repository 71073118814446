import { UserDashboardCalendarWeekAllDayItemInfo } from '@/viewmodels';
import { Box, CardActionArea, Popover, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../../lists';
import { Subheader } from '../../../utils';
import { UserDashboardCalendarAllDayCalendarEvent, UserDashboardCalendarAnnotationView } from '../shared';
import { UserDashboardCalendarWeekWork } from './items';

export interface UserDashboardCalendarWeekDayHeaderMoreProps {
  sx?: SxProps;
  className?: string;
  moreItems: UserDashboardCalendarWeekAllDayItemInfo[];
}

export const UserDashboardCalendarWeekDayHeaderMore = observer(
  ({ sx, className, moreItems }: UserDashboardCalendarWeekDayHeaderMoreProps) => {
    // Only showing a maximum of 3 colors.
    const colors = moreItems.slice(0, 3).map((i) => i.value.color);
    const [popoverRef, setPopoverRef] = useState<HTMLButtonElement | undefined>();
    const strings = LocalizedStrings.calendar.week;

    return (
      <Box sx={sx} className={className}>
        <CardActionArea sx={{ borderRadius: 0.5, px: 0.5 }} onClick={(e) => setPopoverRef(e.currentTarget)}>
          <Stack direction="row" alignItems="center" spacing={0.5}>
            <Stack height={14} direction="row" spacing="2px">
              {colors.map((color) => (
                <ColorIndicator key="index" color={color} isRounded />
              ))}
            </Stack>

            <Typography variant="caption" color="textSecondary">
              {strings.allDayMoreItems(moreItems.length)}
            </Typography>
          </Stack>
        </CardActionArea>

        <Popover
          open={popoverRef != null}
          anchorEl={popoverRef}
          onClose={() => setPopoverRef(undefined)}
          slotProps={{ paper: { sx: { width: 320 } } }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Stack p={2} pt={0}>
            <Subheader disableGutters>{strings.allDayMorePopoverTitle()}</Subheader>
            {moreItems.map((item) => renderItem(item))}
          </Stack>
        </Popover>
      </Box>
    );
  }
);

function renderItem(item: UserDashboardCalendarWeekAllDayItemInfo) {
  switch (item.case) {
    case 'annotation':
      return <UserDashboardCalendarAnnotationView key={item.value.id} annotation={item.value} />;
    case 'calendarEvent':
      return <UserDashboardCalendarAllDayCalendarEvent key={item.value.id} event={item.value} />;
    case 'work':
    case 'publishedWork':
      return <UserDashboardCalendarWeekWork key={item.value.id} work={item} showBorder={false} />;
    default:
      return null;
  }
}
