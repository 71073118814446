import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ConnectedAppViewModel } from '../../viewmodels/connectedApps/ConnectedAppViewModel';
import { ConnectedApp } from './ConnectedApp';
import { ConnectedAppsSectionHeader } from './ConnectedAppsSectionHeader';

export interface ConnectedAppsSectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: ConnectedAppViewModel;
}

export const ConnectedAppsSection = observer(({ sx, className, viewModel }: ConnectedAppsSectionProps) => {
  const { userDashboards } = viewModel;
  const hasManyDashboard = userDashboards.length > 1;

  return (
    <Box sx={sx} className={className}>
      {hasManyDashboard && <ConnectedAppsSectionHeader kind={viewModel.kind} />}

      {userDashboards.map((dashboard) => (
        <ConnectedApp key={dashboard.userDashboardInfo.id} viewModel={dashboard} isHeader={!hasManyDashboard} />
      ))}
    </Box>
  );
});
