import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { CSSProperties } from 'react';

export interface LoadingIndicatorProps {
  className?: string;
  style?: CSSProperties;
  sx?: SxProps;
  /**
   * The loading message. Optional.
   */
  message?: string;
  /**
   * The size. Optional. Default is `normal`.
   */
  size?: 'tiny' | 'small' | 'normal';
  /**
   * The circular progress color. Optional. Default is `primary`.
   */
  color?: 'primary' | 'secondary' | 'inherit';
}

export const LoadingIndicator = ({
  className,
  style,
  message,
  size = 'normal',
  color = 'primary',
  sx
}: LoadingIndicatorProps) => {
  const theme = useTheme();
  const isNormal = size === 'normal';
  const isTiny = size === 'tiny';

  const progressSize = isNormal ? 40 : isTiny ? 24 : 30;
  const messageVariant = isNormal ? 'h6' : 'body1';

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      className={className}
      style={style}
      sx={sx}
    >
      <CircularProgress color={color} size={progressSize} />

      {message != null && message.length > 0 && (
        <Box marginTop={2}>
          <Typography
            color="textSecondary"
            variant={messageVariant}
            textAlign="center"
            sx={{ fontWeight: theme.typography.fontWeightLight }}
          >
            {message}
          </Typography>
        </Box>
      )}
    </Box>
  );
};
