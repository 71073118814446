import { useMediaQuery, useTheme } from '@mui/material';
import { reaction } from 'mobx';
import { useEffect, useState } from 'react';
import { LightMuiThemeAppBarColor } from '../resources/themes';
import { useServices } from './UseServicesHook';

export interface AppBarColors {
  readonly backgroundColor: string | undefined;
  readonly textColor: string | undefined;
}

export function useAppBarColors(supportsBackgroundColor: boolean): AppBarColors {
  const { settings } = useServices();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(() => theme.breakpoints.down('sm'));

  const makeColor = () => {
    const customAccentColor = settings.useCustomPrimaryColor ? settings.customPrimaryColor : undefined;
    const defaultBackgroundColor =
      theme.palette.mode === 'light' ? customAccentColor ?? LightMuiThemeAppBarColor : undefined;

    const backgroundColor =
      supportsBackgroundColor && !isSmallScreen && settings.backgroundImage?.color != null
        ? settings.backgroundImage?.color
        : defaultBackgroundColor;

    const textColor = backgroundColor != null ? theme.palette.getContrastText(backgroundColor) : undefined;
    return { backgroundColor, textColor } as AppBarColors;
  };

  const [colors, setColors] = useState<AppBarColors>(makeColor());

  useEffect(() => {
    // No need to observe `useCustomPrimaryColor` and `customPrimaryColor` as the theme
    // is changed when these values change.
    return reaction(
      () => [settings.backgroundImage],
      () => setColors(makeColor())
    );
  }, []);

  useEffect(() => {
    setColors(makeColor());
  }, [theme, supportsBackgroundColor, isSmallScreen]);

  return colors;
}
