import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactElement, ReactNode } from 'react';

export interface WorkDetailsCardHeaderProps {
  sx?: SxProps;
  className?: string;
  icon: ReactElement;
  title: string;
  subtitle: string;
  rightElement?: () => ReactNode;
}

export const WorkDetailsCardHeader = observer(
  ({ sx, className, icon, subtitle, rightElement, title }: WorkDetailsCardHeaderProps) => {
    return (
      <Stack sx={sx} className={className} direction="row" spacing={1.5} alignItems="flex-start">
        <Box pt={0.5}>{icon}</Box>

        <Stack flex={1}>
          <Typography variant="subtitle2">{title}</Typography>
          <Typography variant="caption" color="textSecondary">
            {subtitle}
          </Typography>
        </Stack>

        {rightElement?.()}
      </Stack>
    );
  }
);
