import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { CheckRounded } from '@mui/icons-material';
import { ListItemText, MenuItem } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useServices } from '../../../hooks';
import { plannedWorkDateLabel } from '../../../models';

export interface WorkStepDateSelectionControlPlannedWorkItemProps {
  sx?: SxProps;
  className?: string;
  plannedWork: PlannedWork;
  isSelected: boolean;
  isCompleted: boolean;
  onSelect: (plannedWork: PlannedWork) => void;
}

export const WorkStepDateSelectionControlPlannedWorkItem = observer(
  ({
    sx,
    className,
    onSelect,
    isSelected,
    plannedWork,
    isCompleted
  }: WorkStepDateSelectionControlPlannedWorkItemProps) => {
    const { dateService, localization } = useServices();
    const startTime = plannedWork.timeSlot!.startTime!.toDate();

    return (
      <MenuItem sx={sx} className={className} onClick={() => onSelect(plannedWork)}>
        <ListItemText
          primary={plannedWorkDateLabel(plannedWork, dateService, localization.localizedStrings)}
          primaryTypographyProps={{ color: isCompleted ? 'textSecondary' : undefined }}
          secondary={format(startTime, 'p')}
        />

        {isSelected && <CheckRounded fontSize="small" color="primary" />}
      </MenuItem>
    );
  }
);
