import { UserDashboardCalendarMonthDayItemInfo } from '@/viewmodels';
import { Box, CardActionArea, Popover, Stack, Theme, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../../lists';
import { Subheader } from '../../../utils';
import { renderUserDashboardCalendarMonthItem } from './UserDashboardCalendarMonthUtils';

export interface UserDashboardCalendarMonthDayMoreItemsProps {
  sx?: SxProps;
  className?: string;
  items: UserDashboardCalendarMonthDayItemInfo[];
  isAllItems: boolean;
}

export const UserDashboardCalendarMonthDayMoreItems = observer(
  ({ sx, className, items, isAllItems }: UserDashboardCalendarMonthDayMoreItemsProps) => {
    const theme = useTheme();
    // Only showing a maximum of 3 colors.
    const colors = items.map((item) => getColorForItem(item, theme)).slice(0, 3);
    const strings = LocalizedStrings.calendar.month;
    const [moreItemsPopoverRef, setMoreItemsPopoverRef] = useState<HTMLButtonElement | undefined>();

    return (
      <Box className={className} sx={sx}>
        <CardActionArea
          sx={{
            borderRadius: 0.5,
            px: 0.5,
            py: '2px'
          }}
          onClick={(e) => setMoreItemsPopoverRef(e.currentTarget)}
        >
          <Stack direction="row" alignItems="center" spacing={0.5}>
            {colors.length > 0 && (
              <Stack direction="row" height={14} spacing="2px">
                {colors.map((color, index) => (
                  <ColorIndicator key={index} color={color} isRounded />
                ))}
              </Stack>
            )}

            <Typography fontSize={12} noWrap flex={1} color="textSecondary">
              {isAllItems ? strings.dayItems(items.length) : strings.dayMoreItems(items.length)}
            </Typography>
          </Stack>
        </CardActionArea>

        <Popover
          open={moreItemsPopoverRef != null}
          anchorEl={moreItemsPopoverRef}
          onClose={() => setMoreItemsPopoverRef(undefined)}
          slotProps={{ paper: { sx: { width: 320 } } }}
          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          transformOrigin={{ horizontal: 'center', vertical: 'top' }}
        >
          <Stack p={2} pt={0}>
            <Subheader disableGutters>{strings.dayMorePopoverTitle()}</Subheader>
            <Stack spacing={0.5}>{items.map(renderUserDashboardCalendarMonthItem)}</Stack>
          </Stack>
        </Popover>
      </Box>
    );
  }
);

function getColorForItem(item: UserDashboardCalendarMonthDayItemInfo, theme: Theme): string {
  switch (item.case) {
    case 'period':
      return item.value.courseSection?.courseSection?.color ?? theme.palette.action.disabled;
    default:
      return item.value.color;
  }
}
