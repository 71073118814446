import { ListSection } from '@/models';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { Observer, observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { ListChildComponentProps, VariableSizeList } from 'react-window';
import { ListRow, Row } from './ListRow';

export interface ListProps {
  sx?: SxProps;
  style?: CSSProperties;
  className?: string;
  /**
   * The sections to display.
   */
  sections: ListSection[];
  /**
   * Index of the row to scroll to. Default is 0.
   */
  scrollToIndex?: number;

  keyForItem: (section: number, row: number) => string;

  /**
   * Specifies a row height. Defaults to 72.
   */
  rowHeight?: number;

  /**
   * Allow to get a specific row height for a certain item. Defaults to use `rowHeight`.
   * @param section The section's index
   * @param row The row's index in the section
   */
  rowHeightForItem?: (section: number, row: number) => number | undefined;

  /**
   * Render section's row
   * @param section The section's index
   * @param row The row's index in the section
   */
  renderItem: (section: number, row: number) => JSX.Element;

  /**
   * Render when there are no rows in all sections. Optional.
   */
  renderEmptyIndicator?: () => JSX.Element;

  /**
   * Hides the divider between each row. Defaults to false.
   */
  hideDivider?: boolean;
  bottomPadding?: number;
}

export const List = observer(
  ({
    sx,
    style,
    className,
    sections,
    renderEmptyIndicator,
    renderItem,
    rowHeight,
    rowHeightForItem,
    keyForItem,
    scrollToIndex = 0,
    hideDivider = false,
    bottomPadding = 0
  }: ListProps) => {
    const listRef = useRef<VariableSizeList>(null);

    useEffect(() => {
      if (scrollToIndex != null) {
        listRef.current?.scrollToItem(scrollToIndex, 'start');
      }
    }, [listRef.current]);

    // Force refresh the list since otherwise the items size won't be updated
    useEffect(() => listRef.current?.resetAfterIndex(0));

    return (
      <Box sx={sx} style={style} className={className}>
        <AutoSizer>
          {(size) => (
            <Observer>
              {() => {
                const numberOfRows = getNumberOfRows(sections);

                if (numberOfRows === 0) {
                  return (
                    <Box
                      sx={{ ...size }}
                      flexDirection="column"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      {renderEmptyIndicator?.()}
                    </Box>
                  );
                }

                return (
                  <VariableSizeList
                    itemKey={(index) => getKeyForIndex(index, sections, keyForItem)}
                    height={size.height ?? 0}
                    width={size.width ?? 0}
                    itemCount={numberOfRows + 1}
                    itemData={{ sections, renderItem, hideDivider, numberOfRows } as ItemRendererData}
                    itemSize={(index) =>
                      getRowHeightAtIndex(index, sections, numberOfRows, bottomPadding, rowHeight, rowHeightForItem)
                    }
                    ref={listRef}
                  >
                    {ItemRenderer}
                  </VariableSizeList>
                );
              }}
            </Observer>
          )}
        </AutoSizer>
      </Box>
    );
  }
);

/**
 * Get the number of rows in sections until `endRow`. If not specified, return the number of rows of all sections.
 * @param sections The list sections.
 * @param endRow The row until which to calculate the number of rows. Optional.
 */
const getNumberOfRows = (sections: ListSection[], endRow?: Row) => {
  let numberOfRows = 0;

  sections.forEach((section, index) => {
    if (endRow != null && index > endRow.section) {
      return false;
    }

    if (section.title != null) {
      numberOfRows++;
    }

    if (endRow != null && index === endRow.section) {
      numberOfRows += endRow.index! + 1;
    } else {
      numberOfRows += section.numberOfRows;
    }
  });

  return numberOfRows;
};

interface ItemRendererData {
  sections: ListSection[];
  renderItem: (section: number, row: number) => JSX.Element;
  hideDivider?: boolean;
  numberOfRows: number;
}

const ItemRenderer = (props: ListChildComponentProps) => {
  const { renderItem, hideDivider, sections, numberOfRows } = props.data as ItemRendererData;

  if (props.index === numberOfRows) {
    return <div {...props} />;
  }

  return (
    <ListRow
      {...props}
      renderItem={renderItem}
      section={getSectionForIndex(props.index, sections)}
      row={getRowForIndex(props.index, sections)}
      hideDivider={hideDivider}
    />
  );
};

const getRowHeightAtIndex = (
  index: number,
  sections: ListSection[],
  numberOfRows: number,
  bottomPadding: number,
  customRowHeight: number | undefined,
  customRowHeightForItem: ((section: number, row: number) => number | undefined) | undefined
) => {
  if (index === numberOfRows) {
    return bottomPadding;
  }
  const row = getRowForIndex(index, sections);

  // The values come from inspecting the dom of the Material-UI page.
  // We normally shouldn't have to hardcode the height values, but react-window
  // needs to know the height of each row. So, we need to live with it for now ¯\_(ツ)_/¯.
  if (row == null) {
    return 0;
  } else if (row.index == null) {
    // Note: The real value is 48, but we want to reduce the bottom margin. Because the
    //       ListSubheader works with a line height, it's simpler to just have the next item
    //       overlap a little.
    return 36;
  } else {
    return customRowHeightForItem?.(row.section, row.index) ?? customRowHeight ?? 72;
  }
};

/**
 * Get a row at index.
 * @param index The index of the row.
 * @param sections The list sections.
 * @param ignoreHeader Indicates to ignore the section header in the calculation. Optional.
 */
const getRowForIndex = (index: number, sections: ListSection[], ignoreHeader?: boolean): Row | undefined => {
  let previousRow = 0;
  let currentRow = 0;

  for (let i = 0; i < sections.length; i++) {
    const section = sections[i];
    // Header + elements of section + footer;
    if (section.title != null && !ignoreHeader) {
      currentRow++;
    }

    currentRow += section.numberOfRows;

    // Row is in section.
    if (currentRow > index) {
      // Header row
      if (index === previousRow && section.title != null && !ignoreHeader) {
        return { section: i, index: undefined };
      } else {
        /* An element in section */
        let elementIndex = index - previousRow;

        // If section has a title, we need to remove its index
        if (section.title != null && !ignoreHeader) {
          elementIndex--;
        }

        return { section: i, index: elementIndex };
      }
    }

    previousRow = currentRow;
  }

  return undefined;
};

const getSectionForIndex = (index: number, sections: ListSection[]): ListSection | undefined => {
  const row = getRowForIndex(index, sections);
  return row != null ? sections[row.section] : undefined;
};

const getKeyForIndex = (
  index: number,
  sections: ListSection[],
  keyForItem: (section: number, row: number) => string
): string => {
  const row = getRowForIndex(index, sections);
  if (row == null) {
    return String(index);
  } else if (row.index == null) {
    const section = getSectionForIndex(index, sections);
    return section?.id ?? String(index);
  } else {
    return keyForItem(row.section, row.index);
  }
};
