import { useServices } from '@/hooks';
import { SharePlannerStepViewModel } from '@/viewmodels';
import { Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { SharePlanner } from '../planner';
import { OnboardingStepActions } from './OnboardingStepActions';

export interface SharePlannerStepProps {
  className?: string;
  viewModel: SharePlannerStepViewModel;
}

export const SharePlannerStep = observer(({ className, viewModel }: SharePlannerStepProps) => {
  const { localization, analytics } = useServices();
  const strings = localization.localizedStrings.onboarding;

  useEffect(() => analytics.logScreenView_Onboarding_SharePlanner(), []);

  return (
    <Stack className={className} spacing={3} alignItems="center">
      <Typography variant="h6">{strings.sharePlannerSubtitle(viewModel.userPersona)}</Typography>

      <SharePlanner viewModel={viewModel.sharePlannerViewModel} />

      <OnboardingStepActions viewModel={viewModel} />
    </Stack>
  );
});
