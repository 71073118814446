import { useServices } from '@/hooks';
import { TimeOfDay } from '@/models';
import {
  ScheduleCycleActivitySchedulesDayColumnInfo,
  ScheduleCyclePeriodSchedulePeriodEditViewModel,
  ScheduleCyclePeriodSchedulesGridViewModel
} from '@/viewmodels';
import { Box, Divider, Stack, Typography, alpha, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Fragment, MouseEvent, UIEvent, useLayoutEffect, useRef, useState } from 'react';
import { ContentCard } from '../../../ContentCard';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';
import { ScheduleCyclePeriodScheduleGridDayColumn } from './ScheduleCyclePeriodScheduleGridDayColumn';
import { ScheduleCyclePeriodScheduleGridHeaderRow } from './ScheduleCyclePeriodScheduleGridHeaderRow';
import { ScheduleCyclePeriodScheduleGridNavigationButton } from './ScheduleCyclePeriodScheduleGridNavigationButton';
import { ScheduleCyclePeriodScheduleGridTimesColumn } from './ScheduleCyclePeriodScheduleGridTimesColumn';

export interface ScheduleCyclePeriodScheduleGridProps {
  sx?: SxProps;
  className?: string;
  viewModel: ScheduleCyclePeriodSchedulesGridViewModel;
  supportsActivitySchedules: boolean;
  onColumnHeaderClick?: (column: ScheduleCycleActivitySchedulesDayColumnInfo, event: MouseEvent<HTMLElement>) => void;
  isReadOnly?: boolean;
}

export const ScheduleCyclePeriodScheduleGrid = observer(
  ({
    sx,
    className,
    viewModel,
    supportsActivitySchedules,
    onColumnHeaderClick,
    isReadOnly = false
  }: ScheduleCyclePeriodScheduleGridProps) => {
    const theme = useTheme();
    const { settingsStorage } = useServices();
    const { columns } = viewModel;
    const gridRef = useRef<HTMLDivElement>(null);
    const hasScrolledInitially = useRef(false);
    const [showScrollRightButton, setShowScrollRightButton] = useState(false);
    const [showScrollLeftButton, setShowScrollLeftButton] = useState(false);

    function scroll(direction: 'left' | 'right') {
      gridRef.current?.scrollBy({ left: direction === 'left' ? -400 : 400, top: 0, behavior: 'smooth' });
    }

    function onScroll(e: UIEvent<HTMLDivElement>) {
      hideOrShowNavigationButtons(e.currentTarget);
    }

    // After first layout, we hide/show navigation buttons
    useLayoutEffect(() => {
      const grid = gridRef.current;
      if (grid != null && !hasScrolledInitially.current) {
        hideOrShowNavigationButtons(grid);
        grid.scrollTo({ top: ScheduleCyclePeriodScheduleGridConstants.pointsPerHour * 7 - 10 });
        hasScrolledInitially.current = true;
      }
    }, [columns]);

    function hideOrShowNavigationButtons(grid: HTMLDivElement) {
      setShowScrollRightButton(grid.scrollLeft < grid.scrollWidth - grid.offsetWidth);
      setShowScrollLeftButton(grid.scrollLeft > 0);
    }

    async function getEditViewModel(
      columnIndex: number,
      periodId: string | undefined,
      time: TimeOfDay
    ): Promise<ScheduleCyclePeriodSchedulePeriodEditViewModel> {
      return await viewModel.createPeriodEditViewModel(columnIndex, periodId, time);
    }

    return (
      <Box
        position="relative"
        height="100%"
        width="100%"
        display="flex"
        justifyContent="center"
        sx={{
          ...sx,
          overflow: 'hidden',
          maxWidth:
            ScheduleCyclePeriodScheduleGridConstants.timesColumnWidth +
            columns.length * (ScheduleCyclePeriodScheduleGridConstants.dayColumnWidth + 1) +
            1 +
            18
        }}
        className={className}
      >
        <ContentCard
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            overflow: 'auto',
            position: 'relative'
          }}
          forcedBorderColor={theme.palette.divider}
          forcedBackgroundColor={alpha(theme.palette.background.paper, 0)}
          ref={gridRef}
          onScroll={onScroll}
        >
          <Stack flex={1} position="absolute">
            <ScheduleCyclePeriodScheduleGridHeaderRow
              columns={columns}
              onColumnHeaderClick={onColumnHeaderClick}
              isReadOnly={isReadOnly || !viewModel.canCreatePeriods}
            />

            <Stack flex={1} direction="row">
              <ScheduleCyclePeriodScheduleGridTimesColumn
                sx={{
                  backgroundColor: alpha(
                    theme.palette.background.paper,
                    showScrollLeftButton ? (settingsStorage.reduceTransparency ? 1 : 0.95) : 0
                  )
                }}
              />

              <Box
                flex={1}
                height={ScheduleCyclePeriodScheduleGridConstants.pointsPerHour * 24}
                zIndex={ScheduleCyclePeriodScheduleGridConstants.dayColumnZIndex}
              >
                <Stack direction="row" height="100%" position="relative">
                  {columns.map((column, index) => (
                    <Fragment key={`day-column-${index}`}>
                      <Box display="flex" flexDirection="column">
                        <ScheduleCyclePeriodScheduleGridDayColumn
                          sx={{ height: '100%', width: '100%' }}
                          column={column}
                          getEditViewModel={getEditViewModel}
                          supportsActivitySchedules={supportsActivitySchedules}
                          canCreatePeriods={!isReadOnly && viewModel.canCreatePeriods}
                          canEditPeriods={!isReadOnly && viewModel.canEditPeriods}
                        />
                      </Box>
                      {index < columns.length - 1 && <Divider orientation="vertical" />}
                    </Fragment>
                  ))}
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </ContentCard>

        {showScrollLeftButton && (
          <ScheduleCyclePeriodScheduleGridNavigationButton
            sx={{ position: 'absolute', bottom: 4, left: 4 }}
            direction="left"
            onClick={() => scroll('left')}
          />
        )}

        {showScrollRightButton && (
          <ScheduleCyclePeriodScheduleGridNavigationButton
            sx={{ position: 'absolute', bottom: 4, right: 4 }}
            direction="right"
            onClick={() => scroll('right')}
          />
        )}

        {viewModel.emptyMessage != null && !isReadOnly && viewModel.canCreatePeriods && (
          <Box
            sx={{
              position: 'absolute',
              left: ScheduleCyclePeriodScheduleGridConstants.timesColumnWidth,
              right: 0,
              top: 0,
              bottom: 0,
              margin: 'auto',
              // Renders below grid so that it does not intercept the click.
              zIndex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Typography fontStyle="italic" color="textSecondary" sx={{ userSelect: 'none' }}>
              {viewModel.emptyMessage}
            </Typography>
          </Box>
        )}
      </Box>
    );
  }
);
