// As @buf/googleapis_googleapis.bufbuild_es is a peer dependency, the intellisense doesn't seem to work.
// This helps as TimeOfDay can now be imported from models.
import { TimeOfDay } from '@buf/googleapis_googleapis.bufbuild_es/google/type/timeofday_pb.js';
import {
  addMinutes,
  areIntervalsOverlapping,
  differenceInMinutes,
  getHours,
  getMinutes,
  isWithinInterval,
  set,
  subMinutes
} from 'date-fns';
import { floor } from 'lodash';
export { TimeOfDay };

export function dateToTimeOfDay(date: Date): TimeOfDay {
  return new TimeOfDay({ hours: getHours(date), minutes: getMinutes(date) });
}

export function timeOfDayToDate(time: TimeOfDay): Date {
  return set(new Date(), { ...time });
}

export function timeOfDayToSeconds(time: TimeOfDay): number {
  return time.hours * 3_600 + time.minutes * 60 + time.seconds;
}

export function timeOfDaySpanOverlaps(
  startTimeOfDay: TimeOfDay,
  endTimeOfDay: TimeOfDay,
  otherStart: TimeOfDay,
  otherEnd: TimeOfDay,
  inclusive: boolean
): boolean {
  try {
    return areIntervalsOverlapping(
      { start: timeOfDayToDate(startTimeOfDay), end: timeOfDayToDate(endTimeOfDay) },
      { start: timeOfDayToDate(otherStart), end: timeOfDayToDate(otherEnd) },
      { inclusive }
    );
  } catch {
    // If the interval is invalid, we return false
    return false;
  }
}

export function timeOfDayIsBetweenOthers(
  timeOfDay: TimeOfDay,
  startTime: TimeOfDay,
  endTime: TimeOfDay,
  options?: { startInclusive?: boolean; endInclusive?: boolean }
): boolean {
  try {
    return isWithinInterval(timeOfDayToDate(timeOfDay), {
      start: addMinutes(timeOfDayToDate(startTime), options?.startInclusive === false ? 1 : 0),
      end: subMinutes(timeOfDayToDate(endTime), options?.endInclusive === false ? 1 : 0)
    });
  } catch {
    // If the interval is invalid, we return false
    return false;
  }
}

export function compareTimeOfDays(first: TimeOfDay, other: TimeOfDay): number {
  return differenceInMinutes(timeOfDayToDate(first), timeOfDayToDate(other), { roundingMethod: 'trunc' });
}

export function timeOfDayToString(time: TimeOfDay): string {
  const hours = time.hours.toString().padStart(2, '0');
  const minutes = time.minutes.toString().padStart(2, '0');
  const seconds = time.seconds.toString().padStart(2, '0');
  const nanos = time.nanos.toString().padStart(10, '0');

  return `${hours}:${minutes}:${seconds}:${nanos}`;
}

export function parseTimeOfDay(value: string): TimeOfDay {
  const components = value.split(':');
  const hours = parseInt(components[0] ?? '0');
  const minutes = parseInt(components[1] ?? '0');
  const seconds = parseInt(components[2] ?? '0');
  const nanos = parseInt(components[3] ?? '0');
  return new TimeOfDay({ hours, minutes, seconds, nanos });
}

export function differenceInMinutesBetweenTimeOfDays(first: TimeOfDay, other: TimeOfDay) {
  return differenceInMinutes(timeOfDayToDate(first), timeOfDayToDate(other), { roundingMethod: 'trunc' });
}

export function addMinutesToTimeOfDay(timeOfDay: TimeOfDay, value: number): TimeOfDay {
  const minutes = timeOfDay.hours * 60 + timeOfDay.minutes + value;
  return new TimeOfDay({ hours: floor(minutes / 60), minutes: minutes % 60 });
}
