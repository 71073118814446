import { ScheduleCycleCreateCopyDialog } from '@/components/shared';
import { ContentCopyRounded, DeleteRounded } from '@mui/icons-material';
import { Box, Divider, ListItemIcon, ListItemText, MenuItem, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useServices, useViewModel } from '../../../hooks';
import { ScheduleCycleEditDetails } from '../../shared/settings/schedule-cycle/details/ScheduleCycleEditDetails';
import { ActionMenu, ActionMenuButton, ConfirmationDialog } from '../../utils';

export interface AdminSchoolScheduleCycleDetailsPageProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  scheduleCycleId: string;
  isReadOnly: boolean;
}

export const AdminSchoolScheduleCycleDetailsPage = observer(
  ({ sx, className, schoolId, scheduleCycleId, isReadOnly }: AdminSchoolScheduleCycleDetailsPageProps) => {
    const { route } = useServices();
    const navigate = useNavigate();

    const strings = LocalizedStrings.admin.schedule.details;

    const mainViewModel = useViewModel(
      (viewModels) => viewModels.createScheduleCycleDetailsViewModel(scheduleCycleId, schoolId, 'school'),
      [scheduleCycleId, schoolId]
    );

    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createScheduleEditDetailsPageViewModel(scheduleCycleId, { kind: 'school', id: schoolId }),
      [scheduleCycleId]
    );

    const [actionMenuButtonRef, setActionMenuButtonRef] = useState<HTMLButtonElement | null>(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showCreateCopy, setShowCreateCopy] = useState(false);

    function onDeleteActionSelect() {
      setActionMenuButtonRef(null);
      setShowDeleteConfirmation(true);
    }

    function onDeleteConfirmationSubmit(hasConfirmed: boolean) {
      setShowDeleteConfirmation(false);

      if (hasConfirmed) {
        void mainViewModel.removeScheduleCycle(route.resolveAdminSchoolSchedulesLocation(schoolId), navigate);
      }
    }

    function onCreateCopyActionSelect() {
      setActionMenuButtonRef(null);
      setShowCreateCopy(true);
    }

    return (
      <Box width="100%" height="100%" sx={sx} className={className} overflow="hidden">
        <Stack width="100%" height="100%" spacing={1} sx={{ maxWidth: 1000 }} overflow="hidden">
          <Stack direction="row" alignItems="center">
            <Typography flex={1} variant="h6">
              {strings.title()}
            </Typography>

            {!isReadOnly && (
              <Box>
                <ActionMenuButton onClick={(e) => setActionMenuButtonRef(e.currentTarget)} />
                <ActionMenu
                  open={actionMenuButtonRef != null}
                  anchorEl={actionMenuButtonRef}
                  onClose={() => setActionMenuButtonRef(null)}
                >
                  <MenuItem onClick={onCreateCopyActionSelect}>
                    <ListItemIcon>
                      <ContentCopyRounded />
                    </ListItemIcon>
                    <ListItemText primary={strings.createCopyOptionTitle()} />
                  </MenuItem>
                  <MenuItem onClick={onDeleteActionSelect}>
                    <ListItemIcon>
                      <DeleteRounded color="error" />
                    </ListItemIcon>
                    <ListItemText primary={strings.deleteOptionTitle()} primaryTypographyProps={{ color: 'error' }} />
                  </MenuItem>
                </ActionMenu>
              </Box>
            )}
          </Stack>

          <Divider />

          <ScheduleCycleEditDetails
            sx={{ flex: 1, overflowY: 'auto' }}
            viewModel={viewModel.editViewModel}
            isReadOnly={viewModel.isReadOnly || isReadOnly}
          />
        </Stack>

        <ConfirmationDialog
          isOpen={showDeleteConfirmation}
          title={strings.deleteConfirmationTitle()}
          message={strings.deleteConfirmationMessage()}
          confirmButtonLabel={strings.deleteConfirmationSubmitTitle()}
          onSubmit={onDeleteConfirmationSubmit}
          isDestructive={true}
        />

        {/* Ensure view-model is reloaded every time */}
        {showCreateCopy && (
          <ScheduleCycleCreateCopyDialog
            isOpen={showCreateCopy}
            scheduleCycle={viewModel.scheduleCycle}
            schoolId={schoolId}
            plannerId={undefined}
            onCancel={() => setShowCreateCopy(false)}
          />
        )}
      </Box>
    );
  }
);
