import { useServices, useViewModel } from '@/hooks';
import { Picture } from '@/models';
import EmptyIcon from '@mui/icons-material/SearchRounded';
import { Box, CircularProgress as MUICircularProgress, Stack, Theme, styled, useMediaQuery } from '@mui/material';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import { Dialog, EmptyListIndicator, SearchBar, UnsplashGallery } from '../utils';

export interface UnsplashDialogProps {
  onClose: (result: Picture | undefined) => void;
  isOpen: boolean;
}

export const UnsplashDialog = observer(({ onClose, isOpen }: UnsplashDialogProps) => {
  const { viewModels, localization } = useServices();
  const navigate = useNavigate();

  function onSuccess(result: Picture) {
    onClose(result);
    navigate(-1);
    return Promise.resolve();
  }

  function onCancel() {
    navigate(-1);
    return Promise.resolve();
  }

  const viewModel = useViewModel(() => viewModels.createUnsplashViewModel(onSuccess, onCancel));
  const strings = localization.localizedStrings.settings;

  const isXSmallScreen = useMediaQuery((th: Theme) => th.breakpoints.down('xs'));
  const isSmallScreen = useMediaQuery((th: Theme) => th.breakpoints.down('sm'));

  const nbColumns = useMemo(() => {
    if (isXSmallScreen) {
      return 1;
    } else if (isSmallScreen) {
      return 2;
    }
    return 3;
  }, [isXSmallScreen, isSmallScreen]);

  const scrollableTargetId = 'scrollable-target';

  return (
    <Dialog
      viewModel={viewModel}
      isOpen={isOpen}
      title={strings.unsplashDialogTitle}
      width="md"
      fullHeight
      renderData={() => (
        <Stack spacing={2} sx={{ height: '100%' }}>
          <SearchBar
            onSearch={(value) => void viewModel.search(value ?? '')}
            placeholder={strings.unsplashSearchPlaceholder}
            debounce={500}
            autoFocus
          />

          <Box flex={1} sx={{ overflow: 'hidden' }}>
            <GalleryContainer
              id={scrollableTargetId}
              mt={1}
              className={clsx(viewModel.pictures.length === 0 && classes.empty)}
            >
              <StyledInfiniteScroll
                dataLength={viewModel.pictures.length}
                next={() => viewModel.loadMore()}
                hasMore={viewModel.hasMore}
                loader={<CircularProgress />}
                scrollableTarget={scrollableTargetId}
              >
                {viewModel.pictures.length === 0 && !viewModel.isLoading && (
                  <EmptyListIndicator
                    title={strings.unsplashNoResultsTitle}
                    subtitle={strings.unsplashNoResultsSubtitle}
                    renderIcon={(className: string) => <EmptyIcon className={className} />}
                  />
                )}
                <UnsplashGallery
                  pictures={viewModel.pictures}
                  onSelectPicture={(value) => (viewModel.selectedPicture = value)}
                  nbColumns={nbColumns}
                />
              </StyledInfiniteScroll>
            </GalleryContainer>
          </Box>
        </Stack>
      )}
    />
  );
});

const PREFIX = 'UnsplashDialog';
const classes = {
  empty: `${PREFIX}-empty`
};

const GalleryContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'normal',
  overflowY: 'auto',
  overflowX: 'hidden',
  height: '100%',

  [`&.${classes.empty}`]: {
    justifyContent: 'center'
  }
}));

const StyledInfiniteScroll = styled(InfiniteScroll)(() => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'inherit !important'
}));

const CircularProgress = styled(MUICircularProgress)(() => ({
  margin: '0 auto'
}));
