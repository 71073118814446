import { PlannerListItemInfoStepsInfo } from '@/viewmodels';
import { alpha, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Gauge } from '../../../utils';

export interface PlannerListItemStepCountProps {
  sx?: SxProps;
  className?: string;
  stepsInfo: PlannerListItemInfoStepsInfo;
}

export function PlannerListItemStepCount({ sx, className, stepsInfo }: PlannerListItemStepCountProps) {
  const theme = useTheme();

  const gaugeColor = (percentage: number): string => {
    if (percentage <= 0.33) {
      return theme.palette.error.main;
    } else if (percentage <= 0.67) {
      return theme.palette.warning.main;
    } else {
      return theme.palette.success.main;
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0.5}
      className={className}
      sx={{
        ...sx,
        borderRadius: 100,
        backgroundColor: alpha(theme.palette.divider, 0.05),
        py: '2px',
        px: 1
      }}
    >
      <Gauge
        size={16}
        value={stepsInfo.completedStepsCount}
        maxValue={stepsInfo.totalStepsCount}
        lineColor={gaugeColor}
      />
      <Typography variant="caption" fontWeight="600" color="textSecondary">
        {stepsInfo.completedStepsCount}/{stepsInfo.totalStepsCount}
      </Typography>
    </Stack>
  );
}
