import {
  ListSection,
  WorkIconInfo,
  getPlannerCourseSectionIsTaught,
  plannerHasAccessKindsForUser,
  urlForExternalSourceBadge,
  urlForPublishedWorkWorkIcon,
  urlForWorkIconFromWork
} from '@/models';
import { ServiceContainer } from '@/providers';
import { ApplicationSettingsStorage, DateService, LocalizationService } from '@/services';
import {
  Loadable,
  PlannerDataStore,
  PlannerDemoDetailedCourseSectionsLoadable,
  PlannerDetailedCourseSectionsLoadable,
  SchoolCourseSectionPublishedWorksLoadable,
  UserDataStore,
  WorkDataStore,
  WorkIconsLoadable
} from '@/stores';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { CourseSectionRole } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_role_pb';
import { Note } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/note_pb';
import { Planner } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_pb';
import { Work } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/work_pb';
import { PublishedWork } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_pb';
import { PublishedWorkStatus } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/published_work_status_pb';
import { compareAsc, compareDesc, differenceInCalendarDays, isBefore, startOfDay } from 'date-fns';
import { chain } from 'lodash';
import { action, computed, makeObservable, observable, runInAction, when } from 'mobx';
import LocalizedStrings from 'strings';
import { propertiesHaveMatchForSearchText } from '../../utils';
import { BaseUpdatableViewModel, UpdatableViewModel } from '../shared';

type PlannerItemsSectionKind = 'upcoming' | 'past';

interface BasePlannerItemsItemInfo {
  readonly id: string;
  readonly courseColor: string;
  readonly comparisonDate: Date;
  readonly comparisonDateIsAllDay: boolean;
  readonly hasDate: boolean;
  readonly comparisonTitle: string;
  readonly searchFields: (string | undefined)[];
}

export interface PlannerItemsPublishedWorkInfo extends BasePlannerItemsItemInfo {
  readonly publishedWork: PublishedWork;
  readonly icon: WorkIconInfo;
}

export interface PlannerItemsWorkInfo extends BasePlannerItemsItemInfo {
  readonly work: Work;
  readonly icon: WorkIconInfo;
}

export interface PlannerItemsNoteInfo extends BasePlannerItemsItemInfo {
  readonly note: Note;
}

export type PlannerItemsItemKind = 'publishedWork' | 'work' | 'note';

export type PlannerItemsItem =
  | { kind: 'publishedWork'; value: PlannerItemsPublishedWorkInfo }
  | { kind: 'work'; value: PlannerItemsWorkInfo }
  | { kind: 'note'; value: PlannerItemsNoteInfo };

export interface PlannerItemsViewModel extends UpdatableViewModel {
  readonly courseSection: CourseSectionDetails | undefined;
  readonly upcomingItems: PlannerItemsItem[];
  readonly pastItems: PlannerItemsItem[];
  readonly sections: ListSection[];
  readonly currentFilterKinds: Set<PlannerItemsItemKind>;
  readonly possibleFilterKinds: PlannerItemsItemKind[];
  readonly canPublishWork: boolean;
  readonly canCreateItem: boolean;
  readonly displayCourseColor: boolean;
  searchText: string;
  minimumDate: Date | undefined;
  maximumDate: Date | undefined;
  showPastItems: boolean;
  showNoDateItems: boolean;

  toggleFilterKind(kind: PlannerItemsItemKind): void;
}

export class AppPlannerItemsViewModel extends BaseUpdatableViewModel implements PlannerItemsViewModel {
  @observable private _searchText = '';
  @observable private _minimumDate: Date | undefined;
  @observable private _maximumDate: Date | undefined;
  @observable private _showPastItems = true;
  @observable private _showNoDateItems = true;
  @observable private _currentFilterKinds = new Set<PlannerItemsItemKind>();

  constructor(
    private readonly _plannerId: string,
    private readonly _courseSectionId: string | undefined,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _workStore: WorkDataStore = ServiceContainer.services.workStore,
    private readonly _dateService: DateService = ServiceContainer.services.dateService,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _settingsStorage: ApplicationSettingsStorage = ServiceContainer.services.settingsStorage
  ) {
    super();
    makeObservable(this);
    void this.loadInitialFilters();

    when(
      () => this.courseSectionsLoadable.hasData,
      () => {
        const updateFilters = async () => {
          const storedKinds = (await this._settingsStorage.plannerItemsFilteredKinds(
            this._plannerId,
            this._courseSectionId
          )) as PlannerItemsItemKind[];

          runInAction(
            () =>
              (this._currentFilterKinds = new Set(
                storedKinds ?? (this.canPublishWork ? ['work', 'note', 'publishedWork'] : ['work', 'note'])
              ))
          );
        };

        void updateFilters();
      }
    );
  }

  @computed
  private get planner(): Planner {
    const planner = this._userStore.getPlannerForId(this._plannerId);

    if (planner == null) {
      throw new Error(`No available planner matching id ${this._plannerId}.`);
    }

    return planner;
  }

  @computed
  private get publishedWorksLoadables(): SchoolCourseSectionPublishedWorksLoadable[] {
    const courseSection = this.courseSection;

    if (courseSection != null) {
      return courseSection.role === CourseSectionRole.TEACHER && courseSection.schoolsCourseSection != null
        ? [
            this._workStore.getPublishedWorksInCourseSection(
              courseSection.schoolsCourseSection.id,
              courseSection.schoolsCourseSection.schoolId
            )
          ]
        : [];
    }

    const schoolCourseSections = chain(this.courseSectionsLoadable.values)
      .map((cs) => (cs.role === CourseSectionRole.TEACHER ? cs.schoolsCourseSection : undefined))
      .compact()
      .value();

    return schoolCourseSections.map((cs) => this._workStore.getPublishedWorksInCourseSection(cs.id, cs.schoolId));
  }

  @computed
  private get courseSectionsLoadable(): PlannerDetailedCourseSectionsLoadable {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get demoCourseSectionsLoadable(): PlannerDemoDetailedCourseSectionsLoadable {
    return this._plannerStore.getDemoCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get workIcons(): WorkIconsLoadable {
    return this._workStore.workIcons;
  }

  @computed
  private get worksLoadable(): Loadable<Work[]> {
    return this._courseSectionId != null
      ? this._workStore.getCourseSectionWorksLoadable(this._plannerId, this._courseSectionId, undefined)
      : this._workStore.getWorksLoadable(this._plannerId);
  }

  @computed
  private get notesLoadable(): Loadable<Note[]> {
    return this._courseSectionId != null
      ? this._workStore.getCourseSectionNotesLoadable(this._plannerId, this._courseSectionId)
      : this._workStore.getNotesLoadable(this._plannerId);
  }

  @computed
  private get courseSectionsBySchoolId(): Map<string, CourseSectionDetails> {
    const values = new Map<string, CourseSectionDetails>();
    this.courseSectionsLoadable.values
      .filter((cs) => cs.schoolsCourseSection != null)
      .forEach((cs) => values.set(cs.schoolsCourseSection!.id, cs));
    return values;
  }

  @computed
  protected get loadables(): Loadable<unknown>[] {
    return [
      this.courseSectionsLoadable,
      this.demoCourseSectionsLoadable,
      this.workIcons,
      this.notesLoadable,
      this.worksLoadable,
      ...this.publishedWorksLoadables
    ];
  }

  @computed
  private get allItems(): PlannerItemsItem[] {
    const worksItems = this.worksLoadable.data.map((w) => this.createItemForWork(w));
    const notesItems = this.notesLoadable.data.map((n) => this.createItemForNote(n));
    const publishedWorksItems =
      chain(this.publishedWorksLoadables)
        .reduce<PublishedWork[]>((prev, cur) => {
          prev.push(...cur.values);
          return prev;
        }, [])
        .map((pw) => this.createItemForPublishedWork(pw))
        .value() ?? [];

    return [
      ...(this._currentFilterKinds.has('work') ? worksItems : []),
      ...(this._currentFilterKinds.has('note') ? notesItems : []),
      ...(this._currentFilterKinds.has('publishedWork') ? publishedWorksItems : [])
    ];
  }

  @computed
  get courseSection(): CourseSectionDetails | undefined {
    if (this._courseSectionId == null) {
      return undefined;
    }

    if (this._settingsStorage.isDemoMode !== true && this._courseSectionId.startsWith('demo')) {
      return this.demoCourseSectionsLoadable.data.get(this._courseSectionId)!;
    }

    return this.courseSectionsLoadable.values.find((cs) => cs.courseSection?.id === this._courseSectionId)!;
  }

  @computed
  get currentFilterKinds(): Set<PlannerItemsItemKind> {
    return this._currentFilterKinds;
  }

  @computed
  get possibleFilterKinds(): PlannerItemsItemKind[] {
    return this.canPublishWork ? ['work', 'note', 'publishedWork'] : ['work', 'note'];
  }

  @computed
  get upcomingItems(): PlannerItemsItem[] {
    return this.getItemsForSection('upcoming');
  }

  @computed
  get pastItems(): PlannerItemsItem[] {
    return this._showPastItems ? this.getItemsForSection('past') : [];
  }

  @computed
  get sections(): ListSection[] {
    const s: ListSection[] = [];
    s.push({
      id: 'upcoming',
      title: undefined,
      numberOfRows: this.upcomingItems.length || (this.pastItems.length > 0 ? 0 : 1)
    });

    if (this.pastItems.length > 0) {
      s.push({
        id: 'past',
        title: LocalizedStrings.planner.items.pastWorksSectionTitle(),
        numberOfRows: this.pastItems.length
      });
    }

    return s;
  }

  @computed
  get canCreateItem(): boolean {
    return plannerHasAccessKindsForUser(this._userStore.user.userId, this.planner, AccessKind.FULL_ACCESS);
  }

  @computed
  get canPublishWork(): boolean {
    const courseSection = this.courseSection;
    return courseSection != null
      ? getPlannerCourseSectionIsTaught(courseSection)
      : this.courseSectionsLoadable.values.some(getPlannerCourseSectionIsTaught);
  }

  @computed
  get displayCourseColor(): boolean {
    return this.courseSection == null;
  }

  @computed
  get searchText(): string {
    return this._searchText;
  }

  set searchText(value: string) {
    this._searchText = value;
  }

  @computed
  get maximumDate(): Date | undefined {
    return this._maximumDate;
  }

  set maximumDate(value: Date | undefined) {
    this._maximumDate = value;
    this._settingsStorage.setPlannerItemsMaximumDate(value, this._plannerId, this._courseSectionId);
  }

  @computed
  get minimumDate(): Date | undefined {
    return this._minimumDate;
  }

  set minimumDate(value: Date | undefined) {
    this._minimumDate = value;
    this._settingsStorage.setPlannerItemsMinimumDate(value, this._plannerId, this._courseSectionId);
  }

  @computed
  get showPastItems(): boolean {
    return this._showPastItems;
  }

  set showPastItems(value: boolean) {
    this._showPastItems = value;
    this._settingsStorage.setPlannerItemsShowPastItems(value, this._plannerId, this._courseSectionId);
  }

  @computed
  get showNoDateItems(): boolean {
    return this._showNoDateItems;
  }

  set showNoDateItems(value: boolean) {
    this._showNoDateItems = value;
    this._settingsStorage.setPlannerItemsShowNoDateItems(value, this._plannerId, this._courseSectionId);
  }

  @action
  toggleFilterKind(kind: PlannerItemsItemKind) {
    const isSelected = this._currentFilterKinds.has(kind);

    if (isSelected && this._currentFilterKinds.size > 1) {
      this._currentFilterKinds.delete(kind);
    } else if (!isSelected) {
      this._currentFilterKinds.add(kind);
    }

    this._settingsStorage.setPlannerItemsFilteredKinds(
      Array.from(this._currentFilterKinds),
      this._plannerId,
      this._courseSectionId
    );
  }

  private getItemsForSection(sectionKind: PlannerItemsSectionKind): PlannerItemsItem[] {
    return this.allItems
      .filter((w) => this.shouldDisplayItem(w, sectionKind))
      .sort((item1, item2) => this.compareItems(item1, item2, sectionKind === 'upcoming' ? 'asc' : 'desc'));
  }

  private createItemForWork(work: Work): PlannerItemsItem {
    const course = this.courseSectionsLoadable.data.get(work.courseSectionId);

    return {
      kind: 'work',
      value: {
        id: work.id,
        work,
        icon: this.getWorkIconInfoForId(work),
        courseColor: course?.courseSection?.color ?? '',
        comparisonDate: this.getWorkComparisonDate(work),
        comparisonDateIsAllDay: work.dueTime != null ? work.isDueAllDay : false,
        hasDate: work.dueTime != null,
        comparisonTitle: work.title,
        searchFields: [work.title, work.description?.text]
      }
    };
  }

  private createItemForNote(note: Note): PlannerItemsItem {
    const course = this.courseSectionsLoadable.data.get(note.courseSectionId);
    const text = note.text?.text ?? '';

    return {
      kind: 'note',
      value: {
        id: note.id,
        note,
        courseColor: course?.courseSection?.color ?? '',
        comparisonDate: this.getNoteComparisonDate(note),
        comparisonDateIsAllDay: note.time != null ? note.isAllDay : false,
        hasDate: note.time != null,
        comparisonTitle: text,
        searchFields: [text]
      }
    };
  }

  private createItemForPublishedWork(publishedWork: PublishedWork): PlannerItemsItem {
    const course = this.courseSectionsBySchoolId.get(publishedWork.courseSectionId);

    return {
      kind: 'publishedWork',
      value: {
        id: publishedWork.id,
        publishedWork,
        icon: this.getPublishedWorkIconInfoForId(publishedWork),
        courseColor: course?.courseSection?.color ?? '',
        comparisonDate: this.getPublishedWorkComparisonDate(publishedWork),
        comparisonDateIsAllDay: publishedWork.dueTime != null ? publishedWork.isDueAllDay : false,
        hasDate: publishedWork.dueTime != null || publishedWork.scheduledPublishTime != null,
        comparisonTitle: publishedWork.title,
        searchFields: [publishedWork.title, publishedWork.description?.text]
      }
    };
  }

  private getWorkIconInfoForId(work: Work): WorkIconInfo {
    const workIcon = this.workIcons.data.iconsById.get(work.iconId)!;
    return {
      id: workIcon.iconId,
      title: workIcon.iconName,
      lightUrl: urlForWorkIconFromWork(workIcon, work, 'light'),
      darkUrl: urlForWorkIconFromWork(workIcon, work, 'dark'),
      externalBadgeUrl: urlForExternalSourceBadge(work.externalSource?.sourceName, this.workIcons.data)
    };
  }

  private getPublishedWorkIconInfoForId(publishedWork: PublishedWork): WorkIconInfo {
    const workIcon = this.workIcons.data.iconsById.get(publishedWork.iconId)!;
    return {
      id: workIcon.iconId,
      title: workIcon.iconName,
      lightUrl: urlForPublishedWorkWorkIcon(workIcon, publishedWork.importance, 'light'),
      darkUrl: urlForPublishedWorkWorkIcon(workIcon, publishedWork.importance, 'dark'),
      externalBadgeUrl: urlForExternalSourceBadge(publishedWork.externalSource?.sourceName, this.workIcons.data)
    };
  }

  private getWorkComparisonDate(work: Work): Date {
    if (work.dueTime != null) {
      const dueDate = work.dueTime.toDate();
      return work.isDueAllDay ? startOfDay(dueDate) : dueDate;
    }

    return startOfDay(work.createdTime!.toDate());
  }

  private getNoteComparisonDate(note: Note): Date {
    if (note.time != null) {
      const date = note.time.toDate();
      return note.isAllDay ? startOfDay(date) : date;
    }

    return startOfDay(note.createdTime!.toDate());
  }

  private getPublishedWorkComparisonDate(work: PublishedWork): Date {
    if (work.dueTime != null) {
      const dueDate = work.dueTime.toDate();
      return work.isDueAllDay ? startOfDay(dueDate) : dueDate;
    } else if (work.scheduledPublishTime != null) {
      return work.scheduledPublishTime.toDate();
    }

    return startOfDay(work.createdTime!.toDate());
  }

  private compareItems(item1: PlannerItemsItem, item2: PlannerItemsItem, order: 'asc' | 'desc'): number {
    const item1Date = item1.value.comparisonDate;
    const item2Date = item2.value.comparisonDate;

    const sortValue = order === 'asc' ? compareAsc(item1Date, item2Date) : compareDesc(item1Date, item2Date);

    if (sortValue != 0) {
      return sortValue;
    }

    // The order parameter only affects the date. Always comparing titles in ascending order.
    return item1.value.comparisonTitle.localeCompare(item2.value.comparisonTitle, this._localization.currentLocale, {
      sensitivity: 'base'
    });
  }

  private shouldDisplayItem(item: PlannerItemsItem, kind: PlannerItemsSectionKind): boolean {
    if (!item.value.hasDate && !this._showNoDateItems) {
      return false;
    }

    if (this._searchText.length > 0 && !propertiesHaveMatchForSearchText(this._searchText, item.value.searchFields)) {
      return false;
    }

    if (item.kind === 'publishedWork' && item.value.publishedWork.status === PublishedWorkStatus.CANCELLED) {
      return false;
    }

    const itemDate = item.value.comparisonDate;

    const isInThePast = item.value.comparisonDateIsAllDay
      ? differenceInCalendarDays(itemDate, this._dateService.now) < 0
      : isBefore(itemDate, this._dateService.now);

    if ((isInThePast && kind === 'upcoming') || (!isInThePast && kind === 'past')) {
      return false;
    }

    if (this.minimumDate != null && differenceInCalendarDays(itemDate, this.minimumDate) < 0) {
      // Work date is before minimum date, so not showing.
      return false;
    }

    // If work date is after maximum date, not showing.
    return !(this.maximumDate != null && differenceInCalendarDays(itemDate, this.maximumDate) > 0);
  }

  private async loadInitialFilters() {
    const [minDate, maxDate, showPastItems, showNoDateItems] = await Promise.all([
      this._settingsStorage.plannerItemsMinimumDate(this._plannerId, this._courseSectionId),
      this._settingsStorage.plannerItemsMaximumDate(this._plannerId, this._courseSectionId),
      this._settingsStorage.plannerItemsShowPastItems(this._plannerId, this._courseSectionId),
      this._settingsStorage.plannerItemsShowNoDateItems(this._plannerId, this._courseSectionId)
    ]);

    runInAction(() => {
      this._minimumDate = minDate;
      this._maximumDate = maxDate;
      this._showPastItems = showPastItems ?? true;
      this._showNoDateItems = showNoDateItems ?? true;
    });
  }
}
