import { css } from '@emotion/css';
import { Alert, AlertTitle, CardActionArea, darken, lighten, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface ActionAlertProps {
  sx?: SxProps;
  className?: string;
  onClick: () => void;
  title?: string;
  message: string;
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'info' | 'warning' | 'error' | 'success';
  severity?: 'info' | 'warning' | 'error' | 'success';
  variant?: 'standard' | 'filled' | 'outlined';
}

export const ActionAlert = observer(
  ({
    onClick,
    disabled,
    title,
    message,
    color = 'primary',
    severity,
    sx,
    className,
    variant = 'standard'
  }: ActionAlertProps) => {
    const theme = useTheme();
    const resolvedColor = theme.palette[color].main;
    const getColor = theme.palette.mode === 'light' ? darken : lighten;
    const getBackgroundColor = theme.palette.mode === 'light' ? lighten : darken;

    const mainStyle = css(
      color != null && {
        ...(variant === 'standard' && {
          backgroundColor: getBackgroundColor(resolvedColor, 0.9),
          color: getColor(resolvedColor, 0.6)
        }),
        ...(variant === 'outlined' && {
          backgroundColor: 'transparent',
          border: `1px solid ${resolvedColor}`,
          color: getColor(resolvedColor, 0.6)
        }),
        ...(variant === 'filled' && {
          backgroundColor: resolvedColor,
          color: theme.palette[color].contrastText
        }),
        ['& .MuiAlert-icon']: {
          color: variant !== 'filled' ? resolvedColor : 'inherit'
        }
      }
    );

    return (
      <CardActionArea sx={{ ...sx, borderRadius: 1.5 }} className={className} onClick={onClick} disabled={disabled}>
        <Alert className={mainStyle} severity={severity} sx={{ py: '2px', px: 2, fontWeight: '500' }}>
          {title != null && <AlertTitle>{title}</AlertTitle>}
          {message}
        </Alert>
      </CardActionArea>
    );
  }
);
