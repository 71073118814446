import { UserDashboardCalendarDayTimedItemInfo } from '@/viewmodels';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { UserDashboardCalendarDayCalendarEventView } from './UserDashboardCalendarDayCalendarEventView';
import { UserDashboardCalendarDayNoteView } from './UserDashboardCalendarDayNoteView';
import { UserDashboardCalendarDayPeriodView } from './UserDashboardCalendarDayPeriodView';
import { UserDashboardCalendarDayPlannedWorkView } from './UserDashboardCalendarDayPlannedWorkView';
import { UserDashboardCalendarDayWorkView } from './UserDashboardCalendarDayWorkView';

export interface UserDashboardCalendarDayTimedItemViewProps {
  sx?: SxProps;
  className?: string;
  item: UserDashboardCalendarDayTimedItemInfo;
  isReadOnly: boolean;
  canPublishWork: boolean;
  isToday: boolean;
  titleInfo?: { title: string; isHighlighted?: boolean };
}

export const UserDashboardCalendarDayTimedItemView = observer(
  ({ sx, className, item, isReadOnly, canPublishWork, titleInfo }: UserDashboardCalendarDayTimedItemViewProps) => {
    return (
      <Stack sx={sx} className={className} spacing={0.5}>
        {titleInfo != null && (
          <Typography
            variant="subtitle2"
            fontWeight="600"
            color={titleInfo.isHighlighted ? 'primary' : 'textSecondary'}
            px={1}
          >
            {titleInfo.title}
          </Typography>
        )}

        {renderItem(item, isReadOnly, canPublishWork)}
      </Stack>
    );
  }
);

function renderItem(item: UserDashboardCalendarDayTimedItemInfo, isReadOnly: boolean, canPublishWork: boolean) {
  switch (item.case) {
    case 'calendarEvent':
      return <UserDashboardCalendarDayCalendarEventView calendarEvent={item.value} />;
    case 'note':
      return <UserDashboardCalendarDayNoteView note={item.value} showTime={false} />;
    case 'plannedWork':
      return <UserDashboardCalendarDayPlannedWorkView plannedWork={item.value} />;
    case 'work':
    case 'publishedWork':
      return <UserDashboardCalendarDayWorkView work={item} showTime={false} />;
    case 'period':
      return (
        <UserDashboardCalendarDayPeriodView
          period={item.value}
          isReadOnly={isReadOnly}
          canPublishWork={canPublishWork}
        />
      );
  }
}
