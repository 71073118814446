import { useActivePlannerId, usePlannerUserHasAccess, useViewModel } from '@/hooks';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { SettingsCancelButton, SettingsContent, SettingsSaveButton } from '../../shared';
import { RenamePlanner } from '../management';

export interface PlannerEditProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerEdit = observer(({ sx, className }: PlannerEditProps) => {
  const plannerId = useActivePlannerId();
  const viewModel = useViewModel(
    (viewModels) => viewModels.createRenamePlannerDialogViewModel(plannerId, undefined, undefined),
    []
  );

  const hasAccess = usePlannerUserHasAccess([AccessKind.FULL_ACCESS]);

  return (
    <SettingsContent sx={sx} className={className}>
      <Stack spacing={3}>
        <RenamePlanner viewModel={viewModel} isReadOnly={!hasAccess} />

        {hasAccess && viewModel.canApply && (
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <SettingsCancelButton onClick={() => viewModel.resetChanges()} disabled={viewModel.isApplying} />

            <SettingsSaveButton
              onClick={() => void viewModel.apply()}
              disabled={viewModel.isApplying}
              loading={viewModel.isApplying}
            />
          </Stack>
        )}
      </Stack>
    </SettingsContent>
  );
});
