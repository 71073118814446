import { CourseSectionInfo, courseSectionInfoToActivity } from '@/models';
import { EditableScheduleCyclePeriodActivityInfo } from '@/viewmodels';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import LocalizedStrings from 'strings';
import { CourseSectionPicker } from '../../../course-section';

export interface ScheduleCyclePeriodEditTermActivityProps {
  sx?: SxProps;
  className?: string;
  activityInfo: EditableScheduleCyclePeriodActivityInfo;
  courseSections: CourseSectionInfo[];
  getCourseSectionForActivity: (activity: Activity) => CourseSectionInfo | undefined;
}

export const ScheduleCyclePeriodEditTermActivity = observer(
  ({
    sx,
    className,
    activityInfo,
    getCourseSectionForActivity,
    courseSections
  }: ScheduleCyclePeriodEditTermActivityProps) => {
    const strings = LocalizedStrings.scheduleCycle.edit;
    const { activity, roomName } = activityInfo;

    const selectedCourse = useMemo(
      () => (activity != null ? getCourseSectionForActivity(activity) : undefined),
      [activity]
    );

    function onSelectCourse(id: string | undefined) {
      const course = courseSections.find((cs) => cs.id === id);
      activityInfo.activity = course != null ? courseSectionInfoToActivity(course) : undefined;

      if (course == null) {
        activityInfo.markAsDeleted();
      }
    }

    const resolvedIsDisabled = useMemo(
      () => selectedCourse != null && !selectedCourse.isPersonal && !selectedCourse.isTeacher,
      [selectedCourse]
    );

    // Only allow selection of personal or taught classes. Still showing student class if selected, but the
    // control is disabled.
    const selectableCourseSections = useMemo(
      () => courseSections.filter((cs) => cs === selectedCourse || cs.isPersonal || cs.isTeacher),
      [courseSections]
    );

    return (
      // Using a -1px spacing so that borders of course picker and TextField overlap
      <Stack sx={sx} className={className} spacing="-1px">
        <CourseSectionPicker
          selectedCourseId={selectedCourse?.id}
          allCourseSections={selectableCourseSections}
          onChange={onSelectCourse}
          elevation={3}
          disabled={resolvedIsDisabled}
          sx={{
            '& .MuiInputBase-input': {
              borderBottomLeftRadius: '0 !important',
              borderBottomRightRadius: '0 !important'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderBottomLeftRadius: '0 !important',
              borderBottomRightRadius: '0 !important',
              // Prevent color overlap with room name TextField when not hovered or focused.
              borderBottomColor: 'transparent'
            }
          }}
        />

        <TextField
          fullWidth
          value={roomName}
          onChange={(e) => (activityInfo.roomName = e.currentTarget.value)}
          placeholder={strings.bellTimes.period.activityScheduleRoomNamePlaceholder()}
          disabled={resolvedIsDisabled}
          sx={{
            '& .MuiInputBase-input': {
              borderTopLeftRadius: '0 !important',
              borderTopRightRadius: '0 !important'
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderTopLeftRadius: '0 !important',
              borderTopRightRadius: '0 !important'
            }
          }}
        />
      </Stack>
    );
  }
);
