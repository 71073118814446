import { FirstPageRounded, LastPageRounded, NavigateBeforeRounded, NavigateNextRounded } from '@mui/icons-material';
import { Pagination, PaginationItem } from '@mui/material';
import { SxProps } from '@mui/system';
import { gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { ChangeEvent } from 'react';

export interface TablePaginationControlsProps {
  sx?: SxProps;
  className?: string;
}

export function TablePaginationControls({ sx, className }: TablePaginationControlsProps) {
  const gridApiRef = useGridApiContext();
  const page = useGridSelector(gridApiRef, gridPageSelector);
  const pageCount = useGridSelector(gridApiRef, gridPageCountSelector);

  function onPageChange(event: ChangeEvent<unknown>, page: number) {
    gridApiRef.current.setPage(page - 1);
  }

  return (
    <Pagination
      sx={sx}
      className={className}
      color="primary"
      count={pageCount}
      page={page + 1}
      showFirstButton
      showLastButton
      renderItem={(item) => (
        <PaginationItem
          components={{
            previous: NavigateBeforeRounded,
            next: NavigateNextRounded,
            first: FirstPageRounded,
            last: LastPageRounded
          }}
          {...item}
        />
      )}
      onChange={onPageChange}
    />
  );
}
