import { useActivePlannerId, useServices } from '@/hooks';
import { UserDashboardCalendarMonthDayItemInfo, UserDashboardCalendarMonthDayViewModel } from '@/viewmodels';
import { Box, CardActionArea, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, isFirstDayOfMonth } from 'date-fns';
import { max } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import AutoSizer from 'react-virtualized-auto-sizer';
import { AllPlannerItemsPasteboardContentKind } from '../../../../services';
import { FloatingPasteButton } from '../../../utils';
import { UserDashboardCalendarMonthDayMoreItems } from './UserDashboardCalendarMonthDayMoreItems';
import { renderUserDashboardCalendarMonthItem } from './UserDashboardCalendarMonthUtils';

export interface UserDashboardCalendarMonthDayViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: UserDashboardCalendarMonthDayViewModel;
  isCurrentMonth: boolean;
}

export const UserDashboardCalendarMonthDayView = observer(
  ({ viewModel, sx, className, isCurrentMonth }: UserDashboardCalendarMonthDayViewProps) => {
    const { pasteboard, route } = useServices();
    const plannerId = useActivePlannerId();
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
    const navigate = useNavigate();

    const dateSize = isExtraSmallScreen ? '20px' : '24px';

    function goToDate(date: Date) {
      const dateString = format(date, 'y-MM-dd');
      const destination = `${route.resolvePlannerLocation(plannerId)}/home?date=${dateString}`;
      navigate(destination);
    }

    function onPasteButtonPressed() {
      pasteboard.setPasteContext({ date: viewModel.date });
    }

    return (
      <Box
        sx={{ ...sx, overflow: 'hidden', display: 'flex', flexDirection: 'column', position: 'relative' }}
        className={className}
        pb={0.5}
      >
        <Stack
          direction="row"
          spacing={isExtraSmallScreen ? 0 : 1}
          pl={1}
          pr={{ xs: 1, xl: 2 }}
          py={{ xs: 0.5, xl: 1 }}
        >
          <CardActionArea sx={{ width: dateSize, height: dateSize, borderRadius: 0.5 }}>
            <Typography
              onClick={() => goToDate(viewModel.date)}
              variant={isExtraSmallScreen ? 'caption' : viewModel.isToday ? 'body2' : 'body1'}
              fontWeight="600"
              color={isCurrentMonth ? 'textPrimary' : 'textSecondary'}
              sx={{
                ...(viewModel.isToday
                  ? {
                      background: theme.palette.error.main,
                      borderRadius: '50%',

                      color: theme.palette.getContrastText(theme.palette.error.main)
                    }
                  : {}),
                display: 'block',
                width: dateSize,
                height: dateSize,
                textAlign: 'center',
                lineHeight: dateSize
              }}
            >
              {format(viewModel.date, 'd')}
            </Typography>
          </CardActionArea>

          {!isExtraSmallScreen && isFirstDayOfMonth(viewModel.date) && (
            <Typography variant="body1" fontWeight="600" color={isCurrentMonth ? 'textPrimary' : 'textSecondary'}>
              {format(viewModel.date, 'MMMM')}
            </Typography>
          )}

          <div style={{ flex: 1 }} />
          {viewModel.cycleDayName.length > 0 && (
            <Typography variant={isExtraSmallScreen ? 'caption' : 'body2'} color="textSecondary">
              {viewModel.cycleDayName}
            </Typography>
          )}
        </Stack>

        {viewModel.items.length > 0 && (
          <Box flex={1}>
            <AutoSizer disableWidth>
              {({ height = 0 }) => {
                const items = viewModel.items;
                let totalHeight = 0;
                let nbOfRowsAvailable = 0;
                for (let i = 0; i < items.length && totalHeight <= height; i++) {
                  const item = items[i];
                  const tempHeight = totalHeight + getHeightForItem(item);

                  if (tempHeight <= height) {
                    nbOfRowsAvailable += 1;
                    totalHeight = tempHeight;
                  }
                }

                const hasMoreItems = nbOfRowsAvailable < items.length;
                const displayedItemsEndIndex = nbOfRowsAvailable - (totalHeight + 22 <= height ? 0 : 1);
                const displayedItems = hasMoreItems
                  ? nbOfRowsAvailable > 1
                    ? items.slice(0, max([displayedItemsEndIndex, 0]))
                    : []
                  : items;

                const moreItems = hasMoreItems ? items.slice(max([displayedItemsEndIndex, 0]), undefined) : [];

                return (
                  <Stack height={height} px={0.5}>
                    {displayedItems.map(renderUserDashboardCalendarMonthItem)}

                    {moreItems.length > 0 && (
                      <UserDashboardCalendarMonthDayMoreItems items={moreItems} isAllItems={items.length === 0} />
                    )}
                  </Stack>
                );
              }}
            </AutoSizer>
          </Box>
        )}

        <FloatingPasteButton
          sx={{
            position: 'absolute',
            right: 4,
            bottom: 4
          }}
          supportedContentKind={AllPlannerItemsPasteboardContentKind}
          action={onPasteButtonPressed}
        />
      </Box>
    );
  }
);

function getHeightForItem(item: UserDashboardCalendarMonthDayItemInfo): number {
  switch (item.case) {
    case 'annotation':
      return 20;
    case 'period':
      return 20;
    case 'note':
      return 24;
    case 'work':
    case 'publishedWork':
      return item.value.description.length > 0 ? 33 : 22;
    case 'plannedWork':
      return 24;
    case 'calendarEvent':
      return 24;
  }
}
