import { useServices } from '@/hooks';
import { plannedWorkDateLabel, timeSlotDuration } from '@/models';
import { WorkPlannedWorkInfo, WorkPlannedWorkStepsEditViewModel } from '@/viewmodels';
import { PlannedWork } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planned_work_pb';
import { Box, Card, Stack, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, isBefore } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { WorkDetailsPlannedWorkActionButton } from './WorkDetailsPlannedWorkActionButton';
import { WorkDetailsPlannedWorkLinkStepsPopover } from './WorkDetailsPlannedWorkLinkStepsPopover';
import { WorkDetailsPlannedWorkStep } from './WorkDetailsPlannedWorkStep';

export interface WorkDetailsPlannedWorkCardProps {
  className?: string;
  sx?: SxProps;
  plannedWork: WorkPlannedWorkInfo;
  canLinkSteps: boolean;
  onDelete: (plannedWork: PlannedWork) => Promise<void>;
  makeLinkStepsViewModel: (plannedWork: PlannedWork) => WorkPlannedWorkStepsEditViewModel;
  isReadOnly: boolean;
  disabled?: boolean;
  showHeader: boolean;
}

export const WorkDetailsPlannedWorkCard = observer(
  ({
    plannedWork,
    onDelete,
    disabled = false,
    isReadOnly,
    className,
    sx,
    showHeader,
    canLinkSteps,
    makeLinkStepsViewModel
  }: WorkDetailsPlannedWorkCardProps) => {
    const { localization, dateService } = useServices();
    const rootRef = useRef<HTMLDivElement>(null);
    const strings = localization.localizedStrings.work.details;
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const backgroundColor = !isDarkMode ? theme.palette.grey.A100 : undefined;

    const [linkStepsViewModel, setLinkStepsViewModel] = useState<WorkPlannedWorkStepsEditViewModel | undefined>();

    const startTime = plannedWork.plannedWork.timeSlot!.startTime!.toDate();
    const endTime = plannedWork.plannedWork.timeSlot!.endTime!.toDate();
    const isCompleted = isBefore(endTime, dateService.now);
    const isActive = !isBefore(dateService.now, startTime) && isBefore(dateService.now, endTime);

    function linkStepsToPlannedWork(plannedWork: PlannedWork) {
      setLinkStepsViewModel(makeLinkStepsViewModel(plannedWork));
    }

    return (
      <Box sx={sx} className={className}>
        <Card sx={{ p: 1.5, pr: 1, pt: 1, backgroundColor }} ref={rootRef} elevation={isDarkMode ? 4 : 0}>
          <Stack direction="row" spacing={1} alignItems="baseline">
            <Stack flex={1} spacing={1} overflow="hidden">
              <Tooltip title={format(startTime, 'PPPPp')} disableInteractive placement="top">
                <Stack>
                  {showHeader && (
                    <Typography variant="caption" color="textSecondary">
                      {strings.plannedWorkCardNextSessionHeader}
                    </Typography>
                  )}
                  <Typography
                    variant="body1"
                    fontWeight="500"
                    color={isActive ? 'primary' : isCompleted ? 'textSecondary' : undefined}
                  >
                    {plannedWorkDateLabel(plannedWork.plannedWork, dateService, localization.localizedStrings)}
                  </Typography>
                  <Typography variant="body2" color={isActive ? 'primary' : isCompleted ? 'textSecondary' : undefined}>
                    {format(startTime, 'p')}
                  </Typography>
                </Stack>
              </Tooltip>

              {plannedWork.steps.length > 0 && (
                <Stack overflow="hidden">
                  {plannedWork.steps.map((step) => (
                    <WorkDetailsPlannedWorkStep key={step.id} step={step} isReadOnly={isReadOnly || disabled} />
                  ))}
                </Stack>
              )}
            </Stack>

            <Stack alignItems="flex-end" spacing={0.5}>
              <Typography variant="subtitle2" color="textSecondary" pr={1}>
                {strings.durationFormat(timeSlotDuration(plannedWork.plannedWork.timeSlot) ?? 0)}
              </Typography>

              {!isReadOnly && (
                <WorkDetailsPlannedWorkActionButton
                  info={plannedWork}
                  onDelete={onDelete}
                  canLinkSteps={canLinkSteps}
                  onLinkSteps={linkStepsToPlannedWork}
                />
              )}
            </Stack>
          </Stack>
        </Card>

        {linkStepsViewModel != null && (
          <WorkDetailsPlannedWorkLinkStepsPopover
            open
            viewModel={linkStepsViewModel}
            anchorEl={rootRef.current ?? undefined}
            anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
            transformOrigin={{ horizontal: 'center', vertical: 'center' }}
            onClose={() => setLinkStepsViewModel(undefined)}
          />
        )}
      </Box>
    );
  }
);
