import { UserDashboardCalendarItemState, UserDashboardCalendarNoteInfo } from '@/viewmodels';
import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ColorIndicator, NoteIcon } from '../../../../lists';
import { ContextMenuHandler } from '../../../ContextMenuHandler';

export interface UserDashboardCalendarWeekPeriodNoteProps {
  sx?: SxProps;
  className?: string;
  note: UserDashboardCalendarNoteInfo;
}

export const UserDashboardCalendarWeekPeriodNote = observer(
  ({ sx, className, note }: UserDashboardCalendarWeekPeriodNoteProps) => {
    return (
      <ContextMenuHandler actions={note.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Stack
            sx={sx}
            className={className}
            direction="row"
            alignItems={'stretch'}
            spacing={0.5}
            overflow="hidden"
            onContextMenu={contextMenuHandler}
          >
            <Box sx={{ height: '100%', py: 0.5 }}>
              <ColorIndicator isRounded color={note.color} sx={{ height: '100%' }} />
            </Box>

            <NoteIcon size={14} sx={{ py: 0.5 }} />

            <Stack flex={1} spacing={-0.5} overflow="hidden">
              <Typography fontSize={10} noWrap color={getColorForTitle(note.state)}>
                {note.text}
              </Typography>
            </Stack>
          </Stack>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
