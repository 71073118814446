import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import {
  StudentBehavior,
  getDailyActivityBehaviorTooltip,
  getInboxBehaviorLevel,
  getInboxBehaviorTooltip,
  getStudentEmotionalStatusLevel,
  getStudentEmotionalStatusTooltip
} from '@/viewmodels';
import InsightsIcon from '@mui/icons-material/TimelineRounded';
import PlannerIcon from '@mui/icons-material/ViewWeekRounded';
import { Box, Button, Stack, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { StudentBehaviors } from '../StudentBehaviors';
import { StudentInsightsPage } from '../insights';
import { StudentPlanner } from '../planner';
import { StudentDetailsNotConnected } from './StudentDetailsNotConnected';
import { StudentDetailsUnknown } from './StudentDetailsUnknown';

type StudentTab = 'planner' | 'insights';

export interface StudentDetailsProps {
  sx?: SxProps;
  className?: string;
}

export const StudentDetails = observer(({ sx, className }: StudentDetailsProps) => {
  const { route, localization, dateService } = useServices();
  const strings = localization.localizedStrings.schoolCourseSection;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

  const params = useParams();
  const plannerId = useActivePlannerId();
  const schoolId = params.schoolId ?? '';
  const courseSectionId = params.courseId ?? '';
  const studentId = params.studentId ?? '';

  const courseViewModel = useViewModel(
    (viewModels) => viewModels.createCourseSectionDetailsViewModel(courseSectionId, plannerId, schoolId),
    [courseSectionId]
  );

  const studentViewModel = courseViewModel.studentsPlannerSummary.allStudents.find((s) => s.id === studentId);

  if (studentViewModel == null) {
    // Shouldn't happen, but just in case.
    return <StudentDetailsUnknown sx={sx} className={className} />;
  }

  const inboxBehaviorLevel = getInboxBehaviorLevel(studentViewModel.inboxItemsCount);
  const inboxBehavior: StudentBehavior =
    studentViewModel.connectionState === 'connected'
      ? {
          kind: 'inbox',
          level: inboxBehaviorLevel,
          tooltip: getInboxBehaviorTooltip(inboxBehaviorLevel, localization)
        }
      : {
          kind: 'inbox',
          level: 'none'
        };

  const dailyActivityBehavior: StudentBehavior =
    studentViewModel.connectionState === 'connected'
      ? {
          kind: 'daily-activity',
          level: studentViewModel.lastConnectionIndicatorLevel,
          tooltip: getDailyActivityBehaviorTooltip(studentViewModel.lastConnection, localization, dateService)
        }
      : {
          kind: 'daily-activity',
          level: 'none'
        };

  const lastEmotionalStatusLevel = getStudentEmotionalStatusLevel(studentViewModel.emotionalStateRating);
  const lastEmotionalStatusBehavior: StudentBehavior =
    studentViewModel.connectionState === 'connected'
      ? {
          kind: 'last-emotional-status',
          level: lastEmotionalStatusLevel,
          tooltip: getStudentEmotionalStatusTooltip(studentViewModel.emotionalStateRating, localization)
        }
      : { kind: 'last-emotional-status', level: 'none' };

  const [searchParams, setSearchParams] = useSearchParams();
  const plannerRoute = route.resolveSchoolStudentPlannerLocation(plannerId, schoolId, courseSectionId, studentId);
  const insightsRoute = route.resolveSchoolStudentInsightsLocation(plannerId, schoolId, courseSectionId, studentId);
  const viewKey = (searchParams.get('view') ?? 'planner') as StudentTab;

  function onStudentTabChange(value: StudentTab) {
    searchParams.set('view', value);
    setSearchParams(searchParams);
  }

  return (
    <Stack sx={sx} className={className} spacing={{ xs: 1, sm: 2 }} height={{ xs: '100%', md: undefined }}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={isSmallScreen ? 'center' : 'flex-start'}
        px={3}
        spacing={2}
      >
        <Tabs value={viewKey} onChange={(_, value: StudentTab) => onStudentTabChange(value)}>
          <Tab
            value="planner"
            icon={<PlannerIcon />}
            iconPosition="start"
            label={strings.studentDetailsPlannerTabTitle}
            component={Link}
            to={plannerRoute}
            sx={{ minHeight: '0px !important' }}
          />
          <Tab
            value="insights"
            icon={<InsightsIcon />}
            iconPosition="start"
            label={strings.studentDetailsInsightsTabTitle}
            component={Link}
            to={insightsRoute}
            sx={{ minHeight: '0px !important' }}
          />
        </Tabs>

        {!isSmallScreen && <Box flex={1} />}

        {!isSmallScreen &&
          viewKey === 'planner' &&
          studentViewModel.plannerId &&
          studentViewModel.isPlannerAccessAuthorized && (
            <Button component={Link} to={route.resolvePlannerLocation(studentViewModel.plannerId)}>
              {strings.studentPlannerOpenStudentPlannerButtonTitle}
            </Button>
          )}

        {!isSmallScreen && (
          <StudentBehaviors
            isCompact={false}
            behaviors={{
              dailyActivity: dailyActivityBehavior,
              inbox: inboxBehavior,
              lastEmotionalStatus: lastEmotionalStatusBehavior
            }}
          />
        )}
      </Stack>

      <Box pt={{ xs: 0, md: 1 }} flex={1}>
        {viewKey === 'planner' && <StudentPlanner />}

        {viewKey === 'insights' &&
          (studentViewModel.connectionState === 'connected' ? (
            <StudentInsightsPage />
          ) : (
            <StudentDetailsNotConnected connectionState={studentViewModel.connectionState} />
          ))}
      </Box>
    </Stack>
  );
});
