import { useEffect, EffectCallback, DependencyList } from 'react';

/**
 * Use effect with a timeout.
 * @param effect The effect callback
 * @param delayMs The delay in milliseconds.
 * @param deps The dependency list.
 */
export function useDelayedEffect(effect: EffectCallback, delayMs: number, deps: DependencyList = []) {
  useEffect(() => {
    const timeout = setTimeout(effect, delayMs);

    return () => clearTimeout(timeout);
  }, deps);
}
