import { Day, dayToPBDate, SchoolAccountCourseSections, UserDailyActivity, UserDailyEmotionalStatus } from '@/models';
import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { Planners } from '@buf/studyo_studyo-today-planners.connectrpc_es/studyo/today/planners/v1/planners_connect';
import { AccountInfo } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_info_pb';
import { AccountKind } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_kind_pb';
import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { School } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_pb';
import { SchoolSharingMode } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/school_sharing_mode_pb';
import { Schools } from '@buf/studyo_studyo-today-schools.connectrpc_es/studyo/today/schools/v1/schools_connect';
import { zipWith } from 'lodash';
import { SchoolTransportService, UseSchoolParticipationCodeResponse } from '../contracts';
import { AppBaseTransportService } from './AppBaseTransportService';

export class AppSchoolTransportService extends AppBaseTransportService implements SchoolTransportService {
  async getParticipatingSchools(): Promise<SchoolInformation[]> {
    const response = await this.performReadRequest(
      'getParticipatingSchools',
      Planners,
      Planners.methods.getParticipatingSchools,
      {}
    );

    return response.schools;
  }

  async createSchool(name: string, subtitle: string | undefined, sharingMode: SchoolSharingMode): Promise<School> {
    return await this.performWriteRequest('createSchool', Schools, Schools.methods.createSchool, {
      name,
      subtitle,
      sharingMode
    });
  }

  async editSchool(schoolId: string, name: string, subtitle: string | undefined): Promise<School> {
    return await this.performWriteRequest(
      'editSchool',
      Schools,
      Schools.methods.renameSchool,
      { schoolId, name, subtitle },
      { isDemoObject: this.isDemoId(schoolId) }
    );
  }

  async shareSchool(schoolId: string, inheritedScheduleCycleId: string | undefined): Promise<void> {
    await this.performWriteRequest(
      'shareSchool',
      Schools,
      Schools.methods.shareSchool,
      { schoolId, inheritedScheduleCycleId },
      { isDemoObject: this.isDemoId(schoolId) }
    );
  }

  async unshareSchool(schoolId: string): Promise<void> {
    await this.performWriteRequest(
      'unshareSchool',
      Schools,
      Schools.methods.unshareSchool,
      { schoolId },
      { isDemoObject: this.isDemoId(schoolId) }
    );
  }

  async archiveSchool(schoolId: string): Promise<void> {
    await this.performWriteRequest(
      'archiveSchool',
      Schools,
      Schools.methods.archiveSchool,
      { schoolId },
      { isDemoObject: this.isDemoId(schoolId) }
    );
  }

  async unarchiveSchool(schoolId: string): Promise<void> {
    await this.performWriteRequest(
      'unarchiveSchool',
      Schools,
      Schools.methods.unarchiveSchool,
      { schoolId },
      { isDemoObject: this.isDemoId(schoolId) }
    );
  }

  async getCourseSectionInSchool(schoolId: string, courseId: string, timezone: string): Promise<CourseSection> {
    return await this.performReadRequest(
      'getSchoolCourseSection',
      Schools,
      Schools.methods.getSchoolCourseSection,
      { schoolId, courseSectionId: courseId, timezone },
      { isDemoObject: this.isDemoId(courseId), supportsDemoMode: true }
    );
  }

  async getSchoolAccounts(
    schoolId: string,
    accountKinds: AccountKind[],
    accountIds: string[] | undefined,
    includeDeletedAccounts: boolean
  ): Promise<Account[]> {
    const response = await this.performReadRequest('getSchoolAccounts', Schools, Schools.methods.getSchoolAccounts, {
      schoolId,
      accountKinds,
      accountIds,
      includeDeletedAccounts
    });

    return response.accounts;
  }

  async getSchoolAccount(accountId: string, schoolId: string): Promise<Account> {
    return await this.performReadRequest('getSchoolAccount', Schools, Schools.methods.getSchoolAccount, {
      accountId,
      schoolId
    });
  }

  async batchCreateSchoolAccounts(schoolId: string, accounts: AccountInfo[], kind: AccountKind): Promise<Account[]> {
    const response = await this.performWriteRequest(
      'batchCreateSchoolAccounts',
      Schools,
      Schools.methods.batchCreateSchoolAccounts,
      {
        schoolId,
        accountKind: kind,
        sendInvitationEmail: true,
        accountInfos: accounts
      }
    );

    return response.accounts;
  }

  async sendTeacherInvitationEmail(schoolId: string, accountIds: string[]): Promise<void> {
    await this.performWriteRequest(
      'sendTeacherInvitationEmailRequest',
      Schools,
      Schools.methods.sendTeacherInvitationEmail,
      { schoolId, accountIds }
    );
  }

  async getSchoolCourseSections(schoolId: string): Promise<CourseSection[]> {
    const response = await this.performReadRequest(
      'getSchoolCourseSections',
      Schools,
      Schools.methods.getCourseSections,
      { schoolId },
      { isDemoObject: this.isDemoId(schoolId), supportsDemoMode: true }
    );
    return response.courseSections;
  }

  async getSchoolDemoCourseSections(schoolId: string): Promise<CourseSection[]> {
    if (this._settingsStorage.isDemoMode) {
      // GetSchoolCourseSections will already return the demo course sections.
      return [];
    }

    const response = await this.performReadRequest(
      'getSchoolDemoCourseSections',
      Schools,
      Schools.methods.getCourseSections,
      { schoolId },
      { isDemoObject: true }
    );
    return response.courseSections;
  }

  async getSchoolCourseSectionsForAccount(schoolId: string, accountId: string): Promise<SchoolAccountCourseSections> {
    const response = await this.performReadRequest(
      'getSchoolCourseSectionsForAccount',
      Schools,
      Schools.methods.getAccountCourseSections,
      { schoolId, accountId },
      { isDemoObject: this.isDemoId(schoolId) || this.isDemoId(accountId) }
    );

    return { student: response.studentCourseSections, teacher: response.teacherCourseSections };
  }

  async updateCourseSectionColorInSchool(courseId: string, backgroundColor: string): Promise<CourseSection> {
    return await this.performWriteRequest(
      'updateCourseSectionColorInSchool',
      Schools,
      Schools.methods.setCourseSectionColor,
      { courseSectionId: courseId, color: backgroundColor },
      { isDemoObject: this.isDemoId(courseId) }
    );
  }

  async getStudentDailyActivity(
    accountId: string,
    schoolId: string,
    minDate: Day,
    maxDate: Day
  ): Promise<UserDailyActivity[]> {
    const response = await this.performReadRequest(
      'getStudentDailyActivity',
      Schools,
      Schools.methods.getStudentDailyActivity,
      { schoolId, accountId, minDate: dayToPBDate(minDate), maxDate: dayToPBDate(maxDate) },
      { isDemoObject: this.isDemoId(accountId), supportsDemoMode: true }
    );

    return zipWith(response.dates, response.values, (date, value) => ({
      date: { year: date.year, month: date.month, day: date.day },
      value
    }));
  }

  async getStudentDailyEmotionalStatus(
    accountId: string,
    schoolId: string,
    minDate: Day,
    maxDate: Day
  ): Promise<UserDailyEmotionalStatus[]> {
    const response = await this.performReadRequest(
      'getStudentDailyEmotionalStatus',
      Schools,
      Schools.methods.getStudentDailyEmotionalPulseRating,
      { schoolId, accountId, minDate: dayToPBDate(minDate), maxDate: dayToPBDate(maxDate) },
      { isDemoObject: this.isDemoId(accountId), supportsDemoMode: true }
    );

    return zipWith(response.dates, response.values, (date, value) => ({
      date: { year: date.year, month: date.month, day: date.day },
      value
    }));
  }

  async getParticipationCode(schoolId: string, accountKind: AccountKind): Promise<string> {
    const response = await this.performReadRequest(
      'getParticipationCode',
      Schools,
      Schools.methods.getParticipationCode,
      { schoolId, accountKind },
      { isDemoObject: this.isDemoId(schoolId) }
    );

    return response.participationCode;
  }

  async useParticipationCode(code: string): Promise<UseSchoolParticipationCodeResponse> {
    const response = await this.performWriteRequest(
      'useSchoolParticipationCode',
      Schools,
      Schools.methods.useParticipationCode,
      { participationCode: code }
    );

    return { schoolId: response.schoolId, accountKind: response.accountKind };
  }

  async getSchool(schoolId: string): Promise<School> {
    return await this.performReadRequest('getSchool', Schools, Schools.methods.getSchool, { schoolId });
  }
}
