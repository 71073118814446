import { useServices } from '@/hooks';
import { dateToTimeOfDay, getOptionalDashConcatenation, TimeOfDay } from '@/models';
import { ScheduleCycleActivitySchedulesPeriodInfo, ScheduleCyclePeriodSchedulePeriodEditViewModel } from '@/viewmodels';
import { GetAppRounded, JoinFullRounded } from '@mui/icons-material';
import { alpha, Box, Card, CardActionArea, Tooltip, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { differenceInMinutes, set } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { formatCalendarItemTimes } from '../../../calendar';
import { ScheduleCyclePeriodEditPopover } from './ScheduleCyclePeriodEditPopover';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';

export interface ScheduleCyclePeriodScheduleGridPeriodViewProps {
  sx?: SxProps;
  period: ScheduleCycleActivitySchedulesPeriodInfo;
  getEditViewModel: (periodId: string, time: TimeOfDay) => Promise<ScheduleCyclePeriodSchedulePeriodEditViewModel>;
  supportsActivitySchedules: boolean;
  isReadOnly: boolean;
}

export const ScheduleCyclePeriodScheduleGridPeriodView = observer(
  ({
    sx,
    period,
    getEditViewModel,
    supportsActivitySchedules,
    isReadOnly
  }: ScheduleCyclePeriodScheduleGridPeriodViewProps) => {
    const { dateService } = useServices();
    const theme = useTheme();
    const strings = LocalizedStrings.scheduleCycle.edit.masterSchedule;

    const topOffset =
      period.startTime.hours * ScheduleCyclePeriodScheduleGridConstants.pointsPerHour +
      (period.startTime.minutes * ScheduleCyclePeriodScheduleGridConstants.pointsPerHour) / 60;

    const startTime = set(dateService.now, {
      hours: period.startTime.hours,
      minutes: period.startTime.minutes
    });
    const endTime = set(dateService.now, {
      hours: period.endTime.hours,
      minutes: period.endTime.minutes
    });

    const activitySchedule = period.primaryActivityScheduleInfo;
    const roomName = activitySchedule?.activitySchedule.roomName;

    const numberOfMinutes = differenceInMinutes(endTime, startTime);
    // Removing 1 so that periods that end at the same time another one begins have a slight offset between.
    const height = (numberOfMinutes * ScheduleCyclePeriodScheduleGridConstants.pointsPerHour) / 60 - 1;
    const isSmallPeriod = numberOfMinutes <= 40;

    const [cardRef, setCardRef] = useState<HTMLButtonElement | null>(null);
    const noCourseColor = theme.palette.mode === 'dark' ? theme.palette.grey['400'] : theme.palette.grey['500'];
    const activityColor = activitySchedule?.activity.color ?? noCourseColor;
    const isCurrentTermActivity = activitySchedule?.isCurrentTerm === true;
    const activityBackgroundColor = alpha(activityColor, isCurrentTermActivity ? 0.8 : cardRef ? 0.3 : 0.15);
    const textColor = isCurrentTermActivity ? theme.palette.getContrastText(activityColor) : theme.palette.text.primary;
    const showTimeOnSameLineAsTitle = isSmallPeriod;

    let title = period.periodLabel;

    if (activitySchedule != null) {
      if (isSmallPeriod) {
        title += ` - ${activitySchedule.activity.title}`;
      } else {
        title += ` - ${getOptionalDashConcatenation(activitySchedule.activity.title, activitySchedule.activity.section)}`;
      }
    } else if (supportsActivitySchedules) {
      title += ` - (${strings.emptyPeriodTitle()})`;
    }

    if (isSmallPeriod) {
      title += ',';
    }

    return (
      <Box
        sx={{
          ...sx,
          position: 'absolute',
          top: topOffset,
          left: 0,
          right: 0,
          height,
          px: 0.5,
          zIndex: ScheduleCyclePeriodScheduleGridConstants.eventsZIndex
        }}
        onClick={(e) => {
          // Prevents background click event when user clicks on the left or right padding of the period.
          e.stopPropagation();
        }}
      >
        <Card
          elevation={cardRef ? 10 : 0}
          sx={{
            height: '100%',
            backgroundImage: 'none',
            borderRadius: 0.5,
            color: textColor,
            position: 'relative',
            border: `1px solid ${alpha(activityColor, isCurrentTermActivity ? 1 : 0.5)}`
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              backgroundColor: activityBackgroundColor
            }}
          />

          <CardActionArea
            disabled={isReadOnly}
            onClick={(e) => {
              e.stopPropagation();
              setCardRef(e.currentTarget);
            }}
            sx={{
              px: '6px',
              py: '2px',
              overflow: 'hidden',
              height: '100%'
            }}
          >
            <Box width={'100%'} height={'100%'}>
              <Box
                display={showTimeOnSameLineAsTitle ? 'inline-block' : 'flex'}
                flexDirection={showTimeOnSameLineAsTitle ? undefined : 'column'}
              >
                <Typography
                  variant="caption"
                  fontWeight={activitySchedule != null ? '600' : '500'}
                  noWrap
                  sx={{ textOverflow: 'clip' }}
                >
                  {title}
                </Typography>
                <Typography
                  noWrap
                  variant="caption"
                  sx={{ textOverflow: 'clip', ml: showTimeOnSameLineAsTitle ? 0.5 : 0, lineHeight: 1 }}
                >
                  {formatCalendarItemTimes(dateToTimeOfDay(startTime), dateToTimeOfDay(endTime), !isSmallPeriod)}
                </Typography>

                {!showTimeOnSameLineAsTitle && roomName != null && roomName.length > 0 && (
                  <Typography
                    noWrap
                    variant="caption"
                    sx={{ textOverflow: 'clip', ml: showTimeOnSameLineAsTitle ? 0.5 : 0, lineHeight: 1, mt: 0.5 }}
                  >
                    {roomName}
                  </Typography>
                )}
              </Box>
            </Box>

            {period.hasConflictingActivitySchedules && (
              <Tooltip title={strings.periodMultipleActivitySchedulesInTermTooltip()} disableInteractive>
                <JoinFullRounded
                  color="inherit"
                  sx={{ position: 'absolute', bottom: 2, right: 2, height: 14, width: 14 }}
                />
              </Tooltip>
            )}

            {!period.hasConflictingActivitySchedules &&
              period.primaryActivityScheduleInfo != null &&
              !period.primaryActivityScheduleInfo.isCurrentTerm && (
                <Tooltip title={strings.inheritedActivityScheduleFromOtherTerm()} disableInteractive>
                  <GetAppRounded
                    color="inherit"
                    sx={{ position: 'absolute', bottom: 2, right: 2, height: 14, width: 14 }}
                  />
                </Tooltip>
              )}
          </CardActionArea>

          {cardRef != null && (
            <ScheduleCyclePeriodEditPopover
              isOpen={true}
              anchorEl={cardRef}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center'
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left'
              }}
              supportsActivities={supportsActivitySchedules}
              sx={{ width: '100%', height: '100%' }}
              getViewModel={() => getEditViewModel(period.id, period.startTime)}
              dismiss={() => setCardRef(null)}
            />
          )}
        </Card>
      </Box>
    );
  }
);
