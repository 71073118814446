import { PlannedWorkEditViewModel } from '@/viewmodels';
import { Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { PlannedWorkEditComponentIds } from './PlannedWorkEditComponentIds';
import { usePlannedWorkEditInteractionContext } from './PlannedWorkEditInteractionContext';
import { PlannedWorkEditInteractionLayer } from './PlannedWorkEditInteractionLayer';
import { PlannedWorkEditDayColumn } from './PlannerWorkEditDayColumn';

export interface PlannerWorkEditScheduleBodyProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannedWorkEditViewModel;
  dates: Date[];
}

export const PlannedWorkEditScheduleBody = observer(
  ({ sx, className, viewModel, dates }: PlannerWorkEditScheduleBodyProps) => {
    const theme = useTheme();
    const dividerStyle = `1px solid ${theme.palette.divider}`;
    const { containerSize } = usePlannedWorkEditInteractionContext();

    return (
      <Box
        sx={{
          ...sx,
          ...containerSize
        }}
        className={className}
        id={PlannedWorkEditComponentIds.weekBody}
      >
        <Box
          sx={{
            ...sx,
            width: '100%',
            height: '100%',
            display: 'flex',
            position: 'relative'
          }}
          className={className}
        >
          <Box sx={{ position: 'absolute', left: 0, top: 0, right: 0, bottom: 0, display: 'flex' }}>
            {dates.map((d, i) => (
              <PlannedWorkEditDayColumn
                key={d.toString()}
                sx={{
                  borderRight: i < 6 ? dividerStyle : undefined,
                  width: `${100 / 7}%`,
                  flex: 1,
                  flexShrink: 0,
                  height: containerSize.height
                }}
                date={d}
                viewModel={viewModel}
              />
            ))}
          </Box>

          <PlannedWorkEditInteractionLayer sx={{ width: '100%', height: '100%' }} dates={dates} viewModel={viewModel} />
        </Box>
      </Box>
    );
  }
);
