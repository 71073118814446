import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PlannerItemsEmptyIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerItemsEmptyIndicator = observer(({ sx, className }: PlannerItemsEmptyIndicatorProps) => {
  return (
    <Typography sx={{ ...sx, px: 3, py: 2 }} className={className} variant="subtitle2" color="textSecondary">
      {LocalizedStrings.planner.items.emptyLabel()}
    </Typography>
  );
});
