export function getOptionalDashConcatenation(
  left: string | undefined,
  right: string | undefined,
  useLongDash?: boolean
): string {
  return left == null || left.length === 0
    ? right ?? ''
    : right == null || right.length === 0
      ? left
      : `${left} ${useLongDash === true ? '—' : '-'} ${right}`;
}
