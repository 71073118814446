import { Stack, Typography } from '@mui/material';
import { Fragment, ReactElement } from 'react';

export interface MultilineTooltipTitleProps {
  lines: string[];
}

export function MultilineTooltipTitle({ lines }: MultilineTooltipTitleProps) {
  return (
    <span>
      {lines.map((line) => (
        <Fragment key={line}>
          {line}
          <br />
        </Fragment>
      ))}
    </span>
  );
}

export interface MultilineTooltipIconTitleProps {
  lines: { title: string; icon: ReactElement }[];
}

export function MultilineTooltipIconTitle({ lines }: MultilineTooltipIconTitleProps) {
  return (
    <Stack spacing={1} p={0.5}>
      {lines.map((line) => (
        <Stack direction="row" spacing={1} key={line.title} alignItems="center">
          {line.icon}
          <Typography fontSize="inherit">{line.title}</Typography>
        </Stack>
      ))}
    </Stack>
  );
}
