import { useServices } from '@/hooks';
import { ServiceProvider } from '@/providers';
import RefreshIcon from '@mui/icons-material/RefreshRounded';
import { Button, Typography } from '@mui/material';
import { StyledEngineProvider, styled } from '@mui/material/styles';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { MouseEvent, ReactNode } from 'react';
import { AppThemeProvider } from '../AppThemeProvider';
import { Splash } from './Splash';
import { ErrorIndicator } from './utils';

export interface ErrorBoundaryProps {
  children: ReactNode;
  services: ServiceProvider;
}

export const ErrorBoundary = (props: ErrorBoundaryProps) => {
  const fallback = <ErrorBoundaryFallback />;
  return (
    <SentryErrorBoundary
      fallback={fallback}
      onError={(error, componentStack) => {
        const { services } = props;

        console.error(error);
        console.log(componentStack);

        const lastErrorMessage = error.message ?? '(no message)';
        services.analytics.logError(`Fatal error: ${lastErrorMessage}`, true);
      }}
    >
      {props.children}
    </SentryErrorBoundary>
  );
};

export const ErrorBoundaryFallback = () => {
  const { localization, intercom, authentication } = useServices();
  const strings = localization.localizedStrings.utils;

  const onRefreshButtonPressed = () => {
    window.location.reload();
  };

  const onContactUsButtonPressed = (e: MouseEvent<HTMLAnchorElement>) => {
    const message = localization.localizedStrings.utils.errorDetailsMessage;

    e.preventDefault();
    intercom.showChat(message);
    return false;
  };

  async function signOut() {
    await authentication.signOut();
  }

  return (
    <StyledEngineProvider injectFirst>
      <AppThemeProvider>
        {() => (
          <Splash width="100%" height="100%">
            <ErrorIndicator useDefaultErrorMessage={false} />
            <Typography variant="caption" color="error" mt={1} sx={{ textAlign: 'center' }}>
              <ErrorText>
                <>
                  {strings.errorContent}
                  <ContactUsLink href="/#" onClick={onContactUsButtonPressed}>
                    {strings.errorContactUs}
                  </ContactUsLink>
                  .
                </>
              </ErrorText>
            </Typography>

            <Button
              color="primary"
              variant="contained"
              sx={{ mt: 2 }}
              startIcon={<RefreshIcon />}
              onClick={onRefreshButtonPressed}
            >
              {strings.errorRefreshLabel}
            </Button>

            <Button color="primary" sx={{ mt: 2 }} onClick={() => void signOut()}>
              {strings.logoutLabel}
            </Button>
          </Splash>
        )}
      </AppThemeProvider>
    </StyledEngineProvider>
  );
};

const ErrorText = styled('pre')(({ theme }) => ({
  textAlign: 'center',
  font: theme.typography.caption.font,
  fontFamily: theme.typography.caption.fontFamily,
  fontWeight: theme.typography.caption.fontWeight,
  fontSize: theme.typography.caption.fontSize
}));

const ContactUsLink = styled('a')(({ theme }) => ({
  color: theme.palette.error.main
}));
