import { useServices } from '@/hooks';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { Link } from 'react-router-dom';

export interface AdminSchoolClassesTableTitleCellProps {
  sx?: SxProps;
  className?: string;
  color: string;
  title: string;
  schoolId: string;
  courseSectionId: string;
}

export function AdminSchoolClassesTableTitleCell({
  sx,
  className,
  color,
  title,
  schoolId,
  courseSectionId
}: AdminSchoolClassesTableTitleCellProps) {
  const { route } = useServices();
  const theme = useTheme();
  const detailsLocation = route.resolveAdminClassDetailsLocation(schoolId, courseSectionId);

  return (
    <Stack
      direction="row"
      spacing={1}
      alignItems="center"
      component={Link}
      to={detailsLocation}
      color={theme.palette.text.primary}
      sx={{ ...sx, textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
      className={className}
    >
      <Box sx={{ backgroundColor: color, width: 4, borderRadius: 2, height: 28 }} />
      <Typography flex={1}>{title}</Typography>
    </Stack>
  );
}
