import { SchoolInformation } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/school_information_pb';
import { Box, Drawer, Stack, Toolbar, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { SideBarButton, UserProfileIcon } from '../../shared';
import { AdminProfileMenu } from './AdminProfileMenu';
import { AdminSidebar } from './AdminSidebar';

const drawerWidth = 240;

export interface AdminLayoutDrawerProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  schoolId: string;
  school: SchoolInformation | undefined;
  variant: 'mobile' | 'web';
}

export const AdminLayoutDrawer = observer(
  ({ isOpen, onClose, variant, sx, className, schoolId, school }: AdminLayoutDrawerProps) => {
    const { user } = useServices();
    const userProfile = user.currentUser!;
    const [showProfileMenu, setShowProfileMenu] = useState(false);
    const profileButtonRef = useRef<HTMLElement | null>(null);
    const theme = useTheme();

    return (
      <Box sx={sx} className={className} overflow="hidden">
        <Drawer
          variant={variant === 'web' ? 'permanent' : 'temporary'}
          open={isOpen}
          onClose={() => onClose()}
          PaperProps={{ sx: { border: 0 } }}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
          sx={{
            width: drawerWidth,
            display: variant === 'mobile' ? { xs: 'block', sm: 'none' } : { xs: 'none', sm: 'flex' },
            '& .MuiDrawer-paper': { width: drawerWidth, boxSizing: 'border-box' }
          }}
        >
          {variant === 'web' ? (
            <Toolbar />
          ) : (
            <Typography variant="h6" fontWeight="600" px={2} pt={2}>
              {LocalizedStrings.admin.sidebar.mobileTitle()}
            </Typography>
          )}

          <Stack sx={{ flex: 1, borderRight: `1px solid ${theme.palette.divider}`, overflow: 'hidden' }}>
            <AdminSidebar sx={{ flex: 1 }} schoolId={schoolId} school={school} onSelectOption={() => onClose()} />

            <SideBarButton
              ref={profileButtonRef}
              sx={{ flex: 0, m: 1 }}
              isCompact={false}
              title={userProfile.fullName}
              secondary={userProfile.emailAddress}
              secondaryCanWrap={false}
              icon={<UserProfileIcon url={userProfile.pictureUrl} userFullName={userProfile.fullName} />}
              iconKind="avatar"
              onSelect={() => setShowProfileMenu(true)}
            />
          </Stack>
        </Drawer>

        <AdminProfileMenu
          isOpen={showProfileMenu}
          onClose={() => setShowProfileMenu(false)}
          anchorEl={profileButtonRef.current}
        />
      </Box>
    );
  }
);
