import { ScheduleCycleKind, ScheduleCycleSpecialDayInfo, titleForCycleDay } from '@/viewmodels';
import { CycleDayEffect } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/cycle_day_effect_pb';
import { DeleteRounded, EditRounded } from '@mui/icons-material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';
import { IconButton, ListItem, ListItemIcon, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { ConfirmationDialog, ListItemTextMultiline, MultilineTooltipIconTitle } from '../../../../utils';
import { SpecialDaySymbolView } from '../../../calendar';
import { iconForSpecialDayOccurrence } from './SpecialDayUtils';
import { SpecialDayEditDialog } from './edit';

interface LocationState {
  showSpecialDayEditForId: string;
}

export interface ScheduleCycleSpecialDaysListItemProps {
  sx?: SxProps;
  className?: string;
  specialDayInfo: ScheduleCycleSpecialDayInfo;
  scheduleCycleKind: ScheduleCycleKind;
  cycleDayNames: string[];
  action?: { role: 'add' | 'remove'; onClick: (id: string) => void };
  onDelete: (id: string) => void;
  isReadOnly: boolean;
  canDisplayTooltip: boolean;
}

export const ScheduleCycleSpecialDaysListItem = observer(
  ({
    sx,
    className,
    specialDayInfo,
    action,
    scheduleCycleKind,
    cycleDayNames,
    isReadOnly,
    onDelete,
    canDisplayTooltip
  }: ScheduleCycleSpecialDaysListItemProps) => {
    const { specialDay } = specialDayInfo;
    const [showTooltip, setShowTooltip] = useState(false);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    const location = useLocation();
    const state = (location.state ?? {}) as LocationState;
    const navigate = useNavigate();
    const strings = LocalizedStrings.scheduleCycle.edit.specialDays.list;

    function showEdit() {
      setShowTooltip(false);
      const newState: LocationState = { ...state, showSpecialDayEditForId: specialDay.id };
      navigate(location, { state: newState });
    }

    function onDeleteButtonClick() {
      setShowTooltip(false);
      setShowDeleteConfirmation(true);
    }

    function onDeleteConfirm(hasConfirmed: boolean) {
      setShowDeleteConfirmation(false);
      if (hasConfirmed) {
        onDelete(specialDay.id);
      }
    }

    return (
      <Tooltip
        title={
          specialDayInfo.occurrences.length > 0 ? (
            <MultilineTooltipIconTitle
              lines={specialDayInfo.occurrences.map((o) => ({
                title: o.title,
                icon: iconForSpecialDayOccurrence(o.occurrence.case)
              }))}
            />
          ) : undefined
        }
        disableInteractive
        placement="right"
        open={canDisplayTooltip && showTooltip}
        disableHoverListener
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
      >
        <ListItem
          sx={{ ...sx, borderRadius: 1, overflow: 'hidden', cursor: !isReadOnly ? 'grab' : undefined }}
          className={className}
          secondaryAction={
            !isReadOnly ? (
              action != null ? (
                <IconButton
                  color={action.role === 'add' ? 'success' : 'error'}
                  onClick={() => action?.onClick(specialDay.id)}
                >
                  {action.role === 'add' ? <AddCircleRoundedIcon /> : <RemoveCircleRoundedIcon />}
                </IconButton>
              ) : (
                <>
                  <IconButton onClick={showEdit} size="small">
                    <EditRounded fontSize="small" />
                  </IconButton>

                  <IconButton onClick={onDeleteButtonClick} size="small" color="error">
                    <DeleteRounded fontSize="small" />
                  </IconButton>
                </>
              )
            ) : undefined
          }
        >
          {specialDay.symbol.length > 0 && (
            <ListItemIcon sx={{ mr: 1 }}>
              <SpecialDaySymbolView size={22} specialDay={specialDay} />
            </ListItemIcon>
          )}

          <ListItemTextMultiline
            primary={specialDay.title}
            secondary={subtitleForSpecialDay(specialDayInfo, scheduleCycleKind, cycleDayNames)}
          />

          {state.showSpecialDayEditForId === specialDay.id && (
            <SpecialDayEditDialog
              specialDayId={state.showSpecialDayEditForId}
              scheduleCycleId={specialDayInfo.scheduleCycleId}
              isOpen={true}
              close={() => navigate(-1)}
            />
          )}

          <ConfirmationDialog
            isOpen={showDeleteConfirmation}
            title={strings.deleteConfirmationTitle()}
            message={strings.deleteConfirmationMessage()}
            onSubmit={onDeleteConfirm}
            confirmButtonLabel={strings.deleteConfirmationSubmitLabel()}
            isDestructive
          />
        </ListItem>
      </Tooltip>
    );
  }
);

function subtitleForSpecialDay(
  specialDayInfo: ScheduleCycleSpecialDayInfo,
  scheduleCycleKind: ScheduleCycleKind,
  cycleDayNames: string[]
): string {
  const { specialDay, schedules } = specialDayInfo;
  const strings = LocalizedStrings.scheduleCycle.edit.specialDays.list;
  const components: string[] = [];

  if (scheduleCycleKind !== 'cycle-day') {
    if (specialDay.cycleDayEffect !== CycleDayEffect.UNSPECIFIED) {
      if (specialDay.cycleDay > 0) {
        const cycleDayTitle = titleForCycleDay(specialDay.cycleDay, scheduleCycleKind, 'long', false, cycleDayNames);
        components.push(strings.specialDayDayOfWeek(cycleDayTitle));
      } else {
        components.push(strings.specialDaySubtitleNoCycleDay());
      }
    }
  } else {
    if (specialDay.cycleDayEffect !== CycleDayEffect.UNSPECIFIED) {
      const effectTitle = LocalizedStrings.models.cycleDayEffect.title[specialDay.cycleDayEffect];
      components.push(strings.specialDaySubtitleCycleDayEffectCycleDay(effectTitle()));
    }

    if (specialDay.cycleDay > 0) {
      components.push(strings.specialDayCycleDay(String(specialDay.cycleDay)));
    }
  }

  if (schedules.length > 0) {
    const schedulesTitle = `${schedules[0].name}${schedules.length > 1 ? ` +${schedules.length - 1}` : ''}`;
    components.push(strings.specialDayBellTimes(schedulesTitle));
  }

  return components.join('\n');
}
