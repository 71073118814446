import { ScheduleCycleActivitySchedulesDayColumnInfo } from '@/viewmodels';
import { WarningAmberRounded } from '@mui/icons-material';
import { CardActionArea, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent } from 'react';
import LocalizedStrings from 'strings';
import { AlertTooltip } from '../../../../utils';
import { ScheduleCyclePeriodScheduleGridConstants } from './ScheduleCyclePeriodScheduleGridConstants';

export interface ScheduleCyclePeriodScheduleGridDayHeaderProps {
  sx?: SxProps;
  column: ScheduleCycleActivitySchedulesDayColumnInfo;
  onClick?: (column: ScheduleCycleActivitySchedulesDayColumnInfo, event: MouseEvent<HTMLElement>) => void;
  isReadOnly: boolean;
}

export const ScheduleCyclePeriodScheduleGridDayHeader = observer(
  ({ sx, column, onClick, isReadOnly }: ScheduleCyclePeriodScheduleGridDayHeaderProps) => {
    const strings = LocalizedStrings.scheduleCycle.edit.masterSchedule;
    const multiplePeriodsTooltip = column.hasMultiplePeriodsWithSameLabel
      ? strings.multiplePeriodsWithSameLabelWarning()
      : undefined;
    const otherOccurrencesTooltip = column.hasOtherOccurrencesWithSameTarget
      ? strings.hasOtherOccurrencesWithSameTarget()
      : undefined;

    return (
      <Stack
        sx={{
          ...sx,
          width: ScheduleCyclePeriodScheduleGridConstants.dayColumnWidth
        }}
        position="relative"
        direction="row"
        alignItems="center"
      >
        <CardActionArea
          sx={{ p: column.subtitle ? 0.5 : 1 }}
          onClick={onClick != null ? (e) => onClick(column, e) : undefined}
          disabled={onClick == null || isReadOnly}
        >
          <Stack flex={1}>
            <Typography variant="subtitle2" color={column.canEdit ? 'textPrimary' : 'textSecondary'} noWrap>
              {column.title}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              fontStyle={!column.canEdit ? 'italic' : undefined}
              noWrap
            >
              {column.subtitle}
            </Typography>
          </Stack>
        </CardActionArea>

        {(multiplePeriodsTooltip != null || otherOccurrencesTooltip != null) && (
          <AlertTooltip titles={[multiplePeriodsTooltip, otherOccurrencesTooltip]} title="" severity="warning">
            <WarningAmberRounded color="warning" fontSize="small" />
          </AlertTooltip>
        )}
      </Stack>
    );
  }
);
