import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';
import { PreferredMaxWidth } from './SettingsConstants';

export interface SettingsContentProps {
  sx?: SxProps;
  className?: string;
  disablePadding?: boolean;
  topPadding?: number;
  children: ReactNode;
  fluidWidth?: boolean;
}

export function SettingsContent({
  sx,
  className,
  disablePadding = false,
  topPadding,
  children,
  fluidWidth = false
}: SettingsContentProps) {
  return (
    <Box
      sx={{ ...sx, height: '100%', width: '100%', display: 'flex', flexDirection: 'column', overflowY: 'auto' }}
      className={className}
      p={{ xs: disablePadding ? 0 : 2, sm: 0 }}
      pt={{ xs: topPadding ?? 1, sm: topPadding ?? (disablePadding ? 0 : 2) }}
      maxWidth={fluidWidth ? undefined : PreferredMaxWidth}
    >
      {children}
    </Box>
  );
}
