import { useServices } from '@/hooks';
import { BaseOnboardingScreenViewModel } from '@/viewmodels';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, StackProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

export interface OnboardingStepActionsProps extends StackProps {
  className?: string;
  viewModel: BaseOnboardingScreenViewModel;

  /**
   * `Next` button label. Optional. Default is `localizedStrings.onboarding.continue`.
   */
  nextButtonLabel?: string;

  showAuthStateButton?: boolean;

  /**
   * Additional actions to render. Optional.
   */
  additionalActions?(): ReactNode;
}

export const OnboardingStepActions = observer(
  ({
    className,
    viewModel,
    nextButtonLabel,
    additionalActions,
    showAuthStateButton = false,
    ...props
  }: OnboardingStepActionsProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.onboarding;

    return (
      <Stack {...props} className={className} spacing={2} direction={{ xs: 'column' }} width="100%">
        <LoadingButton
          color="primary"
          loading={viewModel.isSubmitting}
          variant="contained"
          disabled={!viewModel.canSubmit}
          type="submit"
        >
          {nextButtonLabel ?? strings.continue}
        </LoadingButton>
        {additionalActions?.()}

        {showAuthStateButton && (
          <>
            <Button variant="text" onClick={() => void viewModel.signOut()}>
              {strings.signOut}
            </Button>
          </>
        )}
      </Stack>
    );
  }
);
