import { UserDashboardCalendarWeekPeriodInfoItem } from '@/viewmodels';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ColorIndicator } from '../../../../lists';

export interface UserDashboardCalendarWeekPeriodMoreItemsProps {
  sx?: SxProps;
  className?: string;
  items: UserDashboardCalendarWeekPeriodInfoItem[];
  isAllItems: boolean;
}

export const UserDashboardCalendarWeekPeriodMoreItems = observer(
  ({ sx, className, items, isAllItems }: UserDashboardCalendarWeekPeriodMoreItemsProps) => {
    // Only showing a maximum of 3 colors.
    const colors = items.map((item) => item.value.color).slice(0, 3);
    const strings = LocalizedStrings.calendar.week;

    return (
      <Stack sx={sx} className={className} direction="row" alignItems="center" spacing={0.5}>
        {colors.length > 0 && (
          <Stack direction="row" height={14} spacing="2px">
            {colors.map((color, index) => (
              <ColorIndicator key={index} color={color} isRounded />
            ))}
          </Stack>
        )}

        <Typography fontSize={12} noWrap flex={1} color="textSecondary">
          {isAllItems ? strings.periodItemsCount(items.length) : strings.periodMoreWorks(items.length)}
        </Typography>
      </Stack>
    );
  }
);
