import { PlannedWorkEditDayViewModel } from '@/viewmodels';
import { Box, Paper, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { ScrollbarSpacer } from '../../utils';
import { PlanningWorkEditWeekDayHeader } from './PlannedWorkEditWeekDayHeader';

export interface PlannedWorkEditWeekDayHeadersProps {
  sx?: SxProps;
  className?: string;
  leftPadding: number;
  dates: Date[];
  getDayViewModel: (date: Date) => PlannedWorkEditDayViewModel;
}

export const PlannedWorkEditWeekDayHeaders = observer(
  ({ sx, leftPadding, dates, className, getDayViewModel }: PlannedWorkEditWeekDayHeadersProps) => {
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === 'dark';
    const dividerStyle = `1px solid ${theme.palette.divider}`;

    const viewModels = useMemo(() => dates.map((d) => getDayViewModel(d)), [dates]);

    return (
      <Paper
        elevation={isDarkMode ? 2 : 1}
        sx={{
          ...sx,
          display: 'flex',
          flexDirection: 'row',
          borderBottom: isDarkMode ? dividerStyle : undefined,
          boxShadow: 2
        }}
        className={className}
        square
      >
        <Box sx={{ width: leftPadding }} />

        <Stack direction="row" flex={1}>
          {viewModels.map((vm, index) => (
            <PlanningWorkEditWeekDayHeader
              key={vm.date.toString()}
              sx={{ width: `${100 / 7}%`, flex: 1, flexShrink: 0, borderRight: index < 6 ? dividerStyle : undefined }}
              viewModel={vm}
            />
          ))}
        </Stack>
        <ScrollbarSpacer />
      </Paper>
    );
  }
);
