import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface EmojiSymbolProps {
  emoji: string;
  size: number;
  sx?: SxProps;
  className?: string;
}

export const EmojiSymbol = observer(({ emoji, size, sx, className }: EmojiSymbolProps) => {
  return (
    <Box className={className} sx={{ ...sx, width: size, height: size }}>
      <Typography sx={{ ...sx, width: '100%', height: '100%', lineHeight: `${size}px` }} fontSize={size * 0.9}>
        {emoji}
      </Typography>
    </Box>
  );
});
