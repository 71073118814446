import i18n from 'translate';
import { AdminClassesStrings } from './AdminClassesStrings';
import { AdminScheduleStrings } from './AdminScheduleStrings';
import { AdminTeachersListStrings } from './AdminTeachersListStrings';

export const AdminGeneralManageArchiveActionStrings = {
  actionTitle: () => i18n.t('admin.general.manageActions.archive.actionTitle'),
  confirmationMessage: (schoolName: string) =>
    i18n.t('admin.general.manageActions.archive.confirmationMessage', { schoolName }),
  confirmationSubmit: () => i18n.t('admin.general.manageActions.archive.confirmationSubmit'),
  confirmationTitle: () => i18n.t('admin.general.manageActions.archive.confirmationTitle'),
  errorMessage: () => i18n.t('admin.general.manageActions.archive.errorMessage'),
  subtitle: () => i18n.t('admin.general.manageActions.archive.subtitle'),
  title: () => i18n.t('admin.general.manageActions.archive.title')
};

export const AdminGeneralManageSnapshotActionStrings = {
  baseDateLabel: () => i18n.t('admin.general.manageActions.snapshots.baseDateLabel'),
  createButton: () => i18n.t('admin.general.manageActions.snapshots.createButton'),
  createNameLabel: () => i18n.t('admin.general.manageActions.snapshots.createNameLabel'),
  createNamePlaceholder: () => i18n.t('admin.general.manageActions.snapshots.createNamePlaceholder'),
  noSnapshots: () => i18n.t('admin.general.manageActions.snapshots.noSnapshots'),
  offsetDaysLabel: (count: number) => i18n.t('admin.general.manageActions.snapshots.offsetDaysLabel', { count }),
  restoreButton: () => i18n.t('admin.general.manageActions.snapshots.restoreButton'),
  title: () => i18n.t('admin.general.manageActions.snapshots.title')
};

export const AdminGeneralManageUnArchiveActionStrings = {
  actionTitle: () => i18n.t('admin.general.manageActions.unarchive.actionTitle'),
  confirmationMessage: (schoolName: string) =>
    i18n.t('admin.general.manageActions.unarchive.confirmationMessage', { schoolName }),
  confirmationSubmit: () => i18n.t('admin.general.manageActions.unarchive.confirmationSubmit'),
  confirmationTitle: () => i18n.t('admin.general.manageActions.unarchive.confirmationTitle'),
  errorMessage: () => i18n.t('admin.general.manageActions.unarchive.errorMessage'),
  subtitle: () => i18n.t('admin.general.manageActions.unarchive.subtitle'),
  title: () => i18n.t('admin.general.manageActions.unarchive.title')
};

export const AdminGeneralManageUnshareActionStrings = {
  actionTitle: () => i18n.t('admin.general.manageActions.unshare.actionTitle'),
  confirmationMessage: (schoolName: string) =>
    i18n.t('admin.general.manageActions.unshare.confirmationMessage', { schoolName }),
  confirmationSubmit: () => i18n.t('admin.general.manageActions.unshare.confirmationSubmit'),
  confirmationTitle: () => i18n.t('admin.general.manageActions.unshare.confirmationTitle'),
  errorMessage: () => i18n.t('admin.general.manageActions.unshare.errorMessage'),
  subtitle: () => i18n.t('admin.general.manageActions.unshare.subtitle'),
  title: () => i18n.t('admin.general.manageActions.unshare.title')
};

export const AdminGeneralManageActionsStrings = {
  archive: AdminGeneralManageArchiveActionStrings,
  snapshots: AdminGeneralManageSnapshotActionStrings,
  title: () => i18n.t('admin.general.manageActions.title'),
  unarchive: AdminGeneralManageUnArchiveActionStrings,
  unshare: AdminGeneralManageUnshareActionStrings
};

export const AdminGeneralStrings = {
  adminsListCurrentUserTooltip: () => i18n.t('admin.general.adminsListCurrentUserTooltip'),
  adminsListSubtitle: () => i18n.t('admin.general.adminsListSubtitle'),
  adminsListTitle: () => i18n.t('admin.general.adminsListTitle'),
  informationTitle: () => i18n.t('admin.general.informationTitle'),
  manageActions: AdminGeneralManageActionsStrings,
  participationCodesSubtitle: () => i18n.t('admin.general.participationCodesSubtitle'),
  participationCodesTitle: () => i18n.t('admin.general.participationCodesTitle')
};

export const AdminNotFoundListStrings = {
  goBackToPlannerButton: () => i18n.t('admin.notFound.goBackToPlannerButton'),
  goToSchoolsListButton: () => i18n.t('admin.notFound.goToSchoolsListButton'),
  imageAlt: () => i18n.t('admin.notFound.imageAlt'),
  subtitle: () => i18n.t('admin.notFound.subtitle'),
  title: () => i18n.t('admin.notFound.title')
};

export const AdminProfileMenuStrings = {
  goBackToPlannerButton: () => i18n.t('admin.profileMenu.goBackToPlannerButton'),
  help: () => i18n.t('admin.profileMenu.help'),
  logout: () => i18n.t('admin.profileMenu.logout'),
  manageSubscription: () => i18n.t('admin.profileMenu.manageSubscription'),
  tutorials: () => i18n.t('admin.profileMenu.tutorials'),
  tutorialsUrl: () => i18n.t('admin.profileMenu.tutorialsUrl')
};

export const AdminRouteNames = {
  schoolClassDetails: () => i18n.t('admin.routeNames.schoolClassDetails'),
  schoolClasses: () => i18n.t('admin.routeNames.schoolClasses'),
  schoolGeneralInformation: () => i18n.t('admin.routeNames.schoolGeneralInformation'),
  schoolSchedules: () => i18n.t('admin.routeNames.schoolSchedules'),
  schoolScheduleDetails: () => i18n.t('admin.routeNames.schoolScheduleDetails'),
  schoolStudentDetails: () => i18n.t('admin.routeNames.schoolStudentDetails'),
  schoolStudents: () => i18n.t('admin.routeNames.schoolStudents'),
  schoolTeacherDetails: () => i18n.t('admin.routeNames.schoolTeacherDetails'),
  schoolTeachers: () => i18n.t('admin.routeNames.schoolTeachers')
};

export const AdminSchoolsListStrings = {
  emptyMessage: () => i18n.t('admin.schoolsList.emptyMessage'),
  noResultsMessage: (searchText: string) => i18n.t('admin.schoolsList.noResultsMessage', { searchText }),
  subtitle: () => i18n.t('admin.schoolsList.subtitle'),
  title: () => i18n.t('admin.schoolsList.title')
};

export const AdminSidebarStrings = {
  classes: () => i18n.t('admin.sidebar.classes'),
  general: () => i18n.t('admin.sidebar.general'),
  mobileTitle: () => i18n.t('admin.sidebar.mobileTitle'),
  schedules: () => i18n.t('admin.sidebar.schedules'),
  schools: () => i18n.t('admin.sidebar.schools'),
  students: () => i18n.t('admin.sidebar.students'),
  teachers: () => i18n.t('admin.sidebar.teachers')
};

export const AdminStudentsListStrings = {
  emailColumnHeader: () => i18n.t('admin.studentsList.emailColumnHeader'),
  emptyTitle: () => i18n.t('admin.studentsList.emptyTitle'),
  hasUserTooltip: () => i18n.t('admin.studentsList.hasUserTooltip'),
  nameColumnHeader: () => i18n.t('admin.studentsList.nameColumnHeader'),
  noNameValue: () => i18n.t('admin.studentsList.noNameValue'),
  showDetailsAction: () => i18n.t('admin.studentsList.showDetailsAction'),
  title: () => i18n.t('admin.studentsList.title')
};

export const AdminStrings = {
  accountDetailsNoName: () => i18n.t('admin.accountDetailsNoName'),
  appBarGoBackToPlannerButton: () => i18n.t('admin.appBarGoBackToPlannerButton'),
  appBarGoBackToPlannerCompactButton: () => i18n.t('admin.appBarGoBackToPlannerCompactButton'),
  archivedSchoolBannerMessage: () => i18n.t('admin.archivedSchoolBannerMessage'),
  breadcrumbsHome: () => i18n.t('admin.breadcrumbsHome'),
  defaultSearchPlaceholder: () => i18n.t('admin.defaultSearchPlaceholder'),
  classes: AdminClassesStrings,
  general: AdminGeneralStrings,
  mobileBannerText: () => i18n.t('admin.mobileBannerText'),
  notFound: AdminNotFoundListStrings,
  profileMenu: AdminProfileMenuStrings,
  routeNames: AdminRouteNames,
  schedule: AdminScheduleStrings,
  schoolsList: AdminSchoolsListStrings,
  sidebar: AdminSidebarStrings,
  studentsList: AdminStudentsListStrings,
  teachersList: AdminTeachersListStrings
};
