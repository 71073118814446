import { useServices } from '@/hooks';
import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';

export interface ErrorIndicatorProps {
  className?: string;
  style?: CSSProperties;
  sx?: SxProps;

  /**
   * The error message. Optional.
   */
  message?: string;
  /**
   * The error icon color. Optional. Default is `error`.
   */
  color?: 'primary' | 'secondary' | 'error' | 'textSecondary';
  /**
   * If true, a default message will be displayed if message is undefined. Default to `true`
   */
  useDefaultErrorMessage?: boolean;
}

export const ErrorIndicator = observer(
  ({ className, style, message, color = 'error', useDefaultErrorMessage = true, sx }: ErrorIndicatorProps) => {
    const theme = useTheme();
    const { localization } = useServices();
    const strings = localization.localizedStrings.utils;

    return (
      <Stack
        sx={sx}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={className}
        spacing={1}
        style={style}
      >
        <ErrorIcon
          color={color === 'textSecondary' ? 'primary' : color}
          sx={{ fontSize: 50, color: color === 'textSecondary' ? theme.palette.text.secondary : undefined }}
        />

        {(message != null || useDefaultErrorMessage) && (
          <Typography
            color={color === 'textSecondary' ? 'textSecondary' : 'textPrimary'}
            variant="subtitle1"
            textAlign="center"
            sx={{ fontWeight: theme.typography.fontWeightLight }}
          >
            {message ?? strings.errorDefaultMessage}
          </Typography>
        )}
      </Stack>
    );
  }
);
