import { WorkStepInfo } from '@/viewmodels';
import { CheckCircleRounded, CircleOutlined } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface WorkDetailsPlannedWorkStepProps {
  sx?: SxProps;
  className?: string;
  step: WorkStepInfo;
  isReadOnly: boolean;
}

export const WorkDetailsPlannedWorkStep = observer(
  ({ sx, step, isReadOnly, className }: WorkDetailsPlannedWorkStepProps) => {
    return (
      <Stack sx={sx} className={className} direction="row" spacing={1} alignItems="center" overflow="hidden">
        <IconButton
          size="small"
          disabled={isReadOnly}
          onClick={() => (step.isCompleted = !step.isCompleted)}
          color={step.isCompleted ? 'primary' : undefined}
        >
          {step.isCompleted ? <CheckCircleRounded fontSize="small" /> : <CircleOutlined fontSize="small" />}
        </IconButton>

        <Typography
          flex={1}
          variant="body2"
          noWrap
          color={step.isCompleted ? 'textSecondary' : undefined}
          sx={{ textDecoration: step.isCompleted ? 'line-through' : undefined }}
        >
          {step.title}
        </Typography>
      </Stack>
    );
  }
);
