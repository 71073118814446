import { AddRounded, RemoveCircleOutlineRounded } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  FormGroup,
  FormHelperText,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, Fragment, KeyboardEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useViewModel } from '../../../hooks';
import { Dialog } from '../../utils';

export interface AdminSchoolInviteTeachersDialogProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  schoolId: string;
}

export const AdminSchoolInviteTeachersDialog = observer(
  ({ schoolId, sx, className, isOpen }: AdminSchoolInviteTeachersDialogProps) => {
    const navigate = useNavigate();
    const strings = LocalizedStrings.admin.teachersList.invite;

    function close() {
      navigate(-1);
      return Promise.resolve();
    }

    const viewModel = useViewModel((viewModels) =>
      viewModels.createAdminSchoolInviteTeachersDialogViewModel(schoolId, close)
    );

    const [email, setEmail] = useState('');
    const [hasInvalidEmailError, setHasInvalidEmailError] = useState(false);

    function onTextInputKeyDown(e: KeyboardEvent<HTMLInputElement>) {
      if (e.key === 'Enter') {
        addEmail();
      }
    }

    function onTextInputChange(e: ChangeEvent<HTMLInputElement>) {
      setEmail(e.currentTarget.value);
      setHasInvalidEmailError(false);
    }

    function addEmail() {
      if (viewModel.isEmailValid(email)) {
        viewModel.addEmail(email);
        setEmail('');
      } else {
        setHasInvalidEmailError(true);
      }
    }

    return (
      <Dialog
        sx={sx}
        className={className}
        viewModel={viewModel}
        isOpen={isOpen}
        title={strings.title()}
        width="sm"
        renderData={() => (
          <Stack spacing={2}>
            <FormGroup>
              <Stack direction="row" spacing={1}>
                <TextField
                  sx={{ flex: 1 }}
                  label={strings.emailInputLabel()}
                  value={email}
                  type="email"
                  onChange={onTextInputChange}
                  onKeyDown={onTextInputKeyDown}
                />

                <Button startIcon={<AddRounded />} variant="contained" disabled={email.length === 0} onClick={addEmail}>
                  {strings.addButtonLabel()}
                </Button>
              </Stack>

              <FormHelperText hidden={!hasInvalidEmailError} error>
                {strings.invalidEmailMessage()}
              </FormHelperText>
            </FormGroup>

            {viewModel.emails.length === 0 && (
              <Box height={300} display="flex" justifyContent="center" p={2}>
                <Typography variant="body1" color="textSecondary" fontStyle="italic" p={2} pb={1} textAlign="center">
                  {strings.noEmailsLabel()}
                </Typography>
              </Box>
            )}

            {viewModel.emails.length > 0 && (
              <List sx={{ height: 300, overflow: 'auto' }}>
                {viewModel.emails.map((email, i, emails) => (
                  <Fragment key={email}>
                    <ListItem
                      secondaryAction={
                        <IconButton onClick={() => viewModel.removeEmail(email)} size="medium" color="error">
                          <RemoveCircleOutlineRounded fontSize="small" />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={email} />
                    </ListItem>

                    {i < emails.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </List>
            )}
          </Stack>
        )}
      />
    );
  }
);
