import { Card, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';

interface OnboardingSchoolKindsExplanationSchoolKindPanelProps {
  sx?: SxProps;
  className?: string;
  icon: (sx: SxProps) => ReactNode;
  title: string;
  message: string;
}

export const OnboardingSchoolKindsExplanationSchoolKindPanel = observer(
  ({ sx, icon, title, className, message }: OnboardingSchoolKindsExplanationSchoolKindPanelProps) => {
    const theme = useTheme();
    const isDarkTheme = theme.palette.mode === 'dark';

    return (
      <Card
        elevation={isDarkTheme ? 6 : 1}
        sx={{
          ...sx,
          borderRadius: 1,
          p: 1.5
        }}
        className={className}
      >
        <Stack direction="row" alignItems="center" spacing={2} mb={1}>
          {icon({ color: theme.palette.text.secondary })}
          <Typography fontWeight="600">{title}</Typography>
        </Stack>

        <Typography variant="body2">{message}</Typography>
      </Card>
    );
  }
);
