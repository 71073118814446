import { css } from '@emotion/css';
import { Box, Button, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import NotFoundImageFallback from '../../../resources/images/notFound/illustration_not_found.png';
import NotFoundImage from '../../../resources/images/notFound/illustration_not_found.webp';
import { Image } from '../../utils';

export interface AdminSchoolNotFoundProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolNotFound = observer(({ sx, className }: AdminSchoolNotFoundProps) => {
  const { route, userStore } = useServices();
  const destinationPlannerId = userStore.idOfLastOwnedPlannerShowed;
  const plannerDestination = destinationPlannerId != null ? route.resolvePlannerLocation(destinationPlannerId) : '/';
  const imageStyle = css([{ width: '250px', height: 'auto' }]);
  const strings = LocalizedStrings.admin.notFound;

  return (
    <Box sx={sx} className={className} display="flex" alignItems="center" flexDirection="column" overflow="auto">
      <Stack spacing={4} width={300}>
        <Box sx={{ alignSelf: 'center' }}>
          <Image
            sources={[
              { src: NotFoundImage, type: 'image/webp' },
              { src: NotFoundImageFallback, type: 'image/png' }
            ]}
            fallback={NotFoundImageFallback}
            alt={strings.imageAlt()}
            className={imageStyle}
          />
        </Box>

        <Stack spacing={1}>
          <Typography variant="h6" textAlign="center">
            {strings.title()}
          </Typography>
          <Typography variant="subtitle2" textAlign="center">
            {strings.subtitle()}
          </Typography>
        </Stack>

        <Stack spacing={1}>
          <Button fullWidth variant="contained" component={Link} to="/school-admin">
            {strings.goToSchoolsListButton()}
          </Button>

          <Button fullWidth component={Link} to={plannerDestination}>
            {strings.goBackToPlannerButton()}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
});
