import { ArrowBackRounded } from '@mui/icons-material';
import HelpIcon from '@mui/icons-material/HelpRounded';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import SubscriptionIcon from '@mui/icons-material/PaymentRounded';
import TutorialsIcon from '@mui/icons-material/YouTube';
import {
  Button,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Stack,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Link, useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { RouteTemplates } from '../../../RouteTemplates';
import { useViewModel } from '../../../hooks';
import { UserProfileIcon } from '../../shared';

export interface AdminProfileMenuProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
  anchorEl: HTMLElement | null;
}

export const AdminProfileMenu = observer(({ sx, className, onClose, anchorEl, isOpen }: AdminProfileMenuProps) => {
  const viewModel = useViewModel((viewModels) => viewModels.createAdminProfileMenuViewModel(), []);
  const theme = useTheme();
  const navigate = useNavigate();
  const strings = LocalizedStrings.admin.profileMenu;

  function onOptionClick(action: (() => void) | undefined) {
    return () => {
      onClose();
      action?.();
    };
  }

  async function logout() {
    await viewModel.logout();
    navigate(RouteTemplates.welcome, { replace: true });
  }

  return (
    <Popover
      sx={sx}
      className={className}
      open={isOpen}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      onClose={() => onClose()}
    >
      <Stack spacing={1} sx={{ width: 360, pb: 1 }}>
        <Stack spacing={1} sx={{ p: 2, pb: 0 }}>
          <ListItem disableGutters disablePadding>
            <ListItemAvatar>
              <UserProfileIcon url={viewModel.pictureUrl} userFullName={viewModel.fullName} />
            </ListItemAvatar>
            <ListItemText primary={viewModel.fullName} secondary={viewModel.email} />
          </ListItem>

          <Button
            size="small"
            sx={{ alignSelf: 'flex-start' }}
            component={Link}
            to={viewModel.plannerLocation}
            startIcon={<ArrowBackRounded />}
          >
            {strings.goBackToPlannerButton()}
          </Button>
        </Stack>

        <Divider />

        <MenuItem dense onClick={onOptionClick(() => void viewModel.manageSubscription())}>
          <ListItemIcon>
            <SubscriptionIcon />
          </ListItemIcon>
          <ListItemText>{strings.manageSubscription()}</ListItemText>
        </MenuItem>

        <Divider />

        <Stack>
          <MenuItem dense onClick={onOptionClick(() => viewModel.openHelp())}>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText>{strings.help()}</ListItemText>
          </MenuItem>

          <MenuItem
            dense
            component="a"
            href={strings.tutorialsUrl()}
            target="_blank"
            rel="noreferrer"
            onClick={onOptionClick(undefined)}
          >
            <ListItemIcon>
              <TutorialsIcon />
            </ListItemIcon>
            <ListItemText>{strings.tutorials()}</ListItemText>
          </MenuItem>

          <MenuItem dense onClick={onOptionClick(() => void logout())}>
            <ListItemIcon>
              <LogoutIcon sx={{ color: theme.palette.error.main }} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ color: 'error' }}>{strings.logout()}</ListItemText>
          </MenuItem>
        </Stack>
      </Stack>
    </Popover>
  );
});
