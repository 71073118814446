import { SortedDayOfWeek } from '@/models';
import { UserDashboardCalendarDatePickerDayInfo } from '@/viewmodels';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { isSameMonth, set } from 'date-fns';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { UserDashboardCalendarDatePickerDay } from './UserDashboardCalendarDatePickerDay';

export interface UserDashboardCalendarDatePickerMonthProps {
  sx?: SxProps;
  className?: string;
  weeks: Date[][];
  monthInfo: { month: number; year: number };
  getDayInfo: (date: Date) => UserDashboardCalendarDatePickerDayInfo;
  onSelectDate: (date: Date) => void;
}

export const UserDashboardCalendarDatePickerMonth = observer(
  ({ sx, className, monthInfo, weeks, getDayInfo, onSelectDate }: UserDashboardCalendarDatePickerMonthProps) => {
    return (
      <Stack sx={sx} className={className} spacing={2}>
        <Stack direction="row" spacing={0.5} justifyContent="space-around">
          {SortedDayOfWeek.map((dow) => (
            <Typography key={dow} fontSize={12} fontWeight={600} textAlign="center" color="textSecondary">
              {LocalizedStrings.dateTime.dayOfWeekSingleLetterTitle[dow]()}
            </Typography>
          ))}
        </Stack>

        <Stack spacing={0.5}>
          {weeks.map((week, weekIndex) => (
            <Stack key={weekIndex} direction="row" flex={1} spacing={0.5} justifyContent="space-around">
              {week.map((date, dayIndex) => (
                <UserDashboardCalendarDatePickerDay
                  key={dayIndex}
                  date={date}
                  dayInfo={getDayInfo(date)}
                  isCurrentMonth={isSameMonth(
                    date,
                    set(new Date(), { date: 1, month: monthInfo.month, year: monthInfo.year })
                  )}
                  canBeSelected={true}
                  onSelect={onSelectDate}
                />
              ))}
            </Stack>
          ))}
        </Stack>
      </Stack>
    );
  }
);
