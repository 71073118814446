import { Theme, ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { useServices } from './hooks';
import { makeDarkMuiThemeOptions, makeLightMuiThemeOptions } from './resources/themes';

export interface AppThemeProviderProps {
  children: (theme: Theme) => ReactNode;
  forceReduceTransparency?: boolean;
}

export const AppThemeProvider = observer(({ children, forceReduceTransparency = false }: AppThemeProviderProps) => {
  const { settings, theme } = useServices();
  const reduceTransparency = settings.reduceTransparency || forceReduceTransparency;
  const customAccentColor = settings.useCustomPrimaryColor ? settings.customPrimaryColor : undefined;
  const themeOptions =
    theme.variant === 'light'
      ? makeLightMuiThemeOptions(customAccentColor, reduceTransparency)
      : makeDarkMuiThemeOptions(customAccentColor, reduceTransparency);

  // For some reason, we need to create a new theme everytime. If we store two themes somewhere
  // and just change which one we pass to the ThemeProvider, the theme change is not propagated.
  const muiTheme = createTheme(themeOptions);

  return <ThemeProvider theme={muiTheme}>{children(muiTheme)}</ThemeProvider>;
});
