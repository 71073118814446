import { getOptionalDashConcatenation } from '@/models';
import { localizedExternalSourceName } from '@/resources/strings/utils/ExternalSourceStringsUtils';
import { PlannerItemsViewModel } from '@/viewmodels';
import { AddRounded, FilterListRounded } from '@mui/icons-material';
import { Badge, Box, Button, IconButton, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { ColorIndicator } from '../../lists';
import { CreateWorkOptionsMenu } from '../../shared';
import { SearchBar } from '../../utils';
import { PlannerItemsDisplayedKindsPicker } from './PlannerItemsDisplayedKindsPicker';
import { PlannerItemsFiltersPopover } from './PlannerItemsFiltersPopover';

export interface PlannerItemsHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerItemsViewModel;
}

export const PlannerItemsHeader = observer(({ sx, className, viewModel }: PlannerItemsHeaderProps) => {
  const { navigation, pasteboard } = useServices();
  const location = useLocation();
  const navigate = useNavigate();

  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const strings = LocalizedStrings.planner.items;

  const [filterMenuAnchor, setFilterMenuAnchor] = useState<HTMLButtonElement | undefined>();
  const filtersBadgeCount = Number(viewModel.minimumDate != null) + Number(viewModel.maximumDate != null);

  const [createItemMenuAnchor, setCreateItemMenuAnchor] = useState<HTMLButtonElement | undefined>();

  function onCreateWorkButtonPressed(e: MouseEvent<HTMLButtonElement>) {
    setCreateItemMenuAnchor(e.currentTarget);
  }

  function onFilterButtonPressed(e: MouseEvent<HTMLButtonElement>) {
    setFilterMenuAnchor(e.currentTarget);
  }

  function showCreatePublishedWork() {
    setCreateItemMenuAnchor(undefined);
    navigation.navigateToPublishedWorkEdit(navigate, location, undefined, {
      courseSectionId: viewModel.courseSection?.courseSection?.id
    });
  }

  function showCreateWork() {
    setCreateItemMenuAnchor(undefined);
    navigation.navigateToWorkEdit(navigate, location, undefined, {
      courseSectionId: viewModel.courseSection?.courseSection?.id
    });
  }

  function showCreateNote() {
    setCreateItemMenuAnchor(undefined);
    navigation.navigateToNoteEdit(navigate, location, undefined, {
      courseSectionId: viewModel.courseSection?.courseSection?.id
    });
  }

  function onPaste() {
    setCreateItemMenuAnchor(undefined);
    pasteboard.setPasteContext({ courseSectionId: viewModel.courseSection?.courseSection?.id });
  }

  return (
    <Box sx={sx} className={className}>
      <Stack spacing={isExtraSmallScreen ? 2 : 1}>
        <Stack
          direction={isExtraSmallScreen ? 'column' : 'row'}
          spacing={1}
          alignItems={!isExtraSmallScreen ? 'flex-start' : undefined}
        >
          <Stack flex={1} spacing={isExtraSmallScreen ? 1 : 2} direction="column">
            <Stack
              spacing={isExtraSmallScreen ? 1 : 2}
              alignItems={isExtraSmallScreen ? 'center' : 'flex-start'}
              direction={isExtraSmallScreen ? 'row' : 'column'}
            >
              <Box flex={1} display="flex" flexDirection="row">
                {viewModel.courseSection != null ? (
                  <>
                    <ColorIndicator color={viewModel.courseSection.courseSection!.color} sx={{ mr: 1 }} />

                    <Stack>
                      <Typography
                        variant={isExtraSmallScreen ? 'body1' : 'h6'}
                        fontWeight={isExtraSmallScreen ? 500 : undefined}
                      >
                        {viewModel.courseSection.courseSection!.title}
                      </Typography>
                      <Typography variant="subtitle2" color="textSecondary">
                        {getOptionalDashConcatenation(
                          viewModel.courseSection.courseSection!.section,
                          viewModel.courseSection.courseSection?.externalSource?.sourceName != null
                            ? strings.externalSourceName(
                                localizedExternalSourceName(
                                  viewModel.courseSection.courseSection.externalSource.sourceName
                                )
                              )
                            : undefined
                        )}
                      </Typography>
                    </Stack>
                  </>
                ) : (
                  <Typography
                    variant={isExtraSmallScreen ? 'body1' : 'h6'}
                    fontWeight={isExtraSmallScreen ? 500 : undefined}
                  >
                    {strings.allItemsTitle()}
                  </Typography>
                )}
              </Box>

              {isExtraSmallScreen && (
                <>
                  <IconButton onClick={onFilterButtonPressed}>
                    <Badge badgeContent={filtersBadgeCount} showZero={false} color="primary">
                      <FilterListRounded />
                    </Badge>
                  </IconButton>

                  {viewModel.canCreateItem && (
                    <IconButton onClick={onCreateWorkButtonPressed}>
                      <AddRounded />
                    </IconButton>
                  )}
                </>
              )}
            </Stack>
          </Stack>

          <Stack spacing={1} direction="row" alignItems="center">
            {!isExtraSmallScreen && (
              <IconButton onClick={onFilterButtonPressed}>
                <Badge badgeContent={filtersBadgeCount} showZero={false} color="primary">
                  <FilterListRounded />
                </Badge>
              </IconButton>
            )}

            <SearchBar sx={{ flex: 1 }} onSearch={(value) => (viewModel.searchText = value ?? '')} />
          </Stack>
        </Stack>

        <Stack direction="row" spacing={3} alignItems="center" width="100%" sx={{ overflowX: 'auto' }}>
          {!isExtraSmallScreen && viewModel.canCreateItem && (
            <Button variant="contained-grey" onClick={onCreateWorkButtonPressed}>
              {strings.createWorkButtonTitle()}
            </Button>
          )}

          <PlannerItemsDisplayedKindsPicker
            possibleKinds={viewModel.possibleFilterKinds}
            selectedKinds={viewModel.currentFilterKinds}
            onKindSelection={(kind) => viewModel.toggleFilterKind(kind)}
          />
        </Stack>
      </Stack>

      <PlannerItemsFiltersPopover
        open={filterMenuAnchor != null}
        anchorEl={filterMenuAnchor}
        close={() => setFilterMenuAnchor(undefined)}
        minimumDate={viewModel.minimumDate}
        maximumDate={viewModel.maximumDate}
        showPastItems={viewModel.showPastItems}
        showNoDateItems={viewModel.showNoDateItems}
        onMinimumDateValueChanged={(v) => (viewModel.minimumDate = v)}
        onMaximumDateValueChanged={(v) => (viewModel.maximumDate = v)}
        onShowPastItemsValueChanged={(v) => (viewModel.showPastItems = v)}
        onShowNoDateItemsValueChanged={(v) => (viewModel.showNoDateItems = v)}
      />

      <CreateWorkOptionsMenu
        open={createItemMenuAnchor != null}
        anchorEl={createItemMenuAnchor}
        onClose={() => setCreateItemMenuAnchor(undefined)}
        canPublishWork={viewModel.canPublishWork}
        onCreatePersonalWorkOptionSelect={showCreateWork}
        onCreateNoteOptionSelect={showCreateNote}
        onPublishWorkOptionSelect={showCreatePublishedWork}
        onPaste={onPaste}
      />
    </Box>
  );
});
