import { Stack, TextField, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { useMemo, useState } from 'react';
import LocalizedStrings from 'strings';
import { ScheduleCycleKind, titleForCycleDay } from '../../../../../viewmodels';
import { FormPopoverActions, FormPopoverHeader } from '../../../../utils';

export interface ScheduleCycleCycleDayNamesEditProps {
  sx?: SxProps;
  className?: string;
  onClose: (cycleDayNames: string[] | undefined) => void;
  scheduleKind: ScheduleCycleKind;
  cycleDayCount: number;
  initialCycleDayNames: string[];
}

export const ScheduleCycleCycleDayNamesEdit = observer(
  ({
    sx,
    className,
    onClose,
    initialCycleDayNames,
    cycleDayCount,
    scheduleKind
  }: ScheduleCycleCycleDayNamesEditProps) => {
    const strings = LocalizedStrings.scheduleCycle.create;

    const resolvedInitialCycleDayNames = useMemo(() => {
      const missingCycleDayNamesCount = cycleDayCount - initialCycleDayNames.length;
      const names = initialCycleDayNames;
      // If the existing cycleDayNames length is less that the number of cycleDays, we make sure it is the same length
      // in the local state.
      names.push(...times(Math.max(0, missingCycleDayNamesCount), () => ''));
      return names;
    }, [cycleDayCount, initialCycleDayNames]);

    const [cycleDayNames, setCycleDayNames] = useState(resolvedInitialCycleDayNames);
    const hasChanges = resolvedInitialCycleDayNames.some((name, i) => cycleDayNames[i] !== name);

    function save() {
      onClose(cycleDayNames);
      return Promise.resolve();
    }

    function cancel() {
      onClose(undefined);
      return Promise.resolve();
    }

    return (
      <Stack sx={sx} className={className}>
        <FormPopoverHeader title={strings.cycleDayNamesEditTitle()} />

        <Stack spacing={1} pb={1}>
          <Typography variant="body2" color="textSecondary" p={2} pb={1}>
            {strings.cycleDayNamesEditMessage()}
          </Typography>

          {times(cycleDayCount).map((cycleDayIndex) => (
            <Stack key={cycleDayIndex} direction="row" spacing={1} px={2} alignItems="center">
              <Typography flex={1}>{titleForCycleDay(cycleDayIndex + 1, scheduleKind, 'long', true, [])}</Typography>

              <TextField
                size="small"
                placeholder={titleForCycleDay(cycleDayIndex + 1, scheduleKind, 'short', false, [])}
                value={cycleDayNames[cycleDayIndex]}
                onChange={(e) =>
                  setCycleDayNames(cycleDayNames.map((v, i) => (i === cycleDayIndex ? e.currentTarget.value : v)))
                }
              />
            </Stack>
          ))}
        </Stack>

        <FormPopoverActions onCancel={cancel} onSubmit={save} canSubmit={hasChanges} />
      </Stack>
    );
  }
);
