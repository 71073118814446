import NotFoundImageFallback from '@/resources/images/notFound/illustration_not_found.png';
import NotFoundImage from '@/resources/images/notFound/illustration_not_found.webp';
import { css } from '@emotion/css';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { Image } from '../../utils';

export interface SchoolSettingsNotFoundProps {
  sx?: SxProps;
  className?: string;
}

export const SchoolSettingsNotFound = observer(({ sx, className }: SchoolSettingsNotFoundProps) => {
  const notFoundImageStyle = css([{ width: 300, height: 'auto' }]);

  return (
    <Stack sx={sx} className={className}>
      <Image
        sources={[
          { src: NotFoundImage, type: 'image/webp' },
          { src: NotFoundImageFallback, type: 'image/png' }
        ]}
        fallback={NotFoundImageFallback}
        alt={'Not found illustration'}
        className={notFoundImageStyle}
      />
      <Typography variant="h6">{LocalizedStrings.settings.school.details.notFoundTitle()}</Typography>
      <Typography>{LocalizedStrings.settings.school.details.notFoundSubtitle()}</Typography>
    </Stack>
  );
});
