import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface StudentItemCountIndicatorProps {
  sx?: SxProps;
  className?: string;
  value: number;
  color?: string;
}

export const StudentItemCountIndicator = observer(({ sx, className, value, color }: StudentItemCountIndicatorProps) => {
  return (
    <Box
      className={className}
      sx={{
        ...sx,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Typography color={color}>{value}</Typography>
    </Box>
  );
});
