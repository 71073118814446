import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { Box, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { Observer, observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { courseSectionOccurrenceInfoAreEquals } from '../../../../models';
import { PlannerPastePlannerItemTimeOfDaySelectionViewModel } from '../../../../viewmodels';
import { Subheader, UpdatablePresenter } from '../../../utils';
import { PlannerPastePlannerItemCustomTimeOption } from './PlannerPastePlannerItemCustomTimeOption';
import { PlannerPastePlannerItemOption } from './PlannerPastePlannerItemOption';
import { PlannerPastePlannerItemPeriodView } from './PlannerPastePlannerItemPeriodView';

export interface PlannerPastePlannerItemTimeOfDaySelectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerPastePlannerItemTimeOfDaySelectionViewModel;
  courseSectionsById: Map<string, CourseSectionDetails>;
}

export const PlannerPastePlannerItemTimeOfDaySelection = observer(
  ({ sx, className, viewModel, courseSectionsById }: PlannerPastePlannerItemTimeOfDaySelectionProps) => {
    const strings = LocalizedStrings.planner.pastePlannerItem;

    return (
      <Box sx={sx} className={className} display="flex" flexDirection="column">
        <Subheader>{strings.timeSelectionSectionTitle()}</Subheader>

        <UpdatablePresenter
          viewModel={viewModel}
          loadingMessage={strings.periodsLoadingMessage()}
          renderData={() => {
            return viewModel.availablePeriods.length ? (
              <Stack spacing={1} width="100%" mb={1}>
                {viewModel.availablePeriods.map((period, index) => (
                  <Observer key={`period-${index}`}>
                    {() => {
                      const isSelected =
                        viewModel.selection?.case === 'period' &&
                        courseSectionOccurrenceInfoAreEquals(period, viewModel.selection.value);

                      const courseSection = courseSectionsById.get(period.courseSectionId);

                      return (
                        <PlannerPastePlannerItemPeriodView
                          period={period}
                          courseSection={courseSection}
                          isSelected={isSelected}
                          onSelect={() => (viewModel.selection = { case: 'period', value: period })}
                        />
                      );
                    }}
                  </Observer>
                ))}
              </Stack>
            ) : null;
          }}
        />

        <PlannerPastePlannerItemCustomTimeOption viewModel={viewModel} />

        <PlannerPastePlannerItemOption
          onSelect={() => (viewModel.selection = { case: 'none' })}
          title={strings.noTimeOptionLabel()}
          isSelected={viewModel.selection?.case === 'none'}
        />
      </Box>
    );
  }
);
