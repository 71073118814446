import { useServices } from '@/hooks';
import { PlannerListKind } from '@/models';
import { PlannerPlanningViewModel } from '@/viewmodels';
import { Grid, GridProps, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { PlannerListGridItem } from './PlannerListGridItem';

export interface PlannerPlanningListsGridProps extends GridProps {
  className?: string;
  viewModel: PlannerPlanningViewModel;
  currentListKind: PlannerListKind;
  setCurrentListKind: (kind: PlannerListKind) => void;
}

export const PlannerPlanningListsGrid = observer(
  ({ viewModel, className, currentListKind, setCurrentListKind, ...props }: PlannerPlanningListsGridProps) => {
    const { settings } = useServices();

    const theme = useTheme();
    const showsSummaryLists = useMediaQuery(() =>
      theme.breakpoints.down(settings.isSchoolSidebarCollapsed ? 'lg' : 'xl')
    );

    return (
      <Grid {...props} className={className} container spacing={{ sm: 1, xl: 2 }}>
        {/* Inbox */}
        <PlannerListGridItem
          kind="inbox"
          currentListKind={currentListKind}
          setCurrentListKind={setCurrentListKind}
          showsSummaryLists={showsSummaryLists}
          viewModel={viewModel}
        />

        {/* Today */}
        <PlannerListGridItem
          kind="today"
          currentListKind={currentListKind}
          setCurrentListKind={setCurrentListKind}
          showsSummaryLists={showsSummaryLists}
          viewModel={viewModel}
        />

        {/* Upcoming */}
        <PlannerListGridItem
          kind="upcoming"
          currentListKind={currentListKind}
          setCurrentListKind={setCurrentListKind}
          showsSummaryLists={showsSummaryLists}
          viewModel={viewModel}
        />

        {/* Done */}
        <PlannerListGridItem
          kind="done"
          currentListKind={currentListKind}
          setCurrentListKind={setCurrentListKind}
          showsSummaryLists={showsSummaryLists}
          viewModel={viewModel}
        />
      </Grid>
    );
  }
);
