import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PlannerItemsNoResultsIndicatorProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerItemsNoResultsIndicator = observer(({ sx, className }: PlannerItemsNoResultsIndicatorProps) => {
  return (
    <Typography sx={{ ...sx, px: 3, py: 2 }} className={className} variant="body1" color="textSecondary">
      {LocalizedStrings.planner.items.emptyNoResultsLabel()}
    </Typography>
  );
});
