import { useServices } from '@/hooks';
import { BaseSharePlannerViewModel } from '@/viewmodels';
import { Box, Stack, StackProps, Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CopyText, UpdatablePresenter } from '../../utils';

export interface SharePlannerProps extends StackProps {
  className?: string;
  viewModel: BaseSharePlannerViewModel;
}

export const SharePlanner = observer(({ className, viewModel, ...props }: SharePlannerProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.sharingInvitationCode;

  return (
    <Stack {...props} className={className} spacing={4}>
      <Stack spacing={2} alignItems="center">
        <Typography variant="subtitle2" textAlign="center">
          {strings.sharePlannerText(viewModel.userRelationshipKind, viewModel.targetRelationshipKind)}
        </Typography>

        <UpdatablePresenter
          size="small"
          hasLoadingMinimumHeight={false}
          viewModel={viewModel.sharingInvitationCode}
          loadingMessage={strings.sharePlannerLoadingMessage}
          errorMessageSelector={(error) => strings.sharePlannerLoadingErrorMessage(error.message)}
          renderData={() => (
            <CopyText text={viewModel.sharingInvitationCode.value!}>
              <Box ml={6}>
                <Typography variant="h4">{viewModel.sharingInvitationCode.value}</Typography>
              </Box>
            </CopyText>
          )}
        />
      </Stack>
    </Stack>
  );
});
