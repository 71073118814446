import { useServices } from '@/hooks';
import ClearIcon from '@mui/icons-material/ClearRounded';
import SearchIcon from '@mui/icons-material/SearchRounded';
import { Box, IconButton, InputBase, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { debounce as debounceFn } from 'lodash';
import { useCallback, useState } from 'react';

export interface SearchBarProps {
  className?: string;
  sx?: SxProps;
  placeholder?: string;
  debounce?: number;
  autoFocus?: boolean;

  onSearch: (value: string | undefined) => void;
}

export const SearchBar = ({ className, placeholder, autoFocus, sx, onSearch, debounce }: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const { localization } = useServices();
  const strings = localization.localizedStrings.utils;

  const onSearchCallback = useCallback(debounceFn(onSearch, debounce), [onSearch, debounce]);

  return (
    <Container className={className} sx={sx}>
      <SearchIconContainer>
        <SearchIcon color="action" />
      </SearchIconContainer>
      <InputBase
        value={searchValue ?? ''}
        placeholder={placeholder ?? strings.searchBarDefaultPlaceholder}
        autoFocus={autoFocus}
        classes={{
          root: classes.inputRoot,
          input: classes.input
        }}
        onChange={(event) => {
          const value = event.target.value || undefined;
          setSearchValue(value);
          onSearchCallback?.(value);
        }}
      />
      <CancelIconContainer>
        <IconButton
          onClick={() => {
            setSearchValue(undefined);
            onSearchCallback(undefined);
          }}
        >
          <ClearIcon />
        </IconButton>
      </CancelIconContainer>
    </Container>
  );
};

const PREFIX = 'SearchBar';
const classes = {
  inputRoot: `${PREFIX}-inputRoot`,
  input: `${PREFIX}-input`
};

const Container = styled(Box)(({ theme }) => {
  const isLight = theme.palette.mode === 'light';

  return {
    display: 'flex',
    borderRadius: theme.shape.borderRadius,
    boxShadow: 'none',

    backgroundColor: isLight ? 'rgba(0, 0, 0, 0.06)' : 'rgba(255, 255, 255, 0.09)',
    '&:hover': {
      backgroundColor: isLight ? 'rgba(0, 0, 0, 0.09)' : 'rgba(255, 255, 255, 0.13)'
    },

    [`& .${classes.inputRoot}`]: {
      width: '100%'
    },

    [`& .${classes.input}`]: {
      padding: theme.spacing(1, 1, 1, 0)
    }
  };
});

const SearchIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  padding: theme.spacing(0, 1.5)
}));

const CancelIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'center',
  padding: theme.spacing(0, 0.5)
}));
