import { ServiceContainer } from '@/providers';
import { ConnectedAppsService, LocalizationService } from '@/services';
import { Loadable } from '@/stores';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { computed, makeObservable, observable } from 'mobx';
import { UpdatableViewModel, UpdatableViewModelState } from '../UpdatableViewModel';

export interface CourseSectionListViewModel extends UpdatableViewModel {
  readonly courseSections: CourseSectionDetails[];
  readonly canEditCourseSections: boolean;
  readonly isConnectedToClassroom: boolean;
  deleteCourseSection(courseSection: CourseSectionDetails, shouldAlsoCancelWorks: boolean): Promise<void>;
}

export abstract class AppBaseCourseSectionListViewModel<CourseSectionLoadable extends Loadable<unknown>>
  implements CourseSectionListViewModel
{
  @observable protected _error: string | undefined;

  protected constructor(
    protected readonly _plannerId: string,
    protected readonly _schoolId: string | undefined,
    protected readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _connectedApps: ConnectedAppsService = ServiceContainer.services.connectedApps
  ) {
    makeObservable(this);
  }

  protected abstract get courseSectionsLoadable(): CourseSectionLoadable;
  protected abstract get courseSectionsInfos(): CourseSectionDetails[];

  @computed
  get isConnectedToClassroom(): boolean {
    return (
      this._connectedApps.getConnectedAppService('classroom', this._plannerId, this._schoolId)?.isConnected ?? false
    );
  }

  readonly hasChanges = false;

  abstract get canEditCourseSections(): boolean;

  @computed
  get courseSections(): CourseSectionDetails[] {
    return this.courseSectionsInfos.sort(
      (cs1, cs2) =>
        cs1.courseSection!.title.localeCompare(cs2.courseSection!.title, this._localization.currentLocale, {
          sensitivity: 'base'
        }) ||
        cs1.courseSection!.section.localeCompare(cs2.courseSection!.section, this._localization.currentLocale, {
          sensitivity: 'base'
        })
    );
  }

  @computed
  get state(): UpdatableViewModelState {
    return this.courseSectionsLoadable.state;
  }

  readonly isSubmitting = false;

  @computed
  get hasData(): boolean {
    return this.courseSectionsLoadable.hasData;
  }

  async reloadData() {
    await this.courseSectionsLoadable.fetch(true);
  }

  abstract deleteCourseSection(courseSection: CourseSectionDetails, shouldAlsoCancelWorks: boolean): Promise<void>;
}
