import { useViewModel } from '@/hooks';
import { AddRounded } from '@mui/icons-material';
import { Box, CardActionArea, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { UpdatablePresenter } from '../../../../utils';
import { useScheduleCycleUserDashboard } from '../../UseScheduleCycleUserDashboardHook';
import { ScheduleCyclePeriodScheduleGrid } from '../period-schedules';
import { ScheduleCyclePeriodScheduleDetailsEdit } from './ScheduleCyclePeriodScheduleDetailsEdit';

export interface ScheduleCycleBellTimesPageProps {
  sx?: SxProps;
  className?: string;
  isReadOnly?: boolean;
}

export const ScheduleCycleBellTimesPage = observer(
  ({ sx, className, isReadOnly = false }: ScheduleCycleBellTimesPageProps) => {
    const { plannerId, dashboard, scheduleCycleId } = useScheduleCycleUserDashboard();
    const [addBellTimesButton, setAddBellTimesButton] = useState<HTMLButtonElement | undefined>(undefined);
    const [editBellTimesHeaderInfos, setEditBellTimesHeaderInfos] = useState<
      { element: HTMLElement; id: string } | undefined
    >(undefined);

    const viewModel = useViewModel(
      (viewModels) => viewModels.createScheduleCycleBellTimesPageViewModel(dashboard, plannerId, scheduleCycleId),
      [plannerId, scheduleCycleId]
    );

    const resolvedIsReadOnly = !viewModel.canCreatePeriods || !viewModel.canEditPeriods || isReadOnly;
    const strings = LocalizedStrings.scheduleCycle.edit.bellTimes;

    return (
      <Stack width="100%" height="100%" sx={sx} className={className}>
        <UpdatablePresenter
          viewModel={viewModel}
          sx={{ flex: 1, mt: 2 }}
          className={className}
          renderData={() => (
            <Stack width="100%" height="100%" direction="row">
              {viewModel.columns.length > 0 ? (
                <ScheduleCyclePeriodScheduleGrid
                  viewModel={viewModel}
                  supportsActivitySchedules={false}
                  isReadOnly={resolvedIsReadOnly}
                  onColumnHeaderClick={(column, e) =>
                    setEditBellTimesHeaderInfos({
                      element: e.currentTarget,
                      id: column.id!
                    })
                  }
                />
              ) : (
                <Box height="100%" alignItems="center" display="flex" px={2}>
                  <Typography variant="subtitle2" color="textSecondary" fontStyle="italic">
                    {strings.noBellTimes()}
                  </Typography>
                </Box>
              )}

              {!resolvedIsReadOnly && (
                <Box height="100%" width={50}>
                  <Tooltip title={strings.addBellTimes()} placement="right">
                    <CardActionArea
                      aria-label={strings.addBellTimes()}
                      onClick={(e) => setAddBellTimesButton(e.currentTarget)}
                      sx={{
                        height: '100%',
                        width: '100%',
                        borderRadius: 1.5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      <AddRounded />
                    </CardActionArea>
                  </Tooltip>

                  {addBellTimesButton != null && (
                    <Popover
                      open={true}
                      anchorEl={addBellTimesButton}
                      onClose={() => setAddBellTimesButton(undefined)}
                      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                      slotProps={{ paper: { sx: { width: 400 } } }}
                    >
                      <ScheduleCyclePeriodScheduleDetailsEdit
                        scheduleCycleId={scheduleCycleId}
                        onDismiss={() => setAddBellTimesButton(undefined)}
                      />
                    </Popover>
                  )}

                  {editBellTimesHeaderInfos != null && (
                    <Popover
                      open={true}
                      anchorEl={editBellTimesHeaderInfos.element}
                      onClose={() => setEditBellTimesHeaderInfos(undefined)}
                      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                      slotProps={{ paper: { sx: { width: 400 } } }}
                    >
                      <ScheduleCyclePeriodScheduleDetailsEdit
                        periodScheduleId={editBellTimesHeaderInfos.id}
                        scheduleCycleId={scheduleCycleId}
                        onDismiss={() => setEditBellTimesHeaderInfos(undefined)}
                      />
                    </Popover>
                  )}
                </Box>
              )}
            </Stack>
          )}
        />
      </Stack>
    );
  }
);
