import { Divider, Stack, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { StudentItemCountIndicator } from './StudentItemCountIndicator';

export interface StudentItemCountsIndicatorProps {
  sx?: SxProps;
  className?: string;
  hasLateItems: boolean;
  inboxCount: number;
  todayCount: number;
  upcomingCount: number;
  doneCount: number;
}

export const StudentItemCountsIndicator = observer(
  ({
    sx,
    className,
    inboxCount,
    todayCount,
    upcomingCount,
    doneCount,
    hasLateItems
  }: StudentItemCountsIndicatorProps) => {
    const theme = useTheme();

    return (
      <Stack
        spacing={0}
        className={className}
        sx={{ ...sx, backgroundColor: theme.palette.divider, borderRadius: 1, py: 1 }}
        direction="row"
        alignItems="stretch"
      >
        <StudentItemCountIndicator sx={{ flex: 1 }} value={inboxCount} color={hasLateItems ? 'error' : undefined} />
        <Divider orientation="vertical" />
        <StudentItemCountIndicator sx={{ flex: 1 }} value={todayCount} />
        <Divider orientation="vertical" />
        <StudentItemCountIndicator sx={{ flex: 1 }} value={upcomingCount} />
        <Divider orientation="vertical" />
        <StudentItemCountIndicator sx={{ flex: 1 }} value={doneCount} />
      </Stack>
    );
  }
);
