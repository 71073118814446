import { InfoOutlined, PublishRounded, UndoRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { PublishScheduleCycleChangesResult } from '../../../stores';
import { ConfirmationDialog } from '../../utils';

export interface AdminSchoolScheduleCyclePendingChangesProps {
  sx?: SxProps;
  className?: string;
  onUndo: () => Promise<void>;
  onCancel: () => Promise<void>;
  onSave: () => Promise<PublishScheduleCycleChangesResult>;
  showHasDroppedChangesMessage: () => void;
}

export const AdminSchoolScheduleCyclePendingChanges = observer(
  ({
    sx,
    className,
    onUndo,
    onSave,
    onCancel,
    showHasDroppedChangesMessage
  }: AdminSchoolScheduleCyclePendingChangesProps) => {
    const theme = useTheme();
    const strings = LocalizedStrings.admin.schedule.pendingChanges;

    const [isUndoing, setIsUndoing] = useState(false);
    const [isCancelling, setIsCancelling] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);
    const disableActions = isUndoing || isSaving || isCancelling;

    async function undo() {
      setIsUndoing(true);
      try {
        await onUndo();
      } catch {
        setError(strings.undoErrorMessage());
      } finally {
        setIsUndoing(false);
      }
    }

    async function cancel(hasConfirmed: boolean) {
      setShowCancelConfirmation(false);

      if (!hasConfirmed) {
        return;
      }

      setIsCancelling(true);

      try {
        await onCancel();
      } catch {
        setError(strings.discardErrorMessage());
      } finally {
        setIsCancelling(false);
      }
    }

    async function save() {
      setIsSaving(true);
      try {
        const { hasDroppedChanges } = await onSave();
        if (hasDroppedChanges) {
          showHasDroppedChangesMessage();
        }
      } catch {
        setError(strings.publishErrorMessage());
      } finally {
        setIsSaving(false);
      }
    }

    return (
      <Box
        sx={{
          ...sx,
          px: 2,
          py: 1,
          borderRadius: 1,
          border: `1px solid ${theme.palette.primary.main}`,
          backgroundColor: alpha(theme.palette.primary.main, 0.05)
        }}
        className={className}
      >
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center">
          <Stack flex={1} direction="row" spacing={2} alignItems="center">
            <InfoOutlined color="primary" />

            <Stack flex={1}>
              <Typography variant="subtitle2">{strings.title()}</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                {strings.subtitle()}
              </Typography>
            </Stack>
          </Stack>

          <Stack direction="row" spacing={2} alignItems="center">
            <Tooltip title={strings.undoButtonTooltip()}>
              <IconButton onClick={() => void undo()} disabled={disableActions} size="medium">
                {isUndoing ? (
                  <CircularProgress size="1em" color="inherit" />
                ) : (
                  <UndoRounded color="primary" fontSize="small" />
                )}
              </IconButton>
            </Tooltip>

            <LoadingButton
              variant="text"
              color="error"
              onClick={() => setShowCancelConfirmation(true)}
              loading={isCancelling}
              disabled={disableActions}
              size="small"
            >
              {strings.discardChangesButtonTitle()}
            </LoadingButton>

            <LoadingButton
              color="primary"
              variant="contained"
              startIcon={<PublishRounded />}
              onClick={() => void save()}
              loading={isSaving}
              disabled={disableActions}
              size="small"
            >
              {strings.publishChangesButtonTitle()}
            </LoadingButton>
          </Stack>
        </Stack>

        {showCancelConfirmation && (
          <ConfirmationDialog
            isOpen
            message={strings.discardChangesConfirmation.message()}
            title={strings.discardChangesConfirmation.title()}
            onSubmit={(hasConfirmed) => void cancel(hasConfirmed)}
            isDestructive
            cancelButtonLabel={strings.discardChangesConfirmation.cancelButtonLabel()}
            confirmButtonLabel={strings.discardChangesConfirmation.submitButtonLabel()}
          />
        )}

        <Snackbar
          open={error != null}
          autoHideDuration={6000}
          onClose={() => setError(undefined)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={() => setError(undefined)} severity="error" variant="filled" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      </Box>
    );
  }
);
