import { UserDashboardCalendarDayAllDayItemInfo, UserDashboardCalendarDaySpecificDateViewModel } from '@/viewmodels';
import { Box, Unstable_Grid2 as Grid, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useActivePlannerId, useServices } from '../../../../hooks';
import { ActionAlert } from '../../../utils';
import { UserDashboardCalendarDayAnnotationsView } from './UserDashboardCalendarDayAnnotationsView';
import { UserDashboardCalendarDayCalendarEventView } from './UserDashboardCalendarDayCalendarEventView';
import { UserDashboardCalendarDayErrorIndicator } from './UserDashboardCalendarDayErrorIndicator';
import { UserDashboardCalendarDayHeaderView } from './UserDashboardCalendarDayHeaderView';
import { UserDashboardCalendarDayLoadingIndicator } from './UserDashboardCalendarDayLoadingIndicator';
import { UserDashboardCalendarDayNoteView } from './UserDashboardCalendarDayNoteView';
import { UserDashboardCalendarDaySpecificDateTimedItemsView } from './UserDashboardCalendarDaySpecificDateTimedItemsView';
import { UserDashboardCalendarDayWorkView } from './UserDashboardCalendarDayWorkView';

export interface UserDashboardCalendarDaySpecificDateViewProps {
  sx?: SxProps;
  className?: string;
  viewModel: UserDashboardCalendarDaySpecificDateViewModel;
  hasCalendarSyncError: boolean;
  retryFetchData: () => Promise<void>;
}

export const UserDashboardCalendarDaySpecificDateView = observer(
  ({
    sx,
    className,
    viewModel,
    hasCalendarSyncError,
    retryFetchData
  }: UserDashboardCalendarDaySpecificDateViewProps) => {
    const { route } = useServices();
    const plannerId = useActivePlannerId();

    const { allDayItems } = viewModel;
    const strings = LocalizedStrings.calendar.day;
    const navigate = useNavigate();

    function goToPlanningScreen() {
      const destination = `${route.resolvePlannerLocation(plannerId)}/plan`;
      navigate(destination);
    }

    return (
      <Box
        sx={sx}
        className={className}
        display="flex"
        flexDirection="column"
        height="100%"
        width="100%"
        overflow="hidden"
      >
        <UserDashboardCalendarDayHeaderView
          day={viewModel}
          hasCalendarSyncError={hasCalendarSyncError}
          retryFetchData={() => retryFetchData()}
        />

        {viewModel.annotations.length > 0 && (
          <UserDashboardCalendarDayAnnotationsView annotations={viewModel.annotations} sx={{ my: 1 }} />
        )}

        <Box flex={1} overflow="auto">
          {viewModel.state === 'fulfilled' && (
            <Grid
              sx={{ width: '100%', height: '100%' }}
              container
              columnSpacing={{ xs: 0, md: 3 }}
              rowSpacing={{ xs: 0, md: 3 }}
              p={{ xs: 2, md: 3 }}
              pt={{ xs: 1, md: 1 }}
            >
              <Grid xs={12} md={7}>
                {viewModel.inboxWorkCount > 0 && (
                  <ActionAlert
                    sx={{ mb: 2 }}
                    message={strings.inboxWorkAlert(viewModel.inboxWorkCount)}
                    onClick={() => goToPlanningScreen()}
                    severity="info"
                    variant="filled"
                  />
                )}

                <UserDashboardCalendarDaySpecificDateTimedItemsView viewModel={viewModel} />
              </Grid>

              <Grid xs={12} md={5}>
                <Stack spacing={1}>
                  {allDayItems.length > 0 && (
                    <Typography variant="body1" fontWeight="600">
                      {strings.allDayItemsSectionTitle()}
                    </Typography>
                  )}
                  <Stack pl={1}>{allDayItems.map((item) => renderAllDayItem(item))}</Stack>
                </Stack>
              </Grid>
            </Grid>
          )}

          {viewModel.state === 'error' && (
            <UserDashboardCalendarDayErrorIndicator sx={{ p: { xs: 2, md: 3 } }} retry={retryFetchData} />
          )}

          {viewModel.state === 'loading' && <UserDashboardCalendarDayLoadingIndicator sx={{ p: { xs: 2, md: 3 } }} />}
        </Box>
      </Box>
    );
  }
);

function renderAllDayItem(item: UserDashboardCalendarDayAllDayItemInfo) {
  switch (item.case) {
    case 'calendarEvent':
      return <UserDashboardCalendarDayCalendarEventView key={item.value.id} calendarEvent={item.value} />;
    case 'note':
      return <UserDashboardCalendarDayNoteView key={item.value.id} note={item.value} showTime />;
    case 'work':
    case 'publishedWork':
      return <UserDashboardCalendarDayWorkView work={item} key={item.value.id} showTime />;
  }
}
