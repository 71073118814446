import { useOptionalActivePlannerId, useServices, useViewModel } from '@/hooks';
import { Box, Stack, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { ProductsList } from '../../subscriptions';
import { AlertDialog, Dialog } from '../../utils';
import { CreateSchoolKindPicker } from './CreateSchoolKindPicker';
import { SchoolDetailsEdit } from './SchoolDetailsEdit';

const variants = {
  enter: (direction: number) => {
    return {
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    };
  },
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1
  },
  exit: (direction: number) => {
    return {
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    };
  }
};

export interface CreateSchoolDialogProps {
  isOpen: boolean;
  onClose?: (schoolId: string | undefined) => void;
}

export const CreateSchoolDialog = observer(({ isOpen, onClose }: CreateSchoolDialogProps) => {
  const { localization, route } = useServices();
  const plannerId = useOptionalActivePlannerId();
  const strings = localization.localizedStrings.school;

  const navigate = useNavigate();

  function close(schoolId: string | undefined, attachError: Error | undefined) {
    if (onClose != null) {
      onClose(schoolId);
      return Promise.resolve();
    }

    if (schoolId != null && schoolId.length > 0 && plannerId != null) {
      const newSchoolLocation = route.resolvePlannerSettingsLocation('schools', plannerId);
      const state = { hasAttachErrorSchoolId: attachError != null ? schoolId : undefined };
      navigate(newSchoolLocation, { replace: true, state });
    } else {
      navigate(-1);
    }

    return Promise.resolve();
  }

  const viewModel = useViewModel(
    (viewModels) => viewModels.createCreateSchoolDialogViewModel(plannerId, close),
    [plannerId]
  );

  const title = useMemo(() => {
    if (viewModel.tab === 'picker') {
      return strings.createSchoolTitle;
    } else {
      return viewModel.isCreatingSharedSchool ? strings.createSharedSchoolTitle : strings.createPersonalSchoolTitle;
    }
  }, [viewModel.tab, viewModel.isCreatingSharedSchool]);

  return (
    <Dialog
      viewModel={viewModel}
      isOpen={isOpen}
      title={title}
      width="sm"
      renderData={() => {
        const direction = useMemo(() => (viewModel.tab === 'picker' ? -1 : 1), [viewModel.tab]);

        return (
          <Box width="100%">
            <AnimatePresence initial={false} custom={direction} mode="popLayout">
              <motion.div
                key={viewModel.tab}
                custom={direction}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{ opacity: { duration: 0.2 }, ease: 'easeInOut' }}
              >
                {viewModel.tab === 'picker' && (
                  <CreateSchoolKindPicker onSelection={(kind) => viewModel.selectSchoolKind(kind)} />
                )}

                {viewModel.tab === 'subscribe' && (
                  <Stack spacing={2}>
                    <Typography fontWeight="500" textAlign="center">
                      {LocalizedStrings.school.create.subscribeTitle()}
                    </Typography>
                    <ProductsList viewModel={viewModel.subscribeViewModel} />
                  </Stack>
                )}

                {viewModel.tab === 'details' && <SchoolDetailsEdit viewModel={viewModel} />}
              </motion.div>
            </AnimatePresence>

            {viewModel.editSchoolError != null && (
              <AlertDialog
                isOpen={true}
                title={strings.createSchoolErrorTitle}
                message={strings.createSchoolErrorMessage}
                onSubmit={() => viewModel.clearCreateSchoolError()}
              />
            )}
          </Box>
        );
      }}
    />
  );
});
