import { CourseSectionColors } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService, ThemeService } from '@/services';
import { SchoolCourseSectionsLoadable, SchoolDataStore } from '@/stores';
import { randomElement } from '@/utils';
import { computed, makeObservable, runInAction } from 'mobx';
import { AppBaseCourseSectionEditViewModel, CourseSectionEditInfo } from './CourseSectionEditViewModel';

export class AppSchoolCourseSectionEditViewModel extends AppBaseCourseSectionEditViewModel {
  constructor(
    courseSectionId: string | undefined,
    schoolId: string,
    onSuccess: (courseSectionId: string) => Promise<void>,
    onCancel: () => Promise<void>,
    localization: LocalizationService = ServiceContainer.services.localization,
    theme: ThemeService = ServiceContainer.services.theme,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore
  ) {
    super(courseSectionId, schoolId, onSuccess, onCancel, localization, theme);
    makeObservable(this);

    if (courseSectionId == null) {
      throw new Error('Creation of course section is currently not supported for schools.');
    }
  }

  private get courseSectionsLoadable(): SchoolCourseSectionsLoadable {
    return this._schoolStore.getCourseSections(this._dashboardId);
  }

  @computed
  get canEditNames(): boolean {
    return !this._initialData!.hasExternalSource;
  }

  protected createCourseSection(): Promise<string> {
    throw new Error('Creation of course section is currently not supported for schools.');
  }

  protected async updateCourseSection(id: string): Promise<string> {
    const updatedCourse = await this._schoolStore.updateCourseSectionColor(
      id,
      this._dashboardId,
      this.backgroundColor ?? ''
    );

    return updatedCourse.id;
  }

  protected async loadData(): Promise<void> {
    if (this._courseSectionId == null) {
      const value: CourseSectionEditInfo = {
        title: '',
        section: '',
        color: randomElement(CourseSectionColors)!,
        syncToken: BigInt(0),
        hasExternalSource: false
      };

      runInAction(() => {
        this._initialData = value;
        this._title = value.title;
        this._section = value.section;
        this._color = value.color;
        this._state = 'fulfilled';
      });
      return;
    }

    runInAction(() => (this._state = 'pending'));

    try {
      await this.courseSectionsLoadable.fetch(true);

      if (!this.courseSectionsLoadable.hasData) {
        throw new Error('An error occurred while loading course sections.');
      }

      const courseSection = this.courseSectionsLoadable.values.find((cs) => cs.id === this._courseSectionId);
      if (courseSection == null) {
        throw new Error(`No CourseSection found for id ${this._courseSectionId}`);
      }

      const value: CourseSectionEditInfo = {
        title: courseSection.title,
        section: courseSection.section,
        color: courseSection.color,
        syncToken: courseSection.syncToken,
        hasExternalSource: courseSection.externalSource != null
      };

      runInAction(() => {
        this._initialData = value;
        this._title = value.title;
        this._section = value.section;
        this._color = value.color;
        this._state = 'fulfilled';
      });
    } catch (e) {
      runInAction(() => (this._state = e as Error));
    }
  }
}
