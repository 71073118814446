import { Box, alpha, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { isAdminOfSharedSchool } from '../../../models';
import { SchoolEdit } from '../../school';
import { AdminContentCard } from '../shared';
import { AdminManageSchoolActions } from './AdminManageSchoolActions';
import { AdminSchoolAdminUsersList } from './AdminSchoolAdminUsersList';
import { AdminSchoolParticipationCodesList } from './AdminSchoolParticipationCodesList';

export interface AdminSchoolGeneralInformationProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolGeneralInformation = observer(({ sx, className }: AdminSchoolGeneralInformationProps) => {
  const { userStore } = useServices();
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const strings = LocalizedStrings.admin.general;
  const theme = useTheme();
  const school = userStore.getSchoolForId(schoolId);
  const canManageSchool = school != null && isAdminOfSharedSchool(userStore.user.userId, school);

  return (
    <Grid sx={sx} className={className} container spacing={{ xs: 1, sm: 2 }}>
      <Grid xs={12} md={6}>
        <AdminContentCard title={strings.informationTitle()}>
          <Box p={2} pt={3}>
            <SchoolEdit schoolId={schoolId} />
          </Box>
        </AdminContentCard>
      </Grid>

      <Grid xs={12} md={6}>
        <AdminContentCard title={strings.adminsListTitle()} subtitle={strings.adminsListSubtitle()}>
          <AdminSchoolAdminUsersList schoolId={schoolId} />
        </AdminContentCard>
      </Grid>

      {canManageSchool && (
        <Grid xs={12} md={6}>
          <AdminContentCard title={strings.participationCodesTitle()} subtitle={strings.participationCodesSubtitle()}>
            <AdminSchoolParticipationCodesList sx={{ p: 2 }} schoolId={schoolId} />
          </AdminContentCard>
        </Grid>
      )}

      {canManageSchool && (
        <Grid xs={12} md={6}>
          <AdminContentCard
            title={strings.manageActions.title()}
            sx={{
              border: `1px solid ${alpha(theme.palette.error.main, theme.palette.mode === 'dark' ? 0.5 : 1)}`,
              boxShadow: 'none'
            }}
          >
            <AdminManageSchoolActions schoolId={schoolId} />
          </AdminContentCard>
        </Grid>
      )}
    </Grid>
  );
});
