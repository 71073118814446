import { useServices } from '@/hooks';
import { PlannerListViewModel } from '@/viewmodels';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { PlannerDoneListEmptyIndicator } from './PlannerDoneListEmptyIndicator';
import { PlannerList } from './PlannerList';
import { PlannerListItem } from './PlannerListItem';

export interface PlannerDoneListProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerListViewModel;
}

export const PlannerDoneList = observer(({ sx, className, viewModel }: PlannerDoneListProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;

  return (
    <PlannerList
      sx={sx}
      className={className}
      viewModel={viewModel}
      title={strings.doneTitle}
      subtitle={strings.doneSubtitle}
      renderItem={(item) => <PlannerListItem item={item} displaySteps={false} />}
      renderEmptyIndicator={() => <PlannerDoneListEmptyIndicator />}
    />
  );
});
