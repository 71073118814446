import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { TimePicker } from '@mui/x-date-pickers';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { TimeOfDay, timeOfDayToDate } from '../../../../models';
import { PlannerPastePlannerItemTimeOfDaySelectionViewModel } from '../../../../viewmodels';
import { PlannerPastePlannerItemOption } from './PlannerPastePlannerItemOption';

export interface PlannerPastePlannerItemCustomTimeOptionProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerPastePlannerItemTimeOfDaySelectionViewModel;
}

export const PlannerPastePlannerItemCustomTimeOption = observer(
  ({ sx, className, viewModel }: PlannerPastePlannerItemCustomTimeOptionProps) => {
    const strings = LocalizedStrings.planner.pastePlannerItem;

    function onSelect() {
      if (viewModel.selection?.case === 'time') {
        return;
      }

      const timeOfDay = new TimeOfDay({ hours: 8, minutes: 0 });
      viewModel.selection = { case: 'time', value: timeOfDayToDate(timeOfDay), hasValidationError: false };
    }

    return (
      <Stack sx={sx} className={className} spacing={0.5} direction="row">
        <PlannerPastePlannerItemOption
          sx={{ flex: 1 }}
          isSelected={viewModel.selection?.case === 'time'}
          title={strings.specificTimeOptionLabel()}
          onSelect={onSelect}
        />

        {viewModel.selection?.case === 'time' && (
          <TimePicker
            value={viewModel.selection.value}
            onChange={(date, context) => {
              if (date != null) {
                viewModel.selection = {
                  case: 'time',
                  value: date,
                  hasValidationError: context.validationError != null
                };
              } else {
                viewModel.selection = { case: 'none' };
              }
            }}
            slotProps={{
              textField: {
                helperText: viewModel.selection.hasValidationError
                  ? strings.timePickerInvalidTimeErrorMessage()
                  : undefined,
                error: viewModel.selection.hasValidationError
              }
            }}
          />
        )}
      </Stack>
    );
  }
);
