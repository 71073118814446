import { GoogleCalendarSyncBlocker } from '@/models';
import { ServiceContainer } from '@/providers';
import { GoogleCalendarConnectedAppService, LocalizationService } from '@/services';
import { Calendar } from '@buf/studyo_studyo-today-google-calendar.bufbuild_es/studyo/today/google_calendar/v1/resources/calendar_pb';
import { chain } from 'lodash';
import { ObservableMap, action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppEditableConnectedAppViewModel, EditableConnectedAppViewModel } from '../EditableConnectedAppViewModel';
import { AppGoogleCalendarViewModel, GoogleCalendarViewModel } from './GoogleCalendarViewModel';

export interface EditableGoogleCalendarConnectedAppViewModel extends EditableConnectedAppViewModel {
  readonly calendars: GoogleCalendarViewModel[];
}

export class AppEditableGoogleCalendarConnectedAppViewModel
  extends AppEditableConnectedAppViewModel<Calendar[], GoogleCalendarSyncBlocker>
  implements EditableGoogleCalendarConnectedAppViewModel
{
  constructor(
    protected readonly _connectedApp: GoogleCalendarConnectedAppService,
    onSuccess: (hasChanges: boolean) => Promise<void>,
    startSync: boolean,
    localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(_connectedApp, onSuccess, startSync, localization);
    makeObservable(this);
  }

  @computed
  get calendars(): GoogleCalendarViewModel[] {
    return Array.from(this.calendarsById.values());
  }

  get hasChanges(): boolean {
    return this.calendars.some((c) => c.hasChanges);
  }

  protected readonly isEntitled = true;

  protected loadData(): Promise<Calendar[]> {
    return this._connectedApp.getCalendars();
  }

  protected async onSync(): Promise<void> {
    // Save the selected state
    const lastCalendarsSelectedStateById = new Map<string, boolean>();
    this.calendarsById.forEach((c, key) => lastCalendarsSelectedStateById.set(key, c.isSelected));

    await this.reloadData();

    // Apply the saved selected state to the new data
    lastCalendarsSelectedStateById.forEach((isSelected, key) => {
      const course = this.calendarsById.get(key);
      if (course != null) {
        course.isSelected = isSelected;
      }
    });
  }

  @computed
  private get calendarsById(): ObservableMap<string, GoogleCalendarViewModel> {
    return observable.map<string, GoogleCalendarViewModel>(
      chain(this.data.value ?? [])
        .orderBy('isSelected', 'desc')
        .map((c) => new AppGoogleCalendarViewModel(c))
        .keyBy((c) => c.id)
        .value()
    );
  }

  @action
  async save() {
    try {
      this._isApplying = true;
      this._error = undefined;

      await this._connectedApp.updateCalendarSelection(this.calendars.filter((c) => c.isSelected).map((c) => c.id));
      await this._onSuccess(true);
    } catch (e) {
      runInAction(() => (this._error = (e as Error).message));
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }
}
