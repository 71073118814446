import { PlannerRepeatItemViewModel } from '@/viewmodels';
import { MenuItem, Select } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PlannerRepeatItemPatternKindSelectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerRepeatItemViewModel;
}

export const PlannerRepeatItemPatternKindSelection = observer(
  ({ sx, className, viewModel }: PlannerRepeatItemPatternKindSelectionProps) => {
    const strings = LocalizedStrings.planner.repeatPlannerItem;

    function onPatternKindValueChange(value: string) {
      switch (value) {
        case 'occurrence':
          viewModel.pattern = { case: 'occurrence' };
          return;

        case 'day':
          viewModel.pattern = { case: 'day' };
          return;

        case 'week':
          viewModel.pattern = { case: 'week', weekSkipCount: 1, daysOfWeek: [] };
          return;

        case 'cycleDay':
          viewModel.pattern = { case: 'cycleDay', cycleDays: [] };
          return;

        default:
          viewModel.pattern = { case: 'occurrence' };
          return;
      }
    }

    return (
      <Select
        sx={sx}
        className={className}
        size="small"
        value={viewModel.pattern.case}
        onChange={(e) => onPatternKindValueChange(e.target.value)}
      >
        {viewModel.canRepeatEveryOccurrence && (
          <MenuItem value="occurrence">{strings.everyOccurrenceOption()}</MenuItem>
        )}
        <MenuItem value="day">{strings.everyDayOption()}</MenuItem>
        <MenuItem value="week">{strings.byWeekOption()}</MenuItem>
        <MenuItem value="cycleDay">{strings.specificCycleDaysOption()}</MenuItem>
      </Select>
    );
  }
);
