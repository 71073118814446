import { PlannerListItemInfoUrl } from '@/viewmodels';
import { ListItemButton, Link as MUILink } from '@mui/material';
import { SxProps } from '@mui/system';
import { MouseEvent, ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BackgroundLocationState } from '../../../../BackgroundLocationState';

export interface PlannerListItemRootProps {
  children: ReactNode;
  sx?: SxProps;
  className?: string;
  isHighlighted: boolean;
  url: PlannerListItemInfoUrl | undefined;
  onContextMenu?: (e: MouseEvent) => void;
  disableGutters?: boolean;
}

export function PlannerListItemRoot({
  sx,
  url,
  children,
  className,
  isHighlighted,
  onContextMenu,
  disableGutters
}: PlannerListItemRootProps) {
  const location = useLocation();
  const renderLink = () => {
    switch (url?.kind) {
      case 'internal': {
        const state = {
          ...(url.state || {}),
          backgroundLocation: url.setBackgroundLocation ? location : undefined
        } as BackgroundLocationState;

        return (
          <Link
            style={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}
            to={url.to}
            state={state}
            replace={url.replace}
          />
        );
      }

      case 'external':
        return (
          <MUILink
            sx={{ position: 'absolute', top: 0, right: 0, left: 0, bottom: 0 }}
            href={url.to}
            target={url.target}
            rel={url.target === '_blank' ? 'noreferrer' : undefined}
          />
        );

      case undefined:
        return null;
    }
  };

  return (
    <ListItemButton
      className={className}
      sx={{ ...sx, display: 'flex', color: 'inherit', textDecoration: 'none', position: 'relative' }}
      selected={isHighlighted}
      dense
      onContextMenu={onContextMenu}
      disableGutters={disableGutters}
    >
      {renderLink()}

      {children}
    </ListItemButton>
  );
}
