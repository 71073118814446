import { ServiceContainer } from '@/providers';
import { LocalizationService, UserService } from '@/services';
import { PlannerDataStore, UserDataStore } from '@/stores';
import { ValidateSharingCodeResponse } from '@/transports';
import { PlannerRelationshipKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_relationship_kind_pb';
import { UserPersona } from '@buf/studyo_studyo-today-users.bufbuild_es/studyo/today/users/v1/resources/user_persona_pb';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { AppBaseUseSharingInvitationCodeViewModel, BaseUseSharingInvitationCodeViewModel } from '../shared';
import { AppBaseOnboardingScreenViewModel, BaseOnboardingScreenViewModel } from './BaseOnboardingScreenViewModel';
import { OnboardingViewModel } from './OnboardingViewModel';

export interface UseSharingInvitationCodeStepViewModel
  extends BaseUseSharingInvitationCodeViewModel,
    BaseOnboardingScreenViewModel {
  goBack(): void;
}

export class AppUseSharingInvitationCodeStepViewModel
  extends AppBaseOnboardingScreenViewModel
  implements UseSharingInvitationCodeStepViewModel
{
  @observable private _error: string | undefined;

  constructor(
    onboardingState: OnboardingViewModel,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _user: UserService = ServiceContainer.services.user,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore
  ) {
    super(onboardingState);
    makeObservable(this);
  }

  @computed
  get canApply(): boolean {
    return this.useSharingInvitationCodeViewModel.canApply;
  }

  @computed
  get error(): string | undefined {
    return this.useSharingInvitationCodeViewModel.error ?? this._error;
  }

  @computed
  get validationResponse(): ValidateSharingCodeResponse | undefined {
    return this.useSharingInvitationCodeViewModel.validationResponse;
  }

  @computed
  get isApplying(): boolean {
    return this.useSharingInvitationCodeViewModel.isApplying;
  }

  @computed
  get sharingInvitationCode(): string | undefined {
    return this.useSharingInvitationCodeViewModel.sharingInvitationCode;
  }

  set sharingInvitationCode(value: string | undefined) {
    this.useSharingInvitationCodeViewModel.sharingInvitationCode = value;
  }

  @computed
  get showNoMatchingPlannerAlert(): boolean {
    return this.useSharingInvitationCodeViewModel.showNoMatchingPlannerAlert;
  }

  @computed
  get showParticipationCodeAlert(): boolean {
    return this.useSharingInvitationCodeViewModel.showParticipationCodeAlert;
  }

  dismissNoMatchingPlannerAlert() {
    this.useSharingInvitationCodeViewModel.dismissNoMatchingPlannerAlert();
  }

  async dismissParticipationCodeAlert(userConfirmed: boolean) {
    await this.useSharingInvitationCodeViewModel.dismissParticipationCodeAlert(userConfirmed);
  }

  @computed
  private get useSharingInvitationCodeViewModel(): BaseUseSharingInvitationCodeViewModel {
    return new AppBaseUseSharingInvitationCodeViewModel(
      undefined,
      async (result, hasCreatedPlanner) => {
        await this._userStore.assignPersona(result.impliedUserPersona);
        this._onboardingState.userPersona = result.impliedUserPersona;

        this._onboardingState.plannerId = result.plannerId;
        this._onboardingState.schoolId = result.schoolId;

        if (!hasCreatedPlanner && result.impliedUserPersona !== UserPersona.PARENT) {
          // Creating user own planner if none was created by using the code. Not applicable to parents
          await this._userStore.createPlanner(PlannerRelationshipKind.INDIVIDUAL, undefined, false);
        }
        await this._onboardingState.complete();
      },
      this._localization,
      this._plannerStore,
      this._userStore
    );
  }

  @action
  async submit() {
    this._error = undefined;
    this._isSubmitting = true;

    try {
      await this.useSharingInvitationCodeViewModel.apply(false);
    } catch (e) {
      const error = e as Error;
      const strings = this._localization.localizedStrings.onboarding;
      runInAction(() => (this._error = strings.createPlannerErrorMessage(error.message)));
    } finally {
      runInAction(() => (this._isSubmitting = false));
    }
  }

  @computed
  get canSubmit(): boolean {
    return this.canApply;
  }

  apply(): Promise<void> {
    return Promise.resolve();
  }

  goBack() {
    this._onboardingState.goBack();
  }
}
