import { useOptionalActivePlannerId, useServices } from '@/hooks';
import { dayToDate } from '@/models';
import { DateService } from '@/services';
import { ScheduleCycleListItemInfo } from '@/viewmodels';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { EditRounded, SaveAsRounded } from '@mui/icons-material';
import { Checkbox, IconButton, ListItem, ListItemIcon, ListItemText, Stack, Tooltip, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { differenceInCalendarDays, format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';

export interface ScheduleCycleListItemProps {
  sx?: SxProps;
  className?: string;
  scheduleCycleInfo: ScheduleCycleListItemInfo;
  showSource: boolean;
}

export const ScheduleCycleListItem = observer(
  ({ sx, scheduleCycleInfo, className, showSource }: ScheduleCycleListItemProps) => {
    const { dateService, route } = useServices();
    const plannerId = useOptionalActivePlannerId();
    const navigate = useNavigate();
    const strings = LocalizedStrings.scheduleCycle.list;

    const { canBeIgnored, canToggleIsIgnoredValue, scheduleCycle, userDashboard, isPast, isIgnored } =
      scheduleCycleInfo;

    function showScheduleCycleDetails() {
      // Even if it's a school schedule, we go to the planner's schedule edition if we have
      // a planner.
      const destination =
        plannerId != null
          ? route.resolvePlannerScheduleConfigLocation(scheduleCycle.scheduleCycle.id, undefined, plannerId)
          : scheduleCycleInfo.userDashboard.kind === 'school'
            ? route.resolveAdminSchoolScheduleLocation(
                scheduleCycleInfo.userDashboard.id,
                scheduleCycleInfo.scheduleCycle.scheduleCycle.id
              )
            : undefined;

      if (destination != null) {
        navigate(destination);
      }
    }

    return (
      <ListItem
        sx={sx}
        dense
        className={className}
        secondaryAction={
          <IconButton onClick={showScheduleCycleDetails} size="medium">
            <EditRounded fontSize="medium" />
          </IconButton>
        }
      >
        {canBeIgnored && (
          <ListItemIcon>
            <Tooltip
              title={isIgnored ? strings.ignoredScheduleCycleTooltip() : strings.activeScheduleCycleTooltip()}
              disableInteractive
            >
              <Checkbox
                checked={!isIgnored}
                disabled={!canToggleIsIgnoredValue}
                onChange={() => scheduleCycleInfo.toggleIsIgnored()}
              />
            </Tooltip>
          </ListItemIcon>
        )}

        {scheduleCycle.draftSessionId.length > 0 && (
          <Tooltip title={strings.pendingChangesTooltip()}>
            <ListItemIcon>
              <SaveAsRounded />
            </ListItemIcon>
          </Tooltip>
        )}

        <Stack flex={1}>
          <ListItemText
            primary={scheduleCycle.scheduleCycle.name}
            primaryTypographyProps={isPast ? { color: 'textSecondary', variant: 'subtitle1' } : undefined}
            secondary={getSecondaryTextForScheduleCycle(scheduleCycle.scheduleCycle, isPast, dateService)}
            secondaryTypographyProps={{ color: !isPast ? 'primary' : 'textSecondary' }}
          />

          {showSource && (
            <Typography variant="caption" color="textSecondary" fontWeight="500">
              {userDashboard.kind === 'planner'
                ? strings.personalScheduleLabel()
                : strings.schoolScheduleLabel(userDashboard.school.school!.name)}
            </Typography>
          )}
        </Stack>

        {isPast && (
          <Typography mr={2} variant="caption" color="textSecondary" fontWeight="500" fontStyle="italic">
            {strings.pastScheduleLabel()}
          </Typography>
        )}
      </ListItem>
    );
  }
);

function getSecondaryTextForScheduleCycle(schedule: ScheduleCycle, isPast: boolean, dateService: DateService): string {
  if (!isPast) {
    const startDate = dayToDate(schedule.startDay!);
    const isStarted = differenceInCalendarDays(startDate, dateService.now) <= 0;

    if (isStarted) {
      const endDate = dayToDate(schedule.endDay!);
      const formattedEndDate = format(endDate, 'PPP');
      return LocalizedStrings.scheduleCycle.list.activeScheduleSubtitle(formattedEndDate);
    } else {
      const formattedStartDate = format(startDate, 'PPP');
      return LocalizedStrings.scheduleCycle.list.futureScheduleSubtitle(formattedStartDate);
    }
  }

  const startDate = dayToDate(schedule.startDay!);
  const formattedStartDate = format(startDate, 'PPP');
  const endDate = dayToDate(schedule.endDay!);
  const formattedEndDate = format(endDate, 'PPP');
  return LocalizedStrings.scheduleCycle.list.pastScheduleSubtitle(formattedStartDate, formattedEndDate);
}
