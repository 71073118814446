import { Inventory2Rounded } from '@mui/icons-material';
import { Alert, alpha, Box, Toolbar, Typography, useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useServices } from '../../../hooks';
import { isAdminOfSharedSchool } from '../../../models';
import { LoadingSchoolScreen } from '../../shared';
import { AdminAppBar } from './AdminAppBar';
import { AdminBreadcrumbs } from './AdminBreadcrumbs';
import { AdminLayoutDrawer } from './AdminLayoutDrawer';
import { AdminProfileMenu } from './AdminProfileMenu';
import { AdminSchoolNotFound } from './AdminSchoolNotFound';

export const AdminLayout = observer(() => {
  const { user, userStore } = useServices();
  const userProfile = user.currentUser!;
  const theme = useTheme();
  const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const schoolLoadable = schoolId.length > 0 ? userStore.getSchoolLoadable(schoolId) : undefined;
  const strings = LocalizedStrings.admin;

  const [showDrawer, setShowDrawer] = useState(false);
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const profileButtonRef = useRef<HTMLElement | null>(null);

  if (schoolLoadable != null && !schoolLoadable.hasData) {
    return <LoadingSchoolScreen schoolLoadable={schoolLoadable} />;
  }

  const school = schoolLoadable?.data;
  const canManageSchool =
    (school != null && isAdminOfSharedSchool(userProfile.userId, school)) || userProfile.roles.includes('super-admin');

  return (
    <Box height="100%" width="100%" display="flex" overflow="hidden">
      <AdminAppBar
        sx={{ zIndex: { xs: undefined, sm: theme.zIndex.drawer + 1 } }}
        onDrawerToggleClick={() => setShowDrawer(!showDrawer)}
      />

      <AdminLayoutDrawer
        isOpen={showDrawer}
        onClose={() => setShowDrawer(false)}
        schoolId={schoolId}
        school={school}
        variant="mobile"
      />

      <AdminLayoutDrawer
        isOpen={showDrawer}
        onClose={() => setShowDrawer(false)}
        schoolId={schoolId}
        school={school}
        variant="web"
        sx={{ flexShrink: 0, flexDirection: 'column' }}
      />

      <Box
        component="main"
        sx={{
          zIndex: 2,
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey['50'] : undefined,
          overflow: 'hidden'
        }}
      >
        <Toolbar />

        <Box flex={1} overflow="hidden">
          <Box display="flex" overflow="hidden" width="100%" height="100%" flexDirection="column">
            {schoolId.length > 0 && <AdminBreadcrumbs schoolId={schoolId} />}

            {isExtraSmallScreen && (
              <Box p={0.5} sx={{ backgroundColor: alpha(theme.palette.primary.main, 0.2) }}>
                <Typography textAlign="center" variant="subtitle2">
                  {strings.mobileBannerText()}
                </Typography>
              </Box>
            )}

            {school?.school?.isArchived === true && (
              <Alert
                sx={{ m: 1, border: `1px solid ${alpha(theme.palette.error.main, 0.5)}` }}
                severity="error"
                icon={<Inventory2Rounded fontSize="inherit" color="inherit" />}
              >
                {strings.archivedSchoolBannerMessage()}
              </Alert>
            )}

            <Box flex={1} sx={{ overflowY: 'auto', overflowX: 'hidden' }}>
              {schoolId.length === 0 || canManageSchool ? (
                <Outlet />
              ) : (
                <AdminSchoolNotFound sx={{ height: '100%', p: 4 }} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>

      <AdminProfileMenu
        isOpen={showProfileMenu}
        onClose={() => setShowProfileMenu(false)}
        anchorEl={profileButtonRef.current}
      />
    </Box>
  );
});
