import { useServices } from '@/hooks';
import { ContextMenuActionPredefinedKind, ContextMenuActionsGroup, handleItemAction } from '@/viewmodels';
import { css } from '@emotion/css';
import {
  CalendarTodayRounded,
  CheckOutlined,
  ContentCopyRounded,
  ContentPasteRounded,
  DifferenceRounded,
  EditRounded,
  InfoOutlined,
  LowPriorityRounded,
  RepeatRounded,
  UnarchiveRounded
} from '@mui/icons-material';
import {
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  PopoverPosition,
  Typography,
  alpha,
  useTheme
} from '@mui/material';
import { SxProps } from '@mui/system';
import { Fragment, ReactNode, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExLocalizedStrings } from '../../resources/strings/ExLocalizedStrings';

export interface ContextMenuProps {
  sx?: SxProps;
  className?: string;
  isOpen: boolean;
  actionsGroups: ContextMenuActionsGroup[];
  anchorPosition?: PopoverPosition;
  onClose: () => void;
}

export function ContextMenu({ sx, className, isOpen, onClose, actionsGroups, anchorPosition }: ContextMenuProps) {
  const { localization, settingsStorage } = useServices();
  const reduceTransparency = settingsStorage.reduceTransparency ?? false;

  const theme = useTheme();
  const menuClassName = css({
    minWidth: 250,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    ...(!reduceTransparency
      ? {
          backgroundImage: 'none',
          backgroundColor: alpha(theme.palette.background.paper, 0.85),
          backdropFilter: 'blur(12px)'
        }
      : {})
  });

  const location = useLocation();
  const navigate = useNavigate();

  const groups = useMemo(
    () =>
      actionsGroups.filter((group) => {
        const actions = group.actions.filter((a) => a.hidden !== true);
        return actions.length > 0;
      }),
    [actionsGroups]
  );

  // Using a Popover as a Menu cannot have a Fragment as its children.
  return (
    <Popover
      open={isOpen}
      className={className}
      sx={sx}
      classes={{ paper: menuClassName }}
      onClose={onClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorPosition}
    >
      {groups.map((group, groupIndex) => {
        const actions = group.actions.filter((a) => a.hidden !== true);

        if (actions.length === 0) {
          return null;
        }

        return (
          <Fragment key={`context-menu-group-${groupIndex}`}>
            {groupIndex > 0 && <Divider />}

            {group.title != null && (
              <Typography variant="subtitle2" color="textSecondary" px={2} py={0.5}>
                {group.title()}
              </Typography>
            )}

            {actions.map((action, i) => (
              <MenuItem
                key={`menu-item-${i}`}
                onClick={(e) => {
                  onClose();
                  handleItemAction(e, action.onClick, navigate, location);
                }}
                dense
              >
                <ListItemIcon>
                  {action.icon.case === 'custom' ? action.icon.value : getIconForActionKind(action.icon.value)}
                </ListItemIcon>
                <ListItemText>
                  {action.title?.() ??
                    (action.icon.case !== 'custom'
                      ? getTitleForActionKind(action.icon.value, localization.localizedStrings)
                      : '')}
                </ListItemText>
              </MenuItem>
            ))}
          </Fragment>
        );
      })}
    </Popover>
  );
}

function getIconForActionKind(actionKind: ContextMenuActionPredefinedKind): ReactNode {
  switch (actionKind) {
    case 'show-details':
      return <InfoOutlined />;
    case 'edit':
      return <EditRounded />;
    case 'copy':
      return <ContentCopyRounded />;
    case 'duplicate':
      return <DifferenceRounded />;
    case 'distribute':
      return <LowPriorityRounded />;
    case 'repeat':
      return <RepeatRounded />;
    case 'paste':
      return <ContentPasteRounded />;
    case 'complete':
      return <CheckOutlined />;
    case 'restore':
      return <UnarchiveRounded />;
    case 'plan-work-session':
    case 'edit-work-session':
      return <CalendarTodayRounded />;
  }
}

function getTitleForActionKind(
  actionKind: ContextMenuActionPredefinedKind,
  localizedStrings: ExLocalizedStrings
): string {
  const strings = localizedStrings.workList;

  switch (actionKind) {
    case 'show-details':
      return strings.showDetails;
    case 'edit':
      return strings.edit;
    case 'copy':
      return strings.copy;
    case 'duplicate':
      return strings.duplicate;
    case 'distribute':
      return strings.distribute;
    case 'repeat':
      return strings.repeat;
    case 'paste':
      return strings.paste;
    case 'complete':
      return strings.completeWork;
    case 'restore':
      return strings.markAsActive;
    case 'plan-work-session':
      return strings.createPlannedWork;
    case 'edit-work-session':
      return strings.editPlannedWork;
  }
}
