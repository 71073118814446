import { useServices } from '@/hooks';
import { StudentInsightsWorksViewModel } from '@/viewmodels';
import { css } from '@emotion/css';
import { Info } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { List } from '../../../lists';
import { SearchBar } from '../../../utils';
import { StudentInsightsWorksListItem } from './StudentInsightsWorksListItem';

export interface StudentInsightsWorksListProps {
  sx?: SxProps;
  className?: string;
  viewModel: StudentInsightsWorksViewModel;
}

export const StudentInsightsWorksList = observer(({ sx, className, viewModel }: StudentInsightsWorksListProps) => {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;

  const listsClassName = css([{ width: '100%' }]);

  return (
    <Box sx={sx} className={className}>
      <Stack direction="column" display="flex" justifyContent="space-between" spacing={2} pt={2} height="100%">
        <SearchBar
          onSearch={(searchText) => (viewModel.searchText = searchText ?? '')}
          placeholder={strings.studentWorksTableSearchBarPlaceholder}
          sx={{ mx: 2 }}
        />

        <Stack spacing={1} direction="row" display="flex" alignItems="center" px={2}>
          <Info />

          <Typography variant="subtitle2" color="textSecondary">
            {strings.studentWorksMobileWarning}
          </Typography>
        </Stack>

        <Box flex={1}>
          <List
            className={listsClassName}
            sections={[{ id: 'main', numberOfRows: viewModel.works.length, title: undefined }]}
            scrollToIndex={viewModel.initialWorkIndex}
            keyForItem={(_, row) => viewModel.works[row].id}
            renderItem={(_, index) => {
              const work = viewModel.works[index];
              return <StudentInsightsWorksListItem key={`student-work-${work.id}`} work={work} />;
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
});
