import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useServices } from '../../../../hooks';
import { timeOfDayToDate } from '../../../../models';
import { PlannerPastePlannerItemViewModel } from '../../../../viewmodels';

export interface PlannerPastePlannerItemTimeHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerPastePlannerItemViewModel;
}

export const PlannerPastePlannerItemTimeHeader = observer(
  ({ sx, className, viewModel }: PlannerPastePlannerItemTimeHeaderProps) => {
    const { settings } = useServices();
    const strings = LocalizedStrings.planner.pastePlannerItem;

    if (
      viewModel.date == null ||
      viewModel.timeOfDaySelection == null ||
      viewModel.timeOfDaySelection.case === 'none'
    ) {
      return null;
    }

    if (viewModel.timeOfDaySelection.case === 'time') {
      const time = format(viewModel.timeOfDaySelection.value, 'p');
      return (
        <Typography sx={sx} className={className} variant="subtitle2">
          {strings.existingSpecificTimeHeaderLabel(time)}
        </Typography>
      );
    }

    const period = viewModel.timeOfDaySelection.value;
    const courseSection = viewModel.courseSectionsById.get(period.courseSectionId);
    const formattedStartTime = format(timeOfDayToDate(period.startTime), 'p');
    const formattedEndTime = format(timeOfDayToDate(period.endTime), 'p');
    const courseTitle = courseSection?.courseSection?.title ?? strings.noClassLabel();
    const periodName = settings.calendarShowPeriodLabels ? `${period.periodLabel} - ${courseTitle}` : courseTitle;

    const text = strings.existingTargetPeriodHeaderLabel(periodName, formattedStartTime, formattedEndTime);
    return (
      <Typography sx={sx} className={className} variant="subtitle2">
        {text}
      </Typography>
    );
  }
);
