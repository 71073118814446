import { Box, Grid } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { MediaQuery } from '../../../utils';
import { SchoolCourseSectionDetails } from './SchoolCourseSectionDetails';

export interface SchoolCourseSectionDetailsPageProps {
  sx?: SxProps;
  className?: string;
}

export const SchoolCourseSectionDetailsPage = observer(({ sx, className }: SchoolCourseSectionDetailsPageProps) => {
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const courseSectionId = params.courseId ?? '';

  return (
    <Box sx={sx} className={className} width="100%" height="100%">
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          display: 'flex'
        }}
      >
        <MediaQuery mediaQuery={(th) => th.breakpoints.up('md')}>
          <Grid container spacing={2} rowSpacing={2}>
            <Grid item xs={12}>
              <SchoolCourseSectionDetails schoolId={schoolId} courseSectionId={courseSectionId} />
            </Grid>
          </Grid>
        </MediaQuery>

        <MediaQuery mediaQuery={(th) => th.breakpoints.down('md')}>
          <SchoolCourseSectionDetails schoolId={schoolId} courseSectionId={courseSectionId} sx={{ flex: 1 }} />
        </MediaQuery>
      </Box>
    </Box>
  );
});
