import { BackgroundLocationState } from '@/BackgroundLocationState';
import { useActivePlannerId, useServices, useSync, useViewModel } from '@/hooks';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { onDialogClose } from '../../../utils';
import { StudentInsights } from './StudentInsights';

export interface StudentInsightsPageProps {
  sx?: SxProps;
  className?: string;
}

export const StudentInsightsPage = ({ sx, className }: StudentInsightsPageProps) => {
  const { route } = useServices();
  const location = useLocation();
  const state = (location.state ?? {}) as BackgroundLocationState;
  const navigate = useNavigate();

  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const studentId = params.studentId ?? '';
  const courseSectionId = params.courseId ?? '';

  const plannerId = useActivePlannerId();
  const currentId = `${courseSectionId}-${studentId}`;

  function close() {
    onDialogClose(route, navigate, state, plannerId);
    return Promise.resolve();
  }

  const viewModel = useViewModel(
    (viewModels) => viewModels.createStudentInsightsViewModel(schoolId, studentId, courseSectionId, close),
    [currentId]
  );

  useSync(
    'student-insights',
    async () => {
      await viewModel.reloadData();
    },
    [currentId]
  );

  useEffect(() => {
    // Reloading data when a new student is selected.
    viewModel.reloadData;
  }, [currentId]);

  return (
    <Box width="100%" height="100%" sx={sx} className={className}>
      <StudentInsights viewModel={viewModel} />
    </Box>
  );
};
