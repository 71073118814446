import { ServiceContainer } from '@/providers';
import { LocalizationService } from '@/services';
import { computed, makeObservable, observable, override, runInAction } from 'mobx';
import {
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration
} from './DialogActionButtonConfiguration';
import { AppBaseStaticDialogViewModel, StaticDialogViewModel } from './DialogViewModel';

export interface AsyncConfirmationDialogViewModel extends StaticDialogViewModel {
  readonly hasSubmitError: boolean;
}

export class AppAsyncConfirmationDialogViewModel
  extends AppBaseStaticDialogViewModel
  implements AsyncConfirmationDialogViewModel
{
  @observable private _isCancelling = false;
  @observable private _isConfirming = false;
  @observable private _submitError: Error | undefined;

  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;
  protected readonly _confirmButtonConfig: SaveDialogActionButtonConfiguration;

  constructor(
    protected readonly _confirm: (() => Promise<void>) | undefined,
    private readonly _cancel: (() => Promise<void>) | undefined,
    private readonly _showCancel: boolean,
    confirmButtonLabel: string | undefined,
    cancelButtonLabel: string | undefined,
    isDestructive: boolean | undefined,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization
  ) {
    super(async () => await _cancel?.());
    makeObservable(this);

    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.onCancel()
    );

    this._confirmButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () =>
      this.onConfirm()
    );

    this._confirmButtonConfig.title = () =>
      confirmButtonLabel ?? _localization.localizedStrings.utils.confirmationDialogConfirmButton;
    this._confirmButtonConfig.color = isDestructive ? 'error' : undefined;
    this._cancelButtonConfig.title = () =>
      cancelButtonLabel ?? _localization.localizedStrings.utils.confirmationDialogCancelButton;
    this._cancelButtonConfig.color = 'primary';
  }

  @computed
  get hasSubmitError(): boolean {
    return this._submitError != null;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.showLoading = this._isCancelling;
    this._cancelButtonConfig.isEnabled = !this._isCancelling && !this._isConfirming;
    this._confirmButtonConfig.showLoading = this._isConfirming;
    this._confirmButtonConfig.isEnabled = !this._isCancelling && !this._isConfirming;

    const actions = [this._confirmButtonConfig];
    if (this._showCancel) {
      actions.unshift(this._cancelButtonConfig);
    }
    return actions;
  }

  private async onCancel() {
    runInAction(() => (this._isCancelling = true));

    try {
      await this._cancel?.();
    } catch (e) {
      this._submitError = e as Error;
    } finally {
      runInAction(() => (this._isCancelling = false));
    }
  }

  private async onConfirm() {
    runInAction(() => (this._isConfirming = true));

    try {
      await this._confirm?.();
    } catch (e) {
      runInAction(() => (this._submitError = e as Error));
    } finally {
      runInAction(() => (this._isConfirming = false));
    }
  }
}
