import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { Stack, TextField, Typography, styled } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ColorPicker, Dialog } from '../../utils';

export interface CourseSectionEditDialogProps {
  /**
   * Id of the CourseSection we wish to edit. Optional. If undefined, it creates a new one.
   */
  courseSectionId: string | undefined;
  onClose?: (courseSectionId: string | undefined) => void;
  isOpen: boolean;
}

export const CourseSectionEditDialog = observer(
  ({ courseSectionId, onClose, isOpen }: CourseSectionEditDialogProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.courseSection.edit;
    const navigate = useNavigate();

    function onSuccess(courseId: string) {
      onClose?.(courseId);
      navigate(-1);
      return Promise.resolve();
    }

    function onCancel() {
      onClose?.(undefined);
      navigate(-1);
      return Promise.resolve();
    }

    const plannerId = useActivePlannerId();
    const viewModel = useViewModel(
      (viewModels) =>
        viewModels.createCourseSectionEditViewModel('planner', plannerId, courseSectionId, onSuccess, onCancel),
      [plannerId]
    );

    /**
     * Doing this because we only want the value to be updated on mouse up to avoid the warning to be flickering
     * when dragging the color picker in and off an area that is low contrast
     */
    const [isBackgroundColorLowContrast, setIsBackgroundColorLowContrast] = useState<boolean | undefined>(
      viewModel.isBackgroundColorLowContrast
    );

    useEffect(() => {
      if (viewModel.hasData && isBackgroundColorLowContrast == null) {
        setIsBackgroundColorLowContrast(viewModel.isBackgroundColorLowContrast);
      }
    }, [viewModel.hasData]);

    return (
      <Dialog
        viewModel={viewModel}
        isOpen={isOpen}
        title={courseSectionId != null ? strings.editTitle : strings.addTitle}
        width="xs"
        renderData={() => (
          <Stack direction="column" spacing={2} width="100%">
            <TextField
              label={strings.titleLabel}
              value={viewModel.title}
              fullWidth
              disabled={viewModel.isApplying || !viewModel.canEditNames}
              onChange={(e) => (viewModel.title = e.target.value)}
            />

            <TextField
              label={strings.sectionLabel}
              value={viewModel.section}
              fullWidth
              disabled={viewModel.isApplying || !viewModel.canEditNames}
              onChange={(e) => (viewModel.section = e.target.value)}
            />

            <ColorPicker
              label={strings.colorLabel}
              color={viewModel.backgroundColor}
              palette={viewModel.backgroundColorPalette}
              helperText={
                (isBackgroundColorLowContrast ?? false) &&
                !viewModel.isBackgroundColorInPalette && (
                  <ColorContrastWarning variant="caption">{strings.colorContrastWarning}</ColorContrastWarning>
                )
              }
              onChange={(value) => (viewModel.backgroundColor = value)}
              onMouseUp={() => setIsBackgroundColorLowContrast(viewModel.isBackgroundColorLowContrast)}
            />
          </Stack>
        )}
      />
    );
  }
);

const ColorContrastWarning = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.main
}));
