import { Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { AdminArchiveStateSchoolAction } from './AdminArchiveStateSchoolAction';
import { AdminDemoSchoolSnapshots } from './AdminDemoSchoolSnapshots';
import { AdminUnshareSchoolAction } from './AdminUnshareSchoolAction';

export interface AdminManageSchoolActionsProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
}

export const AdminManageSchoolActions = observer(({ sx, className, schoolId }: AdminManageSchoolActionsProps) => {
  return (
    <Stack sx={sx} className={className} spacing={3} p={2}>
      <AdminUnshareSchoolAction schoolId={schoolId} />
      <AdminArchiveStateSchoolAction schoolId={schoolId} />
      <AdminDemoSchoolSnapshots schoolId={schoolId} />
    </Stack>
  );
});
