import { UnsplashPictureViewModel } from '@/viewmodels';
import CheckCircleIcon from '@mui/icons-material/CheckCircleRounded';
import { ImageListItem, ImageListItemBar, styled, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import clsx from 'clsx';
import { getGradientClassName } from './GradientStyle';
import { UnsplashAttribution } from './UnsplashAttribution';

export interface UnsplashPictureProps {
  className?: string;
  sx?: SxProps;
  viewModel: UnsplashPictureViewModel;
  selected?: boolean;

  onClick?: () => void;
}

export const UnsplashPicture = ({ className, sx, viewModel, selected, onClick }: UnsplashPictureProps) => {
  const theme = useTheme();

  return (
    <Container className={clsx(className, onClick != null && classes.selectable)} sx={sx}>
      <Picture
        className={clsx(selected && classes.selected)}
        src={viewModel.urls.small}
        alt={viewModel.alt}
        onClick={onClick}
      />
      <StyleImageListItemBar
        className={clsx(getGradientClassName(theme), selected && classes.selected)}
        title={
          <UnsplashAttribution picture={viewModel} shortText color={theme.palette.common.white} variant="inherit" />
        }
      />

      {selected && (
        <CheckCircleIcon
          sx={{ color: 'white', position: 'absolute', top: theme.spacing(1), right: theme.spacing(1) }}
        />
      )}
    </Container>
  );
};

const PREFIX = 'UnsplashPicture';
const classes = {
  selectable: `${PREFIX}-selectable`,
  selected: `${PREFIX}-selected`,
  link: `${PREFIX}-link`
};

const Container = styled(ImageListItem)(() => ({ display: 'inline-flex' }));

const Picture = styled('img')(() => ({
  width: '100%',
  height: '100%',
  borderRadius: 12,

  [`&.${classes.selected}`]: {
    opacity: 0.5
  }
}));

const StyleImageListItemBar = styled(ImageListItemBar)(() => ({
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  [`&.${classes.selected}`]: {
    opacity: 0.5
  }
}));
