import {
  compareTerms,
  CourseSectionInfo,
  dateToPBDate,
  dateToTimeOfDay,
  Day,
  dayToPBDate,
  getAllSchedulesTagsFromScheduleCycle,
  plannerCourseSectionDetailsToInfo,
  scheduleCycleSupportsScheduleTags,
  schoolCourseSectionToInfo,
  timeOfDayToDate,
  UserDashboardInfo
} from '@/models';
import { ServiceContainer } from '@/providers';
import { DateService, LocalizationService } from '@/services';
import {
  PlannerDataStore,
  ScheduleCycleDataStore,
  ScheduleCycleDataStoreAddActivityScheduleWhen,
  ScheduleCyclePeriodAt,
  SchoolDataStore,
  UserDataStore
} from '@/stores';
import { Activity } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/activity_pb';
import { Period } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/period_pb';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { Term } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/term_pb';
import { isBefore, set } from 'date-fns';
import { times } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import {
  filterPlannerCourseSectionsForActivitySchedules,
  filterSchoolCourseSectionForActivitySchedules,
  ScheduleCycleActivitySchedulesCoursesFilter
} from '../period-schedules';
import { getScheduleCycleKind, ScheduleCycleKind, titleForCycleDay } from '../ScheduleCycleUtils';

export type ScheduleCycleActivityScheduleWhen = { case: 'cycleDay'; value: number } | { case: 'day' };
export type ScheduleCycleActivityScheduleAtKind = 'periodLabel' | 'specificTimes';

export interface ScheduleCycleActivityScheduleEditViewModel {
  readonly canSave: boolean;
  readonly hasConflict: boolean;
  when: ScheduleCycleActivityScheduleWhen;
  at: ScheduleCycleActivityScheduleAtKind;
  specificDay: Day;
  periodLabel: string;
  customStartTime: Date;
  customEndTime: Date;
  customPeriodLabel: string;
  roomName: string;
  termId: string;
  scheduleTag: string;
  readonly customTimesAreValid: boolean;
  readonly possibleCycleDays: { value: number; title: string }[];
  readonly possiblePeriodLabels: string[];
  readonly possibleTerms: Term[];
  readonly possibleScheduleTags: string[];
  readonly supportsScheduleTags: boolean;

  save(): Promise<void>;
}

export class AppScheduleCycleActivityScheduleEditViewModel implements ScheduleCycleActivityScheduleEditViewModel {
  @observable private _when: ScheduleCycleActivityScheduleWhen;
  @observable private _at: ScheduleCycleActivityScheduleAtKind = 'periodLabel';
  @observable private _periodLabel: string;
  @observable private _customStartTime: Date;
  @observable private _customEndTime: Date;
  @observable private _customPeriodLabel: string;
  @observable private _specificDay: Day;
  @observable private _roomName = '';
  @observable private _termId = '';
  @observable private _scheduleTag = '';
  private _originalScheduleTag: string | undefined;

  constructor(
    private readonly _activityScheduleId: string | undefined,
    private readonly _activity: Activity,
    private readonly _scheduleCycleId: string,
    private readonly _filters: ScheduleCycleActivitySchedulesCoursesFilter | undefined,
    private readonly _dashboard: UserDashboardInfo,
    private readonly _plannerId: string | undefined,
    private readonly _userStore: UserDataStore = ServiceContainer.services.userStore,
    private readonly _plannerStore: PlannerDataStore = ServiceContainer.services.plannerStore,
    private readonly _schoolStore: SchoolDataStore = ServiceContainer.services.schoolStore,
    private readonly _localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _dateService: DateService = ServiceContainer.services.dateService
  ) {
    makeObservable(this);

    const scheduleKind: ScheduleCycleKind = this.scheduleCycle.isDayOfWeekAligned
      ? this.scheduleCycle.cycleDayCount === 7
        ? 'week'
        : 'multiple-week'
      : 'cycle-day';
    const activitySchedule = this.scheduleCycle.activitySchedules.find((a) => a.id === this._activityScheduleId);
    const courses = this.courseSectionInfos.map((c) => ({
      id: c.externalSource?.externalId ?? c.id,
      externalSourceName: c.externalSource?.sourceName
    }));

    if (activitySchedule != null) {
      this._when = activitySchedule.when.case === 'cycleDay' ? activitySchedule.when : { case: 'day' };
      this._specificDay =
        activitySchedule.when.case === 'day' ? activitySchedule.when.value : dateToPBDate(_dateService.now);
      this._termId = activitySchedule.termId;
      this._roomName = activitySchedule.roomName;
      this._scheduleTag = activitySchedule.scheduleTag;
      this._originalScheduleTag = this._scheduleTag || undefined;

      if (activitySchedule.at.case === 'periodLabel') {
        this._at = 'periodLabel';
        this._periodLabel = activitySchedule.at.value;
        this._customStartTime = set(this._dateService.now, { hours: 8, minutes: 0 });
        this._customEndTime = set(this._dateService.now, { hours: 9, minutes: 0 });
        this._customPeriodLabel = '';
      } else {
        this._at = 'specificTimes';
        const availablePeriod = this.scheduleCycleStore.getNextAvailableMasterSchedulePeriod(
          courses,
          scheduleKind,
          this._scheduleTag
        );
        this._periodLabel = availablePeriod?.period.label ?? this.possiblePeriodLabels[0] ?? '';

        const period = activitySchedule.at.value!;
        this._customStartTime = timeOfDayToDate(period.startTime!);
        this._customEndTime = timeOfDayToDate(period.endTime!);
        this._customPeriodLabel = period.label;
      }
    } else {
      this._at = 'periodLabel';

      this._scheduleTag = this.possibleScheduleTags[0] ?? '';
      const availablePeriod = this.scheduleCycleStore.getNextAvailableMasterSchedulePeriod(
        courses,
        scheduleKind,
        this._scheduleTag
      );

      if (availablePeriod != null) {
        this._when = { case: 'cycleDay', value: availablePeriod.cycleDay };
        this._periodLabel = availablePeriod.period.label;
      } else {
        this._when = { case: 'cycleDay', value: 1 };
        this._periodLabel = this.possiblePeriodLabels[0] ?? '';
      }

      this._customStartTime = set(this._dateService.now, { hours: 8, minutes: 0 });
      this._customEndTime = set(this._dateService.now, { hours: 9, minutes: 0 });
      this._customPeriodLabel = '';
      this._specificDay = dateToPBDate(_dateService.now);
    }
  }

  @computed
  private get courseSectionInfos(): CourseSectionInfo[] {
    const hasFilters = this._filters != null;

    if (this._plannerId != null || this._dashboard.kind === 'planner') {
      const plannerId = this._plannerId ?? this._dashboard.id;
      let courses = this._plannerStore.getCourseSectionsInPlanner(plannerId).values;

      if (hasFilters) {
        courses = courses.filter((cs) => filterPlannerCourseSectionsForActivitySchedules(cs, this._filters!));
      }
      return courses.map(plannerCourseSectionDetailsToInfo);
    } else {
      let courses = this._schoolStore.getCourseSections(this._dashboard.id).values;

      if (hasFilters) {
        courses = courses.filter((cs) => filterSchoolCourseSectionForActivitySchedules(cs, this._filters!));
      }
      return courses.map(schoolCourseSectionToInfo);
    }
  }

  @computed
  private get scheduleCycleStore(): ScheduleCycleDataStore {
    return this._userStore.getScheduleCycleStore(this._scheduleCycleId, this._dashboard);
  }

  @computed
  private get scheduleCycle(): ScheduleCycle {
    return this.scheduleCycleStore.scheduleCycle;
  }

  @computed
  private get activityScheduleAddWhen(): ScheduleCycleDataStoreAddActivityScheduleWhen {
    return this._when.case === 'cycleDay'
      ? { case: 'cycleDay', value: this._when.value }
      : { case: 'day', value: dayToPBDate(this._specificDay) };
  }

  @computed
  private get activityScheduleAddAt(): ScheduleCyclePeriodAt {
    return this.at === 'periodLabel'
      ? { case: 'periodLabel', value: this.periodLabel }
      : {
          case: 'period',
          value: new Period({
            startTime: dateToTimeOfDay(this.customStartTime),
            endTime: dateToTimeOfDay(this.customEndTime),
            label: this.customPeriodLabel
          })
        };
  }

  @computed
  get canSave(): boolean {
    if (this.hasConflict) {
      return false;
    }

    return this.at === 'periodLabel' ? this.periodLabel.length > 0 : this.customTimesAreValid;
  }

  @computed
  get hasConflict(): boolean {
    const courses = this.courseSectionInfos.map((c) => ({
      id: c.externalSource?.externalId ?? c.id,
      externalSourceName: c.externalSource?.sourceName
    }));

    return this.scheduleCycleStore.getActivityScheduleHasConflict(
      this._activityScheduleId,
      this.activityScheduleAddWhen,
      this.activityScheduleAddAt,
      this._termId,
      this._scheduleTag,
      courses
    );
  }

  @computed
  get customTimesAreValid(): boolean {
    return isBefore(this.customStartTime, this.customEndTime);
  }

  @computed
  get when(): ScheduleCycleActivityScheduleWhen {
    return this._when;
  }

  set when(value: ScheduleCycleActivityScheduleWhen) {
    this._when = value;
  }

  @computed
  get at(): ScheduleCycleActivityScheduleAtKind {
    return this._at;
  }

  set at(value: ScheduleCycleActivityScheduleAtKind) {
    this._at = value;
  }

  @computed
  get specificDay(): Day {
    return this._specificDay;
  }

  set specificDay(value: Day) {
    this._specificDay = value;
  }

  @computed
  get periodLabel(): string {
    return this._periodLabel;
  }

  set periodLabel(value: string) {
    this._periodLabel = value;
  }

  @computed
  get customStartTime(): Date {
    return this._customStartTime;
  }

  set customStartTime(value: Date) {
    this._customStartTime = value;
  }

  @computed
  get customEndTime(): Date {
    return this._customEndTime;
  }

  set customEndTime(value: Date) {
    this._customEndTime = value;
  }

  @computed
  get customPeriodLabel(): string {
    return this._customPeriodLabel;
  }

  set customPeriodLabel(value: string) {
    this._customPeriodLabel = value;
  }

  @computed
  get roomName(): string {
    return this._roomName;
  }

  set roomName(value: string) {
    this._roomName = value;
  }

  @computed
  get termId(): string {
    return this._termId;
  }

  set termId(value: string) {
    this._termId = value;
  }

  @computed
  get scheduleTag(): string {
    return this._scheduleTag;
  }

  set scheduleTag(value: string) {
    this._scheduleTag = value;
  }

  @computed
  get possibleCycleDays(): { value: number; title: string }[] {
    return times(this.scheduleCycle.cycleDayCount).map((cycleDayIndex) => ({
      value: cycleDayIndex + 1,
      title: titleForCycleDay(
        cycleDayIndex + 1,
        getScheduleCycleKind(this.scheduleCycle),
        'long',
        true,
        this.scheduleCycle.cycleDayNames
      )
    }));
  }

  @computed
  get possiblePeriodLabels(): string[] {
    const labels = new Set<string>();
    this.scheduleCycle.periodSchedules.forEach(
      (ps) => !ps.shouldDelete && ps.periods.forEach((p) => labels.add(p.label))
    );
    return Array.from(labels);
  }

  @computed
  get possibleTerms(): Term[] {
    return Array.from(this.scheduleCycle.terms)
      .filter((t) => !t.shouldDelete)
      .sort((t1, t2) => compareTerms(t1, t2, this._localization.currentLocale));
  }

  @computed
  get possibleScheduleTags(): string[] {
    const tags = getAllSchedulesTagsFromScheduleCycle(this.scheduleCycle, this._localization.currentLocale);
    // If activity schedule points to a schedule tag that doesn't exist, we still display it.
    if (this._originalScheduleTag != null && !tags.includes(this._originalScheduleTag)) {
      tags.push(this._originalScheduleTag);
    }
    const hasNoneEmptyTags = tags.some((t) => t.length > 0);
    return hasNoneEmptyTags ? tags : [];
  }

  @computed
  get supportsScheduleTags(): boolean {
    return this.possibleScheduleTags.length > 0 && scheduleCycleSupportsScheduleTags(this._dashboard);
  }

  async save() {
    await this.scheduleCycleStore.createOrUpdateActivitySchedule(
      this._activityScheduleId,
      this._activity,
      this.activityScheduleAddWhen,
      this.activityScheduleAddAt,
      this._roomName,
      this._termId,
      this._scheduleTag
    );
  }
}
