import { useServices } from '@/hooks';
import { Button, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { gridPageSelector, useGridApiContext, useGridSelector } from '@mui/x-data-grid';
import { useMemo } from 'react';
import { TableAction } from './TableUtils';

export interface TablePaginationActionsProps {
  sx?: SxProps;
  className?: string;
  actions?: TableAction[];
  showGoToInitialPageAction: boolean;
  initialPage?: number;
  scrollToCurrentInitialRowActionTitle?: string;
}

export function TablePaginationActions({
  sx,
  className,
  actions,
  showGoToInitialPageAction,
  initialPage,
  scrollToCurrentInitialRowActionTitle
}: TablePaginationActionsProps) {
  const { localization } = useServices();
  const strings = localization.localizedStrings.workList;

  const gridApiRef = useGridApiContext();
  const currentPage = useGridSelector(gridApiRef, gridPageSelector);

  function scrollToCurrentInitialRowAction() {
    if (initialPage != null) {
      gridApiRef.current.setPage(initialPage);
    }

    return Promise.resolve();
  }

  const allActions = useMemo(() => {
    const allActions: TableAction[] = actions ?? [];

    if (showGoToInitialPageAction && initialPage) {
      allActions.unshift({
        title: scrollToCurrentInitialRowActionTitle ?? strings.studentWorksTableGoToInitialPageDefaultActionTitle,
        action: scrollToCurrentInitialRowAction,
        isDisabled: initialPage === currentPage + 1
      });
    }
    return allActions;
  }, [showGoToInitialPageAction, scrollToCurrentInitialRowActionTitle, initialPage, actions, currentPage]);

  return (
    <Stack sx={sx} className={className} direction="row" spacing={1} alignItems="center" flex={1}>
      {allActions.map((action, index) => (
        <Button key={index} onClick={() => void action.action()} disabled={action.isDisabled}>
          {action.title}
        </Button>
      ))}
    </Stack>
  );
}
