import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';

export interface SideBarHeaderProps {
  sx?: SxProps;
  className?: string;
  isCompact: boolean;
  title: string;
}

export const SideBarHeader = observer(({ sx, className, isCompact, title }: SideBarHeaderProps) => {
  return (
    <Stack
      alignItems="center"
      direction="row"
      spacing={1}
      className={className}
      sx={{ ...sx, px: isCompact ? 1 : 3, pt: isCompact ? 1 : 1.5, pb: isCompact ? 1 : 0 }}
    >
      <Typography flex={1} variant={isCompact ? 'body2' : 'body1'} fontWeight="600">
        {title}
      </Typography>
    </Stack>
  );
});
