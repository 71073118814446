import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { PlannerPastePlannerItemViewModel } from '../../../../viewmodels';
import { CourseSectionPicker } from '../../../shared';
import { Subheader } from '../../../utils';

export interface PlannerPastePlannerItemCourseSelectionProps {
  sx?: SxProps;
  className?: string;
  viewModel: PlannerPastePlannerItemViewModel;
}

export const PlannerPastePlannerItemCourseSelection = observer(
  ({ sx, className, viewModel }: PlannerPastePlannerItemCourseSelectionProps) => {
    const strings = LocalizedStrings.planner.pastePlannerItem;

    return (
      <Stack sx={sx} className={className} spacing={1}>
        <Subheader>{strings.courseSelectionSectionTitle()}</Subheader>

        <CourseSectionPicker
          selectedCourseId={viewModel.targetCourseId}
          allCourseSections={viewModel.courseSections}
          onChange={(courseSectionId) => viewModel.setCourseId(courseSectionId ?? '')}
        />

        <FormGroup>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 0, sm: 0.5 }}>
            {viewModel.timeOfDaySelection?.case === 'period' && (
              <FormControlLabel
                sx={{ flex: 1 }}
                control={
                  <Checkbox checked={viewModel.isPeriodCourseSelected} onChange={() => viewModel.setPeriodCourseId()} />
                }
                label={strings.usePeriodClassOptionLabel()}
              />
            )}

            <FormControlLabel
              sx={{ flex: 1 }}
              control={
                <Checkbox
                  checked={viewModel.isOriginalItemCourseSelected}
                  onChange={() => viewModel.setOriginalItemCourseId()}
                />
              }
              label={strings.useOriginalItemClassOptionLabel()}
            />
          </Stack>
        </FormGroup>
      </Stack>
    );
  }
);
