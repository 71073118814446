import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { GroupsRounded, InfoRounded, PersonRounded } from '@mui/icons-material';
import { Tooltip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import LocalizedStrings from 'strings';
import { useServices } from '../../../../hooks';
import { AdminStudentsListViewModel } from '../../../../viewmodels';
import { Table, TableRowActions } from '../../../lists';
import { AdminTableCellLink } from '../../shared';

type RenderCellData = GridRenderCellParams<Account, Account>;

export interface AdminSchoolStudentsTableProps {
  sx?: SxProps;
  className?: string;
  viewModel: AdminStudentsListViewModel;
}

export const AdminSchoolStudentsTable = observer(({ sx, className, viewModel }: AdminSchoolStudentsTableProps) => {
  const { route } = useServices();
  const strings = LocalizedStrings.admin.studentsList;
  const theme = useTheme();

  const baseStatColumn: Partial<GridColDef> = {
    sortable: false,
    disableColumnMenu: true,
    headerAlign: 'left',
    align: 'left'
  };

  const columns = useMemo(
    () =>
      [
        {
          ...baseStatColumn,
          field: 'connectionState',
          headerName: '',
          align: 'center',
          width: 32,
          renderCell: (data: RenderCellData) =>
            data.row.userId.length > 0 ? (
              <Tooltip title={strings.hasUserTooltip()} disableInteractive>
                <PersonRounded color={theme.palette.mode === 'light' ? 'primary' : undefined} />
              </Tooltip>
            ) : undefined
        },
        {
          ...baseStatColumn,
          headerName: strings.nameColumnHeader(),
          field: 'fullName',
          minWidth: 200,
          renderCell: (data: RenderCellData) => (
            <AdminTableCellLink
              value={data.row.fullName.length > 0 ? data.row.fullName : `(${strings.noNameValue()})`}
              link={route.resolveAdminStudentDetailsLocation(data.row.schoolId, data.row.id)}
            />
          ),
          sortable: true,
          flex: 1
        },
        {
          ...baseStatColumn,
          headerName: strings.emailColumnHeader(),
          field: 'emailAddress',
          flex: 1,
          sortable: true,
          renderCell: (data: RenderCellData) => (
            <AdminTableCellLink value={data.row.emailAddress} link={`mailto:${data.row.emailAddress}`} isExternal />
          )
        },
        {
          field: 'actions',
          headerName: '',
          type: 'actions',
          renderCell: (data: RenderCellData) => (
            <TableRowActions
              actions={[
                {
                  icon: <InfoRounded />,
                  text: strings.showDetailsAction(),
                  selection: {
                    case: 'link',
                    to: route.resolveAdminStudentDetailsLocation(data.row.schoolId, data.row.id)
                  }
                }
              ]}
            />
          )
        }
      ] as GridColDef[],
    []
  );

  return (
    <Table
      sx={sx}
      className={className}
      tableKey="admin_school_students"
      rows={viewModel.students}
      columns={columns}
      searchText={viewModel.searchText}
      emptyOverlayParams={{
        title: strings.emptyTitle(),
        renderIcon: (className) => <GroupsRounded className={className} />
      }}
    />
  );
});
