import { ListItemIcon, MenuItem } from '@mui/material';
import { ReactNode, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ActionMenu, ActionMenuButton } from '../../utils';

export type TableRowActionSelectionKind =
  | { case: 'link'; to: string }
  | { case: 'external-link'; to: string }
  | { case: 'action'; onSelect: () => void };

export interface TableRowAction {
  readonly text: string;
  readonly icon?: ReactNode;
  readonly selection?: TableRowActionSelectionKind;
  readonly disabled?: boolean;
  readonly isHidden?: boolean;
}

export interface TableRowActionsProps {
  actions: (TableRowAction | undefined)[];
}

export function TableRowActions({ actions }: TableRowActionsProps) {
  const [showActionsMenu, setShowActionsMenu] = useState(false);
  const actionsMenuAnchorRef = useRef<HTMLButtonElement>(null);

  function onActionSelect(action: TableRowAction) {
    setShowActionsMenu(false);
    if (action.selection?.case === 'action') {
      action.selection.onSelect();
    }
  }

  return (
    <>
      <ActionMenuButton ref={actionsMenuAnchorRef} onClick={() => setShowActionsMenu(true)} />
      <ActionMenu
        open={showActionsMenu}
        anchorEl={actionsMenuAnchorRef.current}
        onClose={() => setShowActionsMenu(false)}
      >
        {actions.map((action, index) =>
          action != null && action.isHidden !== true ? (
            <MenuItem
              key={index}
              onClick={() => onActionSelect(action)}
              disabled={action.disabled}
              {...(action.selection?.case === 'link' ? { component: Link, to: action.selection.to } : undefined)}
              {...(action.selection?.case === 'external-link'
                ? { component: 'a', href: action.selection.to, target: '_blank', rel: 'noreferrer' }
                : undefined)}
            >
              {action.icon && <ListItemIcon>{action.icon}</ListItemIcon>}
              {action.text}
            </MenuItem>
          ) : null
        )}
      </ActionMenu>
    </>
  );
}
