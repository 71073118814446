import { PlannerItemsItemKind } from '@/viewmodels';
import { PersonRounded, SendRounded, StickyNote2Rounded } from '@mui/icons-material';
import { Chip, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PlannerItemsDisplayedKindsPickerProps {
  sx?: SxProps;
  className?: string;
  possibleKinds: PlannerItemsItemKind[];
  selectedKinds: Set<PlannerItemsItemKind>;
  onKindSelection: (kind: PlannerItemsItemKind) => void;
}

export const PlannerItemsDisplayedKindsPicker = observer(
  ({ sx, className, possibleKinds, selectedKinds, onKindSelection }: PlannerItemsDisplayedKindsPickerProps) => {
    const strings = LocalizedStrings.planner.items;
    const theme = useTheme();
    const isExtraSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    return (
      <Stack sx={sx} className={className} direction="row" spacing={1} alignItems="center">
        {!isExtraSmallScreen && (
          <Typography variant="caption" color="textSecondary">
            {strings.displayedKindsLabel()}
          </Typography>
        )}

        {possibleKinds.map((kind) => (
          <Chip
            key={kind}
            size="small"
            label={titleForKind(kind)}
            icon={iconForKind(kind)}
            color={selectedKinds.has(kind) ? 'primary' : 'default'}
            onClick={() => onKindSelection(kind)}
          />
        ))}
      </Stack>
    );
  }
);

function titleForKind(kind: PlannerItemsItemKind) {
  const strings = LocalizedStrings.planner.items;

  switch (kind) {
    case 'work':
      return strings.displayedKindsWorkOptionLabel();
    case 'note':
      return strings.displayedKindsNoteOptionLabel();
    case 'publishedWork':
      return strings.displayedKindsPublishedWorkOptionLabel();
  }
}

function iconForKind(kind: PlannerItemsItemKind) {
  switch (kind) {
    case 'work':
      return <PersonRounded fontSize="small" />;
    case 'note':
      return <StickyNote2Rounded fontSize="small" />;
    case 'publishedWork':
      return <SendRounded fontSize="small" />;
  }
}
