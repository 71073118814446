import { ScheduleCycleCreateDialog, ScheduleCycleList } from '@/components/shared';
import { Box, Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useViewModel } from '../../../hooks';
import { AdminContentCard } from '../shared';

interface LocationState {
  schoolScheduleCycleListShowCreateSchedule?: boolean;
}

export interface AdminSchoolSchedulesPageProps {
  sx?: SxProps;
  className?: string;
}

export const AdminSchoolSchedulesPage = observer(({ sx, className }: AdminSchoolSchedulesPageProps) => {
  const strings = LocalizedStrings.admin.schedule;
  const params = useParams();
  const schoolId = params.schoolId ?? '';
  const viewModel = useViewModel(
    (viewModels) => viewModels.createSchedulesListViewModel(schoolId, 'school', true),
    [schoolId]
  );

  const location = useLocation();
  const state = (location.state || {}) as LocationState;
  const navigate = useNavigate();

  function showCreateSchedule() {
    const newState: LocationState = { ...state, schoolScheduleCycleListShowCreateSchedule: true };
    navigate(location, { state: newState });
  }

  const canCreateSchedule = viewModel.hasData && !viewModel.isReadOnly;

  return (
    <Box sx={sx} className={className}>
      <AdminContentCard title={strings.listTitle()} sx={{ maxWidth: 800 }}>
        <ScheduleCycleList viewModel={viewModel} showSource={false} showEmptyLabel />

        {canCreateSchedule && (
          <Button sx={{ mx: 2, mb: 3, alignSelf: 'flex-start' }} variant="contained-grey" onClick={showCreateSchedule}>
            {strings.createSchedule()}
          </Button>
        )}

        {state.schoolScheduleCycleListShowCreateSchedule === true && (
          <ScheduleCycleCreateDialog isOpen={true} userDashboard={{ kind: 'school', id: schoolId }} />
        )}
      </AdminContentCard>
    </Box>
  );
});
