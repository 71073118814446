import { useServices } from '@/hooks';
import { StudentsPlannerSummaryViewModel } from '@/viewmodels';
import EmptyListIcon from '@mui/icons-material/EmojiNatureRounded';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import { EmptyListIndicator } from '../../../utils';
import { SchoolCourseSectionStudentPicker } from './SchoolCourseSectionStudentPicker';

export interface SchoolCourseSectionCompactCurrentStudentProps {
  sx?: SxProps;
  className?: string;
  viewModel: StudentsPlannerSummaryViewModel;
}

export const SchoolCourseSectionCompactCurrentStudent = observer(
  ({ viewModel, sx, className }: SchoolCourseSectionCompactCurrentStudentProps) => {
    const { localization } = useServices();
    const strings = localization.localizedStrings.schoolCourseSection;

    const params = useParams();
    const studentId = params.studentId ?? '';
    const student = viewModel.allStudents.find((s) => s.id === studentId);

    if (student == null) {
      return (
        <Box
          sx={sx}
          className={className}
          width="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={2}
        >
          <EmptyListIndicator
            renderIcon={(className) => <EmptyListIcon className={className} />}
            title={strings.detailsNoStudentsTitle}
            subtitle={strings.detailsNoStudentsSubtitle}
          />
        </Box>
      );
    }

    return <SchoolCourseSectionStudentPicker viewModel={viewModel} student={student} sx={sx} className={className} />;
  }
);
