import { Box, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, set } from 'date-fns';
import { times } from 'lodash';
import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';

export interface DayTimesColumnProps {
  sx?: SxProps;
  className?: string;
  pointsPerHour: number;
}

const DayTimesColumnComponent = forwardRef<HTMLDivElement, DayTimesColumnProps>(
  ({ sx, className, pointsPerHour }, ref) => {
    return (
      <Box
        sx={{
          ...sx,
          overflowY: 'hidden',
          flex: 'none',
          display: 'flex',
          alignItems: 'flex-start',
          position: 'static'
        }}
        position="static"
        ref={ref}
        className={className}
      >
        <Box
          sx={{
            position: 'relative',
            boxSizing: 'border-box',
            marginLeft: 'auto',
            display: 'block'
          }}
        >
          {times(24).map((i) => (
            <Box
              key={i}
              sx={{
                height: pointsPerHour,
                position: 'relative',
                paddingRight: '8px',
                textAlign: 'right',
                display: 'block'
              }}
            >
              <Typography
                variant="caption"
                color="textSecondary"
                sx={{
                  position: 'relative',
                  top: -10,
                  display: i === 0 ? 'none' : 'block'
                }}
              >
                {format(set(new Date(), { hours: i, minutes: 0 }), 'p')
                  .replace(/^0+/, '')
                  .replace(':00', '')}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
);

DayTimesColumnComponent.displayName = 'DayTimesColumn';

export const DayTimesColumn = observer(DayTimesColumnComponent);
