import {
  EnglishLocalizedStrings,
  ExLocalizedStrings,
  FrenchLocalizedStrings
} from '@/resources/strings/ExLocalizedStrings';
import { Locale, SupportedLocales } from '@/utils';
import { Locale as UppyLocale } from '@uppy/core';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import English from '@uppy/locales/lib/en_US.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import French from '@uppy/locales/lib/fr_FR.js';
import { Locale as DateLocale, setDefaultOptions } from 'date-fns';
import { enUS, fr } from 'date-fns/locale';
import { action, autorun, computed, makeObservable } from 'mobx';
import { changeLanguage } from 'translate';
import { ApplicationSettingsStorage, AttachmentService, LocalizationService, UserService } from '../contracts';

export class AppLocalizationService implements LocalizationService {
  private readonly _defaultLocale: Locale;

  constructor(
    private readonly _attachmentService: AttachmentService,
    private readonly _settingsStorage: ApplicationSettingsStorage,
    private readonly _user: UserService
  ) {
    makeObservable(this);
    this._defaultLocale = this.normalizeLocale(window.navigator.language, 'en');

    autorun(() => {
      setDefaultOptions({ locale: this.currentDateLocale, weekStartsOn: 0 });
      this._attachmentService.uppyInstance.setOptions({
        locale: this.currentLocale === 'en' ? (English as UppyLocale) : (French as UppyLocale)
      });
      void changeLanguage(this.currentLocale);
    });
  }

  @computed
  private get currentDateLocale(): DateLocale {
    switch (this.currentLocale) {
      case 'en':
        return enUS;
      case 'fr':
        return fr;
    }
  }

  @computed
  get currentLocale(): Locale {
    return this.normalizeLocale(
      this._user.currentUser?.cultureName ?? '',
      (this._settingsStorage.locale as Locale) || this._defaultLocale
    );
  }

  set currentLocale(value: Locale) {
    this._settingsStorage.locale = value;
  }

  @computed
  get localizedStrings(): ExLocalizedStrings {
    return this.resolveStrings(this.currentLocale);
  }

  @computed
  get currentTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  @action
  trySetCurrentLocale(locale: string) {
    this.currentLocale = this.normalizeLocale(locale, this.currentLocale);
  }

  private normalizeLocale(locale: string, defaultLocale: Locale): Locale {
    const normalizedLocale = locale.slice(0, 2).toLowerCase() as Locale;

    // Making sure the normalizedLocale really exists in the Locale enum
    if (SupportedLocales.includes(normalizedLocale)) {
      return normalizedLocale;
    }

    if (normalizedLocale.length !== 0) {
      console.warn(`Unsupported locale "${normalizedLocale}". Using default value "${defaultLocale}".`);
    }
    return defaultLocale;
  }

  resolveStrings(locale: Locale): ExLocalizedStrings {
    switch (locale) {
      case 'en':
        return EnglishLocalizedStrings;

      case 'fr':
        return FrenchLocalizedStrings;
    }
  }
}
