import { OrderedScheduleSymbolKinds, ScheduleSymbolKind } from '@/models';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import { orderBy } from 'lodash';
import { observer } from 'mobx-react-lite';
import { ScheduleSymbol } from './ScheduleSymbol';

export interface ScheduleSymbolStackProps {
  symbols: { kind: ScheduleSymbolKind; color: string }[];
  size: number;
  sx?: SxProps;
  className?: string;
}

export const ScheduleSymbolStack = observer(({ symbols, size, sx, className }: ScheduleSymbolStackProps) => {
  const orderedSymbols = orderBy(symbols, [(s) => OrderedScheduleSymbolKinds.indexOf(s.kind)], ['asc']);

  return (
    <Box className={className} sx={{ ...sx, width: size, height: size }}>
      <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
        {orderedSymbols.map((symbol, index) => (
          <ScheduleSymbol
            key={index}
            symbolKind={symbol.kind}
            size={size}
            color={symbol.color}
            style={{ position: 'absolute', top: 0, left: 0 }}
          />
        ))}
      </Box>
    </Box>
  );
});
