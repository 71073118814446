import { alpha, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import LocalizedStrings from 'strings';

export interface ScheduleCycleSpecialDaysSectionTitleProps {
  sx?: SxProps;
  className?: string;
  title: string;
  isHighlighted?: boolean;
  clearHighlight?: () => void;
}

export function ScheduleCycleSpecialDaysSectionTitle({
  sx,
  className,
  title,
  isHighlighted = false,
  clearHighlight
}: ScheduleCycleSpecialDaysSectionTitleProps) {
  const theme = useTheme();

  return (
    <Stack sx={sx} className={className} direction="column" spacing={0.5} alignItems="flex-start" px={1}>
      <Typography variant="body1" fontWeight="500" color={isHighlighted ? 'primary' : undefined}>
        {title}
      </Typography>

      {isHighlighted && clearHighlight != null && (
        <Typography
          variant="caption"
          onClick={() => clearHighlight()}
          sx={{
            color: theme.palette.text.secondary,
            cursor: 'pointer',
            ['&:hover']: {
              color: alpha(theme.palette.text.secondary, 0.3)
            }
          }}
        >
          {LocalizedStrings.scheduleCycle.edit.specialDays.clearSelectionButtonTitle()}
        </Typography>
      )}
    </Stack>
  );
}
