import { without } from 'lodash';

const formattingRegex = /(?<a>[A-Z0-9]{3})?-?(?<b>[A-Z0-9]{3})?-?(?<c>[A-Z0-9]{1,3})?/i;
const fullRegex = /^[A-Z0-9]{3}-?[A-Z0-9]{3}-?[A-Z0-9]{3}$/i;

export function formatSharingCode(value: string): string {
  const matches = value.match(formattingRegex);
  const groups = without(
    [
      matches?.groups?.a,
      matches?.groups?.b,
      matches?.groups?.c,
      // This optional empty string at the end adds a trailing dash when
      // groups a or b are complete, and c is empty.
      matches?.groups?.c == null && (matches?.groups?.a != null || matches?.groups?.b != null) ? '' : undefined
    ],
    undefined,
    null
  );

  return groups.map((g) => g!.toUpperCase()).join('-');
}

export function isSharingCodeComplete(value?: string): boolean {
  return value?.match(fullRegex) != null;
}
