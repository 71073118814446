import { Capacitor } from '@capacitor/core';
import { Box, Container, Grid, Stack, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoginDialog } from '../onboarding';
import { MediaQuery } from '../utils';
import { WelcomeAppBar } from './WelcomeAppBar';
import { WelcomeInfos } from './WelcomeInfos';
import { WelcomeMainScreenshot } from './WelcomeMainScreenshot';

interface LocationState {
  welcomeShowLogIn?: boolean;
  welcomeShowSignUp?: boolean;
}
export interface WelcomeProps {
  sx?: SxProps;
  className?: string;
}

export const Welcome = observer(({ sx, className }: WelcomeProps) => {
  const location = useLocation();
  const state: LocationState = (location.state ?? {}) as LocationState;
  const navigate = useNavigate();
  const isCapacitor = Capacitor.isNativePlatform();

  function login() {
    const newState: LocationState = { ...state, welcomeShowLogIn: true };
    navigate(location, { state: newState });
  }

  function signUp() {
    const newState: LocationState = { ...state, welcomeShowSignUp: true };
    navigate(location, { state: newState });
  }

  return (
    <>
      <Box className={className} flexDirection="column" display="flex" sx={sx}>
        <Stack height="100%" width="100%" flex={1} spacing={0}>
          <WelcomeAppBar />

          <Box
            sx={{
              width: '100%',
              height: '100%',
              overflowY: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'row'
            }}
          >
            <MainContainer maxWidth="xl">
              <Grid container spacing={4} alignItems={{ xs: 'stretch', md: undefined }}>
                {!isCapacitor && (
                  <MediaQuery mediaQuery={(th) => th.breakpoints.down('md')}>
                    <Grid item xs={12} md={6} lg={6}>
                      <WelcomeMainScreenshot />
                    </Grid>
                  </MediaQuery>
                )}

                <Grid item xs={12} md={6} lg={6}>
                  <WelcomeInfos signUp={signUp} login={login} sx={{ width: '100%', height: '100%' }} />
                </Grid>

                <MediaQuery mediaQuery={(th) => th.breakpoints.up('md')}>
                  <Grid item xs={12} md={6} lg={6}>
                    <WelcomeMainScreenshot sx={{ width: '100%', maxHeight: '100%' }} />
                  </Grid>
                </MediaQuery>
              </Grid>
            </MainContainer>
          </Box>
        </Stack>
      </Box>

      {state.welcomeShowLogIn && <LoginDialog isOpen={true} kind="log-in" />}
      {state.welcomeShowSignUp && <LoginDialog isOpen={true} kind="sign-up" />}
    </>
  );
});

const MainContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  [theme.breakpoints.down('md')]: {
    justifyContent: 'stretch'
  },
  ['@media screen and (max-height: 700px)']: {
    justifyContent: 'stretch'
  }
}));
