import { Card, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { PlannerCopyItemInfo } from '../../../viewmodels';
import { NoteIcon, WorkIcon } from '../../lists';

export interface PlannerCopyOriginalItemCardProps {
  sx?: SxProps;
  className?: string;
  item: PlannerCopyItemInfo;
}

export const PlannerCopyOriginalItemCard = observer(({ sx, className, item }: PlannerCopyOriginalItemCardProps) => {
  const theme = useTheme();

  return (
    <Card
      elevation={theme.palette.mode === 'light' ? 1 : 8}
      sx={{ ...sx, p: 1.5, display: 'flex' }}
      className={className}
    >
      {item.kind !== 'note' ? (
        <WorkIcon icon={item.icon} size={32} color={item.color} displayCourseSectionColor />
      ) : (
        <NoteIcon size={32} color={item.color} />
      )}

      <Stack ml={1} flex={1}>
        <Typography fontWeight="500">{item.title}</Typography>

        {item.subtitle != null && (
          <Typography variant="body2" noWrap>
            {item.subtitle}
          </Typography>
        )}
      </Stack>
    </Card>
  );
});
