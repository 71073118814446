import { Box, ToggleButton, ToggleButtonGroup, ToggleButtonGroupProps, styled } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { MouseEvent, ReactNode } from 'react';

export interface ToggleGroupOption {
  value: string;
  text?: string;
  icon?: (sx: SxProps) => ReactNode;
}

export interface ToggleGroupProps {
  sx?: SxProps;
  className?: string;
  value: string;
  options: ToggleGroupOption[];
  onChange: (newValue: string | null) => void;
  size?: 'small' | 'normal';
}

export const ToggleGroup = observer(
  ({ sx, className, value, options, onChange, size = 'normal' }: ToggleGroupProps) => {
    const handleValueChange = (_: MouseEvent<HTMLElement>, newValue: string | null) => {
      onChange(newValue);
    };

    return (
      <Box
        sx={{
          ...sx,
          display: 'flex',
          flexWrap: 'wrap'
        }}
        className={className}
      >
        <StyledToggleButtonGroup
          size="small"
          value={value}
          exclusive
          onChange={handleValueChange}
          fullWidth
          controlSize={size}
          color="primary"
        >
          {options.map((option, index) => (
            <ToggleButton key={index} value={option.value} sx={{ textTransform: 'none', whiteSpace: 'nowrap' }}>
              {option.text}
              {option.icon?.({ ml: 1 })}
            </ToggleButton>
          ))}
        </StyledToggleButtonGroup>
      </Box>
    );
  }
);

interface StyledToggleButtonGroupProps extends ToggleButtonGroupProps {
  controlSize: 'small' | 'normal';
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup, {
  shouldForwardProp: (name) => name !== 'controlSize'
})<StyledToggleButtonGroupProps>(({ theme, controlSize }) => {
  const verticalPadding = controlSize === 'small' ? 0.5 : 1;

  return {
    '& .MuiToggleButtonGroup-grouped': {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(verticalPadding),
      paddingBottom: theme.spacing(verticalPadding)
    }
  };
});
