import { useActivePlannerId, usePlannerUserHasAccess, useViewModel } from '@/hooks';
import { AccessKind } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/access_kind_pb';
import { AddRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Divider, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Fragment, useState } from 'react';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { CreateSchoolDialog } from '../../school';
import { SettingsCard } from '../../shared';
import { UpdatablePresenter } from '../../utils';
import { PlannerSchoolsListItem } from './PlannerSchoolsListItem';

interface LocationState {
  plannerSchoolsListShowCreateSchool?: boolean;
  hasAttachErrorSchoolId?: string;
}

export interface PlannerSchoolsListProps {
  sx?: SxProps;
  className?: string;
}

export const PlannerSchoolsList = observer(({ sx, className }: PlannerSchoolsListProps) => {
  const plannerId = useActivePlannerId();
  const viewModel = useViewModel((viewModels) => viewModels.createPlannerSchoolsListViewModel(plannerId), [plannerId]);
  const hasAccess = usePlannerUserHasAccess([AccessKind.FULL_ACCESS]);
  const strings = LocalizedStrings.settings.school.list;

  const location = useLocation();
  const state = (location.state ?? {}) as LocationState;
  const navigate = useNavigate();
  const { hasAttachErrorSchoolId } = state;
  const [isAttachingSchool, setIsAttachingSchool] = useState(false);

  function onCreateSchoolButtonClick() {
    const newState: LocationState = { ...state, plannerSchoolsListShowCreateSchool: true };
    navigate(location, { state: newState });
    return Promise.resolve();
  }

  async function retryAttachSchool() {
    if (hasAttachErrorSchoolId == null || hasAttachErrorSchoolId.length === 0) {
      return;
    }

    setIsAttachingSchool(true);

    try {
      await viewModel.attachSchoolToPlanner(hasAttachErrorSchoolId);
      // Removing schoolId from state.
      navigate(location, { replace: true });
    } catch {
      console.warn(`Attach school with id ${hasAttachErrorSchoolId} failed`);
    } finally {
      setIsAttachingSchool(false);
    }
  }

  return (
    <Box sx={sx} className={className}>
      <UpdatablePresenter
        sx={{ width: '100%' }}
        viewModel={viewModel}
        renderData={() => (
          <Stack width="100%" spacing={2}>
            <SettingsCard
              title={strings.title()}
              actions={
                hasAccess && viewModel.canAddSchool
                  ? [{ icon: <AddRounded />, action: () => onCreateSchoolButtonClick() }]
                  : undefined
              }
            >
              <Stack width="100%" pb={2} pt={1}>
                {!viewModel.isReadOnly && hasAttachErrorSchoolId != null && (
                  <Alert
                    severity="error"
                    sx={{ mx: 2 }}
                    action={
                      <LoadingButton
                        color="inherit"
                        size="small"
                        onClick={() => void retryAttachSchool()}
                        loading={isAttachingSchool}
                      >
                        {strings.attachErrorAlertRetryButtonTitle()}
                      </LoadingButton>
                    }
                  >
                    {strings.attachErrorAlertMessage()}
                  </Alert>
                )}

                {viewModel.schools.map((school, index) => (
                  <Fragment key={school.school!.id}>
                    <PlannerSchoolsListItem school={school} isReadOnly={viewModel.isReadOnly} />
                    {index < viewModel.schools.length - 1 && <Divider sx={{ mx: 4 }} />}
                  </Fragment>
                ))}

                {viewModel.schools.length === 0 && (
                  <Typography px={3} py={1} fontStyle="italic" color="textSecondary">
                    {strings.emptyMessage()}
                  </Typography>
                )}
              </Stack>
            </SettingsCard>
          </Stack>
        )}
      />

      {state.plannerSchoolsListShowCreateSchool === true && <CreateSchoolDialog isOpen={true} />}
    </Box>
  );
});
