import { useActivePlannerId, useServices } from '@/hooks';
import { UserDashboardCalendarWeekAllDayItemInfo, UserDashboardCalendarWeekDayViewModel } from '@/viewmodels';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, isSaturday } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { AllPlannerItemsPasteboardContentKind } from '../../../../services';
import { FloatingPasteButton } from '../../../utils';
import { UserDashboardCalendarAllDayCalendarEvent, UserDashboardCalendarAnnotationView } from '../shared';
import { UserDashboardCalendarWeekDayHeaderMore } from './UserDashboardCalendarWeekDayHeaderMore';
import { UserDashboardCalendarWeekWork } from './items';
import { UserDashboardCalendarWeekNoteView } from './items/UserDashboardCalendarWeekNoteView';

export interface UserDashboardCalendarWeekDayHeaderProps {
  sx?: SxProps;
  className?: string;
  viewModel: UserDashboardCalendarWeekDayViewModel;
  isLast: boolean;
}

export const UserDashboardCalendarWeekDayHeader = observer(
  ({ sx, className, viewModel, isLast }: UserDashboardCalendarWeekDayHeaderProps) => {
    const { pasteboard, route } = useServices();
    const plannerId = useActivePlannerId();
    const theme = useTheme();
    const navigate = useNavigate();

    const { date, allDayItems, isToday, cycleDayName } = viewModel;

    function goToDate() {
      const dateString = format(date, 'y-MM-dd');
      const destination = `${route.resolvePlannerLocation(plannerId)}/home?date=${dateString}`;
      navigate(destination);
    }

    const items = allDayItems.length > 3 ? allDayItems.slice(0, 2) : allDayItems;

    function onPasteButtonPressed() {
      pasteboard.setPasteContext({ date: date });
    }

    return (
      <Box
        sx={{
          ...sx,
          position: 'relative',
          boxSizing: 'border-box',
          borderRight: !isLast ? '1px solid transparent' : undefined
        }}
        className={className}
      >
        <Stack spacing={1}>
          <Stack alignSelf="center">
            <Typography variant="subtitle2" color="textSecondary">
              {format(date, 'EEEE')}
            </Typography>

            <Stack direction="row" spacing={1} onClick={goToDate} sx={{ cursor: 'pointer' }} justifyContent="center">
              <Typography
                variant="h5"
                sx={{
                  userSelect: 'none',
                  width: 26,
                  height: 26,
                  borderRadius: '50%',
                  lineHeight: '26px',
                  textAlign: 'center',
                  fontSize: isToday ? 16 : undefined,
                  color: isToday ? theme.palette.getContrastText(theme.palette.error.main) : theme.palette.text.primary,
                  backgroundColor: isToday ? theme.palette.error.main : undefined
                }}
              >
                {format(date, 'd')}
              </Typography>

              {cycleDayName.length > 0 && (
                <Typography variant="caption" color="textSecondary" sx={{ userSelect: 'none' }}>
                  {cycleDayName}
                </Typography>
              )}
            </Stack>
          </Stack>

          <Stack
            flex={1}
            // Adding 1px so that the border overlaps with the translucent border of the container.
            width={isLast ? '100%' : 'calc(100% + 1px)'}
            spacing="2px"
            pb={0.5}
            px={1}
            sx={{
              boxSizing: 'border-box',
              borderRight: isSaturday(date) ? undefined : `1px solid ${theme.palette.divider}`
            }}
          >
            {items.map(renderItem)}
            {allDayItems.length > 3 && (
              <UserDashboardCalendarWeekDayHeaderMore moreItems={allDayItems.slice(2, undefined)} />
            )}
          </Stack>
        </Stack>
        <FloatingPasteButton
          sx={{
            position: 'absolute',
            right: 8,
            bottom: 4
          }}
          supportedContentKind={AllPlannerItemsPasteboardContentKind}
          action={onPasteButtonPressed}
        />
      </Box>
    );
  }
);

function renderItem(item: UserDashboardCalendarWeekAllDayItemInfo) {
  switch (item.case) {
    case 'annotation':
      return <UserDashboardCalendarAnnotationView key={item.value.id} annotation={item.value} />;
    case 'calendarEvent':
      return <UserDashboardCalendarAllDayCalendarEvent key={item.value.id} event={item.value} />;
    case 'note':
      return <UserDashboardCalendarWeekNoteView key={item.value.id} note={item.value} showBorder={false} />;
    case 'work':
    case 'publishedWork':
      return <UserDashboardCalendarWeekWork key={item.value.id} work={item} showBorder={false} />;
    default:
      return null;
  }
}
