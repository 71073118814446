import { GroupsRounded, InfoRounded, PersonRounded } from '@mui/icons-material';
import { Box, FormGroup, FormHelperText, IconButton, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { SchoolKindsExplanationDialog } from '../../onboarding/SchoolKindsExplanationDialog';
import { NavigationButton } from '../../utils';

export interface CreateSchoolKindPickerProps {
  sx?: SxProps;
  className?: string;
  onSelection: (kind: 'personal' | 'shared') => void;
}

export const CreateSchoolKindPicker = observer(({ sx, className, onSelection }: CreateSchoolKindPickerProps) => {
  const [showExplanation, setShowExplanation] = useState(false);
  const strings = LocalizedStrings.school.create.picker;

  return (
    <Box sx={sx} className={className}>
      <Stack spacing={2}>
        <Stack direction="row" justifyContent="center" spacing={1} alignItems="center">
          <Typography fontWeight="500">{strings.title()}</Typography>
          <IconButton size="small" onClick={() => setShowExplanation(true)}>
            <InfoRounded fontSize="small" />
          </IconButton>
        </Stack>

        <FormGroup>
          <Stack spacing={1.5}>
            <NavigationButton
              title={strings.personalSchoolOptionTitle()}
              subtitle={strings.personalSchoolOptionSubtitle()}
              icon={(sx) => <PersonRounded sx={sx} />}
              onClick={() => onSelection('personal')}
            />
            <NavigationButton
              title={strings.sharedSchoolOptionTitle()}
              subtitle={strings.sharedSchoolOptionSubtitle()}
              icon={(sx) => <GroupsRounded sx={sx} />}
              onClick={() => onSelection('shared')}
            />
            <FormHelperText sx={{ px: 1 }}>{strings.personalSchoolShareMessage()}</FormHelperText>
          </Stack>
        </FormGroup>
      </Stack>

      {showExplanation && <SchoolKindsExplanationDialog isOpen={true} close={() => setShowExplanation(false)} />}
    </Box>
  );
});
