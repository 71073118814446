import { AdminClassSchedulesFilterOption, AdminClassSchedulesViewModel } from '@/viewmodels';
import { Account } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/account_pb';
import { CourseSection } from '@buf/studyo_studyo-today-schools.bufbuild_es/studyo/today/schools/v1/resources/course_section_pb';
import { PersonRounded } from '@mui/icons-material';
import { Autocomplete, Box, ListItemIcon, ListItemText, MenuItem, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { HTMLAttributes, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { propertiesHaveMatchForSearchText } from '../../../../utils';
import { Subheader } from '../../../utils';

export interface AdminSchoolScheduleCycleClassSchedulesSearchProps {
  sx?: SxProps;
  className?: string;
  viewModel: AdminClassSchedulesViewModel;
  autoFocus: boolean;
  currentOption: AdminClassSchedulesFilterOption | undefined;
}

export const AdminSchoolScheduleCycleClassSchedulesSearch = observer(
  ({ sx, className, autoFocus, viewModel, currentOption }: AdminSchoolScheduleCycleClassSchedulesSearchProps) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [searchText, setSearchText] = useState('');
    const strings = LocalizedStrings.admin.schedule.classSchedules;

    function setOption(option: AdminClassSchedulesFilterOption | null) {
      setSearchText('');

      if (option == null) {
        searchParams.delete('teacher');
        searchParams.delete('course');
      } else {
        switch (option.case) {
          case 'teacher': {
            searchParams.set('teacher', option.value.id);
            searchParams.delete('course');
            break;
          }

          case 'course': {
            searchParams.set('course', option.value.id);
            searchParams.delete('teacher');
            break;
          }
        }
      }

      setSearchParams(searchParams);
    }

    function titleForSection(section: string) {
      switch (section) {
        case 'teacher':
          return strings.searchTeachersSectionTitle();
        case 'course':
          return strings.searchCoursesSectionTitle();
        default:
          return '';
      }
    }

    return (
      <Autocomplete
        sx={sx}
        className={className}
        slotProps={{ paper: { elevation: 2, sx: { border: (theme) => `1px solid ${theme.palette.divider}` } } }}
        disablePortal
        options={viewModel.options}
        value={null}
        filterOptions={filterOptions}
        inputValue={searchText}
        onInputChange={(_, v) => setSearchText(v)}
        groupBy={(o) => o.case}
        renderGroup={(params) => (
          <div key={params.key}>
            <Subheader>{titleForSection(params.group)}</Subheader>
            {params.children}
          </div>
        )}
        onChange={(_, option) => setOption(option)}
        renderInput={(params) => <TextField autoFocus={autoFocus} {...params} label={strings.searchLabel()} />}
        getOptionLabel={(option) => (option.case === 'teacher' ? option.value.fullName : option.value.title)}
        renderOption={(props, option) =>
          option.case === 'teacher'
            ? renderTeacherOption(option.value, props, option.id === currentOption?.id)
            : renderCourseOption(option.value, props, option.id === currentOption?.id)
        }
      />
    );
  }
);

function filterOptions(
  options: AdminClassSchedulesFilterOption[],
  { inputValue }: { inputValue: string }
): AdminClassSchedulesFilterOption[] {
  if (inputValue.length === 0) {
    return options;
  }

  return options.filter((o) => {
    switch (o.case) {
      case 'teacher':
        return propertiesHaveMatchForSearchText(inputValue, [
          o.value.fullName,
          o.value.emailAddress,
          { value: o.id, method: 'exact', caseSensitive: true }
        ]);
      case 'course':
        return propertiesHaveMatchForSearchText(inputValue, [
          o.value.title,
          { value: o.id, method: 'exact', caseSensitive: true }
        ]);
    }
  });
}

function renderTeacherOption(teacher: Account, props: HTMLAttributes<HTMLLIElement>, isSelected: boolean) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { key, ...allProps } = props;

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <MenuItem key={key} {...allProps} selected={isSelected}>
      <ListItemIcon>
        <PersonRounded />
      </ListItemIcon>
      {teacher.fullName || teacher.emailAddress || LocalizedStrings.admin.schedule.classSchedules.teacherDefaultName()}
    </MenuItem>
  );
}

function renderCourseOption(course: CourseSection, props: HTMLAttributes<HTMLLIElement>, isSelected: boolean) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { key, ...allProps } = props;

  return (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    <MenuItem key={key} {...allProps} selected={isSelected}>
      <Box
        sx={{
          height: course.section.length > 0 ? 36 : 20,
          width: 4,
          borderRadius: 2,
          backgroundColor: course.color,
          mr: 1
        }}
      />
      <ListItemText primary={course.title} secondary={course.section} />
    </MenuItem>
  );
}
