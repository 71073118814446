import { LoadingButton } from '@mui/lab';
import { Stack, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useState } from 'react';

export interface UserDashboardCalendarDayStateIndicatorProps {
  sx?: SxProps;
  className?: string;
  renderIcon: (size: number, color: string) => JSX.Element;
  title: string;
  subtitle?: string;
  action?: { title: string; onClick: () => Promise<void> };
}

export function UserDashboardCalendarDayStateIndicator({
  sx,
  className,
  title,
  renderIcon,
  subtitle,
  action
}: UserDashboardCalendarDayStateIndicatorProps) {
  const theme = useTheme();
  const [showActionLoading, setShowActionLoading] = useState(false);

  async function onActionClick() {
    if (action == null) {
      return;
    }

    setShowActionLoading(true);
    await action.onClick();
    setShowActionLoading(false);
  }

  return (
    <Stack sx={sx} className={className} alignItems="flex-start" spacing={2}>
      <Stack direction="row" spacing={3} alignItems="center">
        {renderIcon(34, theme.palette.text.secondary)}

        <Stack flex={1}>
          <Typography variant="body1" fontWeight="500" color="textSecondary">
            {title}
          </Typography>

          {subtitle && (
            <Typography variant="body2" color="textSecondary">
              {subtitle}
            </Typography>
          )}
        </Stack>
      </Stack>

      {action != null && (
        <LoadingButton
          variant="contained-grey"
          color="primary"
          sx={{ minWidth: 200 }}
          onClick={() => void onActionClick()}
          loading={showActionLoading}
        >
          {action.title}
        </LoadingButton>
      )}
    </Stack>
  );
}
