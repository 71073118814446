import { PlannerListItemInfo } from '@/viewmodels';
import { Box, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { PlannerListItemLateIndicator } from './PlannerListItemLateIndicator';

export interface PlannerListItemDateDetailsProps {
  className?: string;
  sx?: SxProps;
  item: PlannerListItemInfo;
}

export const PlannerListItemDateDetails = observer(({ className, sx, item }: PlannerListItemDateDetailsProps) => {
  const theme = useTheme();

  if (item.primaryDetailKind === 'late-icon') {
    return <PlannerListItemLateIndicator className={className} sx={sx} />;
  }

  const primaryColor: string = useMemo(() => {
    if (item.state === 'completed') {
      return theme.palette.text.disabled;
    }

    switch (item.primaryDetailKind) {
      case 'warning':
        return theme.palette.warning.main;
      case 'error':
        return theme.palette.error.main;
      default:
        return theme.palette.text.primary;
    }
  }, [item.primaryDetailKind, item.state]);

  const secondaryColor: string = useMemo(() => {
    if (item.state === 'completed') {
      return theme.palette.text.disabled;
    }

    return theme.palette.text.secondary;
  }, [item.state]);

  return (
    <Box className={className} sx={sx} display="flex" flexDirection="column" alignItems="flexEnd">
      <Typography variant="body2" noWrap align="right" style={{ color: primaryColor }}>
        {item.primaryDetail ?? '\u00A0'}
      </Typography>
      <Typography variant="body2" noWrap align="right" style={{ color: secondaryColor }}>
        {item.secondaryDetail ?? '\u00A0'}
      </Typography>
    </Box>
  );
});
