import { Button } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import LocalizedStrings from 'strings';
import { useViewModel } from '../../../hooks';
import { ScheduleCycleCreateDialog, ScheduleCycleList, SettingsCard } from '../../shared';

interface LocationState {
  schoolScheduleCycleListShowCreateSchedule?: boolean;
}

export interface SchoolScheduleCycleListProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
  disabled?: boolean;
  showSource: boolean;
}

export const SchoolScheduleCycleList = observer(
  ({ schoolId, className, sx, showSource }: SchoolScheduleCycleListProps) => {
    const viewModel = useViewModel(
      (viewModels) => viewModels.createSchedulesListViewModel(schoolId, 'school', true),
      [schoolId]
    );
    const strings = LocalizedStrings.scheduleCycle.list;

    const location = useLocation();
    const state = (location.state || {}) as LocationState;
    const navigate = useNavigate();

    function showCreateSchedule() {
      const newState: LocationState = { ...state, schoolScheduleCycleListShowCreateSchedule: true };
      navigate(location, { state: newState });
    }

    const canCreateSchedule = viewModel.hasData && !viewModel.isReadOnly;

    return (
      <SettingsCard sx={sx} className={className} title={strings.titleSchool()} canBeCollapsed>
        <ScheduleCycleList viewModel={viewModel} showSource={showSource} showEmptyLabel={!canCreateSchedule} />

        {canCreateSchedule && viewModel.schedules.length === 0 && (
          <Button sx={{ mx: 2, mb: 3, alignSelf: 'flex-start' }} variant="contained-grey" onClick={showCreateSchedule}>
            {strings.createSchoolSchedule()}
          </Button>
        )}

        {state.schoolScheduleCycleListShowCreateSchedule === true && (
          <ScheduleCycleCreateDialog isOpen={true} userDashboard={{ kind: 'school', id: schoolId }} />
        )}
      </SettingsCard>
    );
  }
);
