import {
  ItemRepeatPatternKind,
  ScheduleCycleInfo,
  UserDashboard,
  WorkIconInfo,
  dayToDate,
  urlForExternalSourceBadge,
  urlForPublishedWorkWorkIcon,
  urlForWorkIconFromWork
} from '@/models';
import { ServiceContainer } from '@/providers';
import { PlannerCalendarStore, UserDashboardScheduleCyclesLoadable } from '@/stores';
import { ScheduleCycle } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/schedule_cycle_pb';
import { differenceInCalendarDays } from 'date-fns';
import { computed, makeObservable, observable, override, runInAction } from 'mobx';
import LocalizedStrings from 'strings';
import { UpdatableViewModelState } from '../shared';
import {
  AppBaseUpdatableDialogViewModel,
  BaseDialogActionButtonConfiguration,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../utils';
import { PlannerCopyItemInfo } from './PlannerCopyItemInfo';
import { PlannerCopyItemKind } from './PlannerCopyItemKind';

interface ItemAndDateInfo {
  item: PlannerCopyItemInfo;
  date: Date | undefined;
}

export interface PlannerRepeatScheduleCycleInfo {
  readonly scheduleCycles: ScheduleCycleInfo[];
  readonly userDashboard: UserDashboard;
}

export interface PlannerRepeatItemViewModel extends UpdatableDialogViewModel {
  readonly item: PlannerCopyItemInfo;
  readonly minimumDate: Date;
  untilDate: Date;
  pattern: ItemRepeatPatternKind;
  readonly scheduleCycles: PlannerRepeatScheduleCycleInfo[];
  readonly canRepeatEveryOccurrence: boolean;
  getDatesWithOccurrenceForCourseSection(fromDate: Date, toDate: Date): Date[];
}

export class AppPlannerRepeatItemViewModel
  extends AppBaseUpdatableDialogViewModel
  implements PlannerRepeatItemViewModel
{
  @observable private _state: UpdatableViewModelState = 'pending';
  @observable private _isSaving = false;
  @observable private _item: PlannerCopyItemInfo | undefined;
  @observable private _allScheduleCycles: PlannerRepeatScheduleCycleInfo[] | undefined;
  @observable private _minimumDate: Date | undefined;
  @observable private _untilDate: Date | undefined;
  @observable private _pattern: ItemRepeatPatternKind;
  @observable private _canRepeatEveryOccurrence = false;

  private readonly _saveButtonConfig: BaseDialogActionButtonConfiguration;
  private readonly _cancelButtonConfig: CancelDialogActionButtonConfiguration;
  private readonly _scheduleCyclesLoadables: UserDashboardScheduleCyclesLoadable[];
  private readonly _calendarStore: PlannerCalendarStore;

  constructor(
    private readonly _plannerId: string,
    private readonly _itemKind: PlannerCopyItemKind,
    close: () => Promise<void>,
    localization = ServiceContainer.services.localization,
    private readonly _dateService = ServiceContainer.services.dateService,
    private readonly _plannerStore = ServiceContainer.services.plannerStore,
    private readonly _userStore = ServiceContainer.services.userStore,
    private readonly _workStore = ServiceContainer.services.workStore
  ) {
    super(localization, close);
    makeObservable(this);
    this._calendarStore = _plannerStore.getCalendarStore(_plannerId);
    this._pattern = { case: 'day' };

    this._saveButtonConfig = new BaseDialogActionButtonConfiguration(
      'main',
      'both',
      'right',
      'check',
      'start',
      () => LocalizedStrings.planner.repeatPlannerItem.submitButtonLabel(),
      'contained',
      () => this.save()
    );

    this._cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
      this.dismiss()
    );

    this._scheduleCyclesLoadables = this._userStore.getScheduleCyclesForPlannerAndItsSchools(this._plannerId);
  }

  @computed
  private get courseSectionsLoadable() {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId);
  }

  @computed
  private get workIconsLoadable() {
    return this._workStore.workIcons;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this._isSaving;
    this._saveButtonConfig.showLoading = this._isSaving;
    this._saveButtonConfig.isEnabled = !this._isSaving && this.hasChanges;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  @computed
  get item(): PlannerCopyItemInfo {
    return this._item!;
  }

  @computed
  get canRepeatEveryOccurrence(): boolean {
    return this._canRepeatEveryOccurrence;
  }

  @computed
  get minimumDate(): Date {
    return this._minimumDate!;
  }

  @computed
  get untilDate(): Date {
    return this._untilDate!;
  }

  set untilDate(value: Date) {
    this._untilDate = value;
  }

  @computed
  get pattern(): ItemRepeatPatternKind {
    return this._pattern;
  }

  set pattern(value: ItemRepeatPatternKind) {
    this._pattern = value;
  }

  @computed
  get scheduleCycles(): PlannerRepeatScheduleCycleInfo[] {
    return (
      this._allScheduleCycles
        ?.map((info) => ({
          scheduleCycles: info.scheduleCycles.filter((sc) => this.getIsScheduleCycleIncluded(sc.scheduleCycle)),
          userDashboard: info.userDashboard
        }))
        .filter((info) => info.scheduleCycles.length > 0) ?? []
    );
  }

  @computed
  get state(): UpdatableViewModelState {
    return this._state;
  }
  @computed
  get hasChanges(): boolean {
    if (this._pattern.case === 'week') {
      return this._pattern.daysOfWeek.length > 0;
    }

    if (this._pattern.case === 'cycleDay') {
      return this._pattern.cycleDays.length > 0;
    }

    return true;
  }

  @computed
  get isSubmitting(): boolean {
    return this._isSaving;
  }

  @computed
  get hasData(): boolean {
    return (
      this._allScheduleCycles != null && this._item != null && this._minimumDate != null && this._untilDate != null
    );
  }

  getDatesWithOccurrenceForCourseSection(fromDate: Date, toDate: Date): Date[] {
    if (this.item.courseId == null) {
      return [];
    }

    return this._calendarStore.getOccurrencesForCourseSection(this.item.courseId, fromDate, toDate).map((o) => o.date);
  }

  async reloadData(): Promise<void> {
    runInAction(() => (this._state = 'pending'));
    try {
      const { item, date } = await this.loadItem();

      await Promise.all(this._scheduleCyclesLoadables.map((l) => l.loadable.fetch(true)));

      const scheduleCycles = this._scheduleCyclesLoadables
        .filter((l) => l.loadable.hasData)
        .map<PlannerRepeatScheduleCycleInfo>((l) => ({
          scheduleCycles: l.loadable.data,
          userDashboard: l.userDashboard
        }));

      const miniumDate = date ?? this._dateService.now;

      runInAction(() => {
        this._item = item;
        this._minimumDate = miniumDate;
        this._untilDate = miniumDate;
        this._allScheduleCycles = scheduleCycles;
        this._state = 'fulfilled';
        this._canRepeatEveryOccurrence = (item.courseId?.length ?? 0) > 0;
        this._pattern = this._canRepeatEveryOccurrence ? { case: 'occurrence' } : { case: 'day' };
      });
    } catch (e) {
      runInAction(() => (this._state = e as Error));
    }
  }

  private async save() {
    runInAction(() => (this._isSaving = true));

    try {
      switch (this._itemKind.case) {
        case 'work':
          await this._workStore.repeatWork(this._itemKind.id, this._plannerId, this.untilDate, this.pattern);
          break;

        case 'note':
          await this._workStore.repeatNote(this._itemKind.id, this._plannerId, this.untilDate, this.pattern);
          break;

        case 'publishedWork': {
          await this._workStore.repeatPublishedWork(
            this._itemKind.id,
            this._itemKind.schoolId,
            this._plannerId,
            this.untilDate,
            this.pattern
          );
          break;
        }
      }

      await this.dismiss();
    } catch (e) {
      runInAction(() => (this._error = LocalizedStrings.planner.distributeItem.saveErrorMessage()));
    } finally {
      runInAction(() => (this._isSaving = false));
    }
  }

  private getIsScheduleCycleIncluded(schedule: ScheduleCycle): boolean {
    return (
      differenceInCalendarDays(this.minimumDate, dayToDate(schedule.endDay!)) <= 0 &&
      differenceInCalendarDays(dayToDate(schedule.startDay!), this.untilDate) <= 0
    );
  }

  private async loadItem(): Promise<ItemAndDateInfo> {
    switch (this._itemKind.case) {
      case 'work':
        return await this.loadDataForWork(this._itemKind.id);

      case 'note':
        return await this.loadDataForNote(this._itemKind.id);

      case 'publishedWork':
        return await this.loadDataForPublishedWork(this._itemKind.id, this._itemKind.schoolId);
    }
  }

  private async loadDataForWork(id: string): Promise<ItemAndDateInfo> {
    const workLoadable = this._workStore.getWorkLoadable(id);
    await workLoadable.fetch(false);

    const work = workLoadable.data;
    const course = this.courseSectionsLoadable.data.get(work.courseSectionId);
    const icons = this.workIconsLoadable.data;
    const icon = icons.iconsById.get(work.iconId) ?? icons.iconsById.get(icons.defaultIconId);
    const iconInfo: WorkIconInfo | undefined =
      icon != null
        ? {
            id: icon.iconId,
            title: icon.iconName,
            lightUrl: urlForWorkIconFromWork(icon, work, 'light'),
            darkUrl: urlForWorkIconFromWork(icon, work, 'dark'),
            externalBadgeUrl: urlForExternalSourceBadge(work.externalSource?.sourceName, icons)
          }
        : undefined;

    return {
      item: {
        kind: 'work',
        id: work.id,
        title: work.title,
        subtitle: work.description?.text,
        color: course?.courseSection?.color ?? '',
        courseId: course?.courseSection?.id,
        icon: iconInfo
      },
      date: work.dueTime?.toDate()
    };
  }

  private async loadDataForNote(id: string): Promise<ItemAndDateInfo> {
    const noteLoadable = this._workStore.getNoteLoadable(id);
    await noteLoadable.fetch(false);

    const note = noteLoadable.data;
    const course = this.courseSectionsLoadable.data.get(note.courseSectionId);

    return {
      item: {
        kind: 'note',
        id: note.id,
        title: LocalizedStrings.planner.distributeItem.noteTitle(),
        subtitle: note.text!.text,
        color: course?.courseSection?.color ?? '',
        courseId: course?.courseSection?.id,
        icon: undefined
      },
      date: note.time?.toDate()
    };
  }

  private async loadDataForPublishedWork(id: string, schoolId: string): Promise<ItemAndDateInfo> {
    const workLoadable = this._workStore.getPublishedWorkLoadable(id, schoolId);
    await workLoadable.fetch(false);

    const work = workLoadable.data;
    const allCourseSections = this.courseSectionsLoadable.values;
    const course = allCourseSections.find((cs) => cs.schoolsCourseSection?.id === work.courseSectionId);

    const icons = this.workIconsLoadable.data;
    const icon = icons.iconsById.get(work.iconId) ?? icons.iconsById.get(icons.defaultIconId);
    const iconInfo: WorkIconInfo | undefined =
      icon != null
        ? {
            id: icon.iconId,
            title: icon.iconName,
            lightUrl: urlForPublishedWorkWorkIcon(icon, work.importance, 'light'),
            darkUrl: urlForPublishedWorkWorkIcon(icon, work.importance, 'dark'),
            externalBadgeUrl: urlForExternalSourceBadge(work.externalSource?.sourceName, icons)
          }
        : undefined;

    return {
      item: {
        kind: 'publishedWork',
        id: work.id,
        title: work.title,
        subtitle: work.description?.text,
        color: course?.courseSection?.color ?? '',
        icon: iconInfo,
        schoolId: work.schoolId,
        courseId: course?.courseSection?.id
      },
      date: work.dueTime?.toDate()
    };
  }
}
