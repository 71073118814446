import { alpha, Box, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

export interface PlannedWorkEditSchedulePeriodViewProps {
  sx?: SxProps;
  className?: string;
  color: string;
}

export function PlannedWorkEditSchedulePeriodView({ sx, className, color }: PlannedWorkEditSchedulePeriodViewProps) {
  const theme = useTheme();
  const hasColor = color.length > 0;
  const resolvedColor = hasColor ? color : theme.palette.action.disabled;

  return (
    <Box
      sx={{
        ...sx,
        borderRadius: 0.5,
        overflow: 'hidden',
        backgroundColor: alpha(resolvedColor, hasColor ? 0.2 : 0.05)
      }}
      className={className}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          width: 4,
          backgroundColor: alpha(resolvedColor, hasColor ? 0.4 : 0.2)
        }}
      />
    </Box>
  );
}
