import { useActivePlannerId, useServices } from '@/hooks';
import { MenuItem, Select } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { Link, matchPath } from 'react-router-dom';
import { SettingsSection } from './SettingsSection';

export interface SettingsMenuProps {
  sx?: SxProps;
  className?: string;
  sections: SettingsSection[];
}

export const SettingsMenu = observer(({ sx, className, sections }: SettingsMenuProps) => {
  const { route } = useServices();
  const location = useLocation();
  const plannerId = useActivePlannerId();

  const allRoutes = useMemo(() => {
    return sections.reduce(
      (previousValue, currentValue) => [
        ...previousValue,
        ...currentValue.items.map((i) => route.resolvePlannerSettingsLocation(i.link, plannerId))
      ],
      [] as string[]
    );
  }, [sections]);

  const match = allRoutes.find((route) => matchPath({ path: route, end: false }, location.pathname) != null);

  return (
    <Select sx={sx} className={className} fullWidth value={match ?? location.pathname} size="small">
      {sections.map((section, sectionIndex) => [
        <MenuItem key={`settings-section-${sectionIndex}`} disabled>
          {section.title}
        </MenuItem>,

        ...section.items.map((item, itemIndex) => {
          const path = route.resolvePlannerSettingsLocation(item.link, plannerId);

          return (
            <MenuItem
              key={`settings-section-${sectionIndex}-${itemIndex}`}
              // There is a type error when trying to pass value as a prop ¯\_(ツ)_/¯
              {...{ value: path }}
              component={Link}
              to={path}
            >
              {item.title}
            </MenuItem>
          );
        })
      ])}
    </Select>
  );
});
