import { UpdatablePresenter } from '@/components/utils';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { useViewModel } from '../../../hooks';
import { AdminDemoSchoolCreateSnapshotAction } from './AdminDemoSchoolCreateSnapshotAction';
import { AdminDemoSchoolSnapshotList } from './AdminDemoSchoolSnapshotList';

export interface AdminDemoSchoolSnapshotsProps {
  sx?: SxProps;
  className?: string;
  schoolId: string;
}

export const AdminDemoSchoolSnapshots = observer(({ sx, className, schoolId }: AdminDemoSchoolSnapshotsProps) => {
  const strings = LocalizedStrings.admin.general.manageActions.snapshots;
  const viewModel = useViewModel(
    (viewModels) => viewModels.createAdminDemoSchoolSnapshotsViewModel(schoolId),
    [schoolId]
  );

  return (
    <UpdatablePresenter
      sx={sx}
      className={className}
      viewModel={viewModel}
      renderData={() =>
        viewModel.isDemoSchool ? (
          <Stack width="100%">
            <Typography variant="subtitle2" fontWeight="500">
              {strings.title()}
            </Typography>

            <AdminDemoSchoolSnapshotList
              snapshots={viewModel.snapshots}
              onRestore={(s, o) => viewModel.restoreSnapshot(s, o)}
            />
            <AdminDemoSchoolCreateSnapshotAction sx={{ pt: 1 }} onCreate={(name) => viewModel.createSnapshot(name)} />
          </Stack>
        ) : undefined
      }
    />
  );
});
