import { SpecialDayEditViewModel } from '@/viewmodels';
import { Checkbox, FormControlLabel, FormGroup, Stack, TextField } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { SpecialDayEditBellTimesPicker } from './SpecialDayEditBellTimesPicker';
import { SpecialDayEditCycleDaySection } from './SpecialDayEditCycleDaySection';
import { SpecialDayEditOccurrencesSection } from './SpecialDayEditOccurrencesSection';
import { SpecialDayEditSymbolSection } from './SpecialDayEditSymbolSection';

export interface SpecialDayEditProps {
  sx?: SxProps;
  className?: string;
  viewModel: SpecialDayEditViewModel;
}

export const SpecialDayEdit = observer(({ sx, className, viewModel }: SpecialDayEditProps) => {
  const strings = LocalizedStrings.scheduleCycle.edit.specialDays.edit;
  return (
    <Stack sx={sx} className={className} direction={{ xs: 'column', md: 'column' }} spacing={2}>
      <Stack flex={1} spacing={2}>
        <FormGroup>
          <TextField
            size="small"
            sx={{ mb: 1 }}
            label={strings.titleLabel()}
            value={viewModel.title}
            placeholder={strings.titlePlaceholder()}
            onChange={(e) => (viewModel.title = e.currentTarget.value)}
            fullWidth
          />

          <FormControlLabel
            control={<Checkbox />}
            label={strings.titleVisibilityOptionTitle()}
            checked={viewModel.isTitleVisible}
            onChange={(_, checked) => (viewModel.isTitleVisible = checked)}
          />
        </FormGroup>

        <SpecialDayEditSymbolSection
          symbol={viewModel.symbol}
          onSymbolSelection={(symbol) => (viewModel.symbol = symbol)}
          color={viewModel.color}
          onColorSelection={(color) => (viewModel.color = color)}
          isSymbolVisible={viewModel.isSymbolVisible}
          setIsSymbolVisible={(value) => (viewModel.isSymbolVisible = value)}
        />
      </Stack>

      <Stack flex={1} spacing={2}>
        <SpecialDayEditCycleDaySection
          scheduleCycleKind={viewModel.scheduleCycleKind}
          possibleCycleDays={viewModel.possibleCycleDays}
          cycleDayEffect={viewModel.cycleDayEffect}
          setCycleDayEffect={(effect) => (viewModel.cycleDayEffect = effect)}
          cycleDay={viewModel.cycleDay}
          setCycleDay={(value) => (viewModel.cycleDay = value)}
        />

        <SpecialDayEditBellTimesPicker
          selectedSchedules={viewModel.periodSchedules}
          possibleSchedules={viewModel.possiblePeriodSchedule}
          updateSelection={(ids) => viewModel.setPeriodScheduleIds(ids)}
        />
      </Stack>

      <SpecialDayEditOccurrencesSection
        occurrences={viewModel.occurrences}
        scheduleCycleKind={viewModel.scheduleCycleKind}
        possibleCycleDays={viewModel.possibleCycleDays}
        minDay={viewModel.minDay}
        maxDay={viewModel.maxDay}
        addOccurrence={(when) => viewModel.addOccurrence(when)}
        removeOccurrence={(when) => viewModel.removeOccurrence(when)}
      />
    </Stack>
  );
});
