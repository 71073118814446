import { EnvironmentService, GapiService } from '../contracts';

export class AppGapiService implements GapiService {
  constructor(private readonly _environment: EnvironmentService) {}

  grantOfflineAccess(scopes: string[]): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const client = google.accounts.oauth2.initCodeClient({
        client_id: this._environment.googleClientId,
        scope: scopes.join(' '),
        ux_mode: 'popup',
        select_account: true,
        error_callback: (error) => reject(error.type),
        callback: (response) => {
          if (response.error_description) {
            reject(response.error_description);
          } else {
            resolve(response.code);
          }
        }
      });

      client.requestCode();
    });
  }
}
