import { Day, SortedDayOfWeek, dayToDate } from '@/models';
import { CalendarDay } from '@buf/studyo_studyo-today-schedules.bufbuild_es/studyo/today/schedules/v1/resources/calendar_day_pb';
import { Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { differenceInCalendarDays, isSameMonth } from 'date-fns';
import { isEqual } from 'lodash';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';
import { ScheduleCycleSpecialDaysCalendarHeader } from './ScheduleCycleSpecialDaysCalendarHeader';
import { ScheduleCycleSpecialDaysCalendarTableCell } from './ScheduleCycleSpecialDaysCalendarTableCell';
import { ScheduleCycleSpecialDaysTable } from './ScheduleCycleSpecialDaysTable';

export interface ScheduleCycleSpecialDaysCalendarProps {
  sx?: SxProps;
  className?: string;
  selectedDay: Day | undefined;
  onDaySelection?: (day: Day | undefined) => void;
  currentMonth: (CalendarDay | undefined)[][];
  currentDate: Day;
  canGoToPreviousMonth: boolean;
  canGoToNextMonth: boolean;
  goToPreviousMonth: () => void;
  goToNextMonth: () => void;
  minDay: Day;
  maxDay: Day;
}

export const ScheduleCycleSpecialDaysCalendar = observer(
  ({
    sx,
    className,
    selectedDay,
    onDaySelection,
    currentMonth,
    currentDate,
    canGoToPreviousMonth,
    canGoToNextMonth,
    goToPreviousMonth,
    goToNextMonth,
    minDay,
    maxDay
  }: ScheduleCycleSpecialDaysCalendarProps) => {
    function dayIsSameMonth(day: Day) {
      return isSameMonth(dayToDate(day), dayToDate(currentDate));
    }

    function didSelectDay(day: Day) {
      if (selectedDay != null && isEqual(selectedDay, day)) {
        onDaySelection?.(undefined);
      } else {
        onDaySelection?.(day);
      }
    }

    return (
      <Stack sx={{ ...sx }} className={className} alignItems="flex-start">
        <ScheduleCycleSpecialDaysCalendarHeader
          sx={{ width: '100%' }}
          currentDate={currentDate}
          canGoToPreviousMonth={canGoToPreviousMonth}
          goToPreviousMonth={() => goToPreviousMonth()}
          canGoToNextMonth={canGoToNextMonth}
          goToNextMonth={() => goToNextMonth()}
        />

        <ScheduleCycleSpecialDaysTable>
          <thead>
            <tr>
              {SortedDayOfWeek.map((dow) => (
                <th key={`calendar-header-${dow}`}>
                  <Typography variant="subtitle1" fontWeight="700">
                    {LocalizedStrings.dateTime.dayOfWeekSingleLetterTitle[dow]()}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentMonth.map((week, index) => (
              <tr key={`week-${index}`}>
                {week.map((day, dayIndex) =>
                  day != null ? (
                    <ScheduleCycleSpecialDaysCalendarTableCell
                      key={`week-${index}-day-${dayIndex}`}
                      day={day}
                      isCurrentMonth={(d) => dayIsSameMonth(d)}
                      isSelected={selectedDay != null && day.day != null && isEqual(day.day, selectedDay)}
                      onSelect={onDaySelection != null ? didSelectDay : undefined}
                      canInteract={day.day != null && dayIsInteractable(day.day, minDay, maxDay)}
                    />
                  ) : (
                    <td
                      key={`empty-day-${index}-${dayIndex}`}
                      style={{ borderStyle: 'solid', borderWidth: '2px', borderColor: 'transparent' }}
                    >
                      <div />
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </ScheduleCycleSpecialDaysTable>
      </Stack>
    );
  }
);

function dayIsInteractable(day: Day, minDay: Day, maxDay: Day): boolean {
  const date = dayToDate(day);

  // Prevent selection of a day outside the start and end dates of a schedule cycle, if some are set.
  return (
    differenceInCalendarDays(date, dayToDate(minDay)) >= 0 && differenceInCalendarDays(date, dayToDate(maxDay)) <= 0
  );
}
