import { useActivePlannerId, useServices, useViewModel } from '@/hooks';
import { Box, Stack, useMediaQuery, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { Navigate } from 'react-router';
import { Outlet, useParams } from 'react-router-dom';
import { SchoolCourseSectionCompactCurrentStudent } from './SchoolCourseSectionCompactCurrentStudent';
import { SchoolCourseSectionDetailsStudentGrid } from './SchoolCourseSectionDetailsStudentGrid';

export interface SchoolCourseSectionDetailsColumnsProps {
  sx?: SxProps;
  className?: string;
}

export const SchoolCourseSectionDetailsColumns = observer(
  ({ sx, className }: SchoolCourseSectionDetailsColumnsProps) => {
    const { route } = useServices();
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(() => theme.breakpoints.only('xs'));

    const plannerId = useActivePlannerId();
    const params = useParams();
    const schoolId = params.schoolId ?? '';
    const courseSectionId = params.courseId ?? '';
    const studentId = params.studentId ?? '';

    const viewModel = useViewModel(
      (viewModels) => viewModels.createCourseSectionDetailsViewModel(courseSectionId, plannerId, schoolId),
      [courseSectionId]
    );

    if (
      viewModel.studentsPlannerSummary.allStudents.length > 0 &&
      studentId.length === 0 &&
      viewModel.studentsPlannerSummary.searchText.length === 0
    ) {
      const firstStudentId = viewModel.studentsPlannerSummary.allStudents[0].id;
      return (
        <Navigate
          to={route.resolveSchoolStudentPlannerLocation(plannerId, schoolId, courseSectionId, firstStudentId)}
          replace
        />
      );
    }

    const showStudentDetails =
      viewModel.studentsPlannerSummary.students.length > 0 &&
      (studentId.length === 0 || viewModel.studentsPlannerSummary.students.some((s) => s.id === studentId));

    return (
      <Stack
        sx={{ ...sx, overflow: 'visible' }}
        className={className}
        spacing={{ xs: 1, sm: 2 }}
        pb={!showStudentDetails || studentId.length === 0 ? 2 : undefined}
      >
        {isSmallScreen ? (
          <SchoolCourseSectionCompactCurrentStudent sx={{ mx: 2 }} viewModel={viewModel.studentsPlannerSummary} />
        ) : (
          <SchoolCourseSectionDetailsStudentGrid viewModel={viewModel.studentsPlannerSummary} />
        )}

        {showStudentDetails && (
          <Box flex={1}>
            <Outlet />
          </Box>
        )}
      </Stack>
    );
  }
);
