import { Day, dayToDate, WorkIconInfo } from '@/models';
import { ServiceContainer } from '@/providers';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { PlannerDay } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_day_pb';
import { PlannerItem } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/planner_item_pb';
import { Timestamp } from '@bufbuild/protobuf';
import { isToday as dateIsToday } from 'date-fns/isToday';
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import {
  makeRankedPositionsForCalendarItems,
  plannerDayAnnotationToInfo,
  UserDashboardCalendarAnnotationInfo,
  UserDashboardCalendarItemPosition,
  UserDashboardCalendarItemRankedPosition
} from '../shared';

export interface PlannedWorkEditDayItemPeriodInfo {
  readonly kind: 'course-occurrence';
  readonly color: string;
  readonly position: UserDashboardCalendarItemPosition;
}

export interface PlannedWorkEditDayItemPlannedWorkInfo {
  readonly kind: 'planned-work';
  readonly icon: WorkIconInfo;
  readonly color: string;
  readonly position: UserDashboardCalendarItemPosition;
}
export type PlannedWorkEditDayItemInfo = PlannedWorkEditDayItemPlannedWorkInfo | PlannedWorkEditDayItemPeriodInfo;

export type PlannedWorkEditViewModelDayItem = UserDashboardCalendarItemRankedPosition<PlannedWorkEditDayItemInfo>;

export interface PlannedWorkEditDayViewModel {
  readonly date: Date;
  readonly isToday: boolean;
  readonly cycleDayName: string;
  readonly annotations: UserDashboardCalendarAnnotationInfo[];
  readonly items: PlannedWorkEditViewModelDayItem[];
}

export class AppPlannedWorkEditDayViewModel implements PlannedWorkEditDayViewModel {
  constructor(
    private readonly _day: Day,
    private readonly _workId: string,
    private readonly _plannerId: string,
    private readonly _getPlannerDay: () => PlannerDay | undefined,
    private readonly _getPositionForItem: (
      startTime: Timestamp | undefined,
      endTime: Timestamp | undefined
    ) => UserDashboardCalendarItemPosition,
    private readonly _getWorkIconInfoForId: (iconId: string) => WorkIconInfo,
    private readonly _plannerStore = ServiceContainer.services.plannerStore
  ) {
    makeObservable(this);
  }

  @computed
  protected get courseSections(): Map<string, CourseSectionDetails> {
    return this._plannerStore.getCourseSectionsInPlanner(this._plannerId).data;
  }

  @computed
  private get plannerDay(): PlannerDay | undefined {
    return this._getPlannerDay();
  }

  @computed
  get date(): Date {
    return dayToDate(this._day);
  }

  @computed
  get isToday(): boolean {
    return dateIsToday(this.date);
  }

  @computed
  get cycleDayName(): string {
    if (this.plannerDay == null) {
      return '';
    }

    return !this.plannerDay.isDayOfWeekCycleDayName ? this.plannerDay.cycleDayName : '';
  }

  @computed
  get annotations(): UserDashboardCalendarAnnotationInfo[] {
    const annotations = this.plannerDay?.annotations ?? [];
    return annotations.map((a) => plannerDayAnnotationToInfo(a));
  }

  @computed
  get items(): PlannedWorkEditViewModelDayItem[] {
    const allItems = this.plannerDay?.items ?? [];

    if (allItems.length === 0 || this.plannerDay == null) {
      return [];
    }

    return this.getTimedItems(allItems);
  }

  private getTimedItems(items: PlannerItem[]): PlannedWorkEditViewModelDayItem[] {
    const itemInfos = this.getTimedItemsInfo(items);
    return makeRankedPositionsForCalendarItems(itemInfos, (item) => item.position);
  }

  private getTimedItemsInfo(items: PlannerItem[]): PlannedWorkEditDayItemInfo[] {
    const displayedItems = this.getDisplayedTimedItems(items);

    return chain(displayedItems)
      .map<PlannedWorkEditDayItemInfo | undefined>((item) => {
        switch (item.item.case) {
          case 'work': {
            if (item.plannedWork != null) {
              return {
                kind: 'planned-work',
                icon: this._getWorkIconInfoForId(item.item.value.iconId),
                color: this.courseSections.get(item.item.value.courseSectionId)?.courseSection?.color ?? '',
                position: this._getPositionForItem(item.contextualStartTime, item.contextualEndTime)
              };
            } else {
              return undefined;
            }
          }

          case 'courseSectionOccurrence': {
            return {
              kind: 'course-occurrence',
              color: this.courseSections.get(item.item.value.courseSectionId)?.courseSection?.color ?? '',
              position: this._getPositionForItem(item.contextualStartTime, item.contextualEndTime)
            };
          }

          default:
            return undefined;
        }
      })
      .compact()
      .value();
  }

  private getDisplayedTimedItems(items: PlannerItem[]): PlannerItem[] {
    return items.filter((item) => {
      if (item.isContextualAllDay) {
        return false;
      }

      switch (item.item.case) {
        case 'work': {
          return item.plannedWork != null && item.item.value.id !== this._workId;
        }

        case 'courseSectionOccurrence':
          return true;

        case undefined:
          return false;
      }
    });
  }
}
