import { Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import LocalizedStrings from 'strings';

export interface PlannerItemsNoSearchResultsIndicatorProps {
  sx?: SxProps;
  className?: string;
  searchText: string;
}

export const PlannerItemsNoSearchResultsIndicator = observer(
  ({ sx, className, searchText }: PlannerItemsNoSearchResultsIndicatorProps) => {
    return (
      <Typography sx={{ ...sx, px: 3, py: 2 }} className={className} variant="subtitle2" color="textSecondary">
        {LocalizedStrings.planner.items.emptyNoSearchResultsLabel(searchText)}
      </Typography>
    );
  }
);
