import { useServices } from '@/hooks';
import { dateToTimeOfDay, timeOfDayToDate } from '@/models';
import {
  ScheduleCyclePeriodSchedulePeriodAndActivityEditViewModel,
  ScheduleCyclePeriodSchedulePeriodEditViewModel
} from '@/viewmodels';
import { DeleteRounded } from '@mui/icons-material';
import { Alert, Box, Button, List, ListSubheader, Stack, TextField, Typography, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { format, set } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import LocalizedStrings from 'strings';
import { ConfirmationDialog, FormPopoverActions, FormPopoverHeader, TimePicker } from '../../../../utils';
import { ScheduleCyclePeriodEditSuggestion } from './ScheduleCyclePeriodEditSuggestion';
import { ScheduleCyclePeriodEditTermActivities } from './ScheduleCyclePeriodEditTermActivities';

function viewModelSupportsActivity(
  viewModel: ScheduleCyclePeriodSchedulePeriodEditViewModel | ScheduleCyclePeriodSchedulePeriodAndActivityEditViewModel
): viewModel is ScheduleCyclePeriodSchedulePeriodAndActivityEditViewModel {
  return (viewModel as ScheduleCyclePeriodSchedulePeriodAndActivityEditViewModel).cycleDayTitle != null;
}

export interface ScheduleCyclePeriodEditProps {
  sx?: SxProps;
  className?: string;
  viewModel: ScheduleCyclePeriodSchedulePeriodEditViewModel;
  dismiss?: () => void;
}

export const ScheduleCyclePeriodEdit = observer(
  ({ sx, className, viewModel, dismiss }: ScheduleCyclePeriodEditProps) => {
    const { dateService } = useServices();
    const theme = useTheme();
    const strings = LocalizedStrings.scheduleCycle.edit.bellTimes.period;

    const [showDeleteConfirmationDialog, setShowDeleteConfirmationDialog] = useState(false);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [saveError, setSaveError] = useState<Error | undefined>(undefined);

    const startTime = set(dateService.now, {
      hours: viewModel.startTime.hours,
      minutes: viewModel.startTime.minutes
    });
    const endTime = set(dateService.now, {
      hours: viewModel.endTime.hours,
      minutes: viewModel.endTime.minutes
    });

    async function save() {
      try {
        setSaveError(undefined);
        await viewModel.save();
        dismiss?.();
      } catch (error) {
        setSaveError(error as Error);
      }
    }

    async function deletePeriodSchedule(hasConfirmed: boolean) {
      setShowDeleteConfirmationDialog(false);

      if (hasConfirmed) {
        await viewModel.delete();
        dismiss?.();
      }
    }

    return (
      <Stack sx={{ ...sx, width: '100%', height: '100%', overflow: 'hidden' }} className={className}>
        <FormPopoverHeader
          title={viewModel.isNewPeriod ? strings.newTitle() : strings.editTitle()}
          subtitle={viewModel.scheduleName}
          actions={
            !viewModel.isNewPeriod && !viewModel.isReadOnly
              ? [
                  {
                    icon: <DeleteRounded />,
                    tooltip: strings.deleteButtonTooltip(),
                    onClick: () => setShowDeleteConfirmationDialog(true)
                  }
                ]
              : []
          }
        />

        <Box flex={1} overflow="hidden">
          <List sx={{ height: '100%', width: '100%', overflow: 'auto', p: 2 }} dense>
            <Stack spacing={2}>
              <TextField
                label={strings.labelInputLabel()}
                value={viewModel.periodLabel}
                onChange={(e) => (viewModel.periodLabel = e.target.value)}
                size="small"
                inputProps={{ sx: { ...theme.typography.body2 } }}
                disabled={viewModel.isReadOnly}
              />

              <Stack direction="row" spacing={1}>
                <TimePicker
                  sx={{ flex: 1 }}
                  value={startTime}
                  label={strings.startTimeLabel()}
                  onChange={(v) => (viewModel.startTime = dateToTimeOfDay(v!))}
                  timeSteps={{ minutes: 1 }}
                  disabled={viewModel.isReadOnly}
                />
                <TimePicker
                  sx={{ flex: 1 }}
                  value={endTime}
                  label={strings.endTimeLabel()}
                  onChange={(v) => (viewModel.endTime = dateToTimeOfDay(v!))}
                  timeSteps={{ minutes: 1 }}
                  disabled={viewModel.isReadOnly}
                />
              </Stack>

              {!viewModel.isTimeValid && (
                <Alert variant="standard" severity="warning">
                  {strings.invalidTimesWarning()}
                </Alert>
              )}

              {viewModel.hasConflict && (
                <Alert variant="standard" severity="warning">
                  {strings.hasConflictWarning()}
                </Alert>
              )}
            </Stack>

            {!viewModel.isReadOnly && viewModel.suggestions.length > 0 && (
              <Stack mt={1}>
                {!showSuggestions && (
                  <Button size="small" onClick={() => setShowSuggestions(true)}>
                    {strings.showTimeSuggestionsButtonTitle()}
                  </Button>
                )}

                {showSuggestions && (
                  <>
                    <Box sx={{ height: 40, overflow: 'hidden' }}>
                      <ListSubheader>{strings.timeSuggestionsTitle()}</ListSubheader>
                    </Box>

                    {viewModel.suggestions.map((suggestion) => (
                      <ScheduleCyclePeriodEditSuggestion
                        key={`${format(timeOfDayToDate(suggestion.startTime!), 'p')} - ${format(
                          timeOfDayToDate(suggestion.endTime!),
                          'p'
                        )}`}
                        suggestion={suggestion}
                        onClick={(suggestion) => viewModel.applySuggestion(suggestion)}
                      />
                    ))}
                    <Button size="small" onClick={() => setShowSuggestions(false)}>
                      {strings.hideTimeSuggestionsButtonTitle()}
                    </Button>
                  </>
                )}
              </Stack>
            )}

            {viewModelSupportsActivity(viewModel) && (
              <Stack mt={3} spacing={1}>
                <Stack px={1}>
                  <Typography variant="body1" fontWeight="600">{`${
                    viewModel.cycleDayTitle
                  } - ${strings.activitySchedulePeriodLabel(viewModel.periodLabel || '?')}`}</Typography>
                </Stack>
                <Stack spacing={1}>
                  {viewModel.terms.some((t) => t.isConflict) && (
                    <Alert severity="warning">{strings.activitySchedulesConflict()}</Alert>
                  )}

                  <Stack spacing={2}>
                    {viewModel.terms.map((termInfo) => (
                      <ScheduleCyclePeriodEditTermActivities
                        key={termInfo.term.id || 'entire-year'}
                        termInfo={termInfo}
                        courseSections={viewModel.allActivities}
                        displayLabel={viewModel.terms.length > 1}
                        getCourseSectionForActivity={(a) => viewModel.getCourseSectionForActivity(a)}
                        createActivitySchedule={(termId, courseId) =>
                          viewModel.createActivitySchedule(termId, courseId)
                        }
                      />
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            )}
          </List>

          {showDeleteConfirmationDialog && (
            <ConfirmationDialog
              isOpen={true}
              title={strings.deleteConfirmationTitle()}
              message={strings.deleteConfirmationMessage()}
              confirmButtonLabel={strings.deleteConfirmationSubmitButtonTitle()}
              isDestructive
              onSubmit={(hasConfirmed) => void deletePeriodSchedule(hasConfirmed)}
            />
          )}
        </Box>

        {saveError && (
          <Alert variant="filled" severity="error" sx={{ mx: 2, my: 1 }} onClose={() => setSaveError(undefined)}>
            {strings.saveErrorMessage()}
          </Alert>
        )}

        <FormPopoverActions
          onCancel={() => {
            dismiss?.();
            return Promise.resolve();
          }}
          onSubmit={save}
          canSubmit={viewModel.canSave}
        />
      </Stack>
    );
  }
);
