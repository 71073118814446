import {
  UserDashboardCalendarItemState,
  UserDashboardCalendarPublishedWorkInfo,
  UserDashboardCalendarWorkInfo
} from '@/viewmodels';
import { Box, Stack, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { observer } from 'mobx-react-lite';
import { ColorIndicator, WorkIcon } from '../../../../lists';
import { ContextMenuHandler } from '../../../ContextMenuHandler';

export interface UserDashboardCalendarWeekPeriodWorkProps {
  sx?: SxProps;
  className?: string;
  work: UserDashboardCalendarWorkInfo | UserDashboardCalendarPublishedWorkInfo;
}

export const UserDashboardCalendarWeekPeriodWork = observer(
  ({ sx, className, work }: UserDashboardCalendarWeekPeriodWorkProps) => {
    const hasDescription = work.description.length > 0;
    const colorIndicatorPadding = hasDescription ? 0.5 : '2px';

    return (
      <ContextMenuHandler actions={work.contextMenuActions()}>
        {(contextMenuHandler) => (
          <Stack
            sx={sx}
            className={className}
            direction="row"
            alignItems={hasDescription ? 'stretch' : 'center'}
            spacing={0.5}
            overflow="hidden"
            onContextMenu={contextMenuHandler}
          >
            <Box sx={{ height: '100%', py: colorIndicatorPadding }}>
              <ColorIndicator isRounded color={work.color} sx={{ height: '100%' }} />
            </Box>

            <WorkIcon icon={work.icon} size={14} sx={{ py: hasDescription ? colorIndicatorPadding : undefined }} />

            <Stack flex={1} spacing={-0.5} overflow="hidden">
              <Typography fontSize={12} fontWeight="500" noWrap color={getColorForTitle(work.state)}>
                {work.title}
              </Typography>

              {hasDescription && (
                <Typography fontSize={10} noWrap>
                  {work.description}
                </Typography>
              )}
            </Stack>
          </Stack>
        )}
      </ContextMenuHandler>
    );
  }
);

function getColorForTitle(state: UserDashboardCalendarItemState) {
  switch (state) {
    case 'completed':
      return 'textSecondary';
    case 'late':
      return 'error';
    default:
      return 'textPrimary';
  }
}
