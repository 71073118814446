import { CourseSectionColors } from '@/models';
import { ServiceContainer } from '@/providers';
import { LocalizationService, ThemeService } from '@/services';
import { LoadableState } from '@/stores';
import { action, computed, makeObservable, observable, override, runInAction } from 'mobx';
import { hex, score } from 'wcag-contrast';
import {
  AppBaseUpdatableDialogViewModel,
  CancelDialogActionButtonConfiguration,
  DialogActionButtonConfiguration,
  SaveDialogActionButtonConfiguration,
  UpdatableDialogViewModel
} from '../../utils';

export interface CourseSectionEditInfo {
  readonly title: string;
  readonly section: string;
  readonly color: string;
  readonly hasExternalSource: boolean;
  readonly syncToken: bigint;
}

export interface CourseSectionEditViewModel extends UpdatableDialogViewModel {
  readonly isApplying: boolean;
  readonly hasChanges: boolean;
  readonly isBackgroundColorLowContrast: boolean;
  readonly backgroundColorPalette: string[];
  readonly isBackgroundColorInPalette: boolean;
  readonly canEditNames: boolean;
  title: string;
  section: string;
  backgroundColor: string | undefined;

  save(): Promise<void>;
}

export abstract class AppBaseCourseSectionEditViewModel
  extends AppBaseUpdatableDialogViewModel
  implements CourseSectionEditViewModel
{
  @observable protected _state: LoadableState = 'pending';
  @observable protected _isApplying = false;
  @observable protected _initialData: CourseSectionEditInfo | undefined;
  @observable protected _title: string | undefined;
  @observable protected _section: string | undefined;
  @observable protected _color: string | undefined;

  private _saveButtonConfig = new SaveDialogActionButtonConfiguration('main', this._localization, () => this.save());
  private _cancelButtonConfig = new CancelDialogActionButtonConfiguration('main', this._localization, () =>
    this.dismiss()
  );

  protected constructor(
    protected readonly _courseSectionId: string | undefined,
    protected readonly _dashboardId: string,
    private readonly _onSuccess: (courseSectionId: string) => Promise<void>,
    onCancel: () => Promise<void>,
    localization: LocalizationService = ServiceContainer.services.localization,
    private readonly _theme: ThemeService = ServiceContainer.services.theme
  ) {
    super(localization, onCancel);
    makeObservable(this);
  }

  @computed
  private get canSave(): boolean {
    return this.hasChanges && this.title.length > 0 && this.backgroundColor != null && this.backgroundColor.length > 0;
  }

  @override
  get actions(): DialogActionButtonConfiguration[] {
    this._cancelButtonConfig.isEnabled = !this.isApplying;
    this._saveButtonConfig.isEnabled = !this.isApplying && this.canSave;
    this._saveButtonConfig.showLoading = this.isApplying;
    return [this._cancelButtonConfig, this._saveButtonConfig];
  }

  abstract get canEditNames(): boolean;

  @computed
  get title(): string {
    return this._title!;
  }

  set title(value: string) {
    this._title = value;
  }

  @computed
  get section(): string {
    return this._section!;
  }

  set section(value: string) {
    this._section = value;
  }

  @computed
  get backgroundColor(): string | undefined {
    return this._color;
  }

  set backgroundColor(value: string | undefined) {
    this._color = value;
  }

  @computed
  get state(): LoadableState {
    return this._state;
  }

  @computed
  get hasData(): boolean {
    return this._initialData != null;
  }

  @computed
  get isApplying(): boolean {
    return this._isApplying;
  }

  @computed
  get isSubmitting(): boolean {
    return this.isApplying;
  }

  @computed
  get hasChanges(): boolean {
    return (
      this._initialData?.color !== this._color ||
      this._initialData?.title !== this._title ||
      this._initialData?.section !== this._section
    );
  }

  @computed
  get isBackgroundColorLowContrast(): boolean {
    if (this.backgroundColor == null) {
      return false;
    }

    const contrast = hex(this.backgroundColor, this._theme.variant === 'dark' ? '#000' : '#fff');
    return score(contrast) === 'Fail';
  }

  get backgroundColorPalette(): string[] {
    return CourseSectionColors;
  }

  @computed
  get isBackgroundColorInPalette(): boolean {
    return (
      this.backgroundColorPalette.find((value) => value.toLowerCase() === this.backgroundColor?.toLowerCase()) != null
    );
  }

  async reloadData() {
    await this.loadData();
  }

  @action
  async save() {
    // Section can be empty.
    if (!(this.title.length > 0 && this.backgroundColor != null && this.backgroundColor.length > 0)) {
      throw new Error('Trying to save CourseSection with an empty title or backgroundColor.');
    }

    try {
      this._error = undefined;
      this._isApplying = true;

      const courseId = await (this._courseSectionId == null
        ? this.createCourseSection()
        : this.updateCourseSection(this._courseSectionId));

      runInAction(() => (this._isApplying = false));
      await this._onSuccess(courseId);
    } catch (e) {
      runInAction(() => {
        this._error = (e as Error).message;
        this._isApplying = false;
      });
    }
  }

  protected abstract createCourseSection(): Promise<string>;

  protected abstract updateCourseSection(id: string): Promise<string>;

  protected abstract loadData(): Promise<void>;
}
