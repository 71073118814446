import { IconButton, Stack, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactNode } from 'react';
import { Subheader } from '../../utils';

export interface SideBarSectionProps {
  sx?: SxProps;
  className?: string;
  children: ReactNode;
  title?: string;
  disableTopPadding?: boolean;
  isCompact: boolean;
  action?: { icon: (sx: SxProps) => ReactNode; onClick: () => void; tooltip?: string };
}

export function SideBarSection({
  sx,
  className,
  children,
  title,
  disableTopPadding = false,
  isCompact,
  action
}: SideBarSectionProps) {
  return (
    <Stack
      sx={sx}
      className={className}
      px={1}
      pt={title != null || disableTopPadding ? 0 : isCompact ? 1 : 2}
      pb={isCompact ? 1 : 2}
    >
      {(title != null || (action != null && !isCompact)) && (
        <Stack direction="row" spacing={1} alignItems="center" justifyContent="flex-end">
          {title != null && <Subheader sx={{ flex: 1 }}>{title}</Subheader>}

          {action != null && !isCompact && (
            <Tooltip title={action.tooltip} disableInteractive placement="right" arrow>
              <IconButton size="medium" onClick={() => action?.onClick()}>
                {action.icon({ fontSize: 'medium' })}
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      )}
      {children}
    </Stack>
  );
}
