import { useServices } from '@/hooks';
import { ConfirmationDialogChoice } from '@/viewmodels';
import { CourseSectionDetails } from '@buf/studyo_studyo-today-planners.bufbuild_es/studyo/today/planners/v1/resources/course_section_details_pb';
import { DeleteRounded, EditRounded } from '@mui/icons-material';
import { ListItem, ListItemIcon, ListItemText, MenuItem, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { useRef, useState } from 'react';
import { ColorIndicator } from '../../lists';
import { ActionMenu, ActionMenuButton, ConfirmationDialogWithChoices } from '../../utils';

export interface CourseSectionListItemProps {
  sx?: SxProps;
  className?: string;
  courseSection: CourseSectionDetails;
  canEdit: boolean;
  editCourse: (courseSection: CourseSectionDetails) => void;
  deleteCourse: (courseSection: CourseSectionDetails, shouldAlsoCancelWorks: boolean) => void;
}

export function CourseSectionListItem({
  sx,
  className,
  deleteCourse,
  courseSection,
  editCourse,
  canEdit
}: CourseSectionListItemProps) {
  const { localization } = useServices();
  const strings = localization.localizedStrings.courseSection.lists;
  const theme = useTheme();

  const [showDeleteConfirmAlert, setShowDeleteConfirmAlert] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const menuAnchorRef = useRef<HTMLButtonElement>(null);

  const keepWorksOptionKey = 'keep-associated-works';
  const deleteConfirmAlertChoices: ConfirmationDialogChoice[] = [
    { key: keepWorksOptionKey, title: strings.deleteConfirmKeepWorks }
  ];

  function onSubmitDeleteConfirmation(result: string[] | undefined) {
    setShowDeleteConfirmAlert(false);
    if (result != null) {
      deleteCourse(courseSection, result.includes(keepWorksOptionKey));
    }
  }

  return (
    <>
      <ListItem sx={sx} className={className}>
        <ColorIndicator
          color={courseSection.courseSection!.color}
          sx={{ height: courseSection.courseSection!.externalSource != null ? 40 : 30, mr: 2 }}
        />
        <ListItemText primary={courseSection.courseSection!.title} secondary={courseSection.courseSection!.section} />

        {canEdit && (
          <>
            <ActionMenuButton ref={menuAnchorRef} onClick={() => setShowMenu(true)} />
            <ActionMenu open={showMenu} anchorEl={menuAnchorRef.current} onClose={() => setShowMenu(false)}>
              <MenuItem
                onClick={() => {
                  setShowMenu(false);
                  editCourse(courseSection);
                }}
              >
                <ListItemIcon>
                  <EditRounded />
                </ListItemIcon>
                <ListItemText>{strings.edit}</ListItemText>
              </MenuItem>

              {courseSection.courseSection?.externalSource == null && (
                <MenuItem
                  onClick={() => {
                    setShowMenu(false);
                    setShowDeleteConfirmAlert(true);
                  }}
                >
                  <ListItemIcon>
                    <DeleteRounded color={'error'} />
                  </ListItemIcon>
                  <ListItemText primaryTypographyProps={{ color: theme.palette.error.main }}>
                    {strings.delete}
                  </ListItemText>
                </MenuItem>
              )}
            </ActionMenu>
          </>
        )}
      </ListItem>

      {showDeleteConfirmAlert && (
        <ConfirmationDialogWithChoices
          isOpen={true}
          title={strings.deleteConfirmTitle}
          message={strings.deleteConfirmMessage}
          confirmButtonLabel={strings.deleteConfirmButton}
          choices={deleteConfirmAlertChoices}
          onSubmit={onSubmitDeleteConfirmation}
        />
      )}
    </>
  );
}
